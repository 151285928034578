import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { EntityFilter } from '../models/EntityList';
import { setLoading, setError } from './Site';
import { updateList } from '../Utilities'
import { SelectOption, AllOption, AllGuidOption, EmptyGuid } from '../models/Common'
import { CertificationInfo } from '../models/Certification'

export interface CertificationsState {
    entityType: string,
    languageCode: string,
    countryCode: string,
    metadataDescription: string,
    metadataKeywords: string,
    view: string,
    keywords: string,
    language?: string,
    status: string,
    views: SelectOption[];
    types: SelectOption[];
    technologies: SelectOption[];
    languages: SelectOption[];
    levels: SelectOption[];
    entities: CertificationInfo[];
}

let initialState: CertificationsState = {
    entityType: '',
    languageCode: 'US',
    countryCode: 'en',
    metadataDescription: '',
    metadataKeywords: '',
    view: 'all',
    keywords: '',
    language: undefined,
    status: 'Active',
    views: [AllOption],
    types: [AllGuidOption],
    technologies: [AllGuidOption],
    languages: [AllGuidOption],
    levels: [AllGuidOption],
    entities: []
}

export const certificationsSlice = createSlice({
    name: 'Certifications',
    initialState,
    reducers: {
        setCertifications: (state, action) => {
            state.entities = action.payload;
        },
        setCertificationTechnologies: (state, action) => {
            state.technologies = action.payload;
        },
        setCertificationLevels: (state, action) => {
            state.levels = action.payload;
        },
        setCertificationLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setCertificationTypes: (state, action) => {
            state.types = action.payload;
        },
        setCertificationViews: (state, action) => {
            state.views = action.payload;
        },
        setCertificationSelected: (state, action) => {
            let values: string[] = action.payload.split('-');
            let index = parseInt(values[1]);
            let checked = values[2] === 'true';

            switch (values[0]) {
                case 'views': {
                    state.views = updateList(state.views, index, checked, false);
                    break;
                }
                case 'types': {
                    state.types = updateList(state.types, index, checked);
                    break;
                }
                case 'languages': {
                    state.languages = updateList(state.languages, index, checked);
                    break;
                }
                case 'technologies': {
                    state.technologies = updateList(state.technologies, index, checked);
                    break;
                }
                case 'levels': {
                    state.levels = updateList(state.levels, index, checked);
                    break;
                }
            }
        },
        setCertificationView: (state, action) => {
            state.view = action.payload;
        },
        setCertificationStatus: (state, action) => {
            state.status = action.payload;
        },
        setCertificationKeywords: (state, action) => {
            state.keywords = action.payload;
        }
    }
});

export const { setCertifications, setCertificationSelected, setCertificationView, setCertificationKeywords, setCertificationTechnologies,
    setCertificationLevels, setCertificationLanguages, setCertificationTypes, setCertificationViews, setCertificationStatus } = certificationsSlice.actions;

export const retrieveCertifications = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let state = getState();
    let certifications = state.certifications;
    let request: EntityFilter = {
        types: [EmptyGuid],
        languages: [EmptyGuid],
        technologies: [EmptyGuid],
        view: certifications.view,
        status: certifications.status,
        keywords: certifications.keywords,
        language: certifications.language ?? language
    }

    dispatch(setLoading(true));
    fetch('api/certifications/list', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCertifications(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveTechnologies = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/certifications/technologies', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCertificationTechnologies(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveTypes = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/certifications/types', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCertificationTypes(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveViews = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/certifications/views', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCertificationViews(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export default certificationsSlice.reducer;
