import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair, SelectOption } from '../models/Common';
import { Box, Paragraph, Image, Heading, Text, Select, Anchor, Form, FormField, TextInput, TextArea, Button } from 'grommet';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, submitSupportForm, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    submitSupportForm,
    ...siteSlice.actions,
}

interface ISupportProps {
}

interface SupportState {
    profile: SelectOption | undefined;
    region: SelectOption | undefined;
    contactInfo: any;
    showThankYou: boolean;
}

type SupportProps = ISupportProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Support extends React.PureComponent<SupportProps, SupportState> {
    constructor(props: SupportProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.HomePage_Welcome', 'Metadata.HomePageKeywords',
            'Metadata.HomePageDescription', 'Datacard.HPE_Learner_ID_Label', 'ExternalPages.Support_Intro_Statement', 'ExternalPages.Customer_Label',
            'ExternalPages.Hpe_Employee_Label', 'ExternalPages.Ask_HR_Label', 'ExternalPages.Hpe_Partner_Label', 'ExternalPages.Region_AP_Label',
            'ExternalPages.Region_APJ_Label', 'ExternalPages.Region_APK_Label', 'ExternalPages.Region_EMEA_Label', 'ExternalPages.Region_LA_Label',
            'ExternalPages.Region_NA_Label', 'ExternalPages.Select_Profile_Label', 'ExternalPages.Select_Region_Label', 'ExternalPages.Support_Hours_AP',
            'ExternalPages.Support_Hours_EMEA', 'ExternalPages.Support_Hours_NA', 'ExternalPages.Support_Hours_LA', 'ExternalPages.Support_Hours_Email_Response',
            'ExternalPages.Support_Header_Box1_Title', 'ExternalPages.Support_Header_Box2_Title', 'ExternalPages.Support_Header_Box3_Title',
            'ExternalPages.Support_Header_Box1_Statement', 'ExternalPages.Support_Header_Box2_Statement', 'ExternalPages.Support_Header_Box3_Statement',
            'ExternalPages.Support_FirstName_Label', 'ExternalPages.Support_LastName_Label', 'ExternalPages.Support_Email_Label',
            'ExternalPages.Support_Employee_label', 'ExternalPages.Support_LearnerId_Label', 'ExternalPages.Support_Explain_Label',
            'ExternalPages.Support_Information_Statement', 'ExternalPages.Support_Submit_Statement', 'ExternalPages.Support_ThankYou_Title',
            'ExternalPages.Support_ThankYou_Statement1', 'ExternalPages.Support_ThankYou_Statement2', 'ExternalPages.Support_ThankYou_Statement3',
            'ExternalPages.Support_ThankYou_Signature', 'ExternalPages.Support_Partner_Delivery_Ready', 'ExternalPages.HPE_Partners_Curtomers_Label',
            'ExternalPages.Cancel_Label', 'ExternalPages.Submit_Label'];
        let profile = this.props.match
            ? this.props.match.params.profile
                ? this.props.match.params.profile
                : undefined
            : undefined;

        this.state = {
            profile: profile === "partner" ? { text: "HPE Partner", value: "partner", selected: false } : undefined,
            region: undefined,
            contactInfo: undefined,
            showThankYou: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('help-menu');
        this.props.setLoading(true);
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onProfileChange = (profile: any) => {
        if (profile.value.value === 'employee') {
            window.location.href = "https://myitsupport.ext.hpe.com/HRHome";
        }
        else {
            this.setState({ profile: profile.value });
        }
    }

    onRegionChange = (region: any) => {
        this.setState({ region: region.value });
    }

    onSubmit = () => {
        let email = 'psc.qualifications@hpe.com';
        let region = "NA";
        let form = JSON.parse(JSON.stringify(this.state.contactInfo));

        switch (this.state.region?.value) {
            case 'emea':
                email = this.state.profile?.value === "partner"
                    ? 'psc.qualifications@hpe.com'
                    : 'clsupport.emea@hpe.com';
                region = "EMEA";
                break;
            case 'la':
                email = 'clsupport.lar@hpe.com';
                region = "LAC";
                break;
            case 'na':
                email = this.state.profile?.value === "partner"
                    ? 'psc.qualifications@hpe.com'
                    : 'clsupport.na@hpe.com';
                region = "NA";
                break;
            case 'apj':
                email = this.state.profile?.value === "partner"
                    ? 'psc.qualifications@hpe.com'
                    : 'clsupport.ap@hpe.com';
                region = "APJ";
                break;
            case 'apk':
                email = 'clsupport.kr@hpe.com';
                region = "APJ";
                break;
            case 'ap':
                email = this.state.profile?.value === "partner"
                    ? 'psc.qualifications@hpe.com'
                    : 'clsupport.ap@hpe.com';
                region = "APJ";
                break;
        }
        form.toEmail = email;
        form.region = region;
        this.props.submitSupportForm(form, this.showThankyou);
    }

    showThankyou = () => {
        this.setState({ showThankYou: true });
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Help_Label'], value: "" },
            { key: site.menuStrings['ExternalPages.Contact_Support_Label'], value: "" }
        ];
        let title = !this.state.showThankYou
            ? site.menuStrings['ExternalPages.Contact_Support_Label']
            : site.localizedStrings['ExternalPages.Support_ThankYou_Title'];
        let showRegion = this.state.profile?.value !== 'employee';
        let isPartner = this.state.profile?.value == 'partner';
        let employee = site.localizedStrings['ExternalPages.Hpe_Employee_Label'];
        let customer = site.localizedStrings['ExternalPages.HPE_Partners_Curtomers_Label'];
        let partner = site.localizedStrings['ExternalPages.Support_Partner_Delivery_Ready'];
        let profiles = [
            { text: partner, value: "partner", selected: false },
            { text: customer, value: "customer", selected: false },
            { text: employee, value: "employee", selected: false }
        ];
        let regions = isPartner
            ? [{ text: site.localizedStrings['ExternalPages.Region_AP_Label'], value: "ap" },
            { text: site.localizedStrings['ExternalPages.Region_EMEA_Label'], value: "emea" },
            { text: site.localizedStrings['ExternalPages.Region_NA_Label'], value: "na" }]
            : [{ text: site.localizedStrings['ExternalPages.Region_AP_Label'], value: "ap" },
            { text: site.localizedStrings['ExternalPages.Region_APJ_Label'], value: "apj" },
            { text: site.localizedStrings['ExternalPages.Region_APK_Label'], value: "apk" },
            { text: site.localizedStrings['ExternalPages.Region_EMEA_Label'], value: "emea" },
            { text: site.localizedStrings['ExternalPages.Region_LA_Label'], value: "la" },
            { text: site.localizedStrings['ExternalPages.Region_NA_Label'], value: "na" }];
        let hours = site.localizedStrings['ExternalPages.Support_Hours_AP'];

        switch (this.state.region?.value) {
            case 'emea':
                hours = site.localizedStrings['ExternalPages.Support_Hours_EMEA'];
                break;
            case 'la':
                hours = site.localizedStrings['ExternalPages.Support_Hours_LA'];
                break;
            case 'na':
                hours = site.localizedStrings['ExternalPages.Support_Hours_NA'];
                break;
            case 'apj':
                hours = site.localizedStrings['ExternalPages.Support_Hours_AP'];
                break;
            case 'apk':
                hours = site.localizedStrings['ExternalPages.Support_Hours_AP'];
                break;
            case 'ap':
                hours = site.localizedStrings['ExternalPages.Support_Hours_AP'];
                break;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="Contact_Support" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.HomePageKeywords']}
            metadataDescription={site.localizedStrings['Metadata.HomePageDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad="small">
                <Box fill="horizontal">
                    <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                </Box>
                {!this.state.region && <Box alignSelf="center" >
                    <Paragraph textAlign="center" fill>
                        {parse(formatString(site.localizedStrings['ExternalPages.Support_Intro_Statement'], [`${localUrl}/assistkit`]))}
                    </Paragraph>
                </Box>}
                {!this.state.region && <Box direction="row" pad={{ top: "medium" }} gap="medium">
                    <Box width="medium">
                        <Select options={profiles} value={this.state.profile} onChange={this.onProfileChange} valueKey="value" labelKey="text"
                            placeholder={site.localizedStrings['ExternalPages.Select_Profile_Label']} />
                    </Box>
                    {showRegion && <Box width="medium">
                        <Select options={regions} value={this.state.region} onChange={this.onRegionChange} valueKey="value" labelKey="text"
                            placeholder={site.localizedStrings['ExternalPages.Select_Region_Label']} />
                    </Box>}
                </Box>}
                <Box>
                    {this.state.region && !this.state.showThankYou && <Box fill>
                        {this.state.region && !isPartner && <Box gap="medium">
                            <Text weight="bold" textAlign="center" size="large">{this.state.region.text}</Text>
                            <Box gap="xsmall" pad="medium">
                                <Text textAlign="center">{hours}</Text>
                                <Text textAlign="center">{site.localizedStrings['ExternalPages.Support_Hours_Email_Response']}</Text>
                            </Box>
                        </Box>}
                        <Box direction="row" gap="medium" align="center" fill="horizontal" justify="center">
                            <Box width="medium" height="xsmall" border={{ side: 'all', size: "medium", color: '#CCCCCC' }} gap="xxsmall" pad="small">
                                <Text weight="bold" textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box1_Title']}</Text>
                                <Text textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box1_Statement']}</Text>
                            </Box>
                            <Box width="medium" height="xsmall" border={{ side: 'all', size: "medium", color: '#CCCCCC' }} gap="xxsmall" pad="small">
                                <Text weight="bold" textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box2_Title']}</Text>
                                <Text textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box2_Statement']}</Text>
                            </Box>
                            <Box width="medium" height="xsmall" border={{ side: 'all', size: "medium", color: '#CCCCCC' }} gap="xxsmall" pad="small">
                                <Text weight="bold" textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box3_Title']}</Text>
                                <Anchor alignSelf="center" href="https://www.hpe.com/us/en/legal/privacy.html" target="_blank"
                                    label={site.localizedStrings['ExternalPages.Support_Header_Box3_Statement']} />
                            </Box>
                        </Box>
                        <Box width={{ max: "xlarge" }} alignSelf="center">
                            <Form onSubmit={this.onSubmit} value={this.state.contactInfo}
                                onChange={nextValue => this.setState({ contactInfo: nextValue })}>
                                <Box gap="medium" pad={{ top: "large" }}>
                                    <Box direction="row-responsive" gap="medium">
                                        <FormField fill name="firstName" htmlFor="firstName-input" required
                                            label={site.localizedStrings['ExternalPages.Support_FirstName_Label']} >
                                            <TextInput id="firstName-input" name="firstName" />
                                        </FormField>
                                        <FormField fill name="LastName" htmlFor="lastName-input" required
                                            label={site.localizedStrings['ExternalPages.Support_LastName_Label']} >
                                            <TextInput id="lastName-input" name="LastName" />
                                        </FormField>
                                    </Box>
                                    <Box direction="row-responsive" gap="medium">
                                        <FormField fill name="email" htmlFor="email-input" required
                                            label={site.localizedStrings['ExternalPages.Support_Email_Label']} >
                                            <TextInput id="email-input" name="email" />
                                        </FormField>
                                        <Box fill>
                                        </Box>
                                    </Box>
                                    <Box direction="row-responsive" gap="medium">
                                        <FormField fill name="companyName" htmlFor="company-input" required
                                            label={site.localizedStrings['ExternalPages.Support_Employee_label']} >
                                            <TextInput id="company-input" name="companyName" />
                                        </FormField>
                                        <FormField fill name="learnerId" htmlFor="learnerId-input"
                                            label={site.localizedStrings['ExternalPages.Support_LearnerId_Label']}  >
                                            <TextInput id="learnerId-input" name="learnerId" />
                                        </FormField>
                                    </Box>
                                    <FormField fill name="explanation" htmlFor="explination-input" required
                                        label={site.localizedStrings['ExternalPages.Support_Explain_Label']}>
                                        <TextArea id="explination-input" name="explanation"></TextArea>
                                    </FormField>
                                    <Paragraph fill>
                                        {parse(site.localizedStrings['ExternalPages.Support_Information_Statement'])}
                                    </Paragraph>
                                    <Paragraph fill>
                                        {parse(site.localizedStrings['ExternalPages.Support_Submit_Statement'])}
                                    </Paragraph>
                                    <Box direction="row-responsive" gap="small" justify="end" fill>
                                        <Button secondary label={site.localizedStrings['ExternalPages.Cancel_Label']}
                                            onClick={() => { this.setState({ profile: undefined, region: undefined }) }} />
                                        <Button primary type="submit" label={site.localizedStrings['ExternalPages.Submit_Label']} />
                                    </Box>
                                </Box>
                            </Form>
                        </Box>
                    </Box>}
                    {this.state.showThankYou && <Box fill>
                        <Box width={{ max: "xlarge" }} alignSelf="center" gap="small">
                            <Paragraph fill>{site.localizedStrings['ExternalPages.Support_ThankYou_Statement1']}</Paragraph>
                            <Paragraph fill>{site.localizedStrings['ExternalPages.Support_ThankYou_Statement2']}</Paragraph>
                            <Paragraph fill>{parse(formatString(site.localizedStrings['ExternalPages.Support_ThankYou_Statement3'],
                                [`${localUrl}/support`]))}</Paragraph>
                            <Paragraph fill>{parse(site.localizedStrings['ExternalPages.Support_ThankYou_Signature'])}</Paragraph>
                        </Box>
                    </Box>}
                </Box>
            </Box>}
        </BasePage>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Support as any));
