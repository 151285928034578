import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { SuccessStory } from '../models/SuccessStory';
import { setLoading, setError } from './Site';

export interface SuccessStoriesState {
    data: SuccessStory[] | undefined;
}

let initialState: SuccessStoriesState = {
    data: undefined
}

export const successStoriesSlice = createSlice({
    name: 'SuccessStories',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setData } = successStoriesSlice.actions;

export const retrieveSuccessStories = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language != '' ? language : null
    }

    dispatch(setLoading(true));
    fetch('api/list/successStories', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
        dispatch(setLoading(false));
    });
}

export default successStoriesSlice.reducer;
