import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair, SelectOption } from '../../models/Common';
import {
    Box, Heading, Text, RadioButtonGroup, Select, Form, FormField,
    TextInput, Table, TableBody, TableHeader, TableRow, TableCell, Tip, Anchor
} from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveCountries, retrieveSigningEntities, retrieveGapSummary, exportSummaries, plmtSlice } from '../../store/Plmt';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { hasRole, isPLMTAdmin, getPLMTAdminRegions, formatString } from '../../Utilities';
import { firstBy } from 'thenby';
import { Search, Share, CircleInformation, StatusCritical, StatusGood } from 'grommet-icons';
import { GapSummary as SummaryModel } from '../../models/plmt/GapSummary';
import { SigningEntityInfo } from '../../models/plmt/SigningEntityInfo';
import parse from 'html-react-parser';
import { format, add } from 'date-fns';

const mapDispatchToProps = {
    retrieveCountries,
    retrieveGapSummary,
    retrieveSigningEntities,
    exportSummaries,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
    ...plmtSlice.actions
}

interface IGapSummaryProps {
}

interface IGapSummaryState {
    region?: string;
    country?: SelectOption;
    countries: SelectOption[];
    signingEntities?: SigningEntityInfo[];
    filters?: any;
    filteredResults?: SummaryModel[];
    programs: string[];
    loadingSummary: boolean;
    noSigningEnity: boolean;
}

type GapSummaryProps = IGapSummaryProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class GapSummary extends React.PureComponent<GapSummaryProps, IGapSummaryState> {
    constructor(props: GapSummaryProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label', 'MyLearning.SigningEntity_Tooltip',
            'MyLearning.PLMT_Title', 'MyLearning.Region_Label', 'ExternalPages.Yes_Label', 'ExternalPages.No_Label',
            'MyLearning.Country_Label', 'MyLearning.SigningEntity_Label', 'MyLearning.First_Name_Label', 'MyLearning.Last_Name_Label',
            'MyLearning.Activity_Code_Label', 'MyLearning.Activity_Name_Label', 'MyLearning.Date_Acquired_Label', 'MyLearning.Expires_Label',
            'MyLearning.Type_Label', 'MyLearning.Activities_Filters_Label', 'MyLearning.Export_Label', 'MyLearning.GapSummary_label',
            'MyLearning.PartnerType_Label', 'MyLearning.Program_Label', 'MyLearning.Proficency_Label', 'MyLearning.Completed_Label',
            'MyLearning.Required_Label', 'MyLearning.RequirementsGroup_Label', 'MyLearning.GapSummary_Label', 'MyLearning.Gap_Label',
            'MyLearning.Program_Type_Label', 'MyLearning.RequirementStatus_Label', 'MyLearning.RequirementDetails_Lael',
            'MyLearning.Not_Authorized_Statement', 'ExternalPages.Compliance_ChangeOver'];

        this.state = {
            countries: [],
            programs: [],
            loadingSummary: false,
            noSigningEnity: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveCountries();
        this.props.setActiveMenu('admin-tools-menu');
    }

    componentDidUpdate() {
        let actualUser = this.props.site.actualUser;
        let user = this.props.site.user;
        let isAdmin = isPLMTAdmin(user);
        let hasSwitched = actualUser?.learnerId !== user?.learnerId
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isDistributor = hasRole(user, ["DISTI"]);

        if (user && !isAdmin) {
            if (!this.state.filters?.region && this.props.plmt.countries) {
                let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);

                if (country) {
                    this.setState({ filters: { region: country?.region } });
                    this.onRegionChange({ target: { value: country?.region } });
                }
            }
            if (this.state.filters?.region && !this.state.filters?.country && this.state.countries) {
                let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);
                let filters = JSON.parse(JSON.stringify(this.state.filters));

                if (country) {
                    filters.country = {
                        text: country.name,
                        value: country.code,
                        selected: false
                    };
                    this.setState({ filters: filters, noSigningEnity: false });
                    this.onCountryChange({ value: { value: country?.code } })
                }
            }
            if (user?.partyId && this.props.plmt.signingEntities && this.state.filters?.region && this.state.filters?.country &&
                !this.state.filters?.signingEntity && !this.state.noSigningEnity) {
                let signingEntities = this.props.plmt.signingEntities;

                if (isDistributor && !isPPA && !isPEM) {
                    signingEntities = _.filter(signingEntities, (party) => party.partyId !== Number(user?.partyId))
                }

                if (signingEntities.length === 0 && !this.state.noSigningEnity) {
                    this.setState({ noSigningEnity: true });
                    this.props.setMessage("No authorized Signing Entity found");
                }
                else {
                    let signingEntity = signingEntities.length === 1
                        ? signingEntities[0]
                        : _.find(signingEntities, (party) => party.partyId == Number(user?.partyId));

                    if (signingEntity) {
                        this.onEntityChange({ value: signingEntity })
                    }
                }
            }
        }
        if (this.state.filters?.region && this.state.countries.length === 0) {
            let filters = JSON.parse(JSON.stringify(this.state.filters));

            this.onRegionChange({ target: { value: filters?.region } })
        }
    }

    onRegionChange = (event: any) => {
        let selected = event.target.value;
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[selected].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });
        filters.region = selected;
        delete filters.country;
        delete filters.signingEntity;
        delete filters.program;
        this.props.setGapSummaries(undefined);

        this.setState({ filters: filters, countries: countries.sort(firstBy(a => a.text)), filteredResults: undefined });
    }

    onCountryChange = (event: any) => {
        let selected = event.value;
        let country = _.find(this.props.plmt.countries, (country) => country.code == selected.value);
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[filters?.region].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });
        let request = {
            country: country,
            partyId: !isPLMTAdmin(this.props.site.user) ? this.props.site.user?.partyId : undefined
        }

        filters.country = selected;
        delete filters.signingEntity;
        delete filters.program;
        this.props.setGapSummaries(undefined);
        if (country) {
            this.props.retrieveSigningEntities(request);
        }
        this.setState({ filters: filters, signingEntities: undefined, countries: countries.sort(firstBy(a => a.text)) })
    }

    onEntityChange = (event: any) => {
        let signingEntity = event.value;
        let programs = [...signingEntity.programs];
        let program = 'All';
        let loadingSummary = false;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let request = {
            signingEntityId: signingEntity.partyId,
            country: filters.country?.value,
            region: filters.region
        };

        if (programs.length == 1) {
            program = programs[0];
        }
        else {
            programs.splice(0, 0, "All");
        }
        filters.program = program;
        filters.signingEntity = signingEntity;
        this.props.retrieveGapSummary(request);
        this.setState({
            signingEntities: undefined, programs: programs, filters: filters, loadingSummary: loadingSummary, filteredResults: undefined
        });
    }

    onCountrySearch = (search: string) => {
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[filters?.region].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });

        countries = countries.filter(country => country.value.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            country.text.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        this.setState({ countries: countries });
    }

    onEntitySearch = (search: string) => {
        let signingEntities = this.props.plmt.signingEntities ?? [];
        let entities = signingEntities.filter((entity) => {
            let partyId = `${entity.partyId}`.toLowerCase();

            return (entity.name && entity.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                (partyId.indexOf(search.toLowerCase()) !== -1);
        });

        this.setState({ signingEntities: entities });
    }

    getRegions = () => {
        let site = this.props.site;
        let user = site.user;
        let regionGroups = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let regions = _.map(regionGroups, (countries, region) => region).sort(firstBy(a => a)) ?? [];
        let isAdmin = isPLMTAdmin(user);
        let adminRegions = getPLMTAdminRegions(user);

        if (isAdmin) {
            regions = regions.filter((region) => _.some(adminRegions, (adminRegion) => adminRegion == region));
        }

        return regions;
    }

    onProgramChange = (event: any) => {
        let selected = event.value;
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let summaries: SummaryModel[] = this.props.plmt.gapSummaries ?? [];

        filters.program = selected;
        if (selected !== 'All') {
            summaries = summaries.filter((summary) => summary.program === selected);
        }
        this.setState({ filters: filters, filteredResults: summaries });
    }

    filterByKeywords = (keywords: string) => {
        let summaries: SummaryModel[] = this.props.plmt.gapSummaries ?? [];

        if (keywords) {
            let containsKeywords = keywords.toLowerCase();

            summaries = keywords.indexOf('"') === -1
                ? summaries.filter((summary) => summary.program.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    summary.requirementGroup.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    summary.proficiency.toLowerCase().indexOf(containsKeywords) !== -1)
                : summaries.filter((summary) => summary.program === keywords ||
                    summary.requirementGroup === keywords ||
                    summary.proficiency === keywords);
        }
        return summaries;
    }

    onKeywordsChange = (event: any) => {
        let keywords = event.target.value;
        let summaries: SummaryModel[] = this.filterByKeywords(keywords);

        this.setState({ filteredResults: summaries });
    }

    onExport = (event: any) => {
        let selected = event.value;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let exportRequest = {
            fileType: selected.value,
            signingEntityId: filters.signingEntity.partyId,
            country: filters.country?.value,
            program: filters.program,
            region: filters.region
        };

        this.props.exportSummaries(exportRequest);
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.GapSummary_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let regions = this.getRegions();
        let signingEntities = _.map(this.state.signingEntities ?? this.props.plmt.signingEntities ?? [], entity => {
            return {
                ...entity,
                name: parse(entity.name)
            }
        });
        let results: SummaryModel[] = this.state.filteredResults ?? this.props.plmt.gapSummaries ?? [];
        let canSearch = this.state.filters?.region && this.state.filters?.country && this.state.filters?.signingEntity;
        let disableFilters = results.length === 0;
        let exportOptions = [{
            value: "csv"
        }, {
            text: "Excel",
            value: "xlsx"
        }];
        let filters = this.state.filters;
        let isAdmin = isPLMTAdmin(user);
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isDistributor = hasRole(user, ["DISTI"]) && (isPEM || isPPA);
        let isPlmtUser = isAdmin || isPEM || isPPA || isDistributor;
        let sortOrder: any = { "Sales": 1, "Technical": 2, "Competency": 3 }
        let currentDate = new Date();
        let startDate = new Date();
        let endDate = new Date();
        let endDay = "22nd";

        startDate.setMonth(10);
        startDate.setDate(1);
        endDate.setMonth(10);
        endDate.setDate(22);
        if (!user) {
            return null;
        }
        if (user && !isPlmtUser) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }} height={{ min: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (currentDate >= startDate && currentDate <= endDate) {
            let nextFy = format(add(currentDate, {years: 1}), "yy");

            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }} height={{ min: "large" }}>
                <Text size='large' textAlign="center" weight="bold">
                    {formatString(site.localizedStrings['ExternalPages.Compliance_ChangeOver'], [nextFy, endDay])}
                </Text>
            </Box>;
        }
        if (results.length !== 0) {
            results = _.sortBy(results, [a => a.program, b => sortOrder[b.proficiency]]);
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="GapSummary" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'large' }}>
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={4}>{page}</Heading>
                <Form value={this.state.filters} onChange={nextValue => this.setState({ filters: nextValue })}>
                    <Box gap="medium">
                        <Box direction='row-responsive' gap="large">
                            <Box direction='row-responsive' gap="small" alignContent='start'>
                                <FormField label={<Text weight='bold'>{site.localizedStrings['MyLearning.Region_Label']}</Text>} name="region">
                                    <RadioButtonGroup name="region" id="region-input" options={regions} pad='small' disabled={!isAdmin}
                                        onChange={this.onRegionChange} />
                                </FormField>
                            </Box>
                            <Box gap="small">
                                <Box gap="small">
                                    <Text weight='bold'>{site.localizedStrings['MyLearning.Country_Label']}</Text>
                                    <Select name="country" id="country-input" options={this.state.countries} labelKey='text' valueKey='value'
                                        dropHeight='small' onChange={this.onCountryChange} onSearch={this.onCountrySearch}
                                        disabled={!isAdmin || this.state.filters?.region === undefined} />
                                </Box>
                                <Box gap="small" width="medium">
                                    <Box direction="row" gap="small">
                                        <Text weight='bold'>{site.localizedStrings['MyLearning.SigningEntity_Label']}</Text>
                                        <Tip content={site.localizedStrings['MyLearning.SigningEntity_Tooltip']}>
                                            <CircleInformation size="small" />
                                        </Tip>
                                    </Box>
                                    <Select name="signingEntity" id="signingEntity-input" options={signingEntities} labelKey='name' valueKey='partyId'
                                        dropHeight='small' onChange={this.onEntityChange} onSearch={this.onEntitySearch}
                                        disabled={(!isAdmin && !isDistributor) || this.state.filters?.country === undefined} />
                                </Box>
                                <Box gap="small">
                                    <Text weight='bold'>{site.localizedStrings['MyLearning.Program_Label']}</Text>
                                    <Select name="program" id="program-input" options={this.state.programs}
                                        dropHeight='small' onChange={this.onProgramChange}
                                        disabled={this.state.filters?.signingEntity === undefined} />
                                </Box>
                            </Box>
                        </Box>
                        <Box direction='row-responsive' gap="medium" pad={{ bottom: "small" }}>
                            <Box width="medium">
                                <TextInput name="keywords" id="keywords-input" placeholder="Search" reverse type="search" icon={<Search id="search-icon" />}
                                    onChange={this.onKeywordsChange} disabled={!canSearch} />
                            </Box>
                            <Select name="export" id="export-input" options={exportOptions} disabled={disableFilters} onChange={this.onExport}
                                placeholder={<Text>{site.localizedStrings['MyLearning.Export_Label']}</Text>} labelKey='text' valueKey='value' />
                        </Box>
                    </Box>
                </Form>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell size="medium">
                                <Text weight="bold">{site.localizedStrings['MyLearning.Program_Label']}</Text>
                            </TableCell>
                            <TableCell size="small">
                                <Text weight="bold">{site.localizedStrings['MyLearning.Program_Type_Label']}</Text>
                            </TableCell>
                            <TableCell size="small">
                                <Text weight="bold">{site.localizedStrings['MyLearning.Proficency_Label']}</Text>
                            </TableCell>
                            <TableCell size="medium">
                                <Text weight="bold">{site.localizedStrings['MyLearning.RequirementsGroup_Label']}</Text>
                            </TableCell>
                            <TableCell size="small">
                                <Text weight="bold">{site.localizedStrings['MyLearning.RequirementStatus_Label']}</Text>
                            </TableCell>
                            <TableCell size="small">
                                <Text weight="bold">{site.localizedStrings['MyLearning.RequirementDetails_Lael']}</Text>
                            </TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {_.map(_.groupBy(results, a => [a.program, a.partnerType]), (items, key) => {
                            let keys = key.split(",");
                            let programName = encodeURIComponent(keys[0]);

                            return <TableRow>
                                <TableCell size="medium" verticalAlign="middle" border="bottom" align="left">
                                    <Box direction="row" gap="xsmall" justify="start" align="center">
                                        {
                                            _.every(items, item => item.fulfilled >= item.required)
                                                ? <StatusGood color="brand" />
                                                : <StatusCritical color="red" />
                                        }
                                        <Anchor href={`${localUrl}/plmt/gap-analysis/${filters?.region}/${filters?.country.value}/${filters?.signingEntity.partyId}/${programName}`}
                                            target="_blank" label={keys[0]} reverse icon={<Share size="small" />} />
                                    </Box>
                                </TableCell>
                                <TableCell size="small" verticalAlign="middle" border="bottom">
                                    <Text>{keys[1]}</Text>
                                </TableCell>
                                <TableCell colSpan={4} border="bottom">
                                    {_.map(items, (item, ndx) => {
                                        return <Table>
                                            <TableBody>
                                                <TableRow key={`program-${ndx}`}>
                                                    <TableCell size="small">
                                                        <Text>{item.proficiency}</Text>
                                                    </TableCell>
                                                    <TableCell size="medium">
                                                        <Text>{item.requirementGroup}</Text>
                                                    </TableCell>
                                                    <TableCell size="small" align="center">
                                                        {
                                                            item.fulfilled >= item.required
                                                                ? <StatusGood color="brand" />
                                                                : <StatusCritical color="red" />
                                                        }
                                                    </TableCell>
                                                    <TableCell size="small" align="center">
                                                        <Text>{`${item.fulfilled} / ${item.required}`}</Text>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    })}
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(GapSummary as any));
