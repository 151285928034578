import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { WhatIsNew } from '../models/WhatIsNew';
import { setLoading, setError } from './Site';

export interface OverviewState {
    data: WhatIsNew[] | undefined;
}

let initialState: OverviewState = {
    data: undefined
}

export const overviewSlice = createSlice({
    name: 'Overview',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setData } = overviewSlice.actions;

export const retrieveWhatIsNew = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language != '' ? language : null
    }

    dispatch(setLoading(true));
    fetch('api/list/whatIsNew', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
        dispatch(setLoading(false));
    });
}

export default overviewSlice.reducer;
