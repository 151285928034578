import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Button, Anchor } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import BasePage from '../../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { hasRole, isPLMTAdmin, getPLMTAdminRegions } from '../../Utilities';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface IRegionalReportsProps {
}

interface IRegionalReportsState {
}

type RegionalReportsProps = IRegionalReportsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class RegionalReports extends React.PureComponent<RegionalReportsProps, IRegionalReportsState> {
    constructor(props: RegionalReportsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription',
            'MyLearning.RegionalReports_Label', 'MyLearning.PLMT_Regional_Reports_Statement', 'MyLearning.PLMT_Title',
            'MyLearning.Activity_Reports_for_Regions_Label', 'MyLearning.PLMT_Regional_Analysis_Reports_Header',
            'MyLearning.PLMT_Regional_Gap_Reports_Header', 'MyLearning.Not_Authorized_Statement'];

        this.state = {
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('admin-tools-menu');
    }

    hasRegion = (region: string) => {
        let lowercaseRegion = region.toLowerCase();
        let user = this.props.site.user;
        let hasValue = _.some(user?.audiences, audience => {
            let lowercaseCode = audience.code.toLowerCase()

            return lowercaseCode.indexOf(lowercaseRegion) !== -1;
        });

        return hasValue;
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let documentServer = `${process.env.REACT_APP_MAIN_SITE}`;
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.RegionalReports_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let hasNA = this.hasRegion('NORTH AMERICA');
        let hasEMEA = this.hasRegion('EMEA');
        let hasLAC = this.hasRegion('LAC');
        let hasAPJ = this.hasRegion('APJ');
        let isAdmin = isPLMTAdmin(user);
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isPlmtUser = isAdmin || isPEM || isPPA;

        if (!user) {
            return null;
        }
        if (user && !isPlmtUser) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="RegionalReports" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={3}>{page}</Heading>
                <Text>{site.localizedStrings['MyLearning.PLMT_Regional_Reports_Statement']}</Text>
                <Box pad={{ top: "small", bottom: "small" }} gap="small">
                    <Text weight="bold">{site.localizedStrings['MyLearning.PLMT_Regional_Analysis_Reports_Header']}</Text>
                    <ul>
                        {hasAPJ && <li><Anchor label='APJ Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtActivities_APJ.xlsx`} /></li>}
                        {hasEMEA && <li><Anchor label='EMEA Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtActivities_EMEA.xlsx`} /></li>}
                        {hasLAC && <li><Anchor label='LAC Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtActivities_LAC.xlsx`} /></li>}
                        {hasNA && <li><Anchor label='North America Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtActivities_NORTH AMERICA.xlsx`} /></li>}
                    </ul>
                </Box>
                <Box pad={{ top: "small", bottom: "small" }} gap="small">
                    <Text weight="bold">{site.localizedStrings['MyLearning.PLMT_Regional_Gap_Reports_Header']}</Text>
                    <ul>
                        {hasAPJ && <li><Anchor label='APJ Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtGap_APJ.xlsx`} /></li>}
                        {hasEMEA && <li><Anchor label='EMEA Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtGap_EMEA.xlsx`} /></li>}
                        {hasLAC && <li><Anchor label='LAC Report' target="_blank" href={`${documentServer}/documents/plmtreport/plmtGap_LAC.xlsx`} /></li>}
                        {hasNA && <li><Anchor label='North America Report' target="_blank" href={`${documentServer}/documents/plmtReport/plmtGap_NORTH AMERICA.xlsx`} /></li>}
                    </ul>
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(RegionalReports as any));
