import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Text, Button, Tabs, Tab, Anchor, Accordion, AccordionPanel, Grid } from 'grommet';
import { LinkNext, Search, Group, Mail } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import _ from 'lodash';
import HtmlComponent from '../components/HtmlComponent';
import {
    retrieveMarketingResources, retrieveTrainingEvents, retrieveDeliveryReadinessDocuments, downloadDrd,
    retrieveLearningPartners, downloadResourceFile, downloadDocument, retrieveInstructor, lpcSlice
} from '../store/LearningPartnerConnection';
import { format, parseISO } from 'date-fns';
import { formatString, hasRole } from '../Utilities';
import { MarketingResource } from '../models/lpc/MarketingResource';
import { ResourceType } from '../models/lpc/ResourceType';
import { DeliveryReadinessDocument } from '../models/lpc/DeliveryReadinessDocument';
import { Instructor } from '../models/lpc/Instructor';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    retrieveMarketingResources,
    retrieveTrainingEvents,
    retrieveDeliveryReadinessDocuments,
    retrieveLearningPartners,
    downloadResourceFile,
    downloadDrd,
    downloadDocument,
    retrieveInstructor,
    ...lpcSlice.actions,
    ...siteSlice.actions
}

interface ILearningPartnerConnectionProps {
}

interface ILearningPartnerConnectionState {
    activeTab: number;
    instructorLoaded: boolean;
    reloadInstructor: boolean;
    reloaded: boolean;
}

type LearningPartnerConnectionProps = ILearningPartnerConnectionProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class LearningPartnerConnection extends React.PureComponent<LearningPartnerConnectionProps, ILearningPartnerConnectionState> {

    constructor(props: LearningPartnerConnectionProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : null
            : null;
        let activeTab = 0;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.LpcKeywords', 'Metadata.LpcDescription',
            'ExternalPages.DerivativeWorks_Label', 'ExternalPages.LPC_DerivativeWorks_Statement', 'ExternalPages.LPC_DerivativeWorks_Link',
            'ExternalPages.PartnerResources_Label', 'ExternalPages.MarketingResources_Label', 'ExternalPages.Home_Label',
            'Datacard.Learner_ID_Look_Up_Tool_Label', 'ExternalPages.Reports_Label', 'ExternalPages.SchedulingForms_Label',
            'ExternalPages.Ordering_HPE_Course_Materials_Label', 'ExternalPages.General_Program_Information_Label',
            'ExternalPages.Lookup_LearnerId_Label', 'ExternalPages.LPC_Home_LearnerIdTool_Statement', 'ExternalPages.Instructor_Registration_Label',
            'ExternalPages.Instructor_Finder_Label', 'ExternalPages.Instructor_Training_Events_Label', 'ExternalPages.Delivery_Readiness_Documents_Label',
            'ExternalPages.LPC_MarketingResources_Title', 'ExternalPages.LPC_MarketingResources_Statement', 'ExternalPages.Newletters_Label',
            'ExternalPages.Quarterly_Webinars_Label', 'ExternalPages.Newletter_Archive_label', 'ExternalPages.Quarterly_Webinar_Archive_Label',
            'MyLearning.Not_Authorized_Statement', 'ExternalPages.LPD_Instructor_Registration_Statement', 'ExternalPages.LPD_Instructor_Finder_Statement',
            'ExternalPages.AffiliationManagement_label', 'ExternalPages.ManageAffiliations_label', 'ExternalPages.CIC_IRS_Sales_Status_Label'];

        switch (tab) {
            case 'partner-resources':
                activeTab = 1;
                break;
            case 'derivative-works':
                activeTab = 2;
                break;
            case 'marketing-resources':
                activeTab = 3;
                break;
        }
        this.state = {
            activeTab: activeTab,
            instructorLoaded: false,
            reloadInstructor: false,
            reloaded: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveMarketingResources();
        this.props.retrieveTrainingEvents();
        this.props.retrieveDeliveryReadinessDocuments();
        this.props.retrieveLearningPartners();
        this.props.setActiveMenu('resources-menu');
    }

    public componentDidUpdate() {
        let site = this.props.site;
        let user = site.user;

        if (user && !this.state.instructorLoaded && !this.props.lpc.instructor && !site.loadingUser) {
            this.props.retrieveInstructor();
        }
        if (user && ((!this.props.lpc.instructor && !this.state.instructorLoaded) || (this.state.reloadInstructor && !site.loadingUser))) {
            this.setState({ instructorLoaded: true, reloadInstructor: false, reloaded: site.actualUser !== undefined });
            this.props.retrieveInstructor();
        }
        if (site.actualUser && this.state.instructorLoaded && !this.state.reloadInstructor && !this.state.reloaded && !site.loadingUser) {
            this.setState({ reloadInstructor: true });
        }
        if (!site.actualUser && this.state.reloaded && !site.loadingUser && !this.state.reloadInstructor) {
            this.setState({ reloadInstructor: true });
        }
    }

    public componentWillUnmount() {
    }

    onActivateTab = (activeTab: number) => {
        this.setState({ activeTab: activeTab });
        switch (activeTab) {
            case 1:
                this.props.push('/lpc/partner-resources')
                break;
            case 2:
                this.props.push('/lpc/derivative-works')
                break;
            case 3:
                this.props.push('/lpc/marketing-resources')
                break;
            default: {
                this.props.push('/lpc')
                break;
            }
        }
    }

    onDownloadResource = (resource: MarketingResource) => {
        this.props.downloadResourceFile(resource);
    }

    onDownloadDrd = (drd: DeliveryReadinessDocument) => {
        this.props.downloadDrd(drd);
    }

    onDownloadDocument = (document: string) => {
        this.props.downloadDocument(document);
    }

    onRegisterInstructor = () => {
        this.props.setStringsLoaded(false);
        this.props.push('/lpc/instructor-registration');
    }

    renderNewletters = (newsletters: MarketingResource[]) => {
        let regexp = new RegExp('LPC_FY(\\d{2})Q\\d_Newsletter', 'i');
        let years = _.uniq(_.filter(_.map(newsletters, newsletter => {
            let matches = regexp.exec(newsletter.path);

            return matches != null ? matches[1] : 0;
        }), y => y !== 0));

        return _.map(years, year => {
            let regex = new RegExp(`LPC_FY${year}Q\\d_Newsletter`);
            let yearNewsletters = _.filter(newsletters, newsletter => regex.test(newsletter.path));

            return <Box gap="small" pad="small">
                <Text size="large" weight="bold">{`20${year}`}</Text>
                <ul>
                    {_.map(yearNewsletters, newsletter => <li><Anchor label={newsletter.title} onClick={() => { this.onDownloadResource(newsletter) }} /></li>)}
                </ul>
            </Box>
        });
    }

    renderWebinars = (webinars: MarketingResource[]) => {
        let pattern = new RegExp('LPC_QW_(\\d{4})(\\d{4})_Partner_Ready_Webinar', 'i');
        let years = _.uniq(_.filter(_.map(webinars, webinar => {
            let matches = pattern.exec(webinar.path);

            return matches != null ? matches[1] : 0;
        }), y => y !== 0));

        return _.map(years, year => {
            let regex = new RegExp(`LPC_QW_${year}\\d{4}_Partner_Ready_Webinar`);
            let yearWebinars = _.filter(webinars, webinar => regex.test(webinar.path));

            return <Box gap="small" pad="small">
                <Text size="large" weight="bold">{year}</Text>
                <ul>
                    {_.map(yearWebinars, webinar => <li><Anchor label={webinar.title} onClick={() => { this.onDownloadResource(webinar) }} /></li>)}
                </ul>
            </Box>
        });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.menuStrings['ExternalPages.LearningPartnerConnection_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: page, value: "" }
        ];
        let trainingEvents = this.props.lpc.trainingEvents;
        let drds = this.props.lpc.deliveryReadinessDocuments;
        let salesDrds = _.filter(this.props.lpc.deliveryReadinessDocuments, cw => cw.course.isSales);
        let technicalDrds = _.filter(this.props.lpc.deliveryReadinessDocuments, cw => !cw.course.isSales);
        let technologies = _.uniq(_.flatMap(technicalDrds, c => c.course.technologies));
        let now = new Date();
        let isoDate = parseISO(new Date(now).toISOString());
        let activeNewsletters = _.sortBy(_.filter(this.props.lpc.marketingResources, (mr: MarketingResource) => {
            let archiveDate = mr.archiveDate ? parseISO(mr.archiveDate) : undefined;

            return mr.type === ResourceType.Newsletter && (!archiveDate || archiveDate > isoDate);
        }), (mr: MarketingResource) => mr.title);
        let activeWebinars = _.sortBy(_.filter(this.props.lpc.marketingResources, (mr: MarketingResource) => {
            let archiveDate = mr.archiveDate ? parseISO(mr.archiveDate) : undefined;

            return mr.type === ResourceType.Webinar && (!archiveDate || archiveDate > isoDate);
        }), (mr: MarketingResource) => mr.title);
        let archivedNewsletters = _.sortBy(_.filter(this.props.lpc.marketingResources, (mr: MarketingResource) => {
            let archiveDate = mr.archiveDate ? parseISO(mr.archiveDate) : undefined;

            return mr.type === ResourceType.Newsletter && archiveDate !== undefined && archiveDate <= isoDate;
        }), (mr: MarketingResource) => mr.title);
        let archivedWebinars = _.sortBy(_.filter(this.props.lpc.marketingResources, (mr: MarketingResource) => {
            let archiveDate = mr.archiveDate ? parseISO(mr.archiveDate) : undefined;

            return mr.type === ResourceType.Webinar && archiveDate !== undefined && archiveDate <= isoDate;
        }), (mr: MarketingResource) => mr.title);
        let isLearningPartner = hasRole(user, ['LEARNING_PARTNER']);
        let isLpcAdmin = hasRole(user, ['LPC_ADMIN']);
        let isPPA = hasRole(user, ["PPA"]);
        let subject = encodeURI('New Instructor Registration');
        let body = encodeURIComponent(`Thank you for your interest in affiliating with our organization.\n\nTo start the process use this link, ${localUrl}/lpc/instructor-registration`);
        let registrationUrl = `mailto:?subject=${subject}&body=${body}`;
        let instructor = this.props.lpc.instructor;
        let authorizations = instructor?.authorizations;
        let hasAuthorizations = authorizations && authorizations.length !== 0;
        let isLpPpa = hasRole(user, ['LPC_PPA']);
        let hasAccess = hasAuthorizations || (isLearningPartner && isPPA) || isLpPpa || isLpcAdmin;

        if (!user) {
            return null;
        }
        if (user && !instructor && !isLpcAdmin && !isLpPpa && !(isLearningPartner && isPPA)) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (salesDrds.length !== 0) {
            technologies.push(site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label']);
            technologies = _.uniq(technologies);
        }
        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="LearningPartnerConnection" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.LpcKeywords']}
            metadataDescription={site.localizedStrings['Metadata.LpcDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Heading level={1} textAlign="center" fill>{page}</Heading>
                <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="center">
                    <Tab title={String(site.localizedStrings['ExternalPages.Home_Label'])} >
                        <Box gap="small" width="large" pad="small">
                            <Box gap="small" pad={{ top: "small" }}>
                                <Heading level={4}>{site.localizedStrings['ExternalPages.Reports_Label']}</Heading>
                                <Box>
                                    <ul>
                                        <li>
                                            <Anchor label="RLS introduction and demo recording (pdf 877Kb)"
                                                onClick={() => { this.onDownloadDocument('RLS Introduction and demo recording.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="RLS Guide (pdf 1.43Mb)" onClick={() => { this.onDownloadDocument('RLS Guide.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="RLS Frequently Asked Questions (pdf 618Kb)"
                                                onClick={() => { this.onDownloadDocument('RLS Frequently Asked Questions.pdf') }} />
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                            <Box gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.SchedulingForms_Label']}</Heading>
                                <Box>
                                    <ul>
                                        <li>
                                            <Anchor label="Class Roster (xls 24Kb)"
                                                onClick={() => { this.onDownloadDocument('Class Roster.xlsx') }} />
                                        </li>
                                        <li>
                                            <Anchor label="Class Request Form (xls 86Kb)"
                                                onClick={() => { this.onDownloadDocument('Class Request Form.xlsx') }} />
                                        </li>
                                        <li>
                                            <Anchor label="Instructor Lab Request Form (xls 20Kb)"
                                                onClick={() => { this.onDownloadDocument('Instructor Lab Request Form.xlsx') }} />
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                            {hasAccess && <Box gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.Ordering_HPE_Course_Materials_Label']}</Heading>
                                <Box>
                                    <ul>
                                        <li><Anchor label="Access HPE Press" href="https://hpepress.hpe.com/" target="_blank" /></li>
                                    </ul>
                                </Box>
                            </Box>}
                            {hasAccess && <Box gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.General_Program_Information_Label']}</Heading>
                                <Box>
                                    <ul>
                                        <li>
                                            <Anchor label="Hybrid Program Guide (pdf 2.4Mb)"
                                                onClick={() => { this.onDownloadDocument('Hybrid Program Guide.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="HPE Aruba Networking Certified Instructor Program Guide (pdf 305Kb)"
                                                onClick={() => { this.onDownloadDocument('HPE Aruba Certified Instructor Program Guide.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="Instructor Readiness Guide (pdf 850Kb)"
                                                onClick={() => { this.onDownloadDocument('Instructor Readiness Guide.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="HPE Aruba Networking Certified Instructor Agreement (pdf 2.5Mb)"
                                                onClick={() => { this.onDownloadDocument('HPE Aruba Networking Certified Instructor Agreement.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="HPE Certified Instructor Agreement (pdf 1.7Mb)"
                                                onClick={() => { this.onDownloadDocument('HPE Certified Instructor Agreement.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="Designated Learning Partner Sample Contract (pdf 1.3Mb)"
                                                onClick={() => { this.onDownloadDocument('Designated Learning Partner Sample Contract.pdf') }} />
                                        </li>
                                        <li>
                                            <Anchor label="HPE Training Delivery Partners" href={`${localUrl}/learningPartners`} />
                                        </li>
                                        <li>
                                            <Anchor label="Partner Education Manager Toolkit" href={`${localUrl}/assistkit`} />
                                        </li>
                                    </ul>
                                </Box>
                            </Box>}
                        </Box>
                    </Tab>
                    {hasAccess && <Tab title={String(site.localizedStrings['ExternalPages.PartnerResources_Label'])} >
                        <Box gap="small" fill pad="small">
                            <Box direction="row-responsive" gap="large">
                                <Box fill gap="medium">
                                    {((isLearningPartner && isPPA) || isLpPpa || isLpcAdmin) && <Box gap="small">
                                        <Heading level={4}>{site.localizedStrings['ExternalPages.AffiliationManagement_label']}</Heading>
                                        <Paragraph fill>{site.localizedStrings['ExternalPages.LPD_Instructor_Registration_Statement']}</Paragraph>
                                        <Box width={{ max: "250px" }}>
                                            <Button primary label={site.localizedStrings['ExternalPages.ManageAffiliations_label']} icon={<Group />}
                                                onClick={() => {
                                                    this.props.setStringsLoaded(false);
                                                    this.props.push('/lpc/manage-affiliations');
                                                }} />
                                        </Box>
                                    </Box>}
                                    {((isLearningPartner && isPPA) || isLpPpa || isLpcAdmin) && <Box gap="small">
                                        <Heading level={4}>{site.localizedStrings['ExternalPages.Instructor_Finder_Label']}</Heading>
                                        <Paragraph fill>{site.localizedStrings['ExternalPages.LPD_Instructor_Finder_Statement']}</Paragraph>
                                        <Box width={{ max: "200px" }}>
                                            <Button primary label={site.localizedStrings['ExternalPages.Instructor_Finder_Label']} icon={<Search />}
                                                onClick={() => {
                                                    this.props.setStringsLoaded(false);
                                                    this.props.push('/lpc/instructor-finder');
                                                }} />
                                        </Box>
                                    </Box>}
                                    <Box gap="small">
                                        <Heading level={4}>{site.localizedStrings['ExternalPages.Instructor_Registration_Label']}</Heading>
                                        <Paragraph fill>{site.localizedStrings['ExternalPages.LPD_Instructor_Registration_Statement']}</Paragraph>
                                        <Box width={{ max: "250px" }}>
                                            <Button primary label={site.localizedStrings['ExternalPages.Instructor_Registration_Label']} icon={<Mail />}
                                                href={registrationUrl} />
                                        </Box>
                                    </Box>
                                    <Box gap="small">
                                        <Heading level={4}>{site.localizedStrings['Datacard.Learner_ID_Look_Up_Tool_Label']}</Heading>
                                        <Paragraph fill>{site.localizedStrings['ExternalPages.LPC_Home_LearnerIdTool_Statement']}</Paragraph>
                                        <Box width={{ max: "200px" }}>
                                            <Button primary label={site.localizedStrings['ExternalPages.Lookup_LearnerId_Label']} icon={<Search />}
                                                onClick={() => {
                                                    this.props.setStringsLoaded(false);
                                                    this.props.push('/admin/learner-lookup');
                                                }} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box fill gap="small">
                                    <Accordion multiple>
                                        <AccordionPanel label={site.localizedStrings['ExternalPages.Instructor_Training_Events_Label']}>
                                            <Box>
                                                <ul>
                                                    {_.map(trainingEvents, trainingEvent => {
                                                        return <li>
                                                            <Anchor label={trainingEvent.title} href={trainingEvent.externalUrl} target="_blank" />
                                                        </li>;
                                                    })}
                                                </ul>
                                            </Box>
                                        </AccordionPanel>
                                        <AccordionPanel label={site.localizedStrings['ExternalPages.Delivery_Readiness_Documents_Label']}>
                                            <Box gap="small">
                                                {_.map(technologies, technology => {
                                                    let tech = technology.replace(" ", "_").toLowerCase();
                                                    let techDrd = technology === 'Sales'
                                                        ? salesDrds
                                                        : _.filter(technicalDrds, ac => _.some(ac.course.technologies, act => act === technology));

                                                    return <Box gap="small" key={`box-${tech}`} pad={{ top: 'small' }}>
                                                        <Text size="large" weight="bold">{technology}</Text>
                                                        {_.map(techDrd, drd => <Box gap="xxsmall" key={`drd-${tech}`} pad={{ left: 'small' }}>
                                                            <Text size="small">
                                                                {`Course ${drd.course.number} - ${format(parseISO(drd.course.launchDate), 'MMM-dd-yyyy')}`}
                                                            </Text>
                                                            <Anchor label={`${drd.course.name} (pdf ${drd.fileSizeKb}kb)`} onClick={() => { this.props.downloadDrd(drd); }} />
                                                        </Box>)}
                                                    </Box>;
                                                })}
                                            </Box>
                                        </AccordionPanel>
                                    </Accordion>
                                </Box>
                            </Box>
                        </Box>
                    </Tab>}
                    {hasAccess && <Tab title={String(site.localizedStrings['ExternalPages.DerivativeWorks_Label'])} >
                        <Box gap="small" width="large" pad="small">
                            <Heading level={3}>{site.localizedStrings['ExternalPages.DerivativeWorks_Label']}</Heading>
                            <Box width="large">
                                <Paragraph fill>
                                    <HtmlComponent html={site.localizedStrings['ExternalPages.LPC_DerivativeWorks_Statement']} />
                                </Paragraph>
                            </Box>
                            <Anchor label={site.localizedStrings['ExternalPages.LPC_DerivativeWorks_Link']} color="brand" icon={<LinkNext color="brand" />}
                                reverse onClick={() => { this.onDownloadDocument('Derivative Works Request Form.pdf') }} />
                        </Box>
                    </Tab>}
                    {hasAccess && <Tab title={String(site.localizedStrings['ExternalPages.MarketingResources_Label'])} >
                        <Box gap="small" fill pad="small">
                            <Heading level={3}>{site.localizedStrings['ExternalPages.LPC_MarketingResources_Title']}</Heading>
                            <Box width="large">
                                <Paragraph fill>
                                    <HtmlComponent html={site.localizedStrings['ExternalPages.LPC_MarketingResources_Statement']} />
                                </Paragraph>
                            </Box>
                            <Box direction="row-responsive" gap="large">
                                <Grid gap={{ row: "medium", column: "large" }} rows={["auto", "auto"]} columns={["large", "large"]}>
                                    <Box pad={{ left: "small" }}>
                                        <Heading level={4}>{site.localizedStrings['ExternalPages.Newletters_Label']}</Heading>
                                        {this.renderNewletters(activeNewsletters)}
                                    </Box>
                                    <Box pad={{ left: "small" }}>
                                        <Heading level={4}>{site.localizedStrings['ExternalPages.Quarterly_Webinars_Label']}</Heading>
                                        {this.renderWebinars(activeWebinars)}
                                    </Box>
                                    <Accordion>
                                        <AccordionPanel label={site.localizedStrings['ExternalPages.Newletter_Archive_label']}>
                                            <Box>
                                                {this.renderNewletters(archivedNewsletters)}
                                            </Box>
                                        </AccordionPanel>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionPanel label={site.localizedStrings['ExternalPages.Quarterly_Webinar_Archive_Label']}>
                                            <Box>
                                                {this.renderWebinars(archivedWebinars)}
                                            </Box>
                                        </AccordionPanel>
                                    </Accordion>
                                </Grid>
                            </Box>
                        </Box>
                    </Tab>}
                </Tabs>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(LearningPartnerConnection as any));
