import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, RadioButtonGroup, ResponsiveContext, Image, Anchor,
    DropButton, CheckBox, Grid, Heading, Paragraph, TextInput, Button, Card, CardHeader, CardBody
} from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store/';
import {
    retrieveExams, retrieveExamLanguages, retrieveExamTypes, retrieveExamTechnologies, retrieveExamViews,
    examsSlice
} from './../../store/Exams';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { EntityInfo, KeyValuePair, EmptyGuid } from '../../models/Common';
import { HPEWindowInterface, formatString } from '../../Utilities';
import { Search, Notes } from "grommet-icons";
import { firstBy } from 'thenby'
import BasePage from '../../components/BasePage';
import SummaryCard from '../../components/SummaryCard';
import parse from 'html-react-parser';
import _ from 'lodash';

declare let window: HPEWindowInterface;

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveExams,
    retrieveExamLanguages,
    retrieveExamTypes,
    retrieveExamTechnologies,
    retrieveExamViews,
    ...siteSlice.actions,
    ...examsSlice.actions
}

interface ExamsState {
    activePanel: number;
    firstLoad: boolean;
}

type ExamsProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Exams extends React.PureComponent<ExamsProps, ExamsState> {
    constructor(props: ExamsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;

        this.state = {
            activePanel: 0,
            firstLoad: urlLanguage !== null
        };
    }

    public componentDidMount() {
        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.Technologies_Label', 'ExternalPages.Credential_View_Label',
            'Datacard.Register_For_This_Label', 'ExternalPages.Remote_Proctored_Exams_Statement', 'ExternalPages.Exam_Introduction_Statement',
            'ExternalPages.ExamTypes_Label', 'ExternalPages.Proctored_Label', 'ExternalPages.Web_Based_Label', 'ExternalPages.Performance_Based_Label',
            'ExternalPages.ExamType_Label', 'ExternalPages.DeliveryLanguages_Label', 'Datacard.Exam_ViewAll_Label', 'ExternalPages.DeliveryLanguage_Label',
            'ExternalPages.Narrow_by_Label', 'ExternalPages.Certification_Levels_Label', 'ExternalPages.Learn_More_Label',
            'ExternalPages.Select_Deselect_All_Label', 'Datacard.Register_Label', 'ExternalPages.Search_Label', 'ExternalPages.No_Exams_Found_Statement',
            'ExternalPages.Reset_Filters_Label', 'General.CertificationType_Sales', 'ExternalPages.Credentials_Label', 'Metadata.ExamsKeywords',
            'Metadata.ExamsDescription', 'ExternalPages.View_All_Exams_Card1_Title', 'ExternalPages.View_All_Exams_Card1_Body',
            'ExternalPages.View_All_Exams_Card3_Title', 'ExternalPages.View_All_Exams_Card3_Body'];

        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.retrieveExamLanguages(urlLanguage);
        this.props.retrieveExamTypes(urlLanguage);
        this.props.retrieveExamTechnologies(urlLanguage);
        this.props.retrieveExamViews(urlLanguage);
        this.props.retrieveExams(urlLanguage);
        this.props.setActiveMenu('technical-certification-menu');
    }

    public componentDidUpdate(prevProps: ExamsProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;

        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            if (!this.state.firstLoad) {
                this.props.retrieveExamLanguages(urlLanguage);
                this.props.retrieveExamTypes(urlLanguage);
                this.props.retrieveExamTechnologies(urlLanguage);
                this.props.retrieveExamViews(urlLanguage);
                this.props.retrieveExams(urlLanguage);
            }
            this.setState({ firstLoad: false });
        }
    }

    onRegister = (exam: EntityInfo) => {
        let target = exam.registrationUrl.toLowerCase().indexOf(exam.publicSiteUrl.toLowerCase()) === -1
            ? "_blank"
            : "_self";

        try {
            window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `Exam|ViewAll|${exam.programId}` } }));
        } catch (error) {
            console.log(error);
        }
        window.open(exam.registrationUrl, target);
    }

    onCheckedChange = (event: any) => {
        this.props.setSelected(`${event.target.id}-${event.target.checked}`)
    }

    onViewChange = (event: any) => {
        this.props.setView(event.target.value);
    }

    onKeywordChange = (value: string) => {
        this.props.setKeywords(value);
    }

    onResetFilters = () => {
        this.props.setView('all');
        this.props.setSelected('types-0-true')
        this.props.setSelected('technologies-0-true')
        this.props.setSelected('languages-0-true')
        this.props.setSelected('levels-0-true')
    }

    renderFilters = () => {
        let filters: any = [];
        let site = this.props.site;
        let exams = this.props.exams;
        let views = exams.views.map((view, index) => {
            return {
                disabled: false,
                id: `views-${index}`,
                name: `views-${index}`,
                value: view.value,
                label: view.text
            }
        });
        let selectedItem = exams.views.find((item) => item.value === exams.view) ?? exams.views[0]

        filters = [
            <DropButton className="hpe-button-box" key="views-filter" label={site.localizedStrings['ExternalPages.Credential_View_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {<RadioButtonGroup name="views" options={views} value={selectedItem.value} onChange={this.onViewChange} />}
                    </Box>
                }
            />,
            <DropButton className="hpe-button-box" key="types-filter" label={site.localizedStrings['ExternalPages.ExamType_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {exams.types.map((view, index) => <CheckBox key={`types-${index}`} id={`types-${index}`}
                            checked={view.selected}
                            label={view.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />,
            <DropButton className="hpe-button-box" key="technologies-filter" label={site.localizedStrings['ExternalPages.Technologies_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {exams.technologies.map((item, index) => <CheckBox key={`technologies-${index}`} id={`technologies-${index}`}
                            checked={item.selected}
                            label={item.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />,
            <DropButton className="hpe-button-box" key="languages-filter" label={site.localizedStrings['ExternalPages.DeliveryLanguages_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {exams.languages.map((item, index) => <CheckBox key={`languages-${index}`} id={`languages-${index}`}
                            checked={item.selected}
                            label={item.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />
        ]
        return <Box gap="small">
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Box width="medium" >
                    <TextInput size='xsmall' placeholder={`${site.localizedStrings['ExternalPages.Search_Label']} ...`} value={exams.keywords} icon={<Search />}
                        onChange={event => this.onKeywordChange(event.target.value)} />
                </Box>
            </Box>
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Text weight="bold">{site.localizedStrings['ExternalPages.Narrow_by_Label']}:</Text>
                {filters}
                <Button secondary label={site.localizedStrings['ExternalPages.Reset_Filters_Label']} onClick={this.onResetFilters} />
            </Box>
        </Box>;
    }

    renderList = (size: string) => {
        let site = this.props.site;
        let exams = this.props.exams;
        let entities = exams.entities;
        let view = exams.view;
        let technologies = exams.technologies.filter((tech) => tech.selected).map((tech) => tech.value);
        let languages = exams.languages.filter((lang) => lang.selected).map((lang) => lang.value);
        let types = exams.types.filter((type) => type.selected).map((type) => type.value);
        let allLanguages = languages.some((lang) => lang === EmptyGuid);
        let allTech = technologies.some((tech) => tech === EmptyGuid);
        let keywords = exams.keywords;
        let allTypes = types.some((type) => type === EmptyGuid);
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;

        technologies = technologies.filter((tech) => tech !== EmptyGuid);

        let sortedEntities = [...entities].sort((left, right) => {
            return right.levelOrder - left.levelOrder;
        });
        let filtered = sortedEntities.filter((item) => {
            let hasView = true;

            switch (view) {
                case 'newOnly': {
                    hasView = item.isNew;
                    break;
                }
                case 'all': {
                    break;
                }
                default:
                    hasView = !item.isCredential && item.certificationType?.value === view;
                    break;
            }

            return item.certificationType && hasView && (allTech || (technologies.length !== 0 && item.technologies.some((t) => technologies.some((st) => st === t)))) &&
                (allLanguages || (languages.length !== 0 && item.deliveryLanguages.some((l) => languages.some((dl) => dl === l)))) &&
                (allTypes || (types.length !== 0 && types.some((t) => t === item.type.key))) &&
                (keywords === '' || item.name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1 ||
                    item.programId.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
        });
        let typeGrouping = _.groupBy(filtered, (o) => {
            return [o.certificationType.value]
        });

        return _.size(typeGrouping) !== 0
            ? <Box fill>
                {_.map(typeGrouping, (items, key) => {
                    return <Box key={`${key}-box`} border={{ side: "bottom", size: 'small' }} pad={{ top: "small", bottom: "small" }}>
                        <Heading level='4'>{key}</Heading>
                        {key !== site.localizedStrings['General.CertificationType_Sales'] &&
                            _.map(_.groupBy(_.concat([], _.flatMapDeep(items, (item) => {
                                let data = item.technologies.map((techId) => {
                                    let techOption = exams.technologies.find((t) => t.value === techId);

                                    return {
                                        ...item,
                                        technology: techOption?.text
                                    };
                                });
                                return data;
                            })).sort((l: any, r: any) => l.technology.localeCompare(r.technology)), (o: any) => { return [o.technology] }), (certs: any, technology) => {
                                return <Box key={`${technology}-box`} border={{ side: "bottom", size: 'small' }} pad={{ left: "small", top: "small", bottom: "small" }}>
                                    <Heading level='4'>{technology}</Heading>
                                    <Grid gap={{ row: "small", column: "small" }} rows="xsmall" columns="medium" >
                                        {certs.sort(firstBy("levelOrder").thenBy("name")).map((entity: any) => {
                                            let href = `${localUrl}/datacard/exam/${entity.programId}`;
                                            let status = entity.releaseStatus.key !== 'Active'
                                                ? <Text>{entity.releaseStatus.value}</Text>
                                                : null;
                                            let item = { ...entity };
                                            item.registrationUrl = item.isPerformanceBased ? href : item.registrationUrl;

                                            return entity.digitalBadge
                                                ? <SummaryCard key={`entity-${entity.programId}`} data={item} hoverIndicator href={href} status={status}
                                                    linkLabel={site.localizedStrings['Datacard.Register_Label']} />
                                                : <SummaryCard key={`entity-${entity.programId}`} data={item} hoverIndicator href={href} status={status}
                                                    linkLabel={site.localizedStrings['Datacard.Register_Label']} icon={<Notes size="large" color="#00739D" />} />;
                                        })}
                                    </Grid>
                                </Box>
                            })
                        }

                        {(key === site.localizedStrings['General.CertificationType_Sales']) &&
                            <Grid gap={{ row: "small", column: "small" }} rows="xsmall" columns="medium" >
                                {items.sort(firstBy("levelOrder").thenBy("name")).map((entity) => {
                                    let href = `${localUrl}/datacard/exam/${entity.programId}`;
                                    let item = { ...entity };
                                    let status = entity.releaseStatus.key !== 'Active'
                                        ? <Text>{entity.releaseStatus.value}</Text>
                                        : null;

                                    item.registrationUrl = item.isPerformanceBased ? href : item.registrationUrl;

                                    return entity.digitalBadge
                                        ? <SummaryCard key={`entity-${entity.programId}`} data={item} hoverIndicator href={href} status={status}
                                            linkLabel={site.localizedStrings['Datacard.Register_Label']} />
                                        : <SummaryCard key={`entity-${entity.programId}`} data={item} hoverIndicator href={href} status={status}
                                            linkLabel={site.localizedStrings['Datacard.Register_Label']} icon={<Notes size="large" color="#00739D" />} />;
                                })}
                            </Grid>
                        }
                    </Box>
                })
                }
            </Box >
            : !site.loading && <Box height="small" align="center" justify="center" alignContent="center">
                <Text weight="bold">{site.localizedStrings['ExternalPages.No_Exams_Found_Statement']}</Text>
            </Box>;
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Technical'], value: `${localUrl}/technical-certifications` },
            { key: site.menuStrings['Datacard.Exam_ViewAll_Label'], value: "" }
        ];
        let title = site.menuStrings['Datacard.Exam_ViewAll_Label'];

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="entitylist_exams" {...this.props}
                    metadataDescription={site.localizedStrings['Metadata.ExamsDescription']} metadataKeywords={site.localizedStrings['Metadata.ExamsKeywords']}>
                    {site.stringsLoaded && <Box>
                        <Box gap="medium" fill="horizontal">
                            <Box fill="horizontal">
                                <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                            </Box>
                            <Paragraph fill textAlign="center">
                                {parse(site.localizedStrings['ExternalPages.Exam_Introduction_Statement'])}
                            </Paragraph>
                        </Box>
                        <Box direction="row-responsive" background="#F5F5F5" gap="medium" justify="center" pad="small" margin={{ top: "small", bottom: "medium" }}>
                            <Card width="medium">
                                <CardHeader >
                                    <Image fit="contain" src="images/view-all-exams/HPE201604100787.png" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Exams_Card1_Title']}</Text>
                                    <Box gap="small">
                                        <Paragraph>{parse(formatString(site.localizedStrings['ExternalPages.View_All_Exams_Card1_Body'], [`${localUrl}/certification-exams`]))}</Paragraph>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card width="medium">
                                <CardHeader>
                                    <Image fit="contain" src="images/view-all-exams/HPE20160726030.png" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.ExamTypes_Label']}</Text>
                                    <Box gap="small">
                                        <ul className="bullet-list">
                                            <li>{`${site.localizedStrings['ExternalPages.Proctored_Label']} - (HPE0-xxx, HPE6-xxx, HPE7-xxx)`}</li>
                                            <li> {`${site.localizedStrings['ExternalPages.Web_Based_Label']} - (HPE2-xxx, HPE3-xxx)`}</li>
                                            <li>{`${site.localizedStrings['ExternalPages.Performance_Based_Label']} - (HPE1-xxx, HPE4-xxx, HPE5-XXX)`}</li>
                                        </ul>
                                    </Box>
                                    <Anchor href={`${localUrl}/certification-exams/types`} label={site.localizedStrings['ExternalPages.Learn_More_Label']} />
                                </CardBody>
                            </Card>
                            <Card width="medium">
                                <CardHeader>
                                    <Image fit="contain" src="images/view-all-exams/HPE20160803047_800_0_72_RGB.jpg" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Exams_Card3_Title']}</Text>
                                    <Box gap="small">
                                        <Paragraph>{parse(formatString(site.localizedStrings['ExternalPages.View_All_Exams_Card3_Body'], [`${siteUrl}/hpepress`]))}</Paragraph>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Box>
                    </Box>}
                    <Box gap="small">
                        <Box direction="row-responsive" fill="horizontal" gap="small" pad="small" border={{ side: "bottom", color: 'brand', size: 'small' }}>
                            {this.renderFilters()}
                        </Box>
                        {this.renderList(size)}
                    </Box>
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Exams as any));
