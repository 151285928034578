import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { setLoading, setError } from './Site';
import { SelectOption } from '../models/Common';

export interface AdminState {
    notification?: Notification;
    partnerTypes?: SelectOption[];
    distributors?: SelectOption[];
}

let initialState: AdminState = {
}

export const adminSlice = createSlice({
    name: 'Admin',
    initialState,
    reducers: {
    }
});

export const {  } = adminSlice.actions;

export const retrieveLearnerIds = (info: any): AppThunk => async (dispatch) => {
    let url = 'api/plmt/LookupLearnerIds';
    let data = new FormData();
    let filename = ';'

    if (info.emails) {
        data.append("idList", info.emails);
    }
    if (info.emailFile && info.emailFile.length !== 0) {
        let idFile = info.emailFile[0];

        data.append("importFile", idFile, idFile.name);
    }
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: data
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            filename = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then((blob: any) => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", filename);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export default adminSlice.reducer;
