import React, { HTMLAttributes } from 'react';
import { Box, Text, BoxProps, Card, CardHeader, CardBody, CardFooter, Paragraph } from 'grommet';
import { CourseInfo } from '../models/Calendar'
import parse from 'html-react-parser';

interface ICourseCardProps {
    icon?: JSX.Element;
    data: CourseInfo;
};

type CourseCardProps = ICourseCardProps
    & BoxProps
    & HTMLAttributes<HTMLDivElement>;

export default class CourseCard extends React.PureComponent<CourseCardProps, {}> {

    render() {
        let course = this.props.data;

        return <Card fill {...this.props}>
            <CardHeader background="#00739D" pad="small" height={{min:"110px"}} justify="center">
                <Text weight="bold" size="small" color="white" textAlign="center">{course.title}</Text>
            </CardHeader>
            <CardBody pad="small">
                <Box gap="small">
                    <Box height={{ max: 'medium' }} overflow="auto" wrap>
                        <Paragraph fill size="small">{parse(course.description)}</Paragraph>
                    </Box>
                </Box>
            </CardBody>
            <CardFooter pad="small">
                <Box fill="horizontal">
                    {course.trainingModes.map((mode) => {
                        return <Text size="xsmall">{mode}</Text>;
                    })}
                </Box>
            </CardFooter>
        </Card>;
    }
}
