import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './'
import { setLoading, setError } from './Site';
import { Country } from '../models/Country';
import { UnaffiliatedRegistration } from '../models/UnaffiliatedRegistration';

export interface UnaffiliatedLearnerState {
    restrictedCountries: Country[];
    countries: Country[];
    embargoedCountries: Country[];
}

let initialState: UnaffiliatedLearnerState = {
    countries: [],
    restrictedCountries: [],
    embargoedCountries: []
}

export const unaffiliatedLearnerSlice = createSlice({
    name: 'UnaffiliatedLearner',
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setRestrictedCountries: (state, action) => {
            state.restrictedCountries = action.payload;
        },
        setEmbargoedCountries: (state, action) => {
            state.embargoedCountries = action.payload;
        }
    }
});

export const { setRestrictedCountries, setCountries, setEmbargoedCountries } = unaffiliatedLearnerSlice.actions;

export const retrieveCountries = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/unaffiliatedLearner/countries';

    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCountries(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveRestrictedCountries = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/unaffiliatedLearner/restrictedCountries';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setRestrictedCountries(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveEmbargoedCountries = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/unaffiliatedLearner/embargoedCountries';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setEmbargoedCountries(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const downloadDocument = (document: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/unaffiliatedLearner/downloadDocument';
    let request = {
        document: document
    };
    let fileName = "";

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/pdf",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'target='_blank'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("open", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const submitRegistration = (registration: UnaffiliatedRegistration, callback: Function): AppThunk => async (dispatch, getState) => {
    let url = 'api/unaffiliatedLearner/submitRegistration';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(registration),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        if (data.message || data.statusCode || data.errors) {
            throw new Error(data.message ?? data);
        }
        dispatch(setLoading(false));
        callback();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default unaffiliatedLearnerSlice.reducer;
