import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { FaqSection } from '../models/AssistKit/FaqSection';
import { setLoading, setError } from './Site';

export interface AssistKitState {
    data?: FaqSection[];
}

let initialState: AssistKitState = {
}

export const assistKitSlice = createSlice({
    name: 'AssistKit',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setData } = assistKitSlice.actions;

export const retrieveFaqs = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language != '' ? language : null
    }

    dispatch(setLoading(true));
    fetch('api/list/faqs', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
        dispatch(setLoading(false));
    });
}

export default assistKitSlice.reducer;
