import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Button, Anchor, Tabs, Tab, Tip, Grid, Card, CardBody, CardHeader, CardFooter } from 'grommet';
import { Mail, LinkNext, FormNext, FormPrevious } from 'grommet-icons';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { retrieveSuccessStories } from '../store/SuccessStories';
import BasePage from '../components/BasePage';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveSuccessStories,
    ...siteSlice.actions,
}

interface ISuccessStoriesProps {
}

interface SuccessStoriesState {
    activeTab: number;
    currentPage: number;
    tabSet: boolean;
}

type SuccessStoriesProps = ISuccessStoriesProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class SuccessStories extends React.PureComponent<SuccessStoriesProps, SuccessStoriesState> {
    constructor(props: SuccessStoriesProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['Metadata.SuccessStories_Keywords,', 'Metadata.SuccessStories_Description', 'ExternalPages.SuccessStories_Banner_Title',
            'ExternalPages.SuccessStories_Banner_Statement', 'ExternalPages.MasterMinds_SubTitle', 'ExternalPages.MasterMinds_IntoStatement',
            'ExternalPages.Mastermind_Profiles_Label', 'ExternalPages.SuccessStories_SubTitle', 'ExternalPages.SuccessStories_Share_Statement',
            'ExternalPages.Read_More_Label'];

        this.state = {
            activeTab: 0,
            currentPage: 0,
            tabSet: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveSuccessStories(langInfo[0]);
        this.props.setActiveMenu('resources-menu');
    }

    public componentDidMount() {
    }

    public componentDidUpdate() {
        let successStories = this.props.successStories.data?.filter((story) => story.type == "0") ?? [];
        let masterminds = this.props.successStories.data?.filter((story) => story.type == "1") ?? [];
        let hasSuccessStories = successStories.length > 0;
        let hasMasterminds = masterminds.length > 0;

        if (!hasSuccessStories && hasMasterminds && !this.state.tabSet) {
            this.setState({ activeTab: 1, tabSet: true });
        }
    }

    onActivateTab = (activeTab: number) => {
        this.setState({ activeTab: activeTab });
    }

    setCurrentPage = (page: number) => {
        this.setState({ currentPage: page });
    }

    onNext = () => {
        this.setCurrentPage(this.state.currentPage + 1)
    }

    onPrevious = () => {
        this.setCurrentPage(this.state.currentPage - 1)
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let documentServer = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_DOCUMENT_SERVER}/${langInfo[0]}`
            : `${process.env.REACT_APP_DOCUMENT_SERVER}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: site.menuStrings['Datacard.SuccessStories_Label'], value: "" }
        ];
        let title = site.menuStrings['Datacard.SuccessStories_Label'];
        let successStories = this.props.successStories.data?.filter((story) => story.type == "0") ?? [];
        let masterminds = this.props.successStories.data?.filter((story) => story.type == "1") ?? [];
        let totalPages = Math.round(masterminds.length / 4);
        let start = this.state.currentPage * 4;
        let end = start + 4;
        let currentMasterMinds = masterminds.slice(start, end);
        let hasSuccessStories = successStories.length > 0;
        let hasMasterminds = masterminds.length > 0;

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="SuccessStories" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.SuccessStories_Keywords']}
                    metadataDescription={site.localizedStrings['Metadata.SuccessStories_Description']} >
                    {site.stringsLoaded && <Box gap="medium">
                        <Box pad={{ bottom: "small" }}>
                            <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="start">
                                {hasSuccessStories && <Tab title={(title ?? "")}>
                                    <Box gap="small" pad={{ top: "large" }}>
                                        <Heading level="3" textAlign="center" fill >{site.localizedStrings['ExternalPages.SuccessStories_SubTitle']}</Heading>
                                        <Box pad="small" direction="row" gap="large" justify="center" fill>
                                            <Grid rows="auto" columns="300px" gap="large" justify="center" pad="medium" fill>
                                                {successStories.map((story) => {
                                                    let imageUrl = formatString(story.image, [documentServer]);

                                                    return < Card gap="small">
                                                        <CardHeader>
                                                            <Image fit="contain" src={imageUrl} />
                                                        </CardHeader>
                                                        <CardBody gap="small">
                                                            <Text weight="bold" size="large" >{story.headLine}</Text>
                                                            <Box height={{ max: "small" }} overflow="auto">
                                                                <Paragraph fill>{story.text}</Paragraph>
                                                            </Box>
                                                        </CardBody>
                                                        <CardFooter>
                                                            <Anchor href={story.url} color="brand" size="medium" weight="bold" target="_blank"
                                                                label={<Text>{site.localizedStrings['ExternalPages.Read_More_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                                        </CardFooter>
                                                    </Card>
                                                })}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Tab>}
                                {hasMasterminds && <Tab title={site.localizedStrings['ExternalPages.Mastermind_Profiles_Label']}>
                                    <Box gap="small" pad={{ top: "large" }}>
                                        <Heading level="3" textAlign="center" fill >{site.localizedStrings['ExternalPages.MasterMinds_SubTitle']}</Heading>
                                        <Box width={{ max: 'large' }} alignSelf="center" justify="center">
                                            <Paragraph fill textAlign="center">{site.localizedStrings['ExternalPages.MasterMinds_IntoStatement']}</Paragraph>
                                        </Box>
                                        <Box direction="row-responsive" gap="medium" background="#F5F5F5" pad="medium" >
                                            <Box alignSelf="end" direction="row" fill>
                                                <Button disabled={this.state.currentPage == 0} icon={<FormPrevious />} onClick={this.onPrevious} />

                                                <Grid rows="auto" columns="300px" gap="large" justify="center" pad="medium" fill>
                                                    {currentMasterMinds.map((story) => {
                                                        let imageUrl = formatString(story.image, [documentServer]);
                                                        let storyUrl = formatString(story.url, [documentServer]);

                                                        return <Card gap="small">
                                                            <CardHeader>
                                                                <Image fit="contain" src={imageUrl} />
                                                            </CardHeader>
                                                            <CardBody gap="small">
                                                                <Text weight="bold" size="large" >{story.headLine}</Text>
                                                                <Box height={{ max: "small" }} overflow="auto">
                                                                    <Paragraph fill>{story.text}</Paragraph>
                                                                </Box>
                                                            </CardBody>
                                                            <CardFooter>
                                                                <Anchor href={storyUrl} color="brand" size="medium" weight="bold" target="_blank"
                                                                    label={<Text>{site.localizedStrings['ExternalPages.Read_More_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                                            </CardFooter>
                                                        </Card>
                                                    })}
                                                </Grid>
                                                <Button disabled={this.state.currentPage >= totalPages} icon={<FormNext />} onClick={this.onNext} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Tab>}
                            </Tabs>
                        </Box>
                    </Box>}
                </BasePage>
            )
            }
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(SuccessStories  as any));
