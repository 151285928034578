import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import { Box, FileInput, Heading, Text, TextArea, Button, Form, FormField, RadioButtonGroup, Tip, Paragraph, Anchor } from 'grommet';
import { Upload, Download, CircleInformation } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { loadBuilderInfo, uploaduilder, downloadBuilder, saveBuilderInfo } from '../../store/Plmt';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { User } from '../../models/User';
import { Audience } from '../../models/Audience';
import { format, parseJSON } from 'date-fns';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    loadBuilderInfo,
    uploaduilder,
    downloadBuilder,
    saveBuilderInfo,
    ...siteSlice.actions,
}

interface IManageBuilderProps {
}

interface ManageBuilderState {
    builderFile?: any;
    showProcessingMsg: boolean;
    infoRequested: boolean;
    builderInfo?: any;
    changeList: boolean;
}

type ManageBuilderProps = IManageBuilderProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class ManageBuilder extends React.PureComponent<ManageBuilderProps, ManageBuilderState> {
    constructor(props: ManageBuilderProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label',
            'MyLearning.PLMT_Overview_Paragraph', 'MyLearning.GapSummary_Label', 'MyLearning.Activities_label', 'MyLearning.RegionalReports_Label',
            'MyLearning.PLMT_Title', 'MyLearning.Region_Label', 'MyLearning.DistributionList_Label', 'MyLearning.LastUploadDate_Label',
            'MyLearning.LastUploadBy_Label', 'MyLearning.Download_Label', 'MyLearning.BuilderDrop_Label', 'MyLearning.BuilderSelect_Label',
            'MyLearning.Upload_Label', 'MyLearning.Cancel_Label', 'MyLearning.Save_Label', 'MyLearning.DistributionList_Tooltip',
            'MyLearning.Download_Tooltip', 'MyLearning.ChangeDistributionList_Label', 'MyLearning.PLMT_Information_Link5',
            'MyLearning.Not_Authorized_Statement'];

        this.state = {
            showProcessingMsg: false,
            infoRequested: false,
            changeList: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('admin-tools-menu');
    }

    componentDidUpdate() {
        let user = this.props.site.user;

        if (user && !this.state.builderInfo?.region) {
            let regions = this.getRegions();

            if (regions.length === 1) {
                this.setState({ builderInfo: { region: regions[0] } });
                this.onRegionChange({ target: { value: regions[0] } });
            }
        }
        if (!this.state.builderInfo && this.props.plmt.builderInfo) {
            this.setState({ builderInfo: this.props.plmt.builderInfo });
        }
    }

    onFileSelected = (event: any) => {
        let builderFile = event.target.files[0];

        this.setState({ builderFile: builderFile });
    }

    onUploadBuilder = () => {
        this.props.uploaduilder(this.state.builderFile);
    }

    onDownloadBuilder = () => {
        this.props.downloadBuilder({
            region: this.state.builderInfo?.region,
            updateDate: this.state.builderInfo?.lastUploadDate
        });
    }

    onRegionChange = (event: any) => {
        let selected = event.target.value;
        let builderInfo = this.state.builderInfo ? JSON.parse(JSON.stringify(this.state.builderInfo)) : {};

        builderInfo.region = selected;
        this.setState({ builderInfo: builderInfo, builderFile: undefined });
        this.props.loadBuilderInfo({ region: selected }, this.onChangeComplete);
    }

    onChangeComplete = () => {
        this.setState({ builderInfo: this.props.plmt.builderInfo, changeList: false });
    }

    onChangeDistributionList = () => {
        this.setState({ changeList: true });
    }

    getRegions = () => {
        let user = this.props.site.user;
        let audiences = user?.audiences.filter((audience: Audience) => audience.code.indexOf("PLMT_BUILDER_ADMIN") !== -1);

        return _.map(audiences, (audience: Audience) => {
            let info = audience.code.split("-");

            return info[1];
        });
    }

    isPLMTBuilderAdmin = (user: User | undefined) => {
        return _.some(user?.audiences, (audience: Audience) => audience.code.indexOf("PLMT_BUILDER_ADMIN") !== -1);
    }

    isDirty = () => {
        return !_.isEqual(this.props.plmt.builderInfo?.distributionList, this.state.builderInfo?.distributionList);
    }

    onCancel = () => {
        this.setState({ builderInfo: this.props.plmt.builderInfo, changeList: false });
    }

    onSave = () => {
        let builderInfo = JSON.parse(JSON.stringify(this.state.builderInfo));

        this.props.saveBuilderInfo(builderInfo, this.onChangeComplete);
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.menuStrings['ExternalPages.ManageBuilder_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let builderInfo = this.state.builderInfo;
        let disableControls = !builderInfo;
        let regions = this.getRegions();
        let disableRegion = regions.length === 1;

        if (!user) {
            return null;
        }
        if (user && !this.isPLMTBuilderAdmin(user)) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="PlmtBuilder" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'large' }}>
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={3}>{page}</Heading>
                <Anchor label={site.localizedStrings['MyLearning.PLMT_Information_Link5']} target="_blank"
                    href='https://hpe.sharepoint.com/teams/SNATeam/KPI%20Metric/Forms/AllItems.aspx?OR=Teams%2DHL&CT=1687275430165&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMzA1MDEwMDQyMiIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fteams%2FSNATeam%2FKPI%20Metric%2FDQ%26A%2FAdhoc%20Reports%2FWW%20Channel%20Report&viewid=1f6326f6%2Dab8b%2D4dc2%2Dbf0f%2D9ca34683b80e' />

                <Form value={this.state.builderInfo} onChange={nextValue => this.setState({ builderInfo: nextValue })}>
                    <Box gap="medium">
                        <Box direction='row-responsive' gap="large">
                            <Box direction='row-responsive' gap="small" alignContent='start'>
                                <FormField label={<Text weight='bold'>{site.localizedStrings['MyLearning.Region_Label']}</Text>} name="region">
                                    <RadioButtonGroup name="region" id="region-input" options={regions} pad='small' disabled={disableRegion}
                                        onChange={this.onRegionChange} />
                                </FormField>
                            </Box>
                            <Box gap="medium" width="large">
                                <Box gap="small">
                                    <Box direction="row-responsive" gap="small">
                                        <Text weight="bold" size="small">{site.localizedStrings['MyLearning.LastUploadDate_Label']}</Text>
                                        <Text>
                                            {builderInfo?.lastUploadDate ? format(parseJSON(builderInfo?.lastUploadDate), "dd MMM yyyy hh:mm:ss aa") : ''}
                                        </Text>
                                    </Box>
                                    <Box direction="row-responsive" gap="small">
                                        <Text weight="bold" size="small">{site.localizedStrings['MyLearning.LastUploadBy_Label']}</Text>
                                        <Text>{builderInfo?.lastUploadedBy}</Text>
                                    </Box>
                                </Box>
                                <Box direction="row-responsive" gap="small">
                                    <Box width="small">
                                        <Button label={site.localizedStrings['MyLearning.Download_Label']} primary onClick={this.onDownloadBuilder} icon={<Download />}
                                            disabled={disableControls} />
                                    </Box>
                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                        <Paragraph fill>{`${site.localizedStrings['MyLearning.Download_Tooltip']}`}</Paragraph>
                                    </Box>}>
                                        <CircleInformation size="small" />
                                    </Tip>
                                </Box>
                                <Box direction="row-responsive" gap="small">
                                    <FileInput disabled={disableControls} onChange={this.onFileSelected} messages={{
                                        dropPrompt: site.localizedStrings['MyLearning.BuilderDrop_Label'],
                                        browse: site.localizedStrings['MyLearning.BuilderSelect_Label']
                                    }} />
                                    <Button label={site.localizedStrings['MyLearning.Upload_Label']} primary icon={<Upload />}
                                        disabled={!this.state.builderFile || disableControls} onClick={this.onUploadBuilder} />
                                </Box>
                                <Box width="medium">
                                    <FormField name="distributionList"
                                        label={<Box direction="row-responsive" gap="xsmall" >
                                            <Text weight='bold'>{site.localizedStrings['MyLearning.DistributionList_Label']}</Text>
                                            <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                                <Paragraph fill>{`${site.localizedStrings['MyLearning.DistributionList_Tooltip']}`}</Paragraph>
                                            </Box>}>
                                                <CircleInformation size="small" />
                                            </Tip>
                                        </Box>} >
                                        <TextArea name="distributionList" id="distributionList-input" disabled={!this.state.changeList} />
                                    </FormField>
                                </Box>
                                <Box direction='row-responsive' gap="medium">
                                    {!this.state.changeList && <Button primary label={site.localizedStrings['MyLearning.ChangeDistributionList_Label']} disabled={disableControls}
                                        onClick={this.onChangeDistributionList} />}
                                    {this.state.changeList && <Button primary label={site.localizedStrings['MyLearning.Save_Label']} disabled={!this.isDirty() || disableControls}
                                        onClick={this.onSave} />}
                                    {this.state.changeList && <Button secondary label={site.localizedStrings['MyLearning.Cancel_Label']} disabled={!this.isDirty()}
                                        onClick={this.onCancel} />}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Form>
            </Box>
            }
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(ManageBuilder as any));
