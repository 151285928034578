import { ThunkAction, Action } from '@reduxjs/toolkit';
import certificationReducer, { CertificationState } from './Certification';
import courseReducer, { CourseState } from './Course';
import examReducer, { ExamState } from './Exam';
import upgradePathReducer, { UpgradePathState } from './UpgradePath';
import curriculumReducer, { CurriculumState } from './Curriculum';
import siteReducer, { SiteState } from './Site';
import calendarReducer, { CalendarState } from './Calendar';
import entityListReducer, { EntityListState } from './EntityList';
import learningPartnerReducer, { LearningPartnerState } from './LearningPartner';
import assistKitReducer, { AssistKitState } from './AssistKit';
import successStoriesReducer, { SuccessStoriesState } from './SuccessStories';
import overviewReducer, { OverviewState } from './Overview';
import technicalReducer, { TechnicalState } from './Technical';
import examsReducer, { ExamsState } from './Exams';
import coursesReducer, { CoursesState } from './Courses';
import certificationsReducer, { CertificationsState } from './Certifications';
import credentialsReducer, { CredentialsState } from './Credentials';
import smeReducer, { SmeState } from './Sme';
import plmtReducer, { PlmtState } from './Plmt';
import cicReducer, { CICState } from './CertificationInstructorConnection';
import lpcReducer, { LPCState } from './LearningPartnerConnection';
import unaffiliatedLearnerReducer, { UnaffiliatedLearnerState } from './UnaffiliatedLearnerReg';
import { OktaAuth, AuthState } from '@okta/okta-auth-js';

// The top-level state object
export interface ApplicationState {
    site: SiteState;
    certification: CertificationState;
    course: CourseState;
    exam: ExamState;
    upgradePath: UpgradePathState;
    curriculum: CurriculumState;
    calendar: CalendarState;
    entities: EntityListState;
    partners: LearningPartnerState;
    assistKit: AssistKitState;
    successStories: SuccessStoriesState;
    overview: OverviewState;
    technical: TechnicalState;
    certifications: CertificationsState;
    courses: CoursesState;
    exams: ExamsState;
    credentials: CredentialsState;
    sme: SmeState;
    plmt: PlmtState;
    cic: CICState;
    lpc: LPCState;
    unafilliated: UnaffiliatedLearnerState;
    oktaAuth: OktaAuth;
    authState?: AuthState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    site: siteReducer,
    certification: certificationReducer,
    course: courseReducer,
    exam: examReducer,
    upgradePath: upgradePathReducer,
    curriculum: curriculumReducer,
    calendar: calendarReducer,
    entities: entityListReducer,
    partners: learningPartnerReducer,
    assistKit: assistKitReducer,
    successStories: successStoriesReducer,
    overview: overviewReducer,
    technical: technicalReducer,
    certifications: certificationsReducer,
    courses: coursesReducer,
    exams: examsReducer,
    credentials: credentialsReducer,
    sme: smeReducer,
    plmt: plmtReducer,
    cic: cicReducer,
    lpc: lpcReducer,
    unafilliated: unaffiliatedLearnerReducer
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, ApplicationState, unknown, Action<string>>;

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
