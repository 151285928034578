import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, RadioButtonGroup, ResponsiveContext, DropButton, CheckBox, Grid, Paragraph, Heading, TextInput, Button,
    Card, CardHeader, CardBody, Image, Anchor
} from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store/';
import {
    retrieveCourses, retrieveCourseFormats, retrieveCourseLanguages, retrieveCourseTypes, retrieveCourseViews,
    retrieveCredentialTypes, retrieveCourseTechnologies, coursesSlice
} from './../../store/Courses';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { EntityInfo, KeyValuePair, EmptyGuid, SelectOption } from '../../models/Common';
import { HPEWindowInterface, formatString, updateList } from '../../Utilities';
import BasePage from '../../components/BasePage';
import SummaryCard from '../../components/SummaryCard';
import HtmlComponent from '../../components/HtmlComponent';
import { Search, Book } from "grommet-icons";
import _ from 'lodash';
import { firstBy } from 'thenby'
import parse from 'html-react-parser';
import queryString from 'query-string';

declare let window: HPEWindowInterface;


const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveCourses,
    retrieveCourseFormats,
    retrieveCourseLanguages,
    retrieveCourseTypes,
    retrieveCourseViews,
    retrieveCredentialTypes,
    retrieveCourseTechnologies,
    ...siteSlice.actions,
    ...coursesSlice.actions
}

interface CourseState {
    firstLoad: boolean;
    formats: string[] | null;
    optionsSet: boolean;
}

type CourseProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Courses extends React.PureComponent<CourseProps, CourseState> {
    constructor(props: CourseProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.Technologies_Label', 'ExternalPages.Course_Views_Label',
            'Datacard.Register_For_This_Label', 'ExternalPages.DeliveryLanguages_Label', 'Datacard.View_All_Courses_Label', 'ExternalPages.DeliveryLanguage_Label',
            'ExternalPages.Course_Introduction_Statement', 'ExternalPages.Course_Types_Label', 'ExternalPages.Narrow_by_Label', 'Datacard.Register_Label',
            'ExternalPages.Certification_Levels_Label', 'ExternalPages.Select_Deselect_All_Label',
            'ExternalPages.Search_Label', 'ExternalPages.No_Courses_Found_Statement', 'ExternalPages.DeliveryFormats_Label', 'ExternalPages.Reset_Filters_Label',
            'General.CertificationType_Sales', 'ExternalPages.Credentials_Label', 'Metadata.CoursesKeywords', 'Metadata.CoursesDescription',
            'ExternalPages.View_All_Courses_Card1_Title', 'ExternalPages.View_All_Courses_Card1_Body', 'ExternalPages.View_All_Courses_Card3_Title',
            'ExternalPages.View_All_Courses_Card3_Body', 'ExternalPages.View_All_Courses_Card4_Title', 'ExternalPages.View_All_Courses_Card4_Body'];
        let query = queryString.parse(window.location.search);
        let queryKeys = Object.keys(query);
        let formats: string | string[] | null = null;

        queryKeys.forEach((key, ndx) => {
            let caseKey = key.toLowerCase();

            switch (caseKey) {
                case 'formats':
                    {
                        formats = Array.isArray(query[key]) ? Array.from(query[key] as string[]) : String(query[key]).split(',');
                        break;
                    }
            }
        })
        this.state = {
            firstLoad: urlLanguage !== null,
            formats: formats,
            optionsSet: false
        };
        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.retrieveCourseFormats(urlLanguage);
        this.props.retrieveCourseLanguages(urlLanguage);
        this.props.retrieveCourseViews(urlLanguage);
        this.props.retrieveCredentialTypes(urlLanguage);
        this.props.retrieveCourseTechnologies(urlLanguage);
        this.props.retrieveCourses(urlLanguage);
        this.props.setActiveMenu('training-menu');
    }

    public componentDidMount() {
    }

    public componentDidUpdate(prevProps: CourseProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;

        if (!this.state.optionsSet && (this.props.courses.formats.length > 0)) {
            if (this.state.formats && this.state.formats.length > 0) {
                let list = JSON.stringify(this.props.courses.formats);
                let fmts: SelectOption[] = JSON.parse(list);

                fmts = updateList(fmts, 0, false, true);
                this.state.formats.forEach((format) => {
                    let option = fmts.find((cf: SelectOption) => cf.text.toLowerCase().indexOf(format.toLowerCase()) !== -1);

                    if (option) {
                        option.selected = true;
                    }
                });
                this.props.setFormats(fmts);
            }
            this.setState({ optionsSet: true });
        }
        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            if (!this.state.firstLoad) {
                this.props.retrieveCourseFormats(urlLanguage);
                this.props.retrieveCourseLanguages(urlLanguage);
                this.props.retrieveCourseViews(urlLanguage);
                this.props.retrieveCredentialTypes(urlLanguage);
                this.props.retrieveCourseTechnologies(urlLanguage);
                this.props.retrieveCourses(urlLanguage);
            }
            this.setState({ firstLoad: false });
        }
    }

    onRegister = (course: EntityInfo) => {
        let target = course.registrationUrl.toLowerCase().indexOf(course.publicSiteUrl.toLowerCase()) === -1
            ? "_blank"
            : "_self";

        try {
            window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `Course|ViewAll|${course.programId}` } }));
        } catch (error) {
            console.log(error);
        }
        window.open(course.registrationUrl, target);
    }

    onCheckedChange = (event: any) => {
        let values: string[] = event.target.id.split('-');
        let index = parseInt(values[1]);
        let checked = event.target.checked;

        this.props.setSelected(`${event.target.id}-${event.target.checked}`)
    }

    onViewChange = (event: any) => {
        this.props.setView(event.target.value);
    }

    onKeywordChange = (value: string) => {
        this.props.setKeywords(value);
    }

    onResetFilters = () => {
        this.props.setView('all');
        this.props.setSelected('technologies-0-true')
        this.props.setSelected('languages-0-true')
        this.props.setSelected('formats-0-true')
    }

    renderFilters = () => {
        let filters: any = [];
        let site = this.props.site;
        let courses = this.props.courses;
        let views = courses.views.map((view, index) => {
            return {
                disabled: false,
                id: `views-${index}`,
                name: `views-${index}`,
                value: view.value,
                label: view.text
            }
        });
        let selectedItem = courses.views.find((item) => item.value === courses.view) ?? courses.views[0]

        filters = [
            <DropButton className="hpe-button-box" key="views-filter" label={site.localizedStrings['ExternalPages.Course_Views_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {<RadioButtonGroup name="views" options={views} value={selectedItem.value} onChange={this.onViewChange} />}
                    </Box>
                }
            />,
            <DropButton className="hpe-button-box" key="technologies-filter" label={site.localizedStrings['ExternalPages.Technologies_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {courses.technologies.map((item, index) => <CheckBox key={`technologies-${index}`} id={`technologies-${index}`}
                            checked={item.selected}
                            label={item.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />,
            <DropButton className="hpe-button-box" key="languages-filter" label={site.localizedStrings['ExternalPages.DeliveryLanguages_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {courses.languages.map((item, index) => <CheckBox key={`languages-${index}`} id={`languages-${index}`}
                            checked={item.selected}
                            label={item.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />,
            <DropButton className="hpe-button-box" key="formats-filter" label={site.localizedStrings['ExternalPages.DeliveryFormats_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {_.map(courses.formats, (item, index) => <CheckBox key={`formats-${index}`} id={`formats-${index}`}
                            checked={item.selected}
                            label={item.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />
        ]
        return <Box gap="small">
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Box width="medium" >
                    <TextInput size='xsmall' placeholder={`${site.localizedStrings['ExternalPages.Search_Label']} ...`} value={courses.keywords} icon={<Search />}
                        onChange={event => this.onKeywordChange(event.target.value)} />
                </Box>
            </Box>
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Text weight="bold">{site.localizedStrings['ExternalPages.Narrow_by_Label']}:</Text>
                {filters}
                <Button secondary label={site.localizedStrings['ExternalPages.Reset_Filters_Label']} onClick={this.onResetFilters} />
            </Box>
        </Box>;
    }

    renderList = (size: string) => {
        let site = this.props.site;
        let courses = this.props.courses;
        let entities = this.props.courses.entities;
        let view = courses.view;
        let technologies = courses.technologies.filter((tech) => tech.selected).map((tech) => tech.value);
        let languages = courses.languages.filter((lang) => lang.selected).map((lang) => lang.value);
        let allLanguages = languages.some((lang) => lang === EmptyGuid);
        let allTech = technologies.some((tech) => tech === EmptyGuid);
        let keywords = courses.keywords;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let formats = courses.formats.filter((format) => format.selected).map((format) => format.value);
        let allFormats = formats.some((format) => format === EmptyGuid);

        technologies = technologies.filter((tech) => tech !== EmptyGuid);

        let sortedEntities = [...entities].filter((item) => item !== null).sort((left, right) => {
            return right.levelOrder - left.levelOrder;
        });
        let filtered = sortedEntities.filter((item) => {
            let hasView = true;

            switch (view) {
                case 'newOnly': {
                    hasView = item.isNew;
                    break;
                }
                case 'all': {
                    break;
                }
                default:
                    hasView = item.certificationType.value === view;
                    break;
            }

            return hasView && (allTech || (technologies.length !== 0 && item.technologies.some((t) => technologies.some((st) => st === t)))) &&
                (allLanguages || (languages.length !== 0 && item.deliveryLanguages.some((l) => languages.some((dl) => dl === l)))) &&
                (allFormats || (formats.length !== 0 && item.deliveryFormats.some((t) => formats.some((st) => st === t)))) &&
                (keywords === '' || item.name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1 ||
                    item.programId.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
        });
        let typeGrouping = _.groupBy(filtered, (o) => {
            return [o.certificationType.value]
        });

        return _.size(typeGrouping) !== 0
            ? <Box fill>
                {_.map(typeGrouping, (items, key) => {
                    return <Box key={`${key}-container`} border={{ side: "bottom", size: 'small' }} pad={{ top: "small", bottom: "small" }}>
                        <Heading level='4'>{key}</Heading>
                        {key !== site.localizedStrings['General.CertificationType_Sales'] && !_.some(courses.credentialTypes, (ct: any) => ct.text === key) &&
                            _.map(_.groupBy(_.concat([], _.flatMapDeep(items, (item) => {
                                let data = item.technologies.map((techId) => {
                                    let techOption = courses.technologies.find((t) => t.value === techId);

                                    return {
                                        ...item,
                                        technology: techOption?.text
                                    };
                                });
                                return data;
                            })).sort((l: any, r: any) => l.technology.localeCompare(r.technology)), (o: any) => { return [o.technology] }), (certs: any, technology) => {
                                return <Box key={`${technology}-box`} border={{ side: "bottom", size: 'small' }} pad={{ left: "small", top: "small", bottom: "small" }}>
                                    <Heading level='4'>{technology}</Heading>
                                    <Grid gap={{ row: "small", column: "small" }} rows="xsmall" columns="medium" >
                                        {certs.sort(firstBy("levelOrder").thenBy("name")).map((entity: any) => {
                                            let href = `${localUrl}/datacard/course/${entity.programId}`;
                                            let status = entity.releaseStatus.key !== 'Active'
                                                ? <Text>{entity.releaseStatus.value}</Text>
                                                : null;

                                            return <SummaryCard key={`entity-${entity.programId}`} data={entity} hoverIndicator href={href} status={status}
                                                linkLabel={site.localizedStrings['Datacard.Register_Label']} icon={<Book size="large" color="brand" />} />;
                                        })}
                                    </Grid>
                                </Box>
                            })
                        }

                        {(key === site.localizedStrings['General.CertificationType_Sales'] || _.some(courses.credentialTypes, (ct: any) => ct.text === key)) &&
                            <Grid gap={{ row: "small", column: "small" }} rows="xsmall" columns="medium" >
                                {items.sort(firstBy("levelOrder").thenBy("name")).map((entity) => {
                                    let href = `${localUrl}/datacard/course/${entity.programId}`;
                                    let status = entity.releaseStatus.key !== 'Active'
                                        ? <Text>{entity.releaseStatus.value}</Text>
                                        : null;

                                    return <SummaryCard key={`entity-${entity.programId}`} data={entity} hoverIndicator href={href} status={status}
                                        linkLabel={site.localizedStrings['Datacard.Register_Label']} icon={<Book size="large" color="brand" />} />;
                                })}
                            </Grid>
                        }
                    </Box>
                })
                }
            </Box >
            : !site.loading && <Box height="small" align="center" justify="center" alignContent="center">
                <Text weight="bold">{site.localizedStrings['ExternalPages.No_Courses_Found_Statement']}</Text>
            </Box>;
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Training_Label'], value: "" },
            { key: site.menuStrings['Datacard.View_All_Courses_Label'], value: "" }
        ];
        let title = site.menuStrings['Datacard.View_All_Courses_Label'];
        let formats = _.filter(this.props.courses.formats, (format) => format.value !== EmptyGuid);

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="entitylist_courses" {...this.props}
                    metadataDescription={site.localizedStrings['Metadata.CoursesDescription']} metadataKeywords={site.localizedStrings['Metadata.CoursesKeywords']}>
                    {site.stringsLoaded && <Box>
                        <Box gap="medium" fill="horizontal">
                            <Box fill="horizontal">
                                <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                            </Box>
                            <Box alignSelf="center" fill="horizontal" >
                                <Paragraph fill textAlign="center">
                                    {parse(site.localizedStrings['ExternalPages.Course_Introduction_Statement'])}
                                </Paragraph>
                            </Box>
                        </Box>
                        <Box direction="row-responsive" background="#F5F5F5" gap="medium" justify="center" pad="small" margin={{ top: "small", bottom: "medium" }}>
                            <Card width="medium">
                                <CardHeader>
                                    <Image fit="contain" src="images/view-all-courses/HPE202208240094492_450_0_72_RGB.jpg" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Courses_Card1_Title']}</Text>
                                    <Box gap="small">
                                        <HtmlComponent html={formatString(site.localizedStrings['ExternalPages.View_All_Courses_Card1_Body'], [`${localUrl}/trainingCalendar`])}/>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card width="medium">
                                <CardHeader>
                                    <Image fit="contain" src="images/view-all-courses/HPE202208250054125_450_0_72_RGB.jpg" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.Course_Types_Label']}</Text>
                                    <Box gap="small">
                                        <ul className="bullet-list">
                                            {
                                                formats.map((format, index) => <li key={`format-${index}`}>{format.text}</li>)
                                            }
                                        </ul>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card width="medium">
                                <CardHeader>
                                    <Image fit="contain" src="images/view-all-courses/HPE2022042205381_450_0_72_RGB.jpg" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Courses_Card3_Title']}</Text>
                                    <Box gap="small">
                                        <Paragraph>
                                            {parse(formatString(site.localizedStrings['ExternalPages.View_All_Courses_Card3_Body'], [`${localUrl}/hpepress`]))}
                                        </Paragraph>
                                    </Box>
                                </CardBody>
                            </Card>
                            <Card width="medium">
                                <CardHeader>
                                    <Image fit="contain" src="images/view-all-courses/HPE202208260022023_450_0_72_RGB.jpg" />
                                </CardHeader>
                                <CardBody gap="small">
                                    <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Courses_Card4_Title']}</Text>
                                    <Box gap="small">
                                        <Paragraph>{parse(formatString(site.localizedStrings['ExternalPages.View_All_Courses_Card4_Body'], [`${localUrl}/learningPartners`]))}</Paragraph>
                                    </Box>
                                </CardBody>
                            </Card>
                        </Box>
                    </Box>}
                    <Box gap="small" margin={{ top: "small" }}>
                        <Box direction="row-responsive" fill="horizontal" gap="small" pad="small" border={{ side: "bottom", color: 'brand', size: 'small' }}>
                            {this.renderFilters()}
                        </Box>
                        {this.renderList(size)}
                    </Box>
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Courses as any));
