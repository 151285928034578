import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Text, Image } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import _ from 'lodash';
import HtmlComponent from '../components/HtmlComponent';
import { formatString } from '../Utilities';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ILocateLearnerIdProps {
}

interface ILocateLearnerIdState {
    activeTab: number;
    showNotification: boolean;
    redirectEnabled: boolean;
}

type LocateLearnerIdProps = ILocateLearnerIdProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class LocateLearnerId extends React.PureComponent<LocateLearnerIdProps, ILocateLearnerIdState> {

    constructor(props: LocateLearnerIdProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.LocateLearnerIdKeywords', 'Metadata.LocateLearnerIdDescription',
            'ExternalPages.LocateLearnerId_Label', 'ExternalPages.LocateLearnerId_SuccessMessage', 'ExternalPages.LocateLearnerId_NotFoundMessage',
            'Datacard.HPE_Learner_ID_Label', 'MyLearning.Name_Label'];

        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('getstarted-menu');
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.localizedStrings['ExternalPages.LocateLearnerId_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Get_Started_Label'], value: `${localUrl}/getstarted` },
            { key: page, value: "" }
        ];

        if (!user) {
            return null;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="LocateLearnerId" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.LocateLearnerIdKeywords']}
            metadataDescription={site.localizedStrings['Metadata.LocateLearnerIdDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Box direction="row-responsive" fill gap="large">
                    <Box fill gap="large">
                        <Heading level={3} fill>{page}</Heading>
                        {!user.learnerId && <Paragraph fill size="large">
                            <HtmlComponent html={formatString(site.localizedStrings['ExternalPages.LocateLearnerId_NotFoundMessage'],
                                [`${localUrl}/unaffiliated-learner-reg`, `${localUrl}/support`])} />
                        </Paragraph>}
                        {user.learnerId && <Box gap="small" fill>
                            <Paragraph fill size="large">
                                <HtmlComponent html={site.localizedStrings['ExternalPages.LocateLearnerId_SuccessMessage']} />
                            </Paragraph>
                            <Box direction="row-responsive" gap="small">
                                <Text weight="bold">{`${site.localizedStrings['MyLearning.Name_Label']}:`}</Text>
                                <Text>{`${user?.firstName} ${user.lastName}`}</Text>
                            </Box>
                            <Box direction="row-responsive" gap="small">
                                <Text weight="bold">{`${site.localizedStrings['Datacard.HPE_Learner_ID_Label']}:`}</Text>
                                <Text>{user.learnerId}</Text>
                            </Box>
                        </Box>}
                    </Box>
                    <Box fill alignSelf="center">
                        <Image fit="contain" src="images/get-started/GettyImages_story_256_1600_0_72_RGB.jpg" />
                    </Box>
                </Box>
            </Box>}
        </BasePage>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(LocateLearnerId as any));
