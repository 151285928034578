import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair, SelectOption } from '../../models/Common';
import {
    Box, Heading, Text, Accordion, AccordionPanel, DataTable, RadioButtonGroup, Select, Form, FormField, Layer,
    TextInput, Button, DateInput, CheckBoxGroup, Header, Tip, RadioButton
} from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveCountries, retrieveActivities, retrieveParties, exportActivities, plmtSlice } from '../../store/Plmt';
import BasePage from '../../components/BasePage';
import parse from 'html-react-parser';
import _, { first } from 'lodash';
import { format, parseISO } from 'date-fns';
import { Search, Filter, FormClose, CircleInformation } from 'grommet-icons';
import { firstBy } from 'thenby';
import { Activity } from '../../models/plmt/Activity';
import { hasRole, isPLMTAdmin, getPLMTAdminRegions } from '../../Utilities';
import { PartyInfo } from '../../models/plmt/PartyInfo';

const mapDispatchToProps = {
    retrieveCountries,
    retrieveActivities,
    retrieveParties,
    exportActivities,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
    ...plmtSlice.actions
}

interface IActivitiesProps {
}

interface IActivitiesState {
    countries: SelectOption[];
    parties?: PartyInfo[];
    filters?: any;
    advancedFilters?: any;
    showFilters: boolean;
    filteredResults?: Activity[];
    loadingActivities: boolean;
    relationships: string[];
    availableTypes: string[];
}

type ActivitiesProps = IActivitiesProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Activities extends React.PureComponent<ActivitiesProps, IActivitiesState> {
    constructor(props: ActivitiesProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label',
            'MyLearning.Activities_label', 'MyLearning.PLMT_Activities_Statement', 'MyLearning.PLMT_More_Details', 'MyLearning.PLMT_Title',
            'MyLearning.PLMT_Activities_Detail_Instructions', 'MyLearning.Employee_Participation_Activities_Label', 'MyLearning.Region_Label',
            'MyLearning.Country_Label', 'MyLearning.Party_Label', 'MyLearning.First_Name_Label', 'MyLearning.Last_Name_Label',
            'MyLearning.Activity_Code_Label', 'MyLearning.Activity_Name_Label', 'MyLearning.Date_Acquired_Label', 'MyLearning.Expires_Label',
            'MyLearning.Type_Label', 'ExternalPages.Activities_Filters_Label', 'MyLearning.Export_Label', 'MyLearning.Party_Tooltip',
            'MyLearning.No_Activities_Statement', 'MyLearning.Loading_Activities_Statement', 'MyLearning.SigningEntity_Label',
            'MyLearning.SigningEntity_Tooltip', 'MyLearning.SigningEntity_BusinessRelationshipType_Label', 'MyLearning.Export_Activities_Tooltip',
            'MyLearning.Export_File_Type', 'MyLearning.Not_Authorized_Statement'];

        this.state = {
            showFilters: false,
            countries: [],
            loadingActivities: true,
            relationships: ['All'],
            availableTypes: []
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveCountries();
        this.props.setActiveMenu('admin-tools-menu');
    }

    componentDidUpdate() {
        let user = this.props.site.user;
        let isAdmin = isPLMTAdmin(user);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};

        if (user && !isAdmin) {
            if (!filters?.region && this.props.plmt.countries) {
                let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);

                if (country) {
                    this.setState({ filters: { region: country?.region } });
                    this.onRegionChange({ target: { value: country?.region } });
                }
            }
            if (filters?.region && !filters?.country && this.state.countries) {
                let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);

                if (country) {
                    filters.country = {
                        text: country.name,
                        value: country.code,
                        selected: false
                    };
                    this.setState({ filters: filters });
                    this.onCountryChange({ value: { value: country?.code } })
                }
            }
            if (user?.partyId && this.props.plmt.parties && filters?.region && filters?.country &&
                !filters?.party) {
                let party = _.find(this.props.plmt.parties, (p) => p.partyId === user?.partyId);

                if (party) {
                    this.onPartyChange({ value: party })
                }
                else if (filters?.idType === 'signingEntity') {
                    let request = {
                        country: filters.country.value,
                        idType: 'party',
                        partyId: !isPLMTAdmin(this.props.site.user) ? this.props.site.user?.partyId : undefined
                    }

                    this.onIdTypeChange({ target: { value: 'party' } })
                    this.props.retrieveParties(request);
                }
            }
        }
        if (filters?.region && this.state.countries.length === 0) {
            this.onRegionChange({ target: { value: filters?.region } })
        }
        if (this.props.plmt.activities && this.props.plmt.activities.length !== 0 && this.state.availableTypes.length == 0) {
            let availableTypes = _.sortBy(_.map(_.uniqBy(this.props.plmt.activities, activity => activity.type), a => a.type), a => a);

            this.setState({
                availableTypes: availableTypes,
                advancedFilters: {
                    type: availableTypes
                }
            });
        }
    }

    onRegionChange = (event: any) => {
        let selected = event.target.value;
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[selected].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });

        filters.region = selected;
        delete filters.party;
        delete filters.relationship;
        delete filters.country;
        this.props.setActivities(undefined);
        this.setState({
            filters: filters,
            countries: countries.sort(firstBy(a => a.text)),
            filteredResults: undefined,
            advancedFilters: {
                type: this.state.availableTypes
            }
        });
    }

    onCountryChange = (event: any) => {
        let selected = event.value;
        let country = _.find(this.props.plmt.countries, (country) => country.code == selected.value);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let countries: SelectOption[] = regionGrouping[filters.region].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });
        let request = {
            country: country?.code,
            idType: 'signingEntity',
            partyId: !isPLMTAdmin(this.props.site.user) ? this.props.site.user?.partyId : undefined
        }

        filters.country = selected;
        filters.idType = 'signingEntity';
        delete filters.party;
        delete filters.relationship;
        this.props.setActivities(undefined);
        this.props.retrieveParties(request);
        this.setState({
            filters: filters,
            countries: countries.sort(firstBy(a => a.text)),
            advancedFilters: {
                type: this.state.availableTypes
            }
        })
    }

    onPartyChange = (event: any) => {
        let party = event.value;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let parties = this.props.plmt.parties ?? [];
        let options = filters.idType == 'party'
            ? _.uniq(_.filter(parties, p => p.partyId == party.partyId))
            : _.filter(parties, p => p.signingEntityId == party.partyId);
        let relationships = _.uniq(_.map(options, opt => opt.relationship));

        this.props.setActivities(undefined);
        filters.party = party;
        if (relationships.length > 1) {
            filters.relationship = "All";
            relationships.splice(0, 0, "All");
        }
        relationships = relationships.sort(firstBy(a => a));
        this.setState({
            parties: undefined,
            filters: filters,
            filteredResults: undefined,
            relationships: relationships,
            advancedFilters: {
                type: this.state.availableTypes
            }

        });
        setTimeout(() => {
            this.onRelationshipChange({ value: relationships[0] });
        }, 1);
    }

    onPartySearch = (search: string) => {
        let parties = this.props.plmt.parties ?? [];
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let entities = filters.idType == 'party'
            ? _.filter(parties, (entity) => {
                let partyId = `${entity.partyId}`.toLowerCase();

                return (entity.name && entity.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (partyId.indexOf(search.toLowerCase()) !== -1);
            })
            : _.filter(parties, (entity) => {
                let signingEntityId = `${entity.signingEntityId}`.toLowerCase();

                return entity.signingEntityId !== null && (entity.signingEntity.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    signingEntityId.indexOf(search.toLowerCase()) !== -1);
            });

        this.setState({ parties: entities });
    }

    onShowFilters = () => {
        this.setState({ showFilters: true });
    }

    onHideFilters = () => {
        this.setState({ showFilters: false });
    }

    filterByKeywords = (keywords: string) => {
        let activities: Activity[] = this.props.plmt.activities ?? [];

        if (keywords) {
            let containsKeywords = keywords.toLowerCase();
            let exactKeywords = keywords.replaceAll('"', '');

            activities = keywords.startsWith('"') && keywords.endsWith('"')
                ? _.filter(activities, (activity) => activity.name === exactKeywords ||
                    activity.firstName === exactKeywords ||
                    activity.lastName === exactKeywords ||
                    activity.learnerId === exactKeywords ||
                    activity.code === exactKeywords ||
                    activity.type === exactKeywords)
                : _.filter(activities, (activity) => activity.name.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    activity.firstName.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    activity.lastName.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    activity.code.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    activity.learnerId.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    activity.type.toLowerCase().indexOf(containsKeywords) !== -1);
        }
        return activities;
    }

    onApplyFilters = () => {
        let filters = this.state.advancedFilters;
        let keywords = this.state.filters?.keywords;
        let activities: Activity[] = this.filterByKeywords(keywords);

        this.setState({ filteredResults: [] });
        setTimeout(() => {
            if (filters.type) {
                activities = _.filter(activities, (activity) => _.some(filters.type, (type) => activity.type === type));
            }
            if (filters.dateAcquired) {
                let startDate = parseISO(filters.dateAcquired[0]);
                let endDate = parseISO(filters.dateAcquired[1]);

                activities = _.filter(activities, (activity) => {
                    let dateAcquired = activity.dateAcquired ? parseISO(activity.dateAcquired) : undefined;

                    return dateAcquired !== undefined && dateAcquired >= startDate && dateAcquired <= endDate;
                });
            }
            if (filters.dateExpired) {
                let startDate = parseISO(filters.dateExpired[0]);
                let endDate = parseISO(filters.dateExpired[1]);

                activities = _.filter(activities, (activity) => {
                    let dateExpired = activity.expirationDate ? parseISO(activity.expirationDate) : undefined;

                    return dateExpired !== undefined && dateExpired >= startDate && dateExpired <= endDate;
                });
            }

            this.setState({ filteredResults: activities, showFilters: false });
        }, 250);
    }

    onResetFilters = () => {
        let keywords = this.state.filters.keywords;
        let activities: Activity[] = this.filterByKeywords(keywords);

        this.setState({
            advancedFilters: {
                type: this.state.availableTypes
            },
            filteredResults: activities
        });
    }

    onKeywordsChange = (event: any) => {
        let keywords = event.target.value;

        this.setState({ filteredResults: [] });
        setTimeout(() => {
            let activities: Activity[] = this.filterByKeywords(keywords);

            this.setState({ filteredResults: activities });
        }, 50);
    }

    onActivitiesLoaded = () => {
        this.setState({ loadingActivities: false });
    }

    onExport = (event: any) => {
        let selected = event.value;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let exportRequest = {
            fileType: selected.value,
            idType: filters.idType,
            relationship: filters.relationship,
            country: filters.country?.value,
            partyId: filters.idType == 'party' ? filters.party.partyId : filters.party.signingEntityId
        };
        this.props.exportActivities(exportRequest, this.onExportDone);
    }

    onExportDone = () => {
        let filters = JSON.parse(JSON.stringify(this.state.filters));

        delete filters.export;
        this.setState({ filters: filters });
    }

    getRegions = () => {
        let site = this.props.site;
        let user = site.user;
        let regionGroups = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let regions = _.map(regionGroups, (countries, region) => region).sort(firstBy(a => a)) ?? [];
        let isAdmin = isPLMTAdmin(user);
        let adminRegions = getPLMTAdminRegions(user);
        let filters = JSON.parse(JSON.stringify(this.state.filters ?? {}));

        if (isAdmin) {
            regions = _.filter(regions, (region) => _.some(adminRegions, (adminRegion) => adminRegion == region));
        }
        if (regions.length === 1 && !filters.region) {
            filters.region = regions[0];
            this.setState({ filters: filters });
        }

        return regions;
    }

    onUpdateActivities = () => {
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let request = {
            partyId: filters.party?.partyId,
            country: filters.country?.value,
            relationship: filters.relationship
        }

        this.props.retrieveActivities(request);
    }

    onCountrySearch = (search: string) => {
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[filters?.region].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });

        countries = _.filter(countries, country => country.value.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            country.text.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        this.setState({ countries: countries });
    }

    getOptions = () => {
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let parties = this.state.parties ?? this.props.plmt.parties ?? [];
        let options = filters.idType == 'party'
            ? _.map(_.uniqBy(parties, p => p.partyId), party => {
                return {
                    ...party,
                    partyId: party.partyId,
                    name: parse(party.name).toString()
                }
            })
            : _.map(_.groupBy(_.filter(parties, party => party.signingEntityId !== null), p => [p.signingEntityId, p.signingEntity]), (list, key) => {
                var keys = key.split(',');
                var party = list[0];

                return {
                    ...party,
                    partyId: Number(keys[0]),
                    name: parse(keys[1]).toString()
                }
            });

        return _.sortBy(options, opt => opt.name);
    }

    onIdTypeChange = (evnt: any) => {
        let idType = evnt.target.value;
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let request = {
            country: filters.country?.value,
            idType: idType,
            partyId: !isPLMTAdmin(this.props.site.user) ? this.props.site.user?.partyId : undefined
        }

        filters.idType = idType;
        delete filters.party;
        delete filters.relationship;
        this.props.setActivities(undefined);
        this.props.setParties(undefined);
        this.setState({
            parties: undefined, filters: filters, filteredResults: undefined, relationships: []
        });
        this.props.retrieveParties(request);
    }

    onRelationshipChange = (event: any) => {
        let relationship = event.value;
        let loadingActivities = true;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let request = {
            partyId: filters.party.partyId,
            country: filters.country?.value,
            relationship: relationship,
            idType: filters.idType
        }

        this.props.setActivities(undefined);
        filters.relationship = relationship;
        this.setState({
            filters: filters, loadingActivities, filteredResults: undefined, availableTypes: []
        });
        this.props.retrieveActivities(request, this.onActivitiesLoaded);
    }

    onDeselectTypes = () => {
        this.setState({
            advancedFilters: {
                type: []
            }
        });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.Activities_label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let results: Activity[] = this.state.filteredResults ?? this.props.plmt.activities ?? [];
        let regions = this.getRegions();
        let options = this.getOptions();
        let disableFilters = (this.props.plmt.activities ?? []).length === 0;
        let exportOptions = [{
            text: "CSV",
            value: "csv"
        }, {
            text: "Excel",
            value: "xlsx"
        }];
        let isReadyForActivities = this.state.filters?.region && this.state.filters?.country && this.state.filters?.party &&
            this.state.filters?.relationship;
        let isAdmin = isPLMTAdmin(user);
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isDistributor = hasRole(user, ["DISTI"]) && (isPEM || isPPA);
        let isPlmtUser = isAdmin || isPEM || isPPA || isDistributor;
        let splitLength = Math.round(this.state.availableTypes.length / 2);
        let maxLength = this.state.availableTypes.length;

        if (!user) {
            return null;
        }
        if (user && !isPlmtUser) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="Activities" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={3}>{page}</Heading>
                <Box pad={{ top: "small", bottom: "small" }} gap="medium">
                    <Text>{site.localizedStrings['MyLearning.PLMT_Activities_Statement']}</Text>
                    <Accordion>
                        <AccordionPanel label={site.localizedStrings['MyLearning.PLMT_More_Details']}>
                            <Box pad="small" fill>
                                {parse(site.localizedStrings['MyLearning.PLMT_Activities_Detail_Instructions'])}
                            </Box>
                        </AccordionPanel>
                    </Accordion>
                    <Heading level={4}>{site.localizedStrings['MyLearning.Employee_Participation_Activities_Label']}</Heading>
                    <Form value={this.state.filters} onChange={nextValue => this.setState({ filters: nextValue })}>
                        <Box gap="medium">
                            <Box direction='row-responsive' gap="large">
                                <Box gap="small">
                                    <FormField label={<Text weight='bold'>{site.localizedStrings['MyLearning.Region_Label']}</Text>} name="region">
                                        <RadioButtonGroup name="region" id="region-input" options={regions} pad='small' disabled={!isAdmin}
                                            onChange={this.onRegionChange} />
                                    </FormField>
                                </Box>
                                <Box gap="small">
                                    <Box gap="small">
                                        <Text weight='bold'>{site.localizedStrings['MyLearning.Country_Label']}</Text>
                                        <Select name="country" id="country-input" options={this.state.countries} labelKey='text' valueKey='value'
                                            dropHeight='small' onChange={this.onCountryChange} onSearch={this.onCountrySearch}
                                            disabled={!isAdmin || this.state.filters?.region === undefined} />
                                    </Box>
                                    <Box gap="xxsmall" width="medium">
                                        <Box direction="row" gap="small">
                                            <RadioButton name="signingEntityIdType" value="signingEntity" checked={this.state.filters?.idType === "signingEntity"}
                                                disabled={this.state.filters?.country === undefined} onChange={this.onIdTypeChange}
                                                label={<Text weight="bold">{site.localizedStrings['MyLearning.SigningEntity_Label']}</Text>} />
                                            <RadioButton name="partyIdType" value="party" checked={this.state.filters?.idType === "party"}
                                                disabled={this.state.filters?.country === undefined} onChange={this.onIdTypeChange}
                                                label={<Text weight="bold">{site.localizedStrings['MyLearning.Party_Label']}</Text>} />
                                            <Tip content={site.localizedStrings[this.state.filters?.idType === "party" ? 'MyLearning.Party_Tooltip' : 'MyLearning.SigningEntity_Tooltip']}>
                                                <CircleInformation size="small" />
                                            </Tip>
                                        </Box>
                                        <Select name="party" id="party-input" options={options} labelKey='name' valueKey='partyId'
                                            dropHeight='small' onChange={this.onPartyChange} onSearch={this.onPartySearch}
                                            disabled={(!isAdmin && !isDistributor) || this.state.filters?.country === undefined} />
                                    </Box>
                                    <Box gap="small" width="medium">
                                        <Box direction="row" gap="small">
                                            <Text weight='bold'>{site.localizedStrings['MyLearning.SigningEntity_BusinessRelationshipType_Label']}</Text>
                                        </Box>
                                        <Select name="relationship" id="relationship-input" options={this.state.relationships}
                                            dropHeight='small' onChange={this.onRelationshipChange}
                                            disabled={!this.state.filters?.party || this.state.relationships.length <= 1} />
                                    </Box>
                                    <Box gap="small" width="small">
                                        <Box direction="row" gap="small">
                                            <Text weight='bold'>{site.localizedStrings['MyLearning.Export_Label']}</Text>
                                            <Tip content={site.localizedStrings['MyLearning.Export_Activities_Tooltip']}>
                                                <CircleInformation size="small" />
                                            </Tip>
                                        </Box>
                                        <Select name="export" id="export-input" options={exportOptions} disabled={disableFilters} onChange={this.onExport}
                                            labelKey='text' valueKey='value' placeholder={site.localizedStrings['MyLearning.Export_File_Type']} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box direction='row-responsive' gap="small">
                                <Box width="medium">
                                    <TextInput name="keywords" id="keywords-input" placeholder="Search" reverse type="search"
                                        icon={<Search id="search-icon" />}
                                        onChange={this.onKeywordsChange} disabled={!isReadyForActivities} />
                                </Box>
                                <Button icon={<Filter />} onClick={this.onShowFilters} disabled={this.state.showFilters || disableFilters} />
                            </Box>
                        </Box>
                    </Form>
                    {(!this.state.loadingActivities && results.length !== 0) && <DataTable fill pin="header" primaryKey={false}
                        data={results}
                        columns={[
                            {
                                property: 'firstName',
                                header: <Text>{site.localizedStrings['MyLearning.First_Name_Label']}</Text>
                            },
                            {
                                property: 'lastName',
                                header: <Text>{site.localizedStrings['MyLearning.Last_Name_Label']}</Text>
                            },
                            {
                                property: 'learnerId',
                                header: <Text>HPE Learner ID</Text>
                            },
                            {
                                property: 'type',
                                header: <Text>{site.localizedStrings['MyLearning.Type_Label']}</Text>
                            },
                            {
                                property: 'code',
                                header: <Text>{site.localizedStrings['MyLearning.Activity_Code_Label']}</Text>
                            },
                            {
                                property: 'name',
                                header: <Text>{site.localizedStrings['MyLearning.Activity_Name_Label']}</Text>
                            },
                            {
                                property: 'dateAcquired',
                                header: <Text>{site.localizedStrings['MyLearning.Date_Acquired_Label']}</Text>,
                                render: datum => <Text>{datum.dateAcquired ? format(parseISO(datum.dateAcquired), 'MM/dd/yyyy') : 'N/A'}</Text>
                            },
                            {
                                property: 'expirationDate',
                                header: <Text>{site.localizedStrings['MyLearning.Expires_Label']}</Text>,
                                render: datum => <Text>{datum.expirationDate ? format(parseISO(datum.expirationDate), 'MM/dd/yyyy') : ''}</Text>
                            }
                        ]}
                    />}
                    {(isReadyForActivities && (results.length === 0) && !this.state.loadingActivities) &&
                        <Box direction="row" fill="horizontal" align="center" justify="center">
                            <Text>{site.localizedStrings['MyLearning.No_Activities_Statement']}</Text>
                        </Box>}
                    {(isReadyForActivities && (results.length === 0) && this.state.loadingActivities) &&
                        <Box direction="row" fill="horizontal" align="center" justify="center">
                            <Text>{site.localizedStrings['MyLearning.Loading_Activities_Statement']}</Text>
                        </Box>}
                </Box>
                {this.state.showFilters && <Layer animation="slide" onEsc={this.onHideFilters}>
                    <Box pad="small">
                        <Header>
                            <Heading level={4}>{site.localizedStrings['ExternalPages.Activities_Filters_Label']}</Heading>
                            <Button icon={<FormClose />} onClick={this.onHideFilters} autoFocus />
                        </Header>
                        <Form value={this.state.advancedFilters} onChange={nextValue => this.setState({ advancedFilters: nextValue })}
                            onSubmit={this.onApplyFilters} onReset={this.onResetFilters} >
                            <Box direction="row-responsive" gap="medium">
                                <Box gap="small">
                                    <FormField label="Type" name="type">
                                        <Box direction="row-responsive" gap="small">
                                            <CheckBoxGroup options={this.state.availableTypes.slice(0, splitLength)} name='type' gap="xxsmall" />
                                            {this.state.availableTypes.length > splitLength &&
                                                <CheckBoxGroup options={this.state.availableTypes.slice(splitLength, maxLength)} name='type' gap="xxsmall" />
                                            }
                                        </Box>
                                    </FormField>
                                    <Box direction='row-responsive' justify='between' gap="small">
                                        <Button secondary label="Deselect all" onClick={this.onDeselectTypes} />
                                        <Button type='reset' secondary label="Reset filters" />
                                        <Button type='submit' primary label="Apply filters" />
                                    </Box>
                                </Box>
                                <Box gap="small">
                                    <FormField label="Date Acquired" name='dateAcquired'>
                                        <DateInput name='dateAcquired' format="mm/dd/yyyy-mm/dd/yyyy" defaultValue={[]} calendarProps={{ size: "small" }} />
                                    </FormField>
                                    <FormField label="Date Expired" name='dateExpired'>
                                        <DateInput name='dateExpired' format="mm/dd/yyyy-mm/dd/yyyy" defaultValue={[]} calendarProps={{ size: "small" }} />
                                    </FormField>
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </Layer>}
            </Box>
            }
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Activities as any));
