import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import { Box, Paragraph, Heading, Text, Button, Image } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveLearnerIds } from '../../store/Admin';
import { saveAffiliationRequest, retrieveLearningPartners, retrieveInstructor } from '../../store/LearningPartnerConnection';
import BasePage from '../../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { AffiliationRequest as RequestModel } from '../../models/lpc/AffiliationRequest'
import { LearningPartner } from '../../models/LearningPartner';
import AffiliationRequest from '../../components/AffiliationRequest';
import { formatString } from '../../Utilities';

const mapDispatchToProps = {
    retrieveLearnerIds,
    retrieveLocalizedStrings,
    retrieveInstructor,
    saveAffiliationRequest,
    retrieveLearningPartners,
    ...siteSlice.actions,
}

interface IInstructorRegistrationProps {
}

interface IInstructorRegistrationState {
    regStep: number;
    isFormValid: boolean;
    registrationForm?: RequestModel;
    learningPartners?: LearningPartner[];
    instructorLoaded: boolean;
    formInitialized: boolean;
    reloaded: boolean;
    reloadInstructor: boolean;
}

type InstructorRegistrationProps = IInstructorRegistrationProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class InstructorRegistration extends React.PureComponent<InstructorRegistrationProps, IInstructorRegistrationState> {
    emailInputRef: any;

    constructor(props: InstructorRegistrationProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.LpcKeywords', 'Metadata.LpcDescription', 'MyLearning.Name_Label',
            'ExternalPages.Back_Label', 'ExternalPages.Instructor_Registration_Label', 'ExternalPages.LPC_InstructorRegistration_IntroStatement',
            'ExternalPages.InstructorType_Label', 'ExternalPages.LPC_InstructorRegistration_Title', 'ExternalPages.Cancel_Label',
            'ExternalPages.LPC_InstructorRegistration_Instruction', 'ExternalPages.Next_Label', 'ExternalPages.Submit_Label',
            'Datacard.HPE_Learner_ID_Label', 'ExternalPages.Learning_Partner_Label', 'ExternalPages.RelationshipType_Label',
            'ExternalPages.Registration_Thankyou_Label', 'ExternalPages.LPC_InstructorRegistration_Thankyou_Statement',
            'ExternalPages.LPC_InstructorRegistration_AlreadyRegistered_Statement', 'ExternalPages.LPC_AffiliationRequest_Instruction'];

        this.state = {
            regStep: 0,
            isFormValid: false,
            instructorLoaded: false,
            formInitialized: false,
            reloaded: false,
            reloadInstructor: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveLearningPartners();
        this.props.setActiveMenu('resources-menu');
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public componentDidUpdate() {
        let site = this.props.site;
        let userInfo = site.userInfo;
        let user = site.user;

        // reloadInstructor and reloaded are used for swtich user functionality
        if (user && ((!this.props.lpc.instructor && !this.state.instructorLoaded) || (this.state.reloadInstructor && !site.loadingUser))) {
            this.setState({ instructorLoaded: true, reloadInstructor: false, reloaded: site.actualUser !== undefined });
            this.props.retrieveInstructor();
        }
        if (site.actualUser && this.state.instructorLoaded && !this.state.reloadInstructor && !this.state.reloaded && !site.loadingUser) {
            this.setState({ reloadInstructor: true });
        }
        if (!site.actualUser && this.state.reloaded && !site.loadingUser && !this.state.reloadInstructor) {
            this.setState({ reloadInstructor: true });
        }
    }

    isValidated = (evt: any) => {
        this.setState({ isFormValid: evt.valid });
    }

    processNext = () => {
        let regStep = this.state.regStep + 1;

        this.setState({ regStep: regStep });
    }

    processBack = () => {
        let regStep = this.state.regStep - 1;


        this.setState({ regStep: regStep });
    }

    onCancel = () => {
        this.setState({ regStep: 0 });
    }

    onSubmitForm = (value: any) => {
        this.props.saveAffiliationRequest(value, this.processNext);
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let userInfo = site.userInfo;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.localizedStrings['ExternalPages.Instructor_Registration_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: site.menuStrings['ExternalPages.LearningPartnerConnection_Label'], value: "" },
            { key: page, value: "" }
        ];
        let regStep = this.state.regStep;

        if (!user) {
            return null;
        }

        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="InstructorRegistration" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.LpcKeywords']}
            metadataDescription={site.localizedStrings['Metadata.LpcDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Heading level={1} textAlign="center" fill>{site.localizedStrings['ExternalPages.LPC_InstructorRegistration_Title']}</Heading>
                <Box direction="row-responsive" fill gap="large" pad={{ top: "medium", left: "small", bottom: "small" }} >
                    {this.props.lpc.instructor && <Box fill>
                        <Paragraph fill size="large">
                            {parse(formatString(site.localizedStrings['ExternalPages.LPC_InstructorRegistration_AlreadyRegistered_Statement'],
                                [`${localUrl}/cic`]))}
                        </Paragraph>
                    </Box>}
                    {!this.props.lpc.instructor && <Box fill>
                        {regStep === 0 && <Box fill gap="medium">
                            <Paragraph fill size="large">
                                {parse(site.localizedStrings['ExternalPages.LPC_InstructorRegistration_IntroStatement'])}
                            </Paragraph>
                            <Box width="small">
                                <Button primary label={site.menuStrings['Datacard.Get_Started_Label']} onClick={this.processNext} />
                            </Box>
                        </Box>}
                        {regStep === 1 && <Box gap="large" width="large">
                            <Box gap="small">
                                <Box direction="row-responsive" gap="small">
                                    <Text weight="bold">{`${site.localizedStrings['MyLearning.Name_Label']}:`}</Text>
                                    <Text>{`${user.firstName} ${user.lastName}`}</Text>
                                </Box>
                                <Box direction="row-responsive" gap="small">
                                    <Text weight="bold">{`${site.localizedStrings['Datacard.HPE_Learner_ID_Label']}:`}</Text>
                                    <Text>{user.learnerId}</Text>
                                </Box>
                            </Box>
                            <AffiliationRequest onSubmit={this.onSubmitForm} onCancel={this.onCancel} {...this.props} registration={true} />
                        </Box>}
                        {regStep === 2 && <Box fill gap="medium">
                            <Heading level={3}>
                                {site.localizedStrings['ExternalPages.Registration_Thankyou_Label']}
                            </Heading>
                            <Paragraph fill>
                                {parse(site.localizedStrings['ExternalPages.LPC_InstructorRegistration_Thankyou_Statement'])}
                            </Paragraph>
                        </Box>}
                    </Box>}
                    <Box fill>
                        <Image fit="contain" src="images/lpc/GettyImages-1764854625_1600_0_72_RGB.jpg" />
                    </Box>
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(InstructorRegistration as any));
