import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, Anchor, RadioButtonGroup, ResponsiveContext, TextInput, Grid, DropButton, CheckBox, Paragraph, Heading,
    Button, Accordion, AccordionPanel, Image, RadioButton, Layer, Card, CardHeader, CardBody, CardFooter
} from 'grommet';
import { Search, Clear } from 'grommet-icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import {
    retrieveCredentials, retrieveTechnologies, retrieveTypes, retrieveViews, credentialsSlice
} from '../../store/Credentials';
import { KeyValuePair, RelatedItem, SelectOption, EmptyGuid } from '../../models/Common';
import parse from 'html-react-parser';
import { retrieveLocalizedStrings, retrieveOptions, siteSlice } from '../../store/Site';
import BasePage from '../../components/BasePage';
import { formatString, updateList } from '../../Utilities';
import SummaryCard from '../../components/SummaryCard';
import _ from 'lodash';
import queryString from 'query-string';
import { firstBy } from 'thenby'
import { format, parseISO, compareDesc } from 'date-fns';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveCredentials,
    retrieveTechnologies,
    retrieveTypes,
    retrieveViews,
    retrieveOptions,
    ...siteSlice.actions,
    ...credentialsSlice.actions
}

interface CredentialsState {
    showAll: boolean;
    firstLoad: boolean;
    optionsSet: boolean;
    paramsSet: boolean;
    techOptions: SelectOption[];
    showEmployeeWarning: boolean;
}

interface ICredentialsProps {
    activeTab?: string;
}

type CredentialsProps =
    ApplicationState // ... state we've requested from the Redux store
    & ICredentialsProps
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Credentials extends React.PureComponent<CredentialsProps, CredentialsState> {
    tooltipRef: any;

    constructor(props: CredentialsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : this.props.activeTab ?? ''
            : this.props.activeTab ?? '';
        let activeTab = 0;
        let keys = ['ExternalPages.Technologies_Label', 'ExternalPages.Credential_View_Label', 'ExternalPages.Narrow_by_Label',
            'Datacard.Register_Label', 'ExternalPages.Search_Label', 'ExternalPages.No_Credentials_Found_Statement',
            'ExternalPages.Reset_Filters_Label', 'Metadata.CredentialsKeywords', 'Metadata.CredentialsDescription',
            'ExternalPages.Status_Label', 'ExternalPages.Inactive_On_Label', 'ExternalPages.Credentials_Section_Statement',
            'ExternalPages.Credentials_Section_Title', 'ExternalPages.Credentials_Intro_Statement', 'ExternalPages.Credentials_For_Employees_Label',
            'ExternalPages.EmployeeCertification_Warning_Statement', 'ExternalPages.Yes_Label', 'ExternalPages.No_Label'];

        switch (tab.toLowerCase()) {
            case 'credentials':
                activeTab = 1;
                break;
        }

        this.state = {
            showAll: urlLanguage !== 'ja',
            firstLoad: urlLanguage !== null,
            paramsSet: false,
            optionsSet: false,
            techOptions: [],
            showEmployeeWarning: false
        };
        this.tooltipRef = React.createRef();
        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.retrieveTechnologies(urlLanguage);
        this.props.retrieveTypes(urlLanguage);
        this.props.retrieveViews(urlLanguage);
        this.props.retrieveOptions("Technology", "", this.setTechOptions);
        this.retrieveCredentials()
        this.props.setActiveMenu('technical-certification-menu');
    }

    public componentDidMount() {
    }

    public componentDidUpdate(prevProps: CredentialsProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;
        let tabTechnologies = this.props.credentials.technologies;

        if (!this.state.optionsSet && (tabTechnologies.length > 1) && (this.state.techOptions.length !== 0)) {
            this.parseUrl();
            this.setState({ optionsSet: true });
        }
        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            this.setState({ showAll: urlLanguage !== 'ja' });
            if (!this.state.firstLoad) {
                this.props.retrieveTechnologies(urlLanguage);
                this.props.retrieveTypes(urlLanguage);
                this.props.retrieveViews(urlLanguage);
                this.retrieveCredentials();
            }
            this.setState({ firstLoad: false });
        }
    }

    setTechOptions = (options: SelectOption[]) => {
        this.setState({ techOptions: options })
    }

    parseUrl = (override?: boolean) => {
        let query = queryString.parse(window.location.search);
        let queryKeys = Object.keys(query);

        queryKeys.forEach((key) => {
            let caseKey = key.toLowerCase();

            switch (caseKey) {
                case 'credentialview':
                    if (!this.state.paramsSet || override) {
                        this.props.setCredentialView(String(query[key]));
                    }
                    break;
                case 'technologies':
                    let tabTechnologies = this.props.credentials.technologies;
                    let urlTechnologies = Array.isArray(query[key]) ? query[key] as string[] : String(query[key]).split(',');
                    let techOptions = this.state.techOptions.filter((option) => urlTechnologies.some((st: any) => st.toLowerCase() === option.text.toLowerCase()))
                        .map((option) => option.value);
                    let technologies = tabTechnologies.map((rt: any) => {
                        let json = JSON.stringify(rt);
                        let newTech: SelectOption = JSON.parse(json);

                        newTech.selected = techOptions.some((st) => st === rt.value);

                        return newTech;
                    });

                    this.props.setCredentialTechnologies(technologies);
                    break;
                case 'status':
                    if (!this.state.paramsSet || override) {
                        this.props.setCredentialStatus(query[key]);
                    }
                    break;
            }
        });
        this.setState({ paramsSet: true })
    }

    retrieveCredentials = () => {
        this.props.retrieveCredentials();
    }

    onCheckedChange = (event: any) => {
        let values: string[] = event.target.id.split('-');
        let index = parseInt(values[1]);
        let checked = event.target.checked;
        let list = JSON.stringify(this.props.credentials.technologies);
        let newList: any[] = JSON.parse(list);

        newList = updateList(newList, index, checked);
        this.props.setCredentialTechnologies(newList);
    }

    onViewChange = (event: any) => {
        this.props.setCredentialView(event.target.value);
    }

    onStatusChange = (event: any) => {
        this.props.setCredentialStatus(event.target.value);
        setTimeout(() => {
            this.retrieveCredentials();
        }, 150);
    }

    onKeywordChange = (value: string) => {
        this.props.setCredentialKeywords(value);
    }

    toggoleShowAll = () => {
        this.setState({ showAll: !this.state.showAll });
    }

    onResetFilters = () => {
        let status = this.props.credentials.status;
        let list = JSON.stringify(this.props.credentials.technologies);
        let newList: any[] = JSON.parse(list);

        newList = updateList(newList, 0, true);
        this.props.setCredentialTechnologies(newList);
        this.props.setCredentialView('all');
        if (status !== 'Active') {
            this.props.setCredentialStatus('Active');
            setTimeout(() => {
                this.retrieveCredentials();
            }, 500);
        }
    }

    renderFilters = () => {
        let filters: any = [];
        let site = this.props.site;
        let keywords = this.props.credentials.keywords;
        let status = this.props.credentials.status;
        let tabViews = this.props.credentials.views;
        let views = tabViews.map((view, index) => {
            return {
                disabled: false,
                id: `views-${index}`,
                name: `views-${index}`,
                value: view.value,
                label: view.text
            }
        });
        let tabView = this.props.credentials.view
        let selectedView = tabViews.find((item) => item.value === tabView) ?? tabViews[0]
        let tabTypes = this.props.credentials.types;
        let types = tabTypes.map((type, index) => {
            let option = {
                disabled: false,
                id: `types-${index}`,
                name: `types-${index}`,
                value: type.value,
                label: type.text
            };

            return <RadioButton {...option} key={`radio-types-${index}`} checked={status === option.value} onChange={this.onStatusChange} />
        });
        let viewTile = site.localizedStrings['ExternalPages.Credential_View_Label'];
        let tabTechnologies = this.props.credentials.technologies;

        filters = [
            <DropButton key="views-filter" className="hpe-button-box" label={viewTile}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {<RadioButtonGroup name="views" options={views} value={selectedView.value} onChange={this.onViewChange} />}
                    </Box>
                }
            />,
            <DropButton key="technologies-filter" className="hpe-button-box" label={site.localizedStrings['ExternalPages.Technologies_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }} width={{ min: "240px" }}>
                        {tabTechnologies.map((tech, index) => <CheckBox key={`technologies-${index}`}
                            id={`technologies-${index}`}
                            checked={tech.selected}
                            label={tech.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />,
            <DropButton key="types-filter" className="hpe-button-box" label={site.localizedStrings['ExternalPages.Status_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }} gap="small" overflow="auto">
                        {types}
                    </Box>
                }
            />
        ];

        return <Box gap="small">
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Box width="medium" >
                    <TextInput size='xsmall' placeholder={`${site.localizedStrings['ExternalPages.Search_Label']} ...`} value={keywords} icon={<Search />}
                        onChange={event => this.onKeywordChange(event.target.value)} />
                </Box>
            </Box>
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Text weight="bold">{site.localizedStrings['ExternalPages.Narrow_by_Label']}:</Text>
                {filters}
                <Button secondary label={site.localizedStrings['ExternalPages.Reset_Filters_Label']} onClick={this.onResetFilters} />
            </Box>
        </Box>;
    }

    renderCard = (localUrl: string, entity: any) => {
        let site = this.props.site;
        let href = entity.isSalesPro
            ? entity.registrationUrl
            : `${localUrl}/datacard/${(entity.isCredential ? 'exam' : 'certification')}/${entity.programId}`;
        let status = entity.releaseStatus.key !== 'Active'
            ? <Text size="small">{entity.releaseStatus.value}</Text>
            : null;
        let description = null;
        let registerLabel = null;
        let icon = !entity?.hasDigitalBadge ? <Clear size="large" color="brand" /> : null;
        let tabStatus = this.props.credentials.status;

        switch (entity.releaseStatus.key) {
            case 'Transitional':
            case 'Transitional-Regional':
                if (tabStatus !== "Active" && _.some(entity.replacedBy)) {
                    description = <Box>
                        <Text>Replaced By:</Text>
                        <Box pad={{ left: "xsmall" }}>
                            {
                                _.map(entity.replacedBy, (item: RelatedItem) => {
                                    let targetUrl = `${localUrl}/datacard/${(entity.isCredential ? 'exam' : 'certification')}/${item.modifiedProgramId}`;

                                    return item.isActive
                                        ? <Anchor size="small" href={targetUrl} target="_self">{item?.name}</Anchor>
                                        : <Text size="small" >{item?.name}</Text>
                                })
                            }
                        </Box>
                    </Box>
                } else {
                    registerLabel = site.localizedStrings['Datacard.Register_Label'];
                }
                break;
            case 'Inactive':
                status = <Text size="small">{`${entity.releaseStatus.value}: ${format(parseISO(entity.date), "dd MMM yyyy")}`}</Text>
                href = undefined;
                break;
            case 'Expiring':
                status = <Text size="small">{`${entity.releaseStatus.value}: ${format(parseISO(entity.date), "dd MMM yyyy")}`}</Text>
                href = undefined;
                if (entity.recommendations) {
                    description = <Text>{parse(entity.recommendations)}</Text>;
                }
                break;
            case 'Expired':
                status = null;
                href = undefined;
                break;
            default:
                registerLabel = site.localizedStrings['Datacard.Register_Label'];
                break;
        }

        return <SummaryCard key={`entity-${entity.programId}`} data={entity} hoverIndicator href={href} status={status} icon={icon}
            linkLabel={registerLabel} description={description} />;
    }

    renderGrid = (localUrl: string, grouping: any) => {
        return <Box fill>
            {_.map(grouping, (items, key) => {
                return <Box key={`${key}-box`} border={{ side: "bottom", size: 'small' }} pad={{ top: "small", bottom: "small" }}>
                    <Heading level='4'>
                        {key}
                    </Heading>
                    <Grid gap={{ row: "small", column: "small" }} rows="auto" columns="medium" >
                        {items.sort(firstBy("levelOrder").thenBy("name")).map((entity: any) => this.renderCard(localUrl, entity))}
                    </Grid>
                </Box>
            })}
        </Box >
    }

    renderExpired = (localUrl: string, filtered: any[]) => {
        let dateGrouping = _.groupBy(filtered.sort((l, r) => compareDesc(parseISO(l.date), parseISO(r.date))),
            (o) => { return [format(parseISO(o.date), "MMM yyyy")] });

        return <Accordion>
            {
                _.map(dateGrouping, (dateItems, date) => {
                    let typeGrouping = _.groupBy(dateItems, (o) => { return [o.type.value] });

                    return <AccordionPanel label={date}>
                        {this.renderGrid(localUrl, typeGrouping)}
                    </AccordionPanel>
                })}
        </Accordion>;
    }

    renderList = () => {
        let site = this.props.site;
        let data = this.props.credentials.entities;
        let keywords = this.props.credentials.keywords;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let entities = this.state.showAll
            ? data
            : data.filter((entity) => entity.isDeliveryReady);
        let view = this.props.credentials.view;
        let tabTechnologies = this.props.credentials.technologies;
        let technologies: string[] = tabTechnologies.filter((technology) => technology.selected && technology.value !== EmptyGuid).map(tech => tech.value);
        let allTech = technologies.some((tech) => tech === EmptyGuid);
        let tabStatus = this.props.credentials.status;

        let sortedEntities = [...entities].sort((left, right) => {
            return right.levelOrder - left.levelOrder;
        });
        let filtered = sortedEntities.filter((item) => {
            let hasView = true;

            switch (view) {
                case 'newOnly':
                    hasView = item.isNew;
                    break;
                case 'all':
                    break;
                default:
                    hasView = item.certificationType.value === view;
                    break;
            }

            return hasView && (allTech || item.technologies.some((t) => technologies.some((st) => st === t))) &&
                (keywords === '' || item.name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1 ||
                    item.programId.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
        });
        let typeGrouping = _.groupBy(filtered, (o) => { return [o.certificationType.value] });

        return _.size(filtered) !== 0
            ? tabStatus === "Expired"
                ? this.renderExpired(localUrl, filtered)
                : this.renderGrid(localUrl, typeGrouping)
            : !site.loading && <Box height="small" align="center" justify="center" alignContent="center">
                <Text weight="bold">
                    {site.localizedStrings['ExternalPages.No_Credentials_Found_Statement']}
                </Text>
            </Box>;
    }

    onShowEmployeeCertifications = () => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let employeeUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications/${langInfo[0]}`
            : `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications`;

        this.setState({ showEmployeeWarning: false });
        window.open(employeeUrl, "_blank");
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Technical'], value: `${localUrl}/technical-certifications` },
            { key: site.menuStrings['ExternalPages.Credentials_ViewAll_Label'], value: "" }
        ];
        let title = site.menuStrings['ExternalPages.Credentials_ViewAll_Label'];

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="entitylist_credentials" {...this.props}
            metadataDescription={site.localizedStrings['Metadata.CredentialsDescription']}
            metadataKeywords={site.localizedStrings['Metadata.CredentialsKeywords']}>
            {this.state.showEmployeeWarning && <Layer>
                <Box width="medium" height={{ max: "medium" }}>
                    <Card fill>
                        <CardHeader pad="small" elevation="small" justify="center" background="brand">
                            <Text weight="bold" size="small" textAlign="center" color="white">
                                {site.localizedStrings['Datacard.View_HPE_Certification_Label']}
                            </Text>
                        </CardHeader>
                        <CardBody pad="small" align="center">
                            <Box height={{ max: 'medium' }} overflow="auto" wrap>
                                <Paragraph fill size="small">
                                    {parse(site.localizedStrings['ExternalPages.EmployeeCertification_Warning_Statement'])}
                                </Paragraph>
                            </Box>
                        </CardBody>
                        <CardFooter pad="small">
                            <Box direction="row" fill="horizontal" gap="small" justify="end">
                                <Button alignSelf="end" label={site.localizedStrings['ExternalPages.Yes_Label']} primary
                                    onClick={() => { this.onShowEmployeeCertifications(); }} />
                                <Button alignSelf="end" label={site.localizedStrings['ExternalPages.No_Label']} secondary
                                    onClick={() => { this.setState({ showEmployeeWarning: false }); }} />
                            </Box>
                        </CardFooter>
                    </Card>
                </Box>
            </Layer>}

            {site.stringsLoaded && <Box gap="medium">
                <Box gap="small" fill="horizontal">
                    <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                </Box>
                <Box direction="row-responsive" pad="medium" align="center" gap="large">
                    <Box gap="small" fill>
                        <Heading level="3">{site.localizedStrings['ExternalPages.Credentials_Section_Title']}</Heading>
                        <Box fill gap="small">
                            {parse(formatString(site.localizedStrings['ExternalPages.Credentials_Section_Statement'],
                                [`${localUrl}/technical-certifications-badges`]))}
                            <Anchor onClick={() => { this.setState({ showEmployeeWarning: true }) }}>{site.localizedStrings['ExternalPages.Credentials_For_Employees_Label']}</Anchor>
                        </Box>
                    </Box>
                    <Box fill pad={{ left: "small" }}>
                        <Image src="images/GettyImages-1200911246_800_0_72_RGB.jpg" />
                    </Box>
                </Box>
            </Box>}
            {!site.loading && <Box gap="small">
                <Box pad={{ top: "medium" }}>
                    <Box direction="row-responsive" fill="horizontal" gap="small" pad="small" border={{ side: "bottom", color: 'brand', size: 'small' }}>
                        {this.renderFilters()}
                    </Box>
                    {this.renderList()}
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Credentials as any));
