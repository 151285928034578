import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import {
    Box, Tip, Heading, Text, TextArea, Button, Accordion, AccordionPanel, DataTable, Layer, Form,
    FormField, Footer, Header
} from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import {
    retrieveAffiliations, retrieveInstructors, saveInstructor, saveAffiliationRequest,
    saveAffiliations, setAffiliations, exportAffiliations, exportUnaffiliated
} from '../../store/LearningPartnerConnection';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { LpcAffiliation } from '../../models/lpc/LpcAffiliation';
import { Dislike, Like, SubtractCircle, CircleInformation } from 'grommet-icons';
import { hasRole, formatString } from '../../Utilities';
import HtmlComponent from '../../components/HtmlComponent';
import { formatISO, parseISO, format, differenceInDays } from 'date-fns';
import { AffiliationStatus } from '../../models/lpc/AffiliationStatus';

const mapDispatchToProps = {
    retrieveAffiliations,
    retrieveInstructors,
    saveInstructor,
    saveAffiliationRequest,
    saveAffiliations,
    setAffiliations,
    exportAffiliations,
    exportUnaffiliated,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface IAffiliationManagementProps {
}

interface IAffiliationManagementState {
    showDenialReason: boolean;
    showUnaffiliationReason: boolean;
    affiliationsLoaded: boolean;
    reloadAffiliations: boolean;
    reloaded: boolean;
    activePanel: number[];
    affiliations?: LpcAffiliation[];
    affiliation?: LpcAffiliation;
    updates?: LpcAffiliation[];
}

type AffiliationManagementProps = IAffiliationManagementProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class AffiliationManagement extends React.PureComponent<AffiliationManagementProps, IAffiliationManagementState> {
    emailInputRef: any;

    constructor(props: AffiliationManagementProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.LpcKeywords', 'Metadata.LpcDescription',
            'ExternalPages.AffiliationManagement_label', 'ExternalPages.LPC_Pending_Affiliations_Label', 'ExternalPages.LPC_Current_Affiliations_Label',
            'ExternalPages.Instrutor_Label', 'ExternalPages.Deny_Label', 'ExternalPages.Unaffiliate_Label', 'ExternalPages.Approve_Label',
            'ExternalPages.Reason_Label', 'ExternalPages.LPC_ManageAffiliations_Intro_Statement', 'MyLearning.Not_Authorized_Statement',
            'ExternalPages.LPC_Unaffiliated_Denied_Affiliations_Label', 'MyLearning.Save_Label', 'ExternalPages.Cancel_Label',
            'ExternalPages.LPC_ManageAffiliations_DenyReason_Label', 'ExternalPages.LPC_ManageAffiliations_UnaffiliateReason_Label',
            'ExternalPages.OK_Label', 'ExternalPages.Cancel_Changes_Label', 'ExternalPages.Save_Changes_Label', 'ExternalPages.Email_Label',
            'ExternalPages.LPC_ManageAffiliations_DenyReason_Title', 'ExternalPages.LPC_ManageAffiliations_UnaffiliateReason_Title',
            'ExternalPages.LPC_ManageAffiliations_Unaffiliated_Instructors_Label', 'ExternalPages.LPC_ManageAffiliations_Denied_Affiliations_Label',
            'ExternalPages.LPC_ManageAffiliations_NoAffiliations_Label', 'ExternalPages.Instructor_Name_Label', 'ExternalPages.Learning_Partner_Label',
            'ExternalPages.ApprovalDate_Label', 'ExternalPages.ApprovedBy_Label', 'ExternalPages.Actions_Label', 'ExternalPages.DeniedBy_Label',
            'ExternalPages.DenialDate_Label', 'ExternalPages.DenialReason_Label', 'ExternalPages.UnAffiliatedBy_Label', 'ExternalPages.UnAffiliationDate_Label',
            'ExternalPages.UnAffiliationReason_Label', 'ExternalPages.InstructorType_Label', 'ExternalPages.LPC_Approved_Affiliations_Label',
            'ExternalPages.LPC_Removed_Affiliations_Label', 'MyLearning.Export_Label', 'ExternalPages.ExportAffliations_Tooltip',
            'ExternalPages.AffiliationManagement_SaveChanges_Statement', 'ExternalPages.LPC_Processing_Affiliations_Label',
            'ExternalPages.LPC_ExportUnaffiliated_Label'];

        this.state = {
            showDenialReason: false,
            showUnaffiliationReason: false,
            affiliationsLoaded: false,
            reloadAffiliations: false,
            reloaded: false,
            activePanel: [0]
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('resources-menu');
    }

    public componentDidUpdate() {
        let site = this.props.site;
        // let userInfo = site.userInfo;
        let user = site.user;

        if ((user || site.actualUser) && ((!this.props.lpc.affiliations && !this.state.affiliationsLoaded) || (this.state.reloadAffiliations && !site.loadingUser))) {
            this.setState({ affiliationsLoaded: true, reloadAffiliations: false, reloaded: site.actualUser !== undefined, affiliations: undefined });
            this.props.setAffiliations(undefined);
            setTimeout(() => {
                this.props.retrieveAffiliations();
            }, 200);
        }

        if (((site.actualUser && !this.state.reloaded && this.state.affiliationsLoaded) ||
            (!site.actualUser && this.state.reloaded && !this.state.reloadAffiliations)) && !this.state.reloadAffiliations && !site.loadingUser) {
            this.setState({ reloadAffiliations: true });
        }
        // this.props.retrieveAffiliations();
        // this.setState({ affiliationsLoaded: true });
        if (this.state.affiliationsLoaded && !this.state.affiliations && this.props.lpc.affiliations) {
            this.setState({ affiliations: this.props.lpc.affiliations });
        }
    }

    onApproveRegistration = (selected: LpcAffiliation) => {
        let affiliations = JSON.parse(JSON.stringify(this.state.affiliations)) as LpcAffiliation[];
        let affiliation = JSON.parse(JSON.stringify(selected)) as LpcAffiliation;
        let site = this.props.site;
        let userInfo = site.userInfo;
        let user = site.user;
        let updates = this.state.updates !== undefined
            ? JSON.parse(JSON.stringify(this.state.updates)) as LpcAffiliation[]
            : [];

        affiliation.approvedDeniedBy = {
            email: user?.email as string,
            name: `${user?.firstName} ${user?.lastName}`
        };
        affiliation.approved = true;
        affiliation.approvalDenialDate = formatISO(new Date());
        _.remove(affiliations, (aff) => aff.id === affiliation.id && aff.instructorType === affiliation.instructorType &&
            aff.learningPartner.id === affiliation.learningPartner.id);
        affiliations.push(affiliation);
        updates.push(affiliation);

        this.setState({ affiliations: affiliations, updates: updates });
    }

    onDenyRegistration = (selected: LpcAffiliation) => {
        let affiliation = JSON.parse(JSON.stringify(selected)) as LpcAffiliation;
        let site = this.props.site;
        let userInfo = site.userInfo;

        affiliation.approvedDeniedBy = {
            email: userInfo?.email as string,
            name: userInfo?.name as string
        };
        affiliation.approved = false;
        affiliation.approvalDenialDate = formatISO(new Date());
        affiliation.denialReason = '';
        this.setState({ showDenialReason: true, affiliation: affiliation });
    }

    onUnaffiliate = (selected: LpcAffiliation) => {
        let affiliation = JSON.parse(JSON.stringify(selected)) as LpcAffiliation;
        let site = this.props.site;
        let user = site.user;

        affiliation.unAffiliatedBy = {
            email: user?.email as string,
            name: `${user?.firstName} ${user?.lastName}`
        };
        affiliation.unAffiliationDate = formatISO(new Date());
        affiliation.unAffiliationReason = '';
        this.setState({ showUnaffiliationReason: true, affiliation: affiliation });
    }

    isDirty = () => {
        let original = {
            ...this.props.lpc.affiliations,
        };
        let current = {
            ...this.state.affiliations
        };
        let dirty = !_.isEqual(original, current);

        return dirty;
    }

    onSaveChanges = () => {
        this.props.setAffiliations(undefined);
        this.setState({ affiliations: undefined, updates: undefined });
        this.props.saveAffiliations(this.state.updates as LpcAffiliation[]);
    }

    onCandelChanges = () => {
        this.setState({ affiliations: this.props.lpc.affiliations, updates: undefined });
    }

    onSaveReason = () => {
        let affiliations = JSON.parse(JSON.stringify(this.state.affiliations)) as LpcAffiliation[];
        let affiliation = JSON.parse(JSON.stringify(this.state.affiliation)) as LpcAffiliation;
        let updates = this.state.updates !== undefined
            ? JSON.parse(JSON.stringify(this.state.updates)) as LpcAffiliation[]
            : [];

        _.remove(affiliations, (aff) => aff.id === affiliation.id && aff.instructorType === affiliation.instructorType &&
            aff.learningPartner.id === affiliation.learningPartner.id);
        affiliations.push(affiliation);
        updates.push(affiliation);
        this.setState({ affiliations: affiliations, affiliation: undefined, showDenialReason: false, showUnaffiliationReason: false, updates: updates });
    }

    onCancelReason = () => {
        this.setState({ affiliation: undefined, showDenialReason: false, showUnaffiliationReason: false });
    }

    onActivatePanel = (activeIndexes: number[]) => {
        this.setState({ activePanel: activeIndexes });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let isLpPPA = hasRole(user, ['LPC_PPA']);
        let isLpcAdmin = hasRole(user, ['LPC_ADMIN']);
        let isPPA = hasRole(user, ["PPA"]);
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.menuStrings['ExternalPages.LearningPartnerConnection_Label'];
        let page = site.menuStrings['ExternalPages.ManageAffiliations_label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: site.menuStrings['ExternalPages.LearningPartnerConnection_Label'], value: `${localUrl}/lpc` },
            { key: page, value: "" }
        ];
        let pendingAffiliations = _.filter(this.state.affiliations,
            (aff: LpcAffiliation) => aff.status === AffiliationStatus.Pending && (
                aff.approvalDenialDate === undefined || aff.approvalDenialDate === null || aff.approvalDenialDate === '') &&
                (isLpcAdmin || isLpPPA || aff.learningPartner.partyId == user?.partyId));
        let approvedAffiliations = _.filter(this.state.affiliations,
            (aff: LpcAffiliation) => aff.status === AffiliationStatus.Pending && aff.approved &&
                (aff.approvalDenialDate !== undefined && aff.approvalDenialDate !== null) &&
                (aff.denialReason === undefined || aff.denialReason === null || aff.denialReason === '') &&
                (aff.unAffiliationReason === undefined || aff.unAffiliationReason === null || aff.unAffiliationReason === '') &&
                (isLpcAdmin || isLpPPA || aff.learningPartner.partyId == user?.partyId));
        let inprocessAffiliations = _.filter(this.state.affiliations,
            (aff: LpcAffiliation) => aff.status === AffiliationStatus.Processing && (isLpcAdmin || isLpPPA || aff.learningPartner.partyId == user?.partyId));
        let currentAffiliations = _.filter(this.state.affiliations,
            (aff: LpcAffiliation) => aff.status === AffiliationStatus.Approved &&
                (aff.approvalDenialDate !== undefined && aff.approvalDenialDate !== null) &&
                (aff.denialReason === undefined || aff.denialReason === null || aff.denialReason === '') &&
                (aff.unAffiliationReason === undefined || aff.unAffiliationReason === null || aff.unAffiliationReason === '') &&
                (isLpcAdmin || isLpPPA || aff.learningPartner.partyId == user?.partyId));
        let deniedAffiliations = _.filter(this.state.affiliations, (aff: LpcAffiliation) => (aff.denialReason !== undefined && aff.denialReason !== null &&
            aff.denialReason !== '' && (isLpcAdmin || isLpPPA || aff.learningPartner.partyId == user?.partyId)));
        let remvoedAffiliations = _.filter(this.state.affiliations, (aff: LpcAffiliation) => aff.status === AffiliationStatus.Removed &&
            (aff.unAffiliationDate !== undefined && aff.unAffiliationDate !== null) &&
            (aff.unAffiliationReason !== undefined && aff.unAffiliationReason != null));
        let unAffiliatedInstructors = _.filter(this.state.affiliations, (aff: LpcAffiliation) => aff.status !== AffiliationStatus.Removed &&
            (aff.unAffiliationDate !== undefined && aff.unAffiliationDate !== null) &&
            (aff.unAffiliationReason !== undefined && aff.unAffiliationReason != null));
        let hasAffiliations = pendingAffiliations.length !== 0 || currentAffiliations.length !== 0 || deniedAffiliations.length !== 0 ||
            unAffiliatedInstructors.length !== 0 || approvedAffiliations.length !== 0 || remvoedAffiliations.length !== 0 ||
            inprocessAffiliations.length !== 0;
        let columns = [
            {
                property: 'applicant',
                header: site.localizedStrings['ExternalPages.Instructor_Name_Label'],
                primary: true
            },
            {
                property: 'email',
                header: site.localizedStrings['ExternalPages.Email_Label'],
                primary: true
            },
            {
                property: 'instructorType',
                header: site.localizedStrings['ExternalPages.InstructorType_Label']
            }];

        if (!user) {
            return null;
        }
        if (user && !isLpcAdmin && !isPPA && !isLpPPA) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (isLpcAdmin) {
            currentAffiliations = _.sortBy(currentAffiliations, aff => [aff.applicant, aff.learningPartner.name, aff.instructorType]);
            columns.push({
                property: 'learningPartner.name',
                header: site.localizedStrings['ExternalPages.Learning_Partner_Label']
            })
        }
        else {
            currentAffiliations = _.sortBy(currentAffiliations, aff => [aff.applicant, aff.instructorType]);
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="AffiliationManagement" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.LpcKeywords']}
            metadataDescription={site.localizedStrings['Metadata.LpcDescription']} >
            {site.stringsLoaded && <Box gap="large" pad={{ bottom: 'medium' }}>
                <Heading level={3} textAlign='center' fill>{site.localizedStrings['ExternalPages.AffiliationManagement_label']}</Heading>
                {!hasAffiliations && !site.loading && <Box fill="horizontal">
                    <Heading level={3}>{site.localizedStrings['ExternalPages.LPC_ManageAffiliations_NoAffiliations_Label']}</Heading>
                </Box>}
                {hasAffiliations && <Box gap="medium">
                    <Box width="large" gap="small">
                        <HtmlComponent html={site.localizedStrings['ExternalPages.LPC_ManageAffiliations_Intro_Statement']} />
                        <HtmlComponent html={site.localizedStrings['ExternalPages.AffiliationManagement_SaveChanges_Statement']} />
                    </Box>
                    <Box direction="row-responsive" gap="small" width="large">
                        <Button primary label={site.localizedStrings['ExternalPages.Save_Changes_Label']} disabled={!this.isDirty()}
                            onClick={this.onSaveChanges} />
                        <Button secondary label={site.localizedStrings['ExternalPages.Cancel_Changes_Label']} disabled={!this.isDirty()}
                            onClick={this.onCandelChanges} />
                        <Button primary label={site.localizedStrings['MyLearning.Export_Label']}
                            onClick={this.props.exportAffiliations} tip={site.localizedStrings['ExternalPages.ExportAffliations_Tooltip']} />
                        {isLpcAdmin && <Button primary label={site.localizedStrings['ExternalPages.LPC_ExportUnaffiliated_Label']}
                            onClick={this.props.exportUnaffiliated} />}
                    </Box>
                    <Accordion activeIndex={this.state.activePanel} onActive={this.onActivatePanel} multiple >
                        {pendingAffiliations.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_Pending_Affiliations_Label']}</Heading>
                                <Tip content="Affiliations requiring approval or denial"><CircleInformation size="small" /></Tip>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={pendingAffiliations} primaryKey={false} columns={[
                                    {
                                        property: '',
                                        header: site.localizedStrings['ExternalPages.Actions_Label'],
                                        render: (datum: LpcAffiliation) => <Box>
                                            {(datum.approvalDenialDate === undefined || datum.approvalDenialDate === null) && <Box direction="row-responsive">
                                                <Button icon={<Like color='brand' />} onClick={() => { this.onApproveRegistration(datum); }}
                                                    tip={site.localizedStrings['ExternalPages.Approve_Label']} />
                                                <Button icon={<Dislike color='red' />} onClick={() => { this.onDenyRegistration(datum); }}
                                                    tip={site.localizedStrings['ExternalPages.Deny_Label']} />
                                            </Box>}
                                            {datum.approvalDenialDate !== undefined || datum.approvalDenialDate !== null && <Box>
                                                {datum.approved && <Like color='brand' size="small" />}
                                                {!datum.approved && <Dislike color='red' size="small" />}
                                            </Box>}
                                        </Box>
                                    },
                                    ...columns
                                ]} />
                            </Box>
                        </AccordionPanel>}
                        {approvedAffiliations.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_Approved_Affiliations_Label']}</Heading>
                                <Tip content="Affiliations marked as approved"><CircleInformation size="small" /></Tip>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={approvedAffiliations} primaryKey={false} columns={[
                                    ...columns,
                                    {
                                        property: 'approvedDeniedBy.name',
                                        header: site.localizedStrings['ExternalPages.ApprovedBy_Label']
                                    },
                                    {
                                        property: 'approvalDenialDate',
                                        header: site.localizedStrings['ExternalPages.ApprovalDate_Label'],
                                        render: (datum: LpcAffiliation) =>
                                            datum.approvalDenialDate && <Text>{format(parseISO(datum.approvalDenialDate), 'yyyy-MM-dd HH:mm:ss')}</Text>
                                    }
                                ]} />
                            </Box>
                        </AccordionPanel>}
                        {deniedAffiliations.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_ManageAffiliations_Denied_Affiliations_Label']}</Heading>
                                <Tip content="Affiliations marked as denied"><CircleInformation size="small" /></Tip>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={deniedAffiliations} primaryKey={false} columns={[
                                    ...columns,
                                    {
                                        property: 'approvedDeniedBy.name',
                                        header: site.localizedStrings['ExternalPages.DeniedBy_Label']
                                    },
                                    {
                                        property: 'approvalDenialDate',
                                        header: site.localizedStrings['ExternalPages.DenialDate_Label'],
                                        render: (datum: LpcAffiliation) =>
                                            datum.approvalDenialDate && <Text>{format(parseISO(datum.approvalDenialDate), 'yyyy-MM-dd HH:mm:ss')}</Text>
                                    },
                                    {
                                        property: 'denialReason',
                                        header: site.localizedStrings['ExternalPages.DenialReason_Label']
                                    }
                                ]} />
                            </Box>
                        </AccordionPanel>}
                        {remvoedAffiliations.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_Removed_Affiliations_Label']}</Heading>
                                <Tip content="Affiliations marked for removal"><CircleInformation size="small" /></Tip>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={remvoedAffiliations} primaryKey={false} columns={[
                                    ...columns,
                                    {
                                        property: 'unAffiliatedBy.name',
                                        header: site.localizedStrings['ExternalPages.UnAffiliatedBy_Label'],
                                    },
                                    {
                                        property: 'unAffiliationDate',
                                        header: site.localizedStrings['ExternalPages.UnAffiliationDate_Label'],
                                        render: (datum: LpcAffiliation) =>
                                            datum.unAffiliationDate && <Text>{format(parseISO(datum.unAffiliationDate), 'yyyy-MM-dd HH:mm:ss')}</Text>
                                    },
                                    {
                                        property: 'unAffiliationReason',
                                        header: site.localizedStrings['ExternalPages.UnAffiliationReason_Label'],
                                    }
                                ]} />
                            </Box>
                        </AccordionPanel>}
                        {inprocessAffiliations.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_Processing_Affiliations_Label']}</Heading>
                                <Tip content="Approved affiliations currently being processed"><CircleInformation size="small" /></Tip>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={inprocessAffiliations} primaryKey={false} columns={[
                                    ...columns,
                                    {
                                        property: 'approvedDeniedBy.name',
                                        header: site.localizedStrings['ExternalPages.ApprovedBy_Label']
                                    },
                                    {
                                        property: 'approvalDenialDate',
                                        header: site.localizedStrings['ExternalPages.ApprovalDate_Label'],
                                        render: (datum: LpcAffiliation) =>
                                            datum.approvalDenialDate && <Text>{format(parseISO(datum.approvalDenialDate), 'yyyy-MM-dd HH:mm:ss')}</Text>
                                    }
                                ]} />
                            </Box>
                        </AccordionPanel>}
                        {currentAffiliations.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_Current_Affiliations_Label']}</Heading>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={currentAffiliations} primaryKey={false} columns={[
                                    {
                                        property: '',
                                        header: site.localizedStrings['ExternalPages.Actions_Label'],
                                        render: (datum: LpcAffiliation) => {
                                            let currentDate = new Date();
                                            let approvalDate = parseISO(datum.approvalDenialDate);
                                            let timeDiff = differenceInDays(currentDate, approvalDate);

                                            return <Box direction="row-responsive">
                                                <Button icon={<SubtractCircle size="small" />} onClick={() => { this.onUnaffiliate(datum); }}
                                                    tip={site.localizedStrings['ExternalPages.Unaffiliate_Label']} />
                                            </Box>;
                                        }
                                    },
                                    ...columns,
                                    {
                                        property: 'approvedDeniedBy.name',
                                        header: site.localizedStrings['ExternalPages.ApprovedBy_Label']
                                    },
                                    {
                                        property: 'approvalDenialDate',
                                        header: site.localizedStrings['ExternalPages.ApprovalDate_Label'],
                                        render: (datum: LpcAffiliation) =>
                                            datum.approvalDenialDate && <Text>{format(parseISO(datum.approvalDenialDate), 'yyyy-MM-dd HH:mm:ss')}</Text>
                                    }
                                ]} />
                            </Box>
                        </AccordionPanel>}
                        {unAffiliatedInstructors.length !== 0 && <AccordionPanel
                            label={<Box direction="row-responsive" gap="xsmall" pad="xsmall">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.LPC_ManageAffiliations_Unaffiliated_Instructors_Label']}</Heading>
                                <Tip content="Affiliations requiring approval or denial"><CircleInformation size="small" /></Tip>
                            </Box>}>
                            <Box pad="small">
                                <DataTable fill pin="header" data={unAffiliatedInstructors} primaryKey={false} columns={[
                                    ...columns,
                                    {
                                        property: 'unAffiliatedBy.name',
                                        header: site.localizedStrings['ExternalPages.UnAffiliatedBy_Label'],
                                    },
                                    {
                                        property: 'unAffiliationDate',
                                        header: site.localizedStrings['ExternalPages.UnAffiliationDate_Label'],
                                        render: (datum: LpcAffiliation) =>
                                            datum.unAffiliationDate && <Text>{format(parseISO(datum.unAffiliationDate), 'yyyy-MM-dd HH:mm:ss')}</Text>
                                    },
                                    {
                                        property: 'unAffiliationReason',
                                        header: site.localizedStrings['ExternalPages.UnAffiliationReason_Label'],
                                    }
                                ]} />
                            </Box>
                        </AccordionPanel>}
                    </Accordion>
                </Box>}
                {this.state.showDenialReason && <Layer>
                    <Box gap="medium" pad="small">
                        <Header justify="center">
                            <Heading level={3} textAlign="center" fill>
                                {site.localizedStrings['ExternalPages.LPC_ManageAffiliations_DenyReason_Title']}
                            </Heading>
                        </Header>
                        <Form value={this.state.affiliation} onChange={nextValue => this.setState({ affiliation: nextValue })}
                            onSubmit={this.onSaveReason} >
                            <Box gap="medium">
                                <FormField name="denialReason" required
                                    label={site.localizedStrings['ExternalPages.LPC_ManageAffiliations_DenyReason_Label']} >
                                    <TextArea id="denialReason-input" name="denialReason" />
                                </FormField>
                                <Footer justify="end">
                                    <Button primary type="submit" label={site.localizedStrings['ExternalPages.OK_Label']} />
                                    <Button secondary color="red" label={site.localizedStrings['ExternalPages.Cancel_Label']}
                                        onClick={this.onCancelReason} />
                                </Footer>
                            </Box>
                        </Form>
                    </Box>
                </Layer>}
                {this.state.showUnaffiliationReason && <Layer>
                    <Box gap="medium" pad="small">
                        <Header justify="center">
                            <Heading level={3} textAlign="center" fill>
                                {site.localizedStrings['ExternalPages.LPC_ManageAffiliations_UnaffiliateReason_Title']}
                            </Heading>
                        </Header>
                        <Form value={this.state.affiliation} onChange={nextValue => this.setState({ affiliation: nextValue })}
                            onSubmit={this.onSaveReason} >
                            <Box gap="medium">
                                <FormField name="unAffiliationReason" required
                                    label={site.localizedStrings['ExternalPages.LPC_ManageAffiliations_UnaffiliateReason_Label']} >
                                    <TextArea id="unAffiliationReason-input" name="unAffiliationReason" />
                                </FormField>
                                <Footer justify="end">
                                    <Button primary type="submit" label={site.localizedStrings['ExternalPages.OK_Label']} />
                                    <Button secondary color="red" label={site.localizedStrings['ExternalPages.Cancel_Label']}
                                        onClick={this.onCancelReason} />
                                </Footer>
                            </Box>
                        </Form>
                    </Box>
                </Layer>}
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(AffiliationManagement as any));
