import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, Anchor, RadioButtonGroup, ResponsiveContext, TextInput, Grid, DropButton, CheckBox, Paragraph, Heading,
    Button, Layer, Card, CardHeader, CardBody, CardFooter, Accordion, AccordionPanel, Image, Tip, RadioButton
} from 'grommet';
import { Search, CircleQuestion, Clear } from 'grommet-icons';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store/';
import {
    retrieveCertifications, retrieveTechnologies, retrieveTypes, retrieveViews, certificationsSlice
} from './../../store/Certifications';
import { KeyValuePair, RelatedItem, SelectOption, EmptyGuid } from '../../models/Common';
import parse from 'html-react-parser';
import { retrieveLocalizedStrings, retrieveOptions, siteSlice } from '../../store/Site';
import BasePage from '../../components/BasePage';
import { updateList, formatString } from '../../Utilities';
import SummaryCard from '../../components/SummaryCard';
import _ from 'lodash';
import queryString from 'query-string';
import { firstBy } from 'thenby'
import { format, parseISO, compareDesc } from 'date-fns';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveCertifications,
    retrieveTechnologies,
    retrieveTypes,
    retrieveViews,
    retrieveOptions,
    ...siteSlice.actions,
    ...certificationsSlice.actions
}

interface CertificationListState {
    showAll: boolean;
    firstLoad: boolean;
    showEmployeeWarning: boolean;
    optionsSet: boolean;
    paramsSet: boolean;
    techOptions: SelectOption[];
    jumpDone: boolean;
}

type CertificationListProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Certifications extends React.PureComponent<CertificationListProps, CertificationListState> {
    tooltipRef: any;

    constructor(props: CertificationListProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.Technologies_Label',
            'Datacard.Register_For_This_Label', 'ExternalPages.Certification_Introduction_Statement', 'ExternalPages.Are_You_Looking_For_Training_Statement',
            'ExternalPages.Visit_The_Training_Calendar_Statement', 'ExternalPages.Are_You_Looking_For_Exams_Statement', 'Datacard.Exam_ViewAll_Label',
            'ExternalPages.Software_Certification_Notification', 'ExternalPages.Remote_Proctored_Exams_Statement', 'ExternalPages.Narrow_by_Label',
            'General.LookupItem_HPE_Sales_Certified', 'ExternalPages.Certification_Levels_Label',
            'ExternalPages.Select_Deselect_All_Label', 'ExternalPages.View_Certifications_Language_Msg', 'ExternalPages.View_All_Certifications_Language_Msg',
            'ExternalPages.View_Certifications_Language_Link', 'ExternalPages.View_All_Certifications_Language_Link', 'Datacard.Register_Label',
            'ExternalPages.Search_Label', 'ExternalPages.No_Certifications_Found_Statement', 'ExternalPages.Reset_Filters_Label',
            'General.CertificationType_Sales', 'ExternalPages.Proctored_Label', 'Datacard.View_HPE_Certification_Label',
            'ExternalPages.EmployeeCertification_Warning_Statement', 'ExternalPages.Yes_Label', 'ExternalPages.No_Label',
            'Metadata.CertificationsKeywords', 'Metadata.CertificationsDescription', 'ExternalPages.Status_Label', 'ExternalPages.Inactive_On_Label',
            'ExternalPages.InactiveCertifications_Tooltip', 'ExternalPages.ExpiringCertifications_Tooltip', 'ExternalPages.ExpiredCertifications_Tooltip',
            'ExternalPages.View_All_Certifications_Card2_Title', 'ExternalPages.View_All_Certifications_Card1_Title', 'ExternalPages.View_All_Certifications_Card3_Title',
            'ExternalPages.View_All_Certifications_Card4_Title', 'ExternalPages.View_All_Certifications_Card2_Body', 'ExternalPages.View_All_Certifications_Card2_Bullet1',
            'ExternalPages.View_All_Certifications_Card2_Bullet2', 'ExternalPages.View_All_Certifications_Card2_Bullet3', 'ExternalPages.View_All_Certifications_Card2_Bullet4',
            'ExternalPages.View_All_Certifications_Card1_Bullet1', 'ExternalPages.View_All_Certifications_Card1_Bullet2', 'ExternalPages.View_All_Certifications_Card3_Body',
            'ExternalPages.Certifications_For_Employees_Label', 'ExternalPages.Certification_Courses_Label', 'ExternalPages.Certification_Exams_Label',
            'ExternalPages.Certification_View_Label', 'ExternalPages.View_All_Certifications_Certification_Tab_Tittle', 'ExternalPages.Recommended_Next_Steps_label',
            'ExternalPages.View_All_Certifications_Card2_Bullet5', 'ExternalPages.Learn_More_Label', 'ExternalPages.Credentials_Label',
            'ExternalPages.ActiveCertifications_Tooltip', 'ExternalPages.GoingInactiveCertifications_Tooltip', 'ExternalPages.Combined_Filter_Tooltip'];

        this.state = {
            showAll: urlLanguage !== 'ja',
            firstLoad: urlLanguage !== null,
            showEmployeeWarning: false,
            paramsSet: false,
            optionsSet: false,
            techOptions: [],
            jumpDone: false
        };
        this.tooltipRef = React.createRef();
        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.retrieveTechnologies(urlLanguage);
        this.props.retrieveViews(urlLanguage);
        this.props.retrieveTypes(urlLanguage);
        this.props.retrieveOptions("Technology", "", this.setTechOptions);
        this.retrieveCertifications(urlLanguage);
        this.props.setActiveMenu(this.isSalesCertifications() ? 'sales-certification-menu' : 'technical-certification-menu');
    }

    public componentDidUpdate(prevProps: CertificationListProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;
        let tabTechnologies = this.props.certifications.technologies;

        if (!this.state.optionsSet && (tabTechnologies.length > 1) && (this.state.techOptions.length !== 0)) {
            this.parseUrl();
            this.setState({ optionsSet: true });
        }
        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            this.setState({ showAll: urlLanguage !== 'ja' });
            if (!this.state.firstLoad) {
                this.props.retrieveTechnologies(urlLanguage);
                this.props.retrieveViews(urlLanguage);
                this.props.retrieveTypes(urlLanguage);
                this.retrieveCertifications(urlLanguage);
            }
            this.setState({ firstLoad: false });
        }
    }

    setTechOptions = (options: SelectOption[]) => {
        this.setState({ techOptions: options })
    }

    isSalesCertifications = () => {
        let query = queryString.parse(window.location.search);
        let queryKeys = Object.keys(query);
        let isSales = false;

        queryKeys.forEach((key) => {
            let caseKey = key.toLowerCase();

            switch (caseKey) {
                case 'credentialview':
                    isSales = String(query[key]) === 'Sales Certifications';
                    break;
            }
        });
        return isSales;
    }

    parseUrl = (override?: boolean) => {
        let query = queryString.parse(window.location.search);
        let queryKeys = Object.keys(query);
        let site = this.props.site;
        let currentLang = site.currentLanguage;

        queryKeys.forEach((key) => {
            let caseKey = key.toLowerCase();

            switch (caseKey) {
                case 'credentialview':
                    if (!this.state.paramsSet || override) {
                        this.props.setCertificationView(String(query[key]));
                    }
                    break;
                case 'technologies':
                    let tabTechnologies = this.props.certifications.technologies;
                    let urlTechnologies = Array.isArray(query[key]) ? query[key] as string[] : String(query[key]).split(',');
                    let techOptions = _.map(_.filter(this.state.techOptions,
                        (option) => _.some(urlTechnologies,
                            (st: any) => st.toLowerCase() === option.text.toLowerCase())), (option) => option.value);
                    let technologies = _.map(tabTechnologies, (rt: any) => {
                        let json = JSON.stringify(rt);
                        let newTech: SelectOption = JSON.parse(json);

                        newTech.selected = techOptions.some((st) => st === rt.value);

                        return newTech;
                    });

                    this.props.setCertificationTechnologies(technologies);
                    break;
                case 'status':
                    if (!this.state.paramsSet || override) {
                        this.props.setCertificationStatus(query[key]);
                    }
                    break;
            }
        });
        this.setState({ paramsSet: true })
        setTimeout(() => {
            this.retrieveCertifications(currentLang.value);
        }, 150);
    }

    retrieveCertifications = (language: string = '') => {
        this.props.retrieveCertifications(language);
    }

    onCheckedChange = (event: any) => {
        let values: string[] = event.target.id.split('-');
        let index = parseInt(values[1]);
        let checked = event.target.checked;
        let list = JSON.stringify(this.props.certifications.technologies);
        let newList: any[] = JSON.parse(list);

        newList = updateList(newList, index, checked);
        this.props.setCertificationTechnologies(newList);
    }

    onViewChange = (event: any) => {
        this.props.setCertificationView(event.target.value);
    }

    onStatusChange = (event: any) => {
        let site = this.props.site;
        let currentLang = site.currentLanguage;

        this.props.setCertificationStatus(event.target.value);
        setTimeout(() => {
            this.retrieveCertifications(currentLang.value);
        }, 150);
    }

    onKeywordChange = (value: string) => {
        this.props.setCertificationKeywords(value);
    }

    toggoleShowAll = () => {
        this.setState({ showAll: !this.state.showAll });
    }

    onResetFilters = () => {
        let status = this.props.certifications.status;
        let list = JSON.stringify(this.props.certifications.technologies);
        let newList: any[] = JSON.parse(list);
        let site = this.props.site;
        let currentLang = site.currentLanguage;

        newList = updateList(newList, 0, true);
        this.props.setCertificationTechnologies(newList);
        this.props.setCertificationView('all');
        if (status !== 'Active') {
            this.props.setCertificationStatus('Active');
            setTimeout(() => {
                this.retrieveCertifications(currentLang.value);
            }, 500);
        }
    }

    onShowEmployeeCertifications = () => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let employeeUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications/${langInfo[0]}`
            : `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications`;

        this.setState({ showEmployeeWarning: false });
        window.open(employeeUrl, "_blank");
    }

    renderFilters = () => {
        let filters: any = [];
        let site = this.props.site;
        let keywords = this.props.certifications.keywords;
        let status = this.props.certifications.status;
        let tabViews = this.props.certifications.views;
        let views = tabViews.map((view, index) => {
            return {
                disabled: false,
                id: `views-${index}`,
                name: `views-${index}`,
                value: view.value,
                label: view.text
            }
        });
        let tabView = this.props.certifications.view;
        let selectedView = tabViews.find((item) => item.value === tabView) ?? tabViews[0]
        let tabTypes = this.props.certifications.types;
        let types = tabTypes.map((type, index) => {
            let option = {
                disabled: false,
                id: `types-${index}`,
                name: `types-${index}`,
                value: type.value,
                label: type.text
            };

            return <RadioButton {...option} key={`radio-types-${index}`} checked={status === option.value} onChange={this.onStatusChange} />
        });
        let viewTile = site.localizedStrings['ExternalPages.Certification_View_Label'];
        let tabTechnologies = this.props.certifications.technologies;

        filters = [
            <DropButton key="views-filter" className="hpe-button-box" label={viewTile}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }}>
                        {<RadioButtonGroup name="views" options={views} value={selectedView.value} onChange={this.onViewChange} />}
                    </Box>
                }
            />,
            <DropButton key="technologies-filter" className="hpe-button-box" label={site.localizedStrings['ExternalPages.Technologies_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }} width={{ min: "240px" }}>
                        {tabTechnologies.map((tech, index) => <CheckBox key={`technologies-${index}`}
                            id={`technologies-${index}`}
                            checked={tech.selected}
                            label={tech.text}
                            onChange={this.onCheckedChange}
                        />)}
                    </Box>
                }
            />,
            <DropButton key="types-filter" className="hpe-button-box" label={site.localizedStrings['ExternalPages.Status_Label']}
                dropAlign={{ top: 'bottom', left: 'left' }}
                dropContent={
                    <Box height={{ max: "medium" }} gap="small" overflow="auto">
                        {types}
                    </Box>
                }
            />
        ];

        return <Box gap="small">
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Box width="medium" >
                    <TextInput size='xsmall' placeholder={`${site.localizedStrings['ExternalPages.Search_Label']} ...`} value={keywords} icon={<Search />}
                        onChange={event => this.onKeywordChange(event.target.value)} />
                </Box>
            </Box>
            <Box direction="row-responsive" fill="horizontal" gap="medium">
                <Text weight="bold">{site.localizedStrings['ExternalPages.Narrow_by_Label']}:</Text>
                {filters}
                <Button secondary label={site.localizedStrings['ExternalPages.Reset_Filters_Label']} onClick={this.onResetFilters} />
            </Box>
        </Box>;
    }

    renderCard = (localUrl: string, entity: any, group: string) => {
        let site = this.props.site;
        let href = entity.isSalesPro
            ? entity.registrationUrl
            : `${localUrl}/datacard/certification/${entity.programId}`;
        let status = entity.releaseStatus.key !== 'Active'
            ? <Text size="small">{entity.releaseStatus.value}</Text>
            : null;
        let description = null;
        let registerLabel = null;
        let icon = !entity?.hasDigitalBadge ? <Clear size="large" color="brand" /> : null;
        let tabStatus = this.props.certifications.status;
        let cardHeight = undefined;

        switch (entity.releaseStatus.key) {
            case 'Transitional':
            case 'Transitional-Regional':
                status = <Text size="small">{`${entity.releaseStatus.value}: ${format(parseISO(entity.date), "dd MMM yyyy")}`}</Text>
                if (tabStatus !== "Active" && _.some(entity.replacedBy)) {
                    description = <Box>
                        <Text>Replaced By:</Text>
                        <Box pad={{ left: "xsmall" }}>
                            {
                                _.map(entity.replacedBy, (item: RelatedItem) => {
                                    let targetUrl = `${localUrl}/datacard/certification/${item.modifiedProgramId}`;

                                    return item.isActive
                                        ? <Anchor size="small" href={targetUrl} target="_self">{item?.name}</Anchor>
                                        : <Text size="small" >{item?.name}</Text>
                                })
                            }
                        </Box>
                    </Box>
                } else {
                    registerLabel = site.localizedStrings['Datacard.Register_Label'];
                }
                break;
            case 'Inactive':
            case 'Expiring':
                icon = null;
                status = <Text size="small">{`${entity.releaseStatus.value}: ${format(parseISO(entity.date), "dd MMM yyyy")}`}</Text>
                href = undefined;
                if (entity.recommendations) {
                    description = <Box gap="xsmall">
                        <Text weight="bold" size="small">{site.localizedStrings['ExternalPages.Recommended_Next_Steps_label']}</Text>
                        <Box pad={{ left: 'medium' }}>
                            <Paragraph>{parse(entity.recommendations)}</Paragraph>
                        </Box>
                    </Box>;
                }
                cardHeight = "small";
                break;
            case 'Expired':
                status = null;
                href = undefined;
                break;
            default:
                registerLabel = site.localizedStrings['Datacard.Register_Label'];
                break;
        }

        return <SummaryCard key={`${group.replace(' ', '-')}-entity-${entity.programId}`} data={entity} hoverIndicator href={href} status={status} icon={icon}
            linkLabel={registerLabel} description={description} cardHeight={cardHeight} />;
    }

    renderGrid = (localUrl: string, grouping: any) => {
        let site = this.props.site;
        let tabTechnologies = this.props.certifications.technologies;
        let tabStatus = this.props.certifications.status;

        return <Box fill>
            {_.map(grouping, (items, key) => {
                return <Box key={`${key}-box`} border={{ side: "bottom", size: 'small' }} pad={{ top: "small", bottom: "small" }}>
                    <Heading level='4'>
                        {key}
                    </Heading>
                    {tabStatus !== "Expiring" && key !== site.localizedStrings['General.CertificationType_Sales'] &&
                        _.map(_.groupBy(_.concat([], _.flatMapDeep(items, (item) => {
                            let data = item.technologies.map((techId: any) => {
                                let techOption = tabTechnologies.find((t) => t.value === techId);

                                return {
                                    ...item,
                                    technology: techOption?.text
                                };
                            });
                            return data;
                        })).sort(firstBy('technology')), (o: any) => { return [o.technology] }), (certs: any, technology) => {
                            return <Box key={`${technology}-box`} border={{ side: "bottom", size: 'small' }} pad={{ left: "small", top: "small", bottom: "small" }}>
                                <Heading level='4'>{technology}</Heading>
                                <Grid gap={{ row: "small", column: "small" }} rows="auto" columns="medium" >
                                    {certs.sort(firstBy("levelOrder").thenBy("name")).map((entity: any) => this.renderCard(localUrl, entity, technology))}
                                </Grid>
                            </Box>
                        })
                    }

                    {(tabStatus === "Expiring" || key === site.localizedStrings['General.CertificationType_Sales']) &&
                        <Grid gap={{ row: "small", column: "small" }} rows="auto" columns="medium" >
                            {items.sort(firstBy("levelOrder").thenBy("name")).map((entity: any) => this.renderCard(localUrl, entity, key))}
                        </Grid>
                    }
                </Box>
            })}
        </Box >
    }

    renderExpired = (localUrl: string, filtered: any[]) => {
        let dateGrouping = _.groupBy(filtered.sort((l, r) => compareDesc(parseISO(l.date), parseISO(r.date))), (o) => { return [format(parseISO(o.date), "MMM yyyy")] });

        return <Accordion>
            {
                _.map(dateGrouping, (dateItems, date) => {
                    let typeGrouping = _.groupBy(dateItems, (o) => { return [o.type.value] });

                    return <AccordionPanel label={date}>
                        {this.renderGrid(localUrl, typeGrouping)}
                    </AccordionPanel>
                })}
        </Accordion>;
    }

    renderList = () => {
        let site = this.props.site;
        let data = this.props.certifications.entities;
        let keywords = this.props.certifications.keywords;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let entities = this.state.showAll
            ? data
            : data.filter((entity) => entity.isDeliveryReady);
        let view = this.props.certifications.view;
        let tabTechnologies = this.props.certifications.technologies;
        let technologies: string[] = tabTechnologies.filter((technology) => technology.selected).map(tech => tech.value);
        let allTech = technologies.some((tech) => tech === EmptyGuid);
        let tabStatus = this.props.certifications.status;
        let sortedEntities = _.sortBy(entities, (e) => e.levelOrder);
        let filtered = sortedEntities.filter((item) => {
            let hasView = true;

            switch (view) {
                case 'newOnly':
                    hasView = item.isNew;
                    break;
                case 'all':
                    break;
                default:
                    hasView = item.type.value === view;
                    break;
            }

            return hasView && (allTech || item.technologies.some((t) => technologies.some((st) => st === t))) &&
                (keywords === '' || item.name.toLowerCase().indexOf(keywords.toLowerCase()) !== -1 ||
                    item.programId.toLowerCase().indexOf(keywords.toLowerCase()) !== -1);
        });
        let sortedFiltered = _.sortBy(filtered, (o) => { return [o.type.value] });
        let typeGrouping = _.groupBy(sortedFiltered, (o) => { return [o.type.value] });

        return _.size(filtered) !== 0
            ? tabStatus === "Expired"
                ? this.renderExpired(localUrl, filtered)
                : this.renderGrid(localUrl, typeGrouping)
            : !site.loading && <Box height="small" align="center" justify="center" alignContent="center">
                <Text weight="bold">
                    {site.localizedStrings['ExternalPages.No_Certifications_Found_Statement']}
                </Text>
            </Box>;
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Technical'], value: `${localUrl}/technical-certifications` },
            { key: site.menuStrings['Datacard.View_All_Certifications_Label'], value: "" }
        ];
        let title = site.menuStrings['Datacard.View_All_Certifications_Label'];

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="entitylist_certifications" {...this.props}
            metadataDescription={site.localizedStrings['Metadata.CertificationsDescription']}
            metadataKeywords={site.localizedStrings['Metadata.CertificationsKeywords']}>
            {site.stringsLoaded && <Box>
                <Box gap="medium" fill="horizontal">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" fill="horizontal" >
                        <Paragraph fill textAlign="center">
                            {parse(site.localizedStrings['ExternalPages.Certification_Introduction_Statement'])}
                        </Paragraph>
                    </Box>
                </Box>
                <Box direction="row-responsive" background="#F5F5F5" gap="medium" justify="center" pad="small" margin={{ top: "small", bottom: "medium" }}>
                    <Card width="medium">
                        <CardHeader>
                            <Image fit="contain" src="images/veiw-all-certifications/HPE202208250032550_450_0_72_RGB 21271.jpg" />
                        </CardHeader>
                        <CardBody gap="small">
                            <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Certifications_Card1_Title']}</Text>
                            <Box gap="small">
                                <ul className="bullet-list">
                                    <li>
                                        <Text>
                                            {
                                                parse(formatString(site.localizedStrings['ExternalPages.View_All_Certifications_Card1_Bullet1'],
                                                    [`${localUrl}/technical-certifications`]))
                                            }
                                        </Text>
                                    </li>
                                    <li>
                                        <Text>
                                            {
                                                parse(formatString(site.localizedStrings['ExternalPages.View_All_Certifications_Card1_Bullet2'],
                                                    [`${localUrl}/sales-certifications`]))
                                            }
                                        </Text>
                                    </li>
                                </ul>
                            </Box>
                        </CardBody>
                    </Card>
                    <Card width="medium">
                        <CardHeader>
                            <Image fit="contain" src="images/veiw-all-certifications/HPE202208310010160_450_0_72_RGB 21458.jpg" />
                        </CardHeader>
                        <CardBody gap="small">
                            <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Title']}</Text>
                            <Box gap="xsmall">
                                <Paragraph>{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Body']}</Paragraph>
                                <Box gap="xxsmall">
                                    <ul className="bullet-list">
                                        <li>
                                            <Box direction="row" gap="xsmall" fill="horizontal" align="center" justify="between">
                                                <Text>{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Bullet1']}
                                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                                        <Paragraph fill>{`${site.localizedStrings['ExternalPages.ActiveCertifications_Tooltip']}`}</Paragraph>
                                                    </Box>}>
                                                        <Button margin={{ left: "small" }}>
                                                            <CircleQuestion size="small" color="brand" style={{ marginBottom: "6px" }} />
                                                        </Button>
                                                    </Tip>
                                                </Text>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box direction="row" gap="xsmall" fill="horizontal" align="center" justify="between">
                                                <Text>{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Bullet2']}
                                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                                        <Paragraph fill>{`${site.localizedStrings['ExternalPages.GoingInactiveCertifications_Tooltip']}`}</Paragraph>
                                                    </Box>}>
                                                        <Button margin={{ left: "small" }}>
                                                            <CircleQuestion size="small" color="brand" style={{ marginBottom: "6px" }} />
                                                        </Button>
                                                    </Tip>
                                                </Text>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box direction="row" gap="xsmall" fill="horizontal" align="center" justify="between">
                                                <Text>{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Bullet3']}
                                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                                        <Paragraph fill>{`${site.localizedStrings['ExternalPages.InactiveCertifications_Tooltip']}`}</Paragraph>
                                                    </Box>}>
                                                        <Button margin={{ left: "small" }}>
                                                            <CircleQuestion size="small" color="brand" style={{ marginBottom: "6px" }} />
                                                        </Button>
                                                    </Tip>
                                                </Text>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box direction="row" gap="xxsmall" fill="horizontal" align="center" justify="between">
                                                <Text>{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Bullet4']}
                                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                                        <Paragraph fill>{`${site.localizedStrings['ExternalPages.ExpiringCertifications_Tooltip']}`}</Paragraph>
                                                    </Box>}>
                                                        <Button margin={{ left: "small" }}>
                                                            <CircleQuestion size="small" color="brand" style={{ marginBottom: "6px" }} />
                                                        </Button>
                                                    </Tip>
                                                </Text>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box direction="row" gap="xxsmall" fill="horizontal" align="center" justify="between">
                                                <Text>{site.localizedStrings['ExternalPages.View_All_Certifications_Card2_Bullet5']}
                                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                                        <Paragraph fill>{`${site.localizedStrings['ExternalPages.ExpiredCertifications_Tooltip']}`}</Paragraph>
                                                    </Box>}>
                                                        <Button margin={{ left: "small" }}>
                                                            <CircleQuestion size="small" color="brand" style={{ marginBottom: "6px" }} />
                                                        </Button>
                                                    </Tip>
                                                </Text>
                                            </Box>
                                        </li>
                                    </ul>
                                </Box>
                            </Box>
                        </CardBody>
                    </Card>
                    <Card width="medium">
                        <CardHeader>
                            <Image fit="contain" src="images/veiw-all-certifications/HPE202208250032685_450_0_72_RGB 21272.jpg" />
                        </CardHeader>
                        <CardBody gap="small">
                            <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Certifications_Card3_Title']}</Text>
                            <Paragraph>{parse(site.localizedStrings['ExternalPages.View_All_Certifications_Card3_Body'])}</Paragraph>
                            <Anchor href={`${localUrl}/keep-current`}>{site.localizedStrings['ExternalPages.Learn_More_Label']}</Anchor>
                        </CardBody>
                    </Card>
                    <Card width="medium">   
                        <CardHeader>
                            <Image fit="contain" src="images/veiw-all-certifications/HPE202208300062387_450_0_72_RGB 23361.jpg" />
                        </CardHeader>
                        <CardBody gap="small">
                            <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.View_All_Certifications_Card4_Title']}</Text>
                            <Box gap="small">
                                <ul className="bullet-list">
                                    <li><Anchor href={`${localUrl}/TrainingCalendar`}>{site.menuStrings['Datacard.Training_Calendar_Label']}</Anchor></li>
                                    <li><Anchor href={`${localUrl}/courses`}>{site.localizedStrings['ExternalPages.Certification_Courses_Label']}</Anchor></li>
                                    <li><Anchor href={`${localUrl}/exams`}>{site.localizedStrings['ExternalPages.Certification_Exams_Label']}</Anchor></li>
                                    <li><Anchor onClick={() => { this.setState({ showEmployeeWarning: true }) }}>{site.localizedStrings['ExternalPages.Certifications_For_Employees_Label']}</Anchor></li>
                                    <li><Anchor href={`${localUrl}/credentials`}>{site.localizedStrings['ExternalPages.Credentials_Label']}</Anchor></li>
                                </ul>
                            </Box>
                        </CardBody>
                    </Card>
                </Box>
                {langInfo[0] === 'ja' && <Box>
                    <Text>
                        {parse(site.localizedStrings[this.state.showAll ? 'ExternalPages.View_Certifications_Language_Msg' : 'ExternalPages.View_All_Certifications_Language_Msg'])}
                    </Text>
                    <Anchor onClick={this.toggoleShowAll}
                        label={parse(site.localizedStrings[this.state.showAll ? 'ExternalPages.View_Certifications_Language_Link' : 'ExternalPages.View_All_Certifications_Language_Link'])} />
                </Box>}
            </Box>}
            <Box gap="small">
                {this.state.showEmployeeWarning && <Layer>
                    <Box width="medium" height={{ max: "medium" }}>
                        <Card fill>
                            <CardHeader pad="small" elevation="small" justify="center" background="brand">
                                <Text weight="bold" size="small" textAlign="center" color="white">
                                    {site.localizedStrings['Datacard.View_HPE_Certification_Label']}
                                </Text>
                            </CardHeader>
                            <CardBody pad="small" align="center">
                                <Box height={{ max: 'medium' }} overflow="auto" wrap>
                                    <Paragraph fill size="small">
                                        {parse(site.localizedStrings['ExternalPages.EmployeeCertification_Warning_Statement'])}
                                    </Paragraph>
                                </Box>
                            </CardBody>
                            <CardFooter pad="small">
                                <Box direction="row" fill="horizontal" gap="small" justify="end">
                                    <Button alignSelf="end" label={site.localizedStrings['ExternalPages.Yes_Label']} primary
                                        onClick={() => { this.onShowEmployeeCertifications(); }} />
                                    <Button alignSelf="end" label={site.localizedStrings['ExternalPages.No_Label']} secondary
                                        onClick={() => { this.setState({ showEmployeeWarning: false }); }} />
                                </Box>
                            </CardFooter>
                        </Card>
                    </Box>
                </Layer>}
                <Box pad={{ top: "medium" }}>
                    <Box>
                        <Box direction="row-responsive" fill="horizontal" gap="small" pad="small" border={{ side: "bottom", color: 'brand', size: 'small' }}>
                            {this.renderFilters()}
                        </Box>
                        {this.renderList()}
                    </Box>
                </Box>
            </Box>
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Certifications as any));
