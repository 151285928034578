import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import { Box, Paragraph, Heading, Text, Button, Tabs, Tab, Accordion, AccordionPanel, DataTable, Select, DateInput, CheckBox } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import BasePage from '../../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ILearningAssignmentProps {
}

interface ILearningAssignmentState {
}

type LearningAssignmentProps = ILearningAssignmentProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class LearningAssignment extends React.PureComponent<LearningAssignmentProps, ILearningAssignmentState> {
    constructor(props: LearningAssignmentProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label',
            'MyLearning.PLMT_Overview_Paragraph', 'MyLearning.PLMT_Title', 'MyLearning.LearningAssignment_Label', 'MyLearning.LearningAssigment_Statement',
            'MyLearning.AssignmentType_Label', 'MyLearning.Submit_Changes_Label', 'MyLearning.Email_Notification_Label', 'MyLearning.Email_Information_Label',
            'MyLearning.Not_Authorized_Statement'];

        this.state = {
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('admin-tools-menu');
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.LearningAssignment_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="LearningAssignment" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={3}>{page}</Heading>
                <Box pad={{ top: "small", bottom: "small" }} gap="medium">
                    <Text>{site.localizedStrings['MyLearning.PLMT_Activities_Statement']}</Text>
                    <Accordion>
                        <AccordionPanel label={site.localizedStrings['MyLearning.PLMT_More_Details']}>
                            <Box pad="small" fill>
                                {parse(site.localizedStrings['MyLearning.PLMT_LearningAssignment_Detail_Instructions'])}
                            </Box>
                        </AccordionPanel>
                    </Accordion>
                    <DataTable fill pin="header" pad={{ bottom: "medium" }}
                        data={[]}
                        columns={[
                            {
                                property: 'learnerId',
                                header: <Text>HPE Learner ID</Text>,
                                size: 'small'
                            },
                            {
                                property: 'firstName',
                                header: <Text>{site.localizedStrings['MyLearning.First_Name_Label']}</Text>,
                                size: 'small'
                            },
                            {
                                property: 'lastName',
                                header: <Text>{site.localizedStrings['MyLearning.Last_Name_Label']}</Text>,
                                size: 'small'
                            },
                            {
                                property: 'achieved',
                                header: <Text>{site.localizedStrings['MyLearning.Activity_Name_Label']}</Text>
                            },
                            {
                                property: 'completed',
                                header: <Text>{site.localizedStrings['MyLearning.Date_Acquired_Label']}</Text>,
                                // render: datum => <Text>{datum.dateAcquired ? format(parseISO(datum.dateAcquired), 'MM/dd/yyyy') : 'N/A'}</Text>,
                                size: 'small'
                            },
                            {
                                property: 'assigned',
                                header: <Text>{site.localizedStrings['MyLearning.Expires_Label']}</Text>,
                                // render: datum => <Text>{datum.expirationDate ? format(parseISO(datum.expirationDate), 'MMM/dd/yyyy') : ''}</Text>,
                                size: 'small'
                            },
                            {
                                property: 'dueDate',
                                header: <Text>{site.localizedStrings['MyLearning.Expires_Label']}</Text>,
                                // render: datum => <Text>{datum.expirationDate ? format(parseISO(datum.expirationDate), 'MMM/dd/yyyy') : ''}</Text>,
                                size: 'small'
                            },
                            {
                                property: 'addAssignment',
                                header: <Text>{site.localizedStrings['MyLearning.Expires_Label']}</Text>,
                                // render: datum => <Text>{datum.expirationDate ? format(parseISO(datum.expirationDate), 'MMM/dd/yyyy') : ''}</Text>,
                                size: 'small'
                            },
                            {
                                property: 'email',
                                header: <Text>{site.localizedStrings['MyLearning.Expires_Label']}</Text>,
                                // render: datum => <Text>{datum.expirationDate ? format(parseISO(datum.expirationDate), 'MMM/dd/yyyy') : ''}</Text>,
                                size: 'small'
                            }
                        ]}
                    />
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(LearningAssignment as any));
