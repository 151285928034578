import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { Portfolio } from '../models/Portfolio';
import { setLoading, setError } from './Site';
import { CertificationInfo } from '../models/Certification';
import {EntityInfo, EmptyGuid } from '../models/Common';

export interface TechnicalState {
    portfolios: Portfolio[] | undefined;
    certifications: CertificationInfo[] | undefined;
    credentials: EntityInfo[] | undefined;
}

let initialState: TechnicalState = {
    portfolios: undefined,
    certifications: undefined,
    credentials: undefined
}

export const technicalSlice = createSlice({
    name: 'Technical',
    initialState,
    reducers: {
        setPortfolios: (state, action) => {
            state.portfolios = action.payload;
        },
        setCertifications: (state, action) => {
            state.certifications = action.payload;
        },
        setCredentials: (state, action) => {
            state.credentials = action.payload;
        }
    }
});

export const { setPortfolios, setCertifications, setCredentials } = technicalSlice.actions;

export const retrievePortfolios = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language != '' ? language : null
    }

    dispatch(setLoading(true));
    fetch('api/list/portfolios', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setPortfolios(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveCertifications = (language: string): AppThunk => async (dispatch, getState) => {
    let request = {
        types: [EmptyGuid],
        languages: [EmptyGuid],
        technologies: [EmptyGuid],
        view: 'all',
        language: language
    }

    fetch('api/certifications/list', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCertifications(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveCredentials = (language: string): AppThunk => async (dispatch, getState) => {
    let request = {
        types: [EmptyGuid],
        languages: [EmptyGuid],
        technologies: [EmptyGuid],
        view: 'all',
        language: language
    }

    fetch('api/credentials/list', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCredentials(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export default technicalSlice.reducer;
