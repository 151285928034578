import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { Calendar as CalendarModel, CalendarFilter, CourseResults, CalendarOptions } from '../models/Calendar';
import { SelectOption, AllOption } from '../models/Common';
import { setLoading, setError } from './Site';
import { updateList } from '../Utilities'

export interface CalendarState {
    startDate: string;
    endDate: string;
    currentPage: number;
    pageSize: number;
    keywords: string;
    courseId: string;
    technologies: string[];
    deliveryFormats: string[];
    trainingCompanies: string[];
    languages: string[];
    excludePartnersRestricted: boolean;
    entityTypes: string[];
    entityType: string;
    options: CalendarOptions;
    courses: CourseResults;
}

export const initialState: CalendarState = {
    startDate: "",
    endDate: "",
    currentPage: 1,
    pageSize: 12,
    keywords: "",
    courseId: "",
    technologies: [],
    deliveryFormats: [],
    trainingCompanies: [],
    languages: [],
    excludePartnersRestricted: false,
    entityType: "",
    entityTypes: [],
    options: {
        calendarViews: [],
        regions: [AllOption],
        courseTypes: [AllOption],
        trainingModes: [AllOption],
        technologies: [AllOption],
        jobRoles: [AllOption],
        languages: [AllOption],
        trainingCompanies: [AllOption],
        entityTypes: []
    },
    courses: {
        list: [],
        totalCourses: 0,
        totalPages: 0
    }
}

export const calendarSlice = createSlice({
    name: 'Calendar',
    initialState,
    reducers: {
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setEntityType: (state, action) => {
            state.entityType = action.payload;
        },
        setCourseId: (state, action) => {
            state.courseId = action.payload;
        },
        setTechnologies: (state, action) => {
            state.technologies = action.payload;
        },
        setDeliveryFormats: (state, action) => {
            state.deliveryFormats = action.payload;
        },
        setTrainingCompanies: (state, action) => {
            state.trainingCompanies = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setExcludePartnersRestricted: (state, action) => {
            state.excludePartnersRestricted = action.payload;
        },
        setKeywords: (state, action) => {
            state.keywords = action.payload;
        },
        setOptions: (state, action) => {
            state.options = action.payload;
        },
        setCourses: (state, action) => {
            state.courses = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setSelected: (state, action) => {
            let values: string[] = action.payload.split('-');
            let index = parseInt(values[1]);
            let checked = values[2] === 'true';

            switch (values[0]) {
                case 'calendarViews': {
                    state.options.calendarViews = updateList(state.options.calendarViews, index, checked, false);
                    break;
                }
                case 'courseTypes': {
                    state.options.courseTypes = updateList(state.options.courseTypes, index, checked);
                    break;
                }
                case 'jobRoles': {
                    state.options.jobRoles = updateList(state.options.jobRoles, index, checked);
                    break;
                }
                case 'languages': {
                    state.options.languages = updateList(state.options.languages, index, checked);
                    break;
                }
                case 'regions': {
                    state.options.regions = updateList(state.options.regions, index, checked);
                    break;
                }
                case 'technologies': {
                    state.options.technologies = updateList(state.options.technologies, index, checked);
                    break;
                }
                case 'trainingCompanies': {
                    state.options.trainingCompanies = updateList(state.options.trainingCompanies, index, checked);
                    break;
                }
                case 'trainingModes': {
                    state.options.trainingModes = updateList(state.options.trainingModes, index, checked);
                    break;
                }
                case 'entityTypes': {
                    state.options.entityTypes = updateList(state.options.entityTypes, index, checked, false);
                    break;
                }
            }
        }
    }
});

export const { setCourses, setStartDate, setEndDate, setSelected, setCurrentPage, setCourseId, setTechnologies,
    setExcludePartnersRestricted, setKeywords, setEntityType, setDeliveryFormats, setTrainingCompanies, setLanguages,
    setOptions } = calendarSlice.actions;

export const retrieveOptions = (language: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/calendar/options';
    let request = {
        language: language
    }

    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setOptions(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveCourses = (language: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/calendar/search';
    let state = getState();
    let calendar = state.calendar;
    let options = calendar.options;
    let request = {
        language: language ? language:state.site.currentLanguage.value,
        currentPage: calendar.currentPage,
        calendarViews: options.calendarViews.filter((view) => view.selected).map((view) => view.value),
        regions: options.regions.filter((region) => region.selected && region.value !== 'all').map((view) => view.value),
        courseTypes: options.courseTypes.filter((type) => type.selected && type.value !== 'all').map((view) => view.value),
        trainingModes: options.trainingModes.filter((mode) => mode.selected && mode.value !== 'all').map((view) => view.value),
        technologies: options.technologies.filter((tech) => tech.selected && tech.value !== 'all').map((view) => view.value),
        jobRoles: options.jobRoles.filter((role) => role.selected && role.value !== 'all').map((view) => view.value),
        languages: options.languages.filter((lang) => lang.selected && lang.value !== 'all').map((view) => view.value),
        trainingCompanies: options.trainingCompanies.filter((company) => company.selected && company.value !== 'all').map((view) => view.value),
        entityTypes: options.entityTypes.filter((view) => view.selected).map((view) => view.value),
        excludePartnersRestricted: calendar.excludePartnersRestricted,
        courseId: calendar.courseId,
        keywords: calendar.keywords,
        startDate: calendar.startDate && calendar.startDate !== '' ? calendar.startDate : null,
        endDate: calendar.endDate && calendar.endDate !== '' ? calendar.endDate : null
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCourses(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveOfferings = (language: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/calendar/search';
    let state = getState();
    let calendar = state.calendar;
    let options = calendar.options;
    let request: CalendarFilter = {
        language: language.startsWith('en') ? null : language,
        currentPage: calendar.currentPage,
        calendarViews: options.calendarViews.filter((view) => view.selected).map((view) => view.value),
        regions: options.regions.filter((region) => region.selected && region.value !== 'all').map((view) => view.value),
        courseTypes: options.courseTypes.filter((type) => type.selected && type.value !== 'all').map((view) => view.value),
        trainingModes: options.trainingModes.filter((mode) => mode.selected && mode.value !== 'all').map((view) => view.value),
        technologies: options.technologies.filter((tech) => tech.selected && tech.value !== 'all').map((view) => view.value),
        jobRoles: options.jobRoles.filter((role) => role.selected && role.value !== 'all').map((view) => view.value),
        languages: options.languages.filter((lang) => lang.selected && lang.value !== 'all').map((view) => view.value),
        trainingCompanies: options.trainingCompanies.filter((company) => company.selected && company.value !== 'all').map((view) => view.value),
        entityTypes: options.entityTypes.filter((view) => view.selected).map((view) => view.value),
        excludePartnersRestricted: calendar.excludePartnersRestricted,
        courseId: calendar.courseId,
        keywords: calendar.keywords,
        startDate: calendar.startDate && calendar.startDate !== '' ? calendar.startDate : null,
        endDate: calendar.endDate && calendar.endDate !== '' ? calendar.endDate : null
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCourses(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default calendarSlice.reducer;
