import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { EntityFilter } from '../models/EntityList';
import { setLoading, setError } from './Site';
import { updateList } from '../Utilities'
import { SelectOption, EntityInfo, EmptyGuid, AllOption, AllGuidOption } from '../models/Common'

export interface ExamsState {
    entityType: string,
    languageCode: string,
    countryCode: string,
    metadataDescription: string,
    metadataKeywords: string,
    view: string,
    keywords: string,
    lauguage?: string,
    views: SelectOption[];
    types: SelectOption[];
    technologies: SelectOption[];
    languages: SelectOption[];
    formats: SelectOption[];
    entities: EntityInfo[];
}

let initialState: ExamsState = {
    entityType: '',
    languageCode: 'US',
    countryCode: 'en',
    metadataDescription: '',
    metadataKeywords: '',
    view: 'all',
    keywords: '',
    lauguage: undefined,
    views: [AllOption],
    types: [AllGuidOption],
    technologies: [AllGuidOption],
    languages: [AllGuidOption],
    formats: [AllGuidOption],
    entities: []
}

export const examsSlice = createSlice({
    name: 'Exams',
    initialState,
    reducers: {
        setExams: (state, action) => {
            state.entities = action.payload;
        },
        setTechnologies: (state, action) => {
            state.technologies = action.payload;
        },
        setFormats: (state, action) => {
            state.formats = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setTypes: (state, action) => {
            state.types = action.payload;
        },
        setViews: (state, action) => {
            state.views = action.payload;
        },
        setSelected: (state, action) => {
            let values: string[] = action.payload.split('-');
            let index = parseInt(values[1]);
            let checked = values[2] === 'true';

            switch (values[0]) {
                case 'views': {
                    state.views = updateList(state.views, index, checked, false);
                    break;
                }
                case 'types': {
                    state.types = updateList(state.types, index, checked);
                    break;
                }
                case 'languages': {
                    state.languages = updateList(state.languages, index, checked);
                    break;
                }
                case 'technologies': {
                    state.technologies = updateList(state.technologies, index, checked);
                    break;
                }
                case 'formats': {
                    state.formats = updateList(state.formats, index, checked);
                    break;
                }
            }
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setKeywords: (state, action) => {
            state.keywords = action.payload;
        }
    }
});

export const { setExams, setSelected, setView, setKeywords, setTechnologies, setFormats, setLanguages, setTypes,
    setViews } = examsSlice.actions;

export const retrieveExams = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let state = getState();
    let exams = state.exams;
    let request: EntityFilter = {
        types: exams.types.filter((view) => view.selected).map((view) => view.value) ?? [EmptyGuid],
        languages: exams?.languages.filter((view) => view.selected).map((view) => view.value) ?? [EmptyGuid],
        technologies: exams?.technologies.filter((view) => view.selected).map((view) => view.value) ?? [EmptyGuid],
        view: exams.view,
        keywords: exams.keywords,
        language: language,
        status: ''
    }

    dispatch(setLoading(true));
    fetch('api/exams/list', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setExams(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveExamTechnologies = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/exams/technologies', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setTechnologies(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveExamLanguages = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/exams/languages', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setLanguages(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveExamFormats = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/exams/formats', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setFormats(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveExamTypes = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/exams/types', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setTypes(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveExamViews = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/exams/views', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setViews(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export default examsSlice.reducer;
