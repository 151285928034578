import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from '.';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export default function setupStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        routerMiddleware(history)
    ];
    const rootReducer = combineReducers({
        router: connectRouter(history),
        ...reducers
    });
    const enhancers: any = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    // const persistConfig = {
    //     key: 'root',
    //     storage,
    //     blacklist: ['router']
    // }
    // const persistedReducer = persistReducer(persistConfig, rootReducer);

    if (process.env.NODE_ENV !== 'production') {
        const { logger } = require(`redux-logger`);
        
        middleware.push(logger);
    }
// if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    //     enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    // }

    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            [...getDefaultMiddleware(), ...middleware],
        enhancers: enhancers,
        preloadedState: initialState,
        devTools: process.env.NODE_ENV !== 'production'
    });
}
