import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair, SelectOption } from '../../models/Common';
import {
    Box, Heading, Text, Accordion, AccordionPanel, DataTable, RadioButtonGroup, Select, Form, FormField, Layer,
    Button, CheckBox, Header, Tip, TextInput
} from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveCountries, retrieveSigningEntities, retrieveDistributors, saveDistributors, plmtSlice } from '../../store/Plmt';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { hasRole, isPLMTAdmin, getPLMTAdminRegions } from '../../Utilities';
import { firstBy } from 'thenby';
import { CircleInformation, Search } from 'grommet-icons';
import { SigningEntityInfo } from '../../models/plmt/SigningEntityInfo';

const mapDispatchToProps = {
    retrieveCountries,
    retrieveSigningEntities,
    retrieveDistributors,
    saveDistributors,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
    ...plmtSlice.actions
}

interface IDistributorsProps {
}

interface IDistributorsState {
    region?: string;
    country?: SelectOption;
    countries: SelectOption[];
    signingEntities?: SigningEntityInfo[];
    filters?: any;
    distributors?: SelectOption[];
    showFilters: boolean;
    programs: string[];
    loadingSummary: boolean;
}

type DistributorsProps = IDistributorsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Distributors extends React.PureComponent<DistributorsProps, IDistributorsState> {
    constructor(props: DistributorsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label',
            'MyLearning.Distributors_Label', 'MyLearning.PLMT_Distributors_Statement', 'MyLearning.PLMT_More_Details', 'MyLearning.PLMT_Title',
            'MyLearning.PLMT_Distributors_Detail_Instructions', 'MyLearning.Region_Label', 'MyLearning.GapSummary_Label',
            'MyLearning.Country_Label', 'MyLearning.SigningEntity_Label', 'MyLearning.Distributors_Usage_Statement',
            'MyLearning.Distributor_Management_Label', 'MyLearning.Distributor_Selection_Label', 'MyLearning.Assign_View_Label',
            'MyLearning.Proficency_Label', 'MyLearning.RequirementsGroup_Label', 'MyLearning.Cancel_Label', 'MyLearning.Save_Label',
            'MyLearning.Program_Type_Label', 'MyLearning.DistributorName_Label', 'MyLearning.Has_Access_Label',
            'MyLearning.Not_Authorized_Statement'];

        this.state = {
            countries: [],
            showFilters: false,
            programs: [],
            loadingSummary: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveCountries();
        this.props.setActiveMenu('admin-tools-menu');
    }

    componentDidUpdate() {
        let user = this.props.site.user;
        let isAdmin = isPLMTAdmin(user);

        if (user && !isAdmin) {
            if (!this.state.filters?.region && this.props.plmt.countries) {
                let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);

                if (country) {
                    this.setState({ filters: { region: country?.region } });
                    this.onRegionChange({ target: { value: country?.region } });
                }
            }
            if (this.state.filters?.region && !this.state.filters?.country && this.state.countries) {
                let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);
                let filters = JSON.parse(JSON.stringify(this.state.filters));

                if (country) {
                    filters.country = {
                        text: country.name,
                        value: country.code,
                        selected: false
                    };
                    this.setState({ filters: filters });
                    this.onCountryChange({ value: { value: country?.code } })
                }
            }
            if (user?.partyId && this.props.plmt.signingEntities && this.state.filters?.region && this.state.filters?.country &&
                !this.state.filters?.signingEntity) {
                let signingEntity = _.find(this.props.plmt.signingEntities, (party) => party.partyId == Number(user?.partyId));

                if (signingEntity) {
                    this.onEntityChange({ value: signingEntity })
                }
            }
        }
        if (this.state.filters?.region && this.state.countries.length === 0) {
            let filters = JSON.parse(JSON.stringify(this.state.filters));

            this.onRegionChange({ target: { value: filters?.region } })
        }
    }

    onRegionChange = (event: any) => {
        let selected = event.target.value;
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[selected].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });
        filters.region = selected;
        delete filters.country;
        delete filters.signingEntity;
        delete filters.program;
        this.props.setDistributors(undefined);

        this.setState({ filters: filters, countries: _.sortBy(countries, country => country.text), distributors: undefined });
    }

    onCountryChange = (event: any) => {
        let selected = event.value;
        let country = _.find(this.props.plmt.countries, (country) => country.code == selected.value);
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[filters?.region].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });
        let request = {
            country: country,
            partyId: !isPLMTAdmin(this.props.site.user) ? this.props.site.user?.partyId : undefined
        }

        filters.country = selected;
        delete filters.signingEntity;
        delete filters.program;
        this.props.setSigningEntities(undefined);
        this.props.retrieveSigningEntities(request);
        this.setState({ filters: filters, countries: _.sortBy(countries, country => country.text), distributors: undefined })
    }

    onEntityChange = (event: any) => {
        let signingEntity = event.value;
        let programs = signingEntity.programs;
        let program = '';
        let loadingSummary = false;
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let request = {
            region: filters.region?.value,
            country: filters.country?.value,
            signingEntityId: signingEntity.partyId
        };

        if (programs.length == 1) {
            program = programs[0];
        }
        filters.program = program;
        filters.signingEntity = signingEntity;
        this.props.retrieveDistributors(request, this.onDistributorsLoaded);
        this.setState({
            signingEntities: undefined, programs: programs, filters: filters, loadingSummary: loadingSummary
        });
    }

    onCountrySearch = (search: string) => {
        let regionGrouping = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let countries: SelectOption[] = regionGrouping[filters?.region].map(country => {
            return {
                text: country.name,
                value: country.code,
                selected: false
            }
        });

        countries = countries.filter(country => country.value.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            country.text.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        this.setState({ countries: countries });
    }

    onEntitySearch = (search: string) => {
        let signingEntities = this.props.plmt.signingEntities ?? [];
        let entities = signingEntities.filter((entity) => {
            let partyId = `${entity.partyId}`.toLowerCase();

            return (entity.name && entity.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                (partyId.indexOf(search.toLowerCase()) !== -1);
        });

        this.setState({ signingEntities: entities });
    }

    getRegions = () => {
        let site = this.props.site;
        let user = site.user;
        let regionGroups = _.groupBy(this.props.plmt.countries, (country) => country.region);
        let regions = _.sortBy(_.map(regionGroups, (countries, region) => region), a => a) ?? [];
        let isAdmin = isPLMTAdmin(user);
        let adminRegions = getPLMTAdminRegions(user);

        if (isAdmin) {
            regions = regions.filter((region) => _.some(adminRegions, (adminRegion) => adminRegion == region));
        }
        regions = regions.filter(region => region == "NORTH AMERICA");

        return regions;
    }

    isDirty = () => {
        let originalDistributors = {
            ...this.props.plmt.distributors,
        };
        let currentDistributors = {
            ...this.state.distributors
        };

        return !_.isEqual(originalDistributors, currentDistributors);
    }

    onCancel = () => {
        this.setState({ distributors: this.props.plmt.distributors });
    }

    onSave = () => {
        let filters = JSON.parse(JSON.stringify(this.state.filters));
        let request = {
            region: filters.region,
            country: filters.country.value,
            signingEntityId: filters.signingEntity.partyId,
            distributors: this.state.distributors?.filter(distributor => distributor.selected)
        };
        this.props.saveDistributors(request, this.onDistributorsLoaded)
    }

    onDistributorsLoaded = () => {
        this.setState({ distributors: this.props.plmt.distributors });
    }

    onAccessChanged = (partyId: string, checked: boolean) => {
        let currentDistributors: SelectOption[] = JSON.parse(JSON.stringify(this.state.distributors));
        let updatedDistributors = currentDistributors.filter((d: SelectOption) => d.value !== partyId);
        let distributor = currentDistributors.find((d: SelectOption) => d.value === partyId);

        if (distributor) {
            distributor.selected = checked;
            updatedDistributors.push(distributor);
            this.setState({ distributors: updatedDistributors });
        }
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.Distributor_Management_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let regions = this.getRegions();
        let signingEntities = this.state.signingEntities ?? this.props.plmt.signingEntities ?? [];
        let isAdmin = isPLMTAdmin(user);
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isDistributor = hasRole(user, ["DISTI"]);
        let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);
        let distributors = _.sortBy(this.state.distributors, dist => dist.text);

        if (!user) {
            return null;
        }
        if (!isAdmin && (isDistributor || ((isPEM || isPPA) && country && country?.region !== 'NORTH AMERICA'))) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (!this.state.filters?.region && regions?.length == 1) {
            this.onRegionChange({ target: { value: regions[0] } });
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="Distributors" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'large' }}>
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small" />
                </Box>
                <Box pad={{ top: "small", bottom: "small" }} gap="medium">
                    <Heading level={3}>{page}</Heading>
                    <Text>{site.localizedStrings['MyLearning.PLMT_Distributors_Statement']}</Text>
                    <Text>{site.localizedStrings['MyLearning.Distributors_Usage_Statement']}</Text>
                    <Heading level={4}>{site.localizedStrings['MyLearning.Distributor_Selection_Label']}</Heading>
                    <Form value={this.state.filters} onChange={nextValue => this.setState({ filters: nextValue })}>
                        <Box gap="medium">
                            <Box direction='row-responsive' gap="large">
                                <Box direction='row-responsive' gap="small" alignContent='start'>
                                    <FormField label={<Text weight='bold'>{site.localizedStrings['MyLearning.Region_Label']}</Text>} name="region">
                                        <RadioButtonGroup name="region" id="region-input" options={regions} pad='small' disabled={!isAdmin}
                                            onChange={this.onRegionChange} />
                                    </FormField>
                                </Box>
                                <Box gap="small">
                                    <Box gap="xsmall">
                                        <Text weight='bold'>{site.localizedStrings['MyLearning.Country_Label']}</Text>
                                        <Select name="country" id="country-input" options={this.state.countries} labelKey='text' valueKey='value'
                                            dropHeight='small' onChange={this.onCountryChange} onSearch={this.onCountrySearch}
                                            disabled={!isAdmin || this.state.filters?.region === undefined} />
                                    </Box>
                                    <Box gap="xsmall" width="medium">
                                        <Box direction="row" gap="small">
                                            <Text weight='bold'>{site.localizedStrings['MyLearning.SigningEntity_Label']}</Text>
                                            <Tip content="Search by Signing Entity name or ID">
                                                <CircleInformation size="small" />
                                            </Tip>
                                        </Box>
                                        <Select name="signingEntity" id="signingEntity-input" options={signingEntities} labelKey='name' valueKey='partyId'
                                            dropHeight='small' onChange={this.onEntityChange} disabled={!isAdmin || this.state.filters?.country === undefined}
                                            onSearch={this.onEntitySearch} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Form>
                    <Heading level={4}>{site.localizedStrings['MyLearning.Assign_View_Label']}</Heading>
                    {distributors && <Box gap='small'>
                        <Box direction='row-responsive' gap="medium">
                            <Button primary label={site.localizedStrings['MyLearning.Save_Label']} onClick={this.onSave} disabled={!this.isDirty()} />
                            <Button secondary label={site.localizedStrings['MyLearning.Cancel_Label']} onClick={this.onCancel} disabled={!this.isDirty()} />
                        </Box>
                        <Box height="large" width={{ max: "large" }}>
                            <DataTable pin="header"
                                data={distributors}
                                columns={[
                                    {
                                        property: 'text',
                                        size: 'medium',
                                        header: <Text>{site.localizedStrings['MyLearning.DistributorName_Label']}</Text>,
                                    },
                                    {
                                        property: 'selected',
                                        size: 'small',
                                        align: 'center',
                                        header: <Text>{site.localizedStrings['MyLearning.GapSummary_Label']}</Text>,
                                        render: datum => <CheckBox checked={datum.selected} onChange={(event) => this.onAccessChanged(datum.value, event.target.checked)} />
                                    }
                                ]}
                            />
                        </Box>
                    </Box>}
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Distributors as any));
