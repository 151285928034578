import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, Accordion, AccordionPanel, Anchor, Paragraph, ResponsiveContext,
    Table, TableBody, TableRow, TableCell, Heading
} from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store/';
import { retrieveCourse, courseSlice } from './../../store/Course';
import parse from 'html-react-parser';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { Course as CourseModel } from '../../models/Course';
import { formatString, HPEWindowInterface } from '../../Utilities'
import { KeyValuePair } from '../../models/Common';
import BasePage from '../../components/BasePage';

declare let window: HPEWindowInterface;


const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveCourse,
    ...courseSlice.actions,
    ...siteSlice.actions
}

interface CourseState {
    activePanel: number;
}

type CourseProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Course extends React.PureComponent<CourseProps, CourseState> {
    constructor(props: CourseProps) {
        super(props);
        this.state = {
            activePanel: 0
        };
    }

    public componentDidMount() {
        let currentLang = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['Datacard.Digital_Badge_Label', 'Datacard.Digital_Badge_Statement',
            'Datacard.Course_NotAvailable', 'Datacard.Course_Id_Label', 'Datacard.HPE_Product_Num_Label', 'Datacard.Course_Format_Duration_Label',
            'Datacard.Skill_Level_Label', 'Datacard.Print_This_Page', 'Datacard.Topics_Label', 'ExternalPages.Certification_And_Learning_Label',
            'Datacard.Delivery_Languages_Label', 'Datacard.Lab_Required_Label', 'Datacard.Related_Certifications_Label', 'Datacard.Course_Preparation_Statement',
            'Datacard.Course_ExamList_Statement', 'Datacard.Course_Description_Label', 'Datacard.Course_Ideal_Cadidate_Label', 'Datacard.Course_Prerequisite_Label',
            'Datacard.Objectives_Label', 'Datacard.How_To_Register_Label', 'Datacard.Policies_Fees_Cancellations_Label', 'General.ExternalSabaUrl',
            'Datacard.Training_Calendar_Statement', 'Datacard.Take_This_Course_Statement', 'Datacard.Register_For_Course_Statement', 'Datacard.Upon_Completion_Label',
            'Datacard.Summary_Label', 'Datacard.Details_Label', 'Datacard.Exam_AdditionalMaterials_Label'];
        let currentDate = new Date();
        let launchDate = new Date('2022-06-01T14:00:00.000Z');

        this.props.retrieveLocalizedStrings(keys, currentLang);
        if (this.props.match.params.id === '0001189907' && currentDate >= launchDate) {
            this.props.history.push('0001196525');
            this.props.history.go(0);
        }
        else {
            this.props.retrieveCourse(this.props.match.params.id, currentLang);
        }
    }

    public componentDidUpdate(prevProps: CourseProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;

        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            this.props.retrieveCourse(this.props.match.params.id, urlLanguage);
        }
    }

    onPanelChange = (activePanels: number[]) => {
        if (activePanels.length !== 0) {
            this.setState({ activePanel: activePanels[0] });
        }
        else {
            this.setState({ activePanel: -1 });
        }
    }

    renderSummary = (course: CourseModel) => {
        let site = this.props.site;
        let summaryData = [];

        summaryData.push(<TableRow key="sum-row-programId">
            <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Course_Id_Label']}</Text></TableCell>
            <TableCell >{course.programId}</TableCell>
        </TableRow>);
        if (course.hpProductNumber) {
            summaryData.push(<TableRow key="sum-row-hpProductNumber">
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.HPE_Product_Num_Label']}</Text></TableCell>
                <TableCell >{course.hpProductNumber}</TableCell>
            </TableRow>);
        }
        if (course.courseFormats) {
            summaryData.push(<TableRow key="sum-row-courseFormats">
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Course_Format_Duration_Label']}</Text></TableCell>
                <TableCell ><Box>{parse(course.courseFormats)}</Box></TableCell>
            </TableRow>);
        }
        if (course.skillLevel) {
            summaryData.push(<TableRow key="sum-row-skillLevel">
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Skill_Level_Label']}</Text></TableCell>
                <TableCell ><Box>{course.skillLevel}</Box></TableCell>
            </TableRow>);
        }
        summaryData.push(<TableRow key="sum-row-deliveryLanguages">
            <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Delivery_Languages_Label']}</Text></TableCell>
            <TableCell ><Box>{course.deliveryLanguages}</Box></TableCell>
        </TableRow>);
        summaryData.push(<TableRow key="sum-row-labRequired">
            <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Lab_Required_Label']}</Text></TableCell>
            <TableCell ><Box>{course.labRequired}</Box></TableCell>
        </TableRow>);
        if (course.relatedCertifications.length !== 0) {
            let releatedCerts = course.relatedCertifications.map((related, index) => {
                if (related.isActive) {
                    let url: string = this.props.match.params.lang
                        ? `${process.env.REACT_APP_MAIN_SITE}/${this.props.match.params.lang}/datacard/${related.entityType}/${related.modifiedProgramId}`
                        : `${process.env.REACT_APP_MAIN_SITE}/datacard/${related.entityType}/${related.modifiedProgramId}`;

                    if (related.entityType.indexOf('Other') !== -1) {
                        url = formatString(`${site.localizedStrings['General.ExternalSabaUrl']}`, [related.programId]);
                    }
                    return <Anchor href={url} label={related.name} key={`rc-${index}`} size="small"></Anchor>
                }
                return <Text>{parse(related.name)}</Text>
            });
            summaryData.push(<TableRow key="sum-row-relatedCertifications">
                <TableCell border="right"><Text weight="bold">{site.localizedStrings['Datacard.Related_Certifications_Label']}</Text></TableCell>
                <TableCell ><Box>{releatedCerts}</Box></TableCell>
            </TableRow>);
        }
        if (course.inPreparationExams.length !== 0) {
            let exams = course.inPreparationExams.map((related, index) => {
                if (related.isActive) {
                    let url: string = this.props.match.params.lang
                        ? `${process.env.REACT_APP_MAIN_SITE}/${this.props.match.params.lang}/datacard/${related.entityType}/${related.modifiedProgramId}`
                        : `${process.env.REACT_APP_MAIN_SITE}/datacard/${related.entityType}/${related.modifiedProgramId}`;

                    if (related.entityType.indexOf('Other') !== -1) {
                        url = formatString(`${site.localizedStrings['General.ExternalSabaUrl']}`, [related.programId]);
                    }
                    return <Anchor href={url} label={related.name} key={`ex-${index}`} size="small"></Anchor>
                }
                return <Text>{parse(related.name)}</Text>
            });
            summaryData.push(<TableRow key="sum-row-inPreparationExams">
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Course_Preparation_Statement']}</Text></TableCell>
                <TableCell >
                    <Box pad={{ top: 'xsmall' }}>
                        <Text>{site.localizedStrings['Datacard.Course_ExamList_Statement']}</Text>
                        {exams}
                    </Box>
                </TableCell>
            </TableRow>);
        }
        if (course.additionalStudyMaterial.length !== 0) {
            summaryData.push(<TableRow key="sum-row-additionalStudyMaterial">
                <TableCell border="right" ><Text weight="bold">{site.localizedStrings['Datacard.Exam_AdditionalMaterials_Label']}</Text></TableCell>
                <TableCell >
                    <Box>
                        {
                            course.additionalStudyMaterial.map((am, index) => <Anchor href={am.value} key={`am-${index}`}>{am.key}</Anchor>)
                        }
                    </Box>
                </TableCell>
            </TableRow>);
        }
        return <Table>
            <TableBody>
                {summaryData}
            </TableBody>
        </Table>;
    }

    onRegister = (course: CourseModel) => {
        let target = course.registrationUrl.toLowerCase().indexOf(course.publicSiteUrl.toLowerCase()) === -1
            ? "_blank"
            : "_self";

        try {
            window.dispatchEvent(new CustomEvent("ANALYTICS.LINK", { detail: { link_name: `Course|DataCard|${course.programId}` } }));
        } catch (error) {
            console.log(error);
        }
        window.open(course.registrationUrl, target);
    }

    renderCourse = (course: CourseModel) => {
        let site = this.props.site;
        let topics = course.topics.map((topic, index) =>
            <li key={`topic-${index}`} style={{ paddingBottom: '10px' }}>
                <Text weight="bold">{topic.key}</Text>
                {topic.value && <Box>{parse(topic.value)}</Box>}
            </li>
        );
        let label = course.isWbtOnly ? site.localizedStrings['Datacard.Take_This_Course_Statement'] : site.localizedStrings['Datacard.Register_For_Course_Statement'];

        return <ResponsiveContext.Consumer>
            {size => (
                <Box fill>
                    <Box pad={{ bottom: "small" }} gap="small">
                        <Paragraph fill>{parse(course.description)}</Paragraph>
                        <Box gap="small">
                            {!course.isWbtOnly && course.policiesFeesCancellations && <Box>
                                <Heading level="5">{site.localizedStrings['Datacard.Policies_Fees_Cancellations_Label']}</Heading>
                                <Paragraph fill>{parse(course.policiesFeesCancellations)}</Paragraph>
                            </Box>}
                            <Box fill="horizontal">
                                <Anchor onClick={() => { this.onRegister(course); }} style={{ fontWeight: "bold" }}>{label}</Anchor>
                            </Box>
                        </Box>
                    </Box>
                    <Accordion activeIndex={this.state.activePanel} onActive={activeIndexes => { this.onPanelChange(activeIndexes); }}>
                        <AccordionPanel label={site.localizedStrings['Datacard.Summary_Label']}>
                            <Box pad={{ top: "small", left: "medium" }}>
                                {this.renderSummary(course)}
                            </Box>
                        </AccordionPanel>
                        <AccordionPanel label={site.localizedStrings['Datacard.Details_Label']}>
                            <Box pad={{ left: "medium" }}>

                                <Box pad={{ top: "small" }}>
                                    <Heading level="5">{site.localizedStrings['Datacard.Course_Ideal_Cadidate_Label']}</Heading>
                                    <Paragraph fill>{parse(course.whoShouldAttend)}</Paragraph>
                                </Box>
                                {course.prerequisiteStatement && <Box>
                                    <Heading level="5">{site.localizedStrings['Datacard.Course_Prerequisite_Label']}</Heading>
                                    <Paragraph>{parse(course.prerequisiteStatement)}</Paragraph>
                                </Box>
                                }
                            </Box>
                        </AccordionPanel>
                        {course.topics.length !== 0 && <AccordionPanel label={site.localizedStrings['Datacard.Topics_Label']}>
                            <Box pad={{ top: "small", left: "medium" }}>
                                <ul>
                                    {topics}
                                </ul>
                            </Box>
                        </AccordionPanel>
                        }
                        <AccordionPanel label={site.localizedStrings['Datacard.Upon_Completion_Label']}>
                            <Box pad={{ top: "small", left: "medium" }}>
                                <Paragraph fill>{parse(course.objectivesLeadInStatement)}</Paragraph>
                                <Box fill>
                                    {parse(course.objectives)}
                                </Box>
                            </Box>
                        </AccordionPanel>
                    </Accordion>
                </Box>
            )}
        </ResponsiveContext.Consumer>;
    }

    public render() {
        let course = this.props.course.data;
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Training_Label'], value: "" },
            { key: site.menuStrings['Datacard.View_All_Courses_Label'], value: `${localUrl}/courses` },
            { key: course?.name, value: `datacard/course/${course?.programId}` }
        ];
        let printUrl = `${siteUrl}/datasheet/course/${course?.programId}?version=${course?.version}`;
        let title = course?.name ?? '';

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName={`datacard_${course?.programId}`} print={printUrl} {...this.props}
                    metadataKeywords={course?.metadataKeywords} metadataDescription={course?.metadataDescription} >
                    {course && <Box fill>
                        {this.renderCourse(course)}
                    </Box>
                    }
                    {!this.props.site.loading && !course && <Box fill align="center" justify="center" height={{ min: "medium" }}>
                        {parse(formatString(site.localizedStrings['Datacard.Course_NotAvailable'], [siteUrl]))}
                    </Box>
                    }
                </BasePage>
            )}
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Course  as any));
