import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.'
import { Exam } from '../models/Exam';
import { setLoading, setError } from './Site';

export interface ExamState {
    data: Exam | undefined
}

let initialState: ExamState = {
    data: undefined
}

export const examSlice = createSlice({
    name: 'Exam',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setData } = examSlice.actions;

export const retrieveExam = (id: string, lang?: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/published/exam';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({ id: id, language: lang !== undefined ? lang : null }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default examSlice.reducer;
