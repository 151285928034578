import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Button } from 'grommet';
import { hasRole, isPLMTAdmin, getPLMTAdminRegions } from '../../Utilities';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import BasePage from '../../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface IMapReportsProps {
}

interface IMapReportsState {
}

type MapReportsProps = IMapReportsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class MapReports extends React.PureComponent<MapReportsProps, IMapReportsState> {
    constructor(props: MapReportsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription',
            'MyLearning.PLMT_Title', 'MyLearning.MapReports_label', 'MyLearning.Not_Authorized_Statement'];

        this.state = {
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('admin-tools-menu');
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.Map_Reports_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let isAdmin = isPLMTAdmin(user);
        let isMapUser = (site.user?.companyName
            ? _.some(["sophela", "selectium"], (name) => site.user?.companyName.toLowerCase().indexOf(name) !== -1)
            : false) && hasRole(user, ["PPA"]);

        if (!user) {
            return null;
        }
        if (user && !isMapUser && !isAdmin) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="MapReports" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={3}>{page}</Heading>
                <Box pad={{ top: "small", bottom: "small" }} gap="medium">
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(MapReports as any));
