import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState, AppThunk } from '.';
import { EntityFilter, SearchResults as EntityListResults } from '../models/EntityList';
import { setLoading, setError } from './Site';
import { updateList } from '../Utilities'
import { SelectOption, EmptyGuid } from '../models/Common'

export interface EntityListState {
    entityType: string,
    languageCode: string,
    countryCode: string,
    metadataDescription: string,
    metadataKeywords: string,
    view: string,
    keywords: string,
    lauguage?: string,
    status: string,
    data: EntityListResults
}

export const allOption = {
    selected: true,
    text: "Select/Deselect All",
    value: 'all'
}

export const allGuidOption = {
    selected: true,
    text: "Select/Deselect All",
    value: EmptyGuid
}

let initialState: EntityListState = {
    entityType: '',
    languageCode: 'US',
    countryCode: 'en',
    metadataDescription: '',
    metadataKeywords: '',
    view: 'all',
    keywords: '',
    lauguage: undefined,
    status: 'Active',
    data: {
        views: [allOption],
        types: [allGuidOption],
        technologies: [allGuidOption],
        languages: [allGuidOption],
        levels: [allGuidOption],
        entities: []
    }
}

export const entityListSlice = createSlice({
    name: 'EntityList',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setEntities: (state, action) => {
            if (state.data) {
                state.data.entities = action.payload;
            }
        },
        setTechnologies: (state, action) => {
            if (state.data) {
                state.data.technologies = action.payload;
            }
        },
        setSelected: (state, action) => {
            let values: string[] = action.payload.split('-');
            let index = parseInt(values[1]);
            let checked = values[2] === 'true';

            if (state.data) {
                switch (values[0]) {
                    case 'views': {
                        state.data.views = updateList(state.data.views, index, checked, false);
                        break;
                    }
                    case 'types': {
                        state.data.types = updateList(state.data.types, index, checked);
                        break;
                    }
                    case 'languages': {
                        state.data.languages = updateList(state.data.languages, index, checked);
                        break;
                    }
                    case 'technologies': {
                        state.data.technologies = updateList(state.data.technologies, index, checked);
                        break;
                    }
                    case 'levels': {
                        state.data.levels = updateList(state.data.levels, index, checked);
                        break;
                    }
                }
            }
        },
        setView: (state, action) => {
            state.view = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setKeywords: (state, action) => {
            state.keywords = action.payload;
        }
    }
});

export const { setData, setEntities, setSelected, setView, setKeywords, setTechnologies, setStatus } = entityListSlice.actions;

export const retrieveCertifications = (uiState?: any, language: string = ''): AppThunk => async (dispatch, getState) => {
    let state = getState();
    let entities = state.entities;
    let data = entities.data;
    let request: EntityFilter = {
        types: [EmptyGuid],
        languages: data?.languages ? (data?.languages.filter((lang) => lang.selected).map((lang) => lang.value) ?? [EmptyGuid]) : [EmptyGuid],
        technologies: data?.technologies ? (data?.technologies.filter((tech) => tech.selected).map((tech) => tech.value) ?? [EmptyGuid]) : [EmptyGuid],
        view: entities.view,
        status: entities.status,
        keywords: entities.keywords,
        language: language
    }

    dispatch(setLoading(true));
    fetch('api/list/certifications', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        if (uiState) {
            if (uiState.technologies.length !== 0) {
                let technologies = data.technologies.map((rt: any) => {
                    let json = JSON.stringify(rt);
                    let newTech: SelectOption = JSON.parse(json);

                    newTech.selected = uiState.technologies.some((st: any) => st.toLowerCase() === rt.text.toLowerCase());

                    return newTech;
                });

                dispatch(setTechnologies(technologies));
            }
            if (uiState.credentialView) {
                dispatch(setView(uiState.credentialView));
            }
            if (uiState.status) {
                dispatch(setStatus(uiState.status));
            }
        }
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}
export const retrieveCredentials = (uiState?: any, language: string = ''): AppThunk => async (dispatch, getState) => {
    let state = getState();
    let entities = state.entities;
    let data = entities.data;
    let request: EntityFilter = {
        types: [EmptyGuid],
        languages: data?.languages ? (data?.languages.filter((lang) => lang.selected).map((lang) => lang.value) ?? [EmptyGuid]) : [EmptyGuid],
        technologies: data?.technologies ? (data?.technologies.filter((tech) => tech.selected).map((tech) => tech.value) ?? [EmptyGuid]) : [EmptyGuid],
        view: entities.view,
        status: entities.status,
        keywords: entities.keywords,
        language: language
    }

    dispatch(setLoading(true));
    fetch('api/credentials/list', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        if (uiState) {
            if (uiState.technologies.length !== 0) {
                let technologies = data.technologies.map((rt: any) => {
                    let json = JSON.stringify(rt);
                    let newTech: SelectOption = JSON.parse(json);

                    newTech.selected = uiState.technologies.some((st: any) => st.toLowerCase() === rt.text.toLowerCase());

                    return newTech;
                });

                dispatch(setTechnologies(technologies));
            }
            if (uiState.credentialView) {
                dispatch(setView(uiState.credentialView));
            }
            if (uiState.status) {
                dispatch(setStatus(uiState.status));
            }
        }
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveExams = (language: string = ''): AppThunk => async (dispatch, getState) => {
    retrieveEntities('api/list/exams', getState(), dispatch, language);
}

export const retrieveCourses = (language: string = ''): AppThunk => async (dispatch, getState) => {
    retrieveEntities('api/list/courses', getState(), dispatch, language);
}

const retrieveEntities = (url: string, state: ApplicationState, dispatch: any, language: string) => {
    let entities = state.entities;
    let data = entities.data;
    let request: EntityFilter = {
        types: data?.types.filter((view) => view.selected).map((view) => view.value) ?? [allOption],
        languages: data?.languages.filter((view) => view.selected).map((view) => view.value) ?? [allOption],
        technologies: data?.technologies.filter((view) => view.selected).map((view) => view.value) ?? [allGuidOption],
        view: entities.view,
        keywords: entities.keywords,
        language: language,
        status: ''
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default entityListSlice.reducer;
