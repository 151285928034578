import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Text, Button, TextArea, Table, TableHeader, TableRow, TableBody, TableCell, Image, Anchor } from 'grommet';
import { Hpe, LinkNext } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import HtmlComponent from '../components/HtmlComponent';
import { formatString } from '../Utilities';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ICertificationDistinctionProps {
}

interface ICertificationDistinctionState {
}

type CertificationDistinctionProps = ICertificationDistinctionProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class CertificationDistinction extends React.PureComponent<CertificationDistinctionProps, ICertificationDistinctionState> {

    constructor(props: CertificationDistinctionProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.CertificationDistinctionKeywords', 'Metadata.CertificationDistinctionDescription',
            "ExternalPages.CertificationDistinction_Intro_Statement", "ExternalPages.CertificationDistinction_Label",
            "ExternalPages.CertificationDistinction_Section2_Title", "ExternalPages.CertificationDistinction_Section2_Statement",
            "ExternalPages.ProfessionalAchiever_Label", "ExternalPages.ExpertAchiever_Label",
            "ExternalPages.DistinguishedAchiever_Label", "ExternalPages.CertificationDistinction_ProfessionalAchiever_Text",
            "ExternalPages.CertificationDistinction_ExpertAchiever_Text", "ExternalPages.CertificationDistinction_DistinguishedAchiever_Text"];

        this.state = {
            displayForm: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('communities-programs-menu');
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    gotoTermsAndConditions = () => {
        let elem = document.getElementById('terms-conditions');

        elem?.scrollIntoView();
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.menuStrings['ExternalPages.CertificationDistinction_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_CommunitiesPrograms'], value: "" },
            { key: page, value: "" }
        ];

        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="CertificationDistinction" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.CertificationDistinctionKeywords']}
            metadataDescription={site.localizedStrings['Metadata.CertificationDistinctionDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Box direction="row-responsive" gap="large" pad="small">
                    <Box gap="small" alignSelf="center" fill>
                        <Heading level="3">{page}</Heading>
                        <Paragraph fill>
                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationDistinction_Intro_Statement'],
                                ["https://www.hpe.com/psnow/doc/a00141252enw"]))}
                        </Paragraph>
                    </Box>
                    <Box fill alignSelf="center" width="large">
                        <Image fit="contain" src="images/certification-distinction/distinction image.png" />
                    </Box>
                </Box>
                <Box direction="row-responsive" border={{ side: "top" }} fill="horizontal" />
                <Box gap="small">
                    <Heading level="4">{site.localizedStrings['ExternalPages.CertificationDistinction_Section2_Title']}</Heading>
                    <Paragraph fill>{site.localizedStrings['ExternalPages.CertificationDistinction_Section2_Statement']}</Paragraph>
                </Box>
                <Box direction="row-responsive" gap="large" pad="medium" justify="center" fill>
                    <Box gap="small" width="medium">
                        <Image src="images/certification-distinction/Certification_Distinction_professional achiever 2024_300x300.png" />
                        <Box pad="small" background="white">
                            <Heading level="4">{site.localizedStrings['ExternalPages.ProfessionalAchiever_Label']}</Heading>
                            <HtmlComponent html={site.localizedStrings['ExternalPages.CertificationDistinction_ProfessionalAchiever_Text']} />
                        </Box>
                    </Box>
                    <Box gap="small" width="medium">
                        <Image src="images/certification-distinction/Certification_Distinction_expert achiever 2024_300x300.png" />
                        <Box pad="small" background="white">
                            <Heading level="4">{site.localizedStrings['ExternalPages.ExpertAchiever_Label']}</Heading>
                            <HtmlComponent html={site.localizedStrings['ExternalPages.CertificationDistinction_ExpertAchiever_Text']} />
                        </Box>
                    </Box>
                    <Box gap="small" width="medium">
                        <Image src="images/certification-distinction/Certification_Distinction_distinguished_300x300.png" />
                        <Box pad="small" background="white">
                            <Heading level="4">{site.localizedStrings['ExternalPages.DistinguishedAchiever_Label']}</Heading>
                            <HtmlComponent html={site.localizedStrings['ExternalPages.CertificationDistinction_DistinguishedAchiever_Text']} />
                        </Box>
                    </Box>
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(CertificationDistinction as any));
