import React from 'react';
import { Box, Form, FormField, Button, Text, CheckBoxGroup, Paragraph, Select } from 'grommet';
import { ApplicationState } from '../store';
import { LearningPartner } from '../models/LearningPartner';
import { AffiliationRequest as RequestModel } from '../models/lpc/AffiliationRequest'
import _ from 'lodash';
import parse from 'html-react-parser';
import { InstructorType } from '../models/lpc/InstructorType';
import { InstructorRelationshipType } from '../models/lpc/InstructorRelationshipType';

const mapDispatchToProps = {
}


interface IAffiliationRequestProps {
    onCancel: Function;
    onSubmit: Function;
    registration?: boolean;
}

interface IAffiliationRequestState {
    data?: RequestModel;
    learningPartners?: LearningPartner[];
    isFormValid: boolean;
}

type AffiliationRequestProps =
    ApplicationState // ... state we've requested from the Redux store
    & IAffiliationRequestProps
    & typeof mapDispatchToProps; // ... plus incoming routing parameters


export default class AffiliationRequest extends React.PureComponent<AffiliationRequestProps, IAffiliationRequestState> {
    constructor(props: AffiliationRequestProps) {
        super(props);

        this.state = {
            isFormValid: false
        };
    }

    onCancel = () => {
        this.setState({ data: undefined });
        this.props.onCancel();
    }

    onSubmit = (data: any) => {
        let form = JSON.parse(JSON.stringify(data)) as RequestModel;
        let user = this.props.site.user;
        let emailRegexp = /hpe\.com/i;
        let isEmployee = emailRegexp.test(user?.email as string);
        let isImpersonating = this.props.site.actualUser && this.props.site.user?.email !== this.props.site.actualUser?.email;

        form.email = user?.email as string;
        form.applicant = `${user?.firstName} ${user?.lastName}`;
        if (!isImpersonating) {
            form.oktaId = this.props.site.userInfo?.sub as string;
        }
        form.relationshipType = isEmployee
            ? InstructorRelationshipType.Employee
            : InstructorRelationshipType.Exclusive;

        this.props.onSubmit(form);
    }

    onSearchPartner = (searchText: string) => {
        let regexp = new RegExp(searchText, 'i');
        let filtered = _.filter(this.props.lpc.learningPartners, lp => regexp.test(lp.name));

        this.setState({ learningPartners: filtered });
    }

    onPartnerSelected = (option: any) => {
        let data = (this.state.data ? JSON.parse(JSON.stringify(this.state.data)) : {}) as RequestModel;
        let isValid = (data.instructorTypes && data.instructorTypes.length !== 0);

        data.learningPartner = option;
        this.setState({ learningPartners: undefined, data: data, isFormValid: isValid });
    }

    isValidated = (evt: any) => {
        let data = (this.state.data ? JSON.parse(JSON.stringify(this.state.data)) : {}) as RequestModel;
        let isValid = data.learningPartner !== undefined && (data.instructorTypes && data.instructorTypes.length !== 0);

        this.setState({ isFormValid: isValid });
    }

    public render() {
        let site = this.props.site;
        let learningPartners = this.state.learningPartners ?? this.props.lpc.learningPartners ?? [];
        let instructorTypes = _.map(Object.keys(InstructorType), (key, index) => {
            return {
                label: key,
                value: index
            };
        });
        let instructor = this.props.lpc.instructor;
        let messageString = this.props.registration
            ? 'ExternalPages.LPC_InstructorRegistration_Instruction'
            : 'ExternalPages.LPC_AffiliationRequest_Instruction';

        learningPartners = _.sortBy(_.filter(learningPartners, lp => !_.some(instructor?.affiliations, aff => aff.learningPartner.partyId === lp.partyId)),
            lp => lp.name);
        return <Form value={this.state.data} onChange={nextValue => this.setState({ data: nextValue })}
            onValidate={this.isValidated} validate='change' onSubmit={({ value }) => { this.onSubmit(value); }}>
            <Box gap="medium">
                <Box gap="small">
                    <Paragraph fill>
                        {parse(site.localizedStrings[messageString])}
                    </Paragraph>
                    <Box width="medium">
                        <FormField name="learningPartner" label={site.localizedStrings['ExternalPages.Learning_Partner_Label']}
                            htmlFor="learningPartner-input" required >
                            <Select name="learningPartner" id="learningPartner-input" options={learningPartners} dropHeight="small"
                                onChange={({ option }) => this.onPartnerSelected(option)} labelKey="name" valueKey="partyId" onSearch={this.onSearchPartner} />
                        </FormField>
                        <FormField name="instructorTypes" label={site.localizedStrings['ExternalPages.InstructorType_Label']}
                            htmlFor="instructorTypes-input" required >
                            <CheckBoxGroup name="instructorTypes" id="instructorTypes-input" options={instructorTypes}
                                direction="row-responsive" />
                        </FormField>
                    </Box>
                </Box>
                <Box direction="row-responsive" gap="small" justify="end">
                    <Button primary type="submit" label={site.localizedStrings['ExternalPages.Submit_Label']}
                        disabled={!this.state.isFormValid} />
                    <Button secondary color="red" label={site.localizedStrings['ExternalPages.Cancel_Label']}
                        onClick={this.onCancel} />
                </Box>
            </Box>
        </Form>
    }
}
