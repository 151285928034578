import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import {
    Box, Heading, Text, Accordion, AccordionPanel, DataTable, Select, Table, TableRow, TableCell, TableBody
} from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveGapAnalysis, exportSummaries } from '../../store/Plmt';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { hasRole, isPLMTAdmin } from '../../Utilities';
import { StatusCritical, StatusGood, StatusWarning } from 'grommet-icons';
import { GapAnalysis as GapAnalysisModel } from '../../models/plmt/GapAnalysis';
import { format, parseISO } from 'date-fns';
import parse from 'html-react-parser';

const mapDispatchToProps = {
    retrieveGapAnalysis,
    exportSummaries,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface IGapAnalysisProps {
}

interface IGapAnalysisState {
    region: string,
    country: string,
    signingEntityId: string
}

type GapAnalysisProps = IGapAnalysisProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class GapAnalysis extends React.PureComponent<GapAnalysisProps, IGapAnalysisState> {
    constructor(props: GapAnalysisProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label',
            'MyLearning.PLMT_Title', 'MyLearning.SigningEntity_Label', 'MyLearning.Activity_Code_Label', 'MyLearning.Activity_Name_Label',
            'MyLearning.Date_Acquired_Label', 'MyLearning.Expires_Label', 'MyLearning.Type_Label', 'MyLearning.Export_Label', 'MyLearning.GapSummary_Label',
            'MyLearning.PartnerType_Label', 'MyLearning.GapAnalysis_Title', 'MyLearning.Country_Label', 'MyLearning.Program_Label', 'MyLearning.Status_Label',
            'MyLearning.RequirementsGroup_Label', 'MyLearning.Region_Label', 'MyLearning.Expiration_Label', 'MyLearning.Duration_label',
            'MyLearning.AchievedBadges_Label', 'MyLearning.CLStatus_Label', 'MyLearning.CLBadges_Label', 'MyLearning.Name_Label', 'MyLearning.Proficency_Label',
            'MyLearning.RequirementsSubgroup_Label', 'MyLearning.OverallStatus_Label', 'MyLearning.Met_label', 'MyLearning.Gap_Label', 'MyLearning.NotMet_Label',
            'MyLearning.ActivityType_Label', 'MyLearning.ActivityExpirationDate_Label', 'MyLearning.SigningEntityId_Label', 'MyLearning.No_Compliance_Statement',
            'MyLearning.RequirementStatus_Label', 'MyLearning.RequirementDetails_Lael', 'MyLearning.Loading_Activities_Statement',
            'MyLearning.Not_Authorized_Statement'];
        let region = this.props.match?.params?.region;
        let country = this.props.match?.params?.country;
        let signingEntityId = this.props.match?.params?.signingEntityId;
        let program = this.props.match?.params?.program;
        let request = {
            region: region,
            country: country,
            signingEntityId: signingEntityId,
            program: decodeURIComponent(program),
            requirementGroup: ''
        };

        this.state = {
            region: region,
            country: country,
            signingEntityId: signingEntityId
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveGapAnalysis(request);
        this.props.setActiveMenu('admin-tools-menu');
    }

    onExport = (event: any) => {
        let selected = event.value;
        let exportRequest = {
            fileType: selected.value,
            region: this.state.region,
            country: this.state.country,
            signingEntityId: this.state.signingEntityId
        };

        this.props.exportSummaries(exportRequest);
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.GapSummary_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/plmt` },
            { key: page, value: "" }
        ];
        let exportOptions = [{
            value: "csv"
        }, {
            text: "Excel",
            value: "xlsx"
        }];
        let analysisInfo = this.props.plmt.gapAnalysis;
        let isAdmin = isPLMTAdmin(user);
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isDistributor = hasRole(user, ["DISTI"]) && (isPEM || isPPA);
        let isPlmtUser = isAdmin || isPEM || isPPA || isDistributor;
        let data: GapAnalysisModel[] = [];
        let sortOrder: any = { "Sales": 1, "Technical": 2, "Competency": 3 }

        if (!user) {
            return null;
        }
        if (user && !isPlmtUser) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (analysisInfo && (analysisInfo.analysis.length !== 0)) {
            data = _.sortBy(analysisInfo.analysis, [b => sortOrder[b.proficiency], a => a.requirementGroup]);
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="GapAnalysis" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Heading level={4}>{site.localizedStrings['MyLearning.GapAnalysis_Title']}</Heading>
                <Box gap="medium" pad={{ bottom: "small" }}>
                    <Box direction="row-responsive" gap="medium">
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">{site.localizedStrings['MyLearning.Region_Label']}:</Text>
                            <Text>{analysisInfo?.region}</Text>
                        </Box>
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">{site.localizedStrings['MyLearning.Country_Label']}:</Text>
                            <Text>{analysisInfo?.country}</Text>
                        </Box>
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">{site.localizedStrings['MyLearning.SigningEntityId_Label']}:</Text>
                            <Text>{analysisInfo?.signingEntityId}</Text>
                        </Box>
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">{site.localizedStrings['MyLearning.SigningEntity_Label']}:</Text>
                            <Text>{analysisInfo?.signingEntity}</Text>
                        </Box>
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">{site.localizedStrings['MyLearning.Program_Label']}:</Text>
                            <Text>{decodeURIComponent(analysisInfo?.program as string)}</Text>
                        </Box>
                    </Box>
                    <Box>
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">{`${site.localizedStrings['MyLearning.OverallStatus_Label']}:`}:</Text>
                            {analysisInfo?.programMet && <Box direction="row" gap="xxsmall">
                                <StatusGood color="brand" />
                                <Text color='brand' weight='bold'>{`${site.localizedStrings['MyLearning.Met_label']}`}</Text>
                            </Box>}
                            {!analysisInfo?.programMet && <Box direction="row" gap="xxsmall">
                                <StatusCritical color="red" />
                                <Text color='red' weight='bold'>{`${site.localizedStrings['MyLearning.NotMet_Label']}`}</Text>
                            </Box>}
                        </Box>
                    </Box>
                    <Box width="small">
                        <Select name="export" id="export-input" options={exportOptions} labelKey='text' valueKey='value' disabled={!analysisInfo?.analysis.length}
                            placeholder={<Text>{site.localizedStrings['MyLearning.Export_Label']}</Text>} onChange={this.onExport} />
                    </Box>
                    {analysisInfo && <Box>
                        <Accordion>
                            {
                                _.map(_.sortBy(analysisInfo?.summaries, [b => sortOrder[b.proficiency], a => a.requirementGroup]), summary => {
                                    let fulfilled = summary.fulfilled;
                                    let required = summary.required;
                                    let items = analysisInfo?.analysis.filter(item => item.proficiency == summary.proficiency &&
                                        item.requirementGroup == summary.requirementGroup) ?? [];
                                    let table = <Box fill="horizontal" >
                                        <Table>
                                            <TableRow >
                                                <TableCell size="small">
                                                    <Text weight="bold">{site.localizedStrings['MyLearning.Proficency_Label']}</Text>
                                                </TableCell>
                                                <TableCell size="medium">
                                                    <Text weight="bold">{site.localizedStrings['MyLearning.RequirementsGroup_Label']}</Text>
                                                </TableCell>
                                                <TableCell align='center' size="xsmall">
                                                    <Text weight="bold">{site.localizedStrings['MyLearning.RequirementStatus_Label']}</Text>
                                                </TableCell>
                                                <TableCell align='center' size="xsmall">
                                                    <Text weight="bold">{site.localizedStrings['MyLearning.RequirementDetails_Lael']}</Text>
                                                </TableCell>
                                            </TableRow>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        <Text>{summary.proficiency}</Text>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Text>{summary.requirementGroup}</Text>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        {
                                                            fulfilled >= required
                                                                ? <StatusGood color="brand" />
                                                                : <StatusCritical color="red" />
                                                        }
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <Text>{`${fulfilled} / ${required}`}</Text>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Box>;
                                    return <AccordionPanel label={table} >
                                        {(items.length !== 0) &&
                                            <Box pad="small">
                                                <DataTable fill pin="header" data={items}
                                                    columns={[
                                                        {
                                                            property: 'activityType',
                                                            header: '',
                                                            render: datum => <Box direction="row" gap="small">
                                                                {!datum.counted && <StatusWarning color="#FEC901" />}
                                                            </Box>
                                                        },
                                                        {
                                                            property: 'activityType',
                                                            align: 'center',
                                                            header: <Text>{site.localizedStrings['MyLearning.ActivityType_Label']}</Text>
                                                        },
                                                        {
                                                            property: 'activityName',
                                                            header: <Text>{site.localizedStrings['MyLearning.Activity_Name_Label']}</Text>
                                                        },
                                                        {
                                                            property: 'dateAcquired',
                                                            header: <Text>{site.localizedStrings['MyLearning.Date_Acquired_Label']}</Text>,
                                                            render: datum => <Text>{datum.dateAcquired ? format(parseISO(datum.dateAcquired), 'MM/dd/yyyy') : 'N/A'}</Text>
                                                        },
                                                        {
                                                            property: 'expirationDate',
                                                            header: <Text>{site.localizedStrings['MyLearning.ActivityExpirationDate_Label']}</Text>,
                                                            render: datum => <Text>{datum.expirationDate ? format(parseISO(datum.expirationDate), 'MM/dd/yyyy') : ''}</Text>
                                                        },
                                                        {
                                                            property: 'clStatus',
                                                            header: <Text>{site.localizedStrings['MyLearning.CLStatus_Label']}</Text>
                                                        },
                                                        {
                                                            property: 'name',
                                                            primary: true,
                                                            header: <Text>{site.localizedStrings['MyLearning.Name_Label']}</Text>,
                                                            render: datum => <Text>{`${datum.firstName ?? ""} ${datum.lastName ?? ""}`}</Text>
                                                        },
                                                        {
                                                            property: 'learnerId',
                                                            header: <Text>HPE Learner ID</Text>
                                                        },
                                                        {
                                                            property: 'country',
                                                            align: 'center',
                                                            header: <Text>{site.localizedStrings['MyLearning.Country_Label']}</Text>
                                                        }
                                                    ]} />
                                            </Box>
                                        }
                                        {(items.length === 0) &&
                                            <Box direction="row" fill="horizontal" align="center" justify="center" pad={{ top: 'small', bottom: 'small' }}>
                                                <Text>{site.localizedStrings['MyLearning.No_Compliance_Statement']}</Text>
                                            </Box>
                                        }
                                    </AccordionPanel>
                                })
                            }
                        </Accordion>
                    </Box>}
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(GapAnalysis as any));
