import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Text, Button, TextArea, Form, FileInput } from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { retrieveLearnerIds } from '../store/Admin';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLearnerIds,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ILearnerIdLookupProps {
}

interface ILearnerIdLookupState {
    displayForm: boolean;
    emaiFile?: any;
    emaiList?: string;
    idInfo?: any;
}

type LearnerIdLookupProps = ILearnerIdLookupProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class LearnerIdLookup extends React.PureComponent<LearnerIdLookupProps, ILearnerIdLookupState> {

    constructor(props: LearnerIdLookupProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'MyLearning.LearnerId_Lookup_Label',
            'MyLearning.LearnerID_Lookup_Disclaimer', 'MyLearning.PLMT_Title', 'MyLearning.I_Agree_Label'];

        this.state = {
            displayForm: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('admin-tools-menu');
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onAgreed = () => {
        this.setState({ displayForm: true });
    }

    onFileSelected = (event: any) => {
        let idInfo = { ...this.state.idInfo };

        idInfo.emails = undefined;
        this.setState({ idInfo: idInfo });
    }

    onListChanged = () => {
        let idInfo = { ...this.state.idInfo };

        idInfo.emailFile = undefined;
        this.setState({ idInfo: idInfo });
    }

    onSubmit = () => {
        this.props.retrieveLearnerIds(this.state.idInfo);
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let page = site.localizedStrings['MyLearning.LearnerId_Lookup_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.AdminTools_Label'], value: "" },
            { key: page, value: "" }
        ];
        let fileSelected = this.state.idInfo?.emailFile && this.state.idInfo?.emailFile.length !== 0;
        let emailsEntered = this.state.idInfo?.emails && this.state.idInfo?.emails !== '';

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="LearnerIdLookup" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Heading level={3}>{page}</Heading>
                <Box gap='medium' width="large">
                    <Box gap='small' width="large">
                        <Paragraph fill>{parse(site.localizedStrings['MyLearning.LearnerID_Lookup_Disclaimer'])}</Paragraph>
                        <Box width="xsmall">
                            <Button primary label={site.localizedStrings['MyLearning.I_Agree_Label']} onClick={this.onAgreed}
                                disabled={this.state.displayForm} />
                        </Box>
                    </Box>
                    {this.state.displayForm && <Box gap="small">
                        <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: 'black', size: "xsmall" }} fill></Box>
                        <Form onChange={nextValue => this.setState({ idInfo: nextValue })} onSubmit={this.onSubmit}
                            onReset={() => this.setState({ idInfo: undefined })}>
                            <Box pad={{ top: "small" }} width="large" gap='small'>
                                <Text weight="bold">Input email addresses</Text>
                                <FileInput name='emailFile' multiple={false} messages={{
                                    browse: "Select file",
                                    dropPrompt: "Drag and drop XLS, XLSX, or CSV file"
                                }} accept=".csv, .xls, .xlsx" disabled={emailsEntered} />
                                <Text weight="bold" alignSelf='center'>OR</Text>
                                <TextArea fill rows={8} name='emails' placeholder='Enter email addresses, comma separated (delimited)'
                                    disabled={fileSelected} />
                                <Box direction='row-responsive' justify='between'>
                                    <Button type="submit" primary label="Submit" alignSelf='start' disabled={!fileSelected && !emailsEntered} />
                                    <Button type="reset" label="Clear" active alignSelf='end' disabled={!fileSelected && !emailsEntered} />
                                </Box>
                            </Box>
                        </Form>
                    </Box>}
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(LearnerIdLookup  as any));
