import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { KeyValuePair } from '../models/Common';
import { SiteState } from '../store/Site';
import { Helmet } from "react-helmet";
import { Box, Anchor, Tip, ThemeContext } from 'grommet';
import { Print } from 'grommet-icons';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { setupMetrics } from '../Utilities'
import _ from 'lodash';

interface IBasePageProps {
    breadcrumbs: KeyValuePair[];
    pageName: string;
    metadataDescription?: string;
    metadataKeywords?: string;
    title: string;
    print?: string;
    site: SiteState;
}

interface BasePageState {
    langLoaded: boolean;
}

export type BasePageProps =
    IBasePageProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

export default class BasePage extends React.PureComponent<BasePageProps, BasePageState> {

    constructor(props: BasePageProps) {
        super(props);

        this.state = {
            langLoaded: false
        }
    }

    public componentDidUpdate() {
        let site = this.props.site;

        if (site.availableLanguages.length !== 0 && !this.state.langLoaded) {
            let siteLanguage = _.find(site.availableLanguages, sl => _.includes(sl.value, this.props.match.params.lang));
            let urlLanguage = siteLanguage?.value ?? site.currentLanguage.value;
            let langInfo = urlLanguage.split('-');
            let lang = langInfo[0] !== 'en' ? `${langInfo[0]}_` : "";
            let pageName = `${lang}${this.props.pageName}`;

            setupMetrics(pageName, langInfo[0], langInfo[1]);
            this.setState({ langLoaded: true });
        }
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let breadcrumbs = this.props.breadcrumbs;
        let activeIndex = breadcrumbs.length - 1;

        return <Box fill>
            <Helmet htmlAttributes={{ lang: `${langInfo[0]}-${langInfo[1]}` }}>
                <meta name="hp_design_version" content="hpexpnontridion" />
                <meta name="lifecycle" content="applied_use" />
                <meta name="page_content" content="Services" />
                <meta name="product_service_name" content="supercategory.education and training" />
                <meta name="robots" content="follow,index" />
                <meta name="segment" content="Segment Neutral" />
                <meta name="target_country" content={`${langInfo[1]}`} />
                <meta name="user_profile" content="Non-targeted" />
                <meta name="web_section_id" content="R11528" />
                <meta name="analytics_section" content="gw" />
                <meta name="company_code" content="hpe" />
                <meta name="sub_bu" content="TSG.ServicesEnt" />
                <meta name="bu" content="Shared" />
                {this.props.metadataDescription && <meta name="description" content={`${this.props.metadataDescription}`} />}
                {this.props.metadataKeywords && <meta name="keywords" content={`${this.props.metadataKeywords}`} />}
                <title>{`${this.props.title} | Certification and Learning`}</title>
            </Helmet>
            <Box fill height={{ min: "small" }} gap="small">
                {site.stringsLoaded && site.menusLoaded && <Box fill="horizontal" direction="row" justify="between">
                    <Box fill="horizontal" justify='center'>
                        <Breadcrumb>
                            {
                                breadcrumbs.map((breadcrumb, index) =>
                                    index === activeIndex || breadcrumb.value === ''
                                        ? <BreadcrumbItem key={`bc-${index}`} active >{breadcrumb.key}</BreadcrumbItem>
                                        : <BreadcrumbItem key={`bc-${index}`} tag="a" href={`${breadcrumb.value}`}>{breadcrumb.key}</BreadcrumbItem>
                                )
                            }
                        </Breadcrumb>
                    </Box>
                    {this.props.print && <Box direction="row-responsive" justify="end" >
                        <Box alignSelf="end"  >
                            <ThemeContext.Extend value={{ tip: { drop: { align: { "right": "left", "top": "top" } } } }} >
                                <Tip content={site.localizedStrings['Datacard.Print_This_Page']}>
                                    <Anchor size="small" alignSelf="end" href={this.props.print} icon={<Print size="medium" />} id="print-page" target="_blank" />
                                </Tip>
                            </ThemeContext.Extend>
                        </Box>
                    </Box>}
                </Box>}
                {this.props.children}
            </Box>
        </Box>
    }
}
