import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import {
    Box, Paragraph, Heading, Text, Select, Form, CheckBoxGroup, TextInput, FormField, MaskedInput,
    Accordion, AccordionPanel, RadioButtonGroup, Button, Footer, CheckBox, Anchor
} from 'grommet';
import { FormNextLink, FormPreviousLink } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { UnaffiliatedRegistration } from '../models/UnaffiliatedRegistration';
import { retrieveCountries, retrieveRestrictedCountries, retrieveEmbargoedCountries, downloadDocument, submitRegistration } from '../store/UnaffiliatedLearnerReg';
import { Country } from '../models/Country';
import HtmlComponent from '../components/HtmlComponent';
import { formatString } from '../Utilities';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveCountries,
    retrieveRestrictedCountries,
    retrieveEmbargoedCountries,
    submitRegistration,
    downloadDocument,
    ...siteSlice.actions,
}

interface IUnaffiliatedLearnerRegProps {
}

interface IUnaffiliatedLearnerRegState {
    registrationForm?: UnaffiliatedRegistration;
    formStep: number;
    countries?: Country[];
    activeIndex: number;
    restrictedCountries?: Country[];
    isFormValid: boolean;
    termsClicked: boolean;
    piSectionAccessed: boolean;
    baSectionAccessed: boolean;
    caSectionAccessed: boolean;
    ppSectionAccessed: boolean;
}

type UnaffiliatedLearnerRegProps = IUnaffiliatedLearnerRegProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class UnaffiliatedLearnerReg extends React.PureComponent<UnaffiliatedLearnerRegProps, IUnaffiliatedLearnerRegState> {

    constructor(props: UnaffiliatedLearnerRegProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.UnaffiliatedLearnerRegKeywords', 'Metadata.UnaffiliatedLearnerRegDescription',
            'ExternalPages.UnaffiliatedReg_RestrictedCountries_Label', 'ExternalPages.UnaffiliatedReg_CitizenshipVerification_Label',
            'ExternalPages.UnaffiliatedReg_PartnerRegistration_Statement', 'ExternalPages.UnaffiliatedReg_Citizenship_Question1',
            'ExternalPages.UnaffiliatedReg_RestrictedCountry_Label',
            'ExternalPages.Yes_Label', 'ExternalPages.Next_Label', 'ExternalPages.Back_Label', 'ExternalPages.Cancel_Label',
            'ExternalPages.UnaffiliatedReg_Citizenship_Question2', 'ExternalPages.UnaffiliatedReg_Name_Note', 'ExternalPages.No_Label',
            'ExternalPages.UnaffiliatedReg_Citizenship_Question3', 'ExternalPages.UnaffiliatedReg_EmbargoedCountry_Label',
            'ExternalPages.UnaffiliatedReg_PrivacyPolicy_Statement', 'ExternalPages.UnaffiliatedReg_BusinessAddress_Statement',
            'ExternalPages.UnaffiliatedReg_PersonalInformation_Label', 'ExternalPages.UnaffiliatedReg_BusinessAddress_Label',
            'ExternalPages.UnaffiliatedReg_ContactAddress_Label', 'ExternalPages.UnaffiliatedReg_PrivacyPolicy_Label',
            'ExternalPages.Submit_Label', 'ExternalPages.UnaffiliatedReg_EmbargoedCounties_Label', 'ExternalPages.UnaffiliatedReg_Denial_Statement',
            'ExternalPages.UnaffiliatedReg_ContactAddress_Statement', 'ExternalPages.UnaffiliatedReg_PersonalInformation_Statement',
            'ExternalPages.UnaffiliatedReg_Salutation_Label', 'ExternalPages.UnaffiliatedReg_FullName_Label', 'ExternalPages.UnaffiliatedReg_FirstName_Label',
            'ExternalPages.UnaffiliatedReg_LastName_Label', 'ExternalPages.UnaffiliatedReg_JobTitle_Label', 'ExternalPages.UnaffiliatedReg_Email_Label',
            'ExternalPages.UnaffiliatedReg_Phone_Label', 'ExternalPages.UnaffiliatedReg_Fax_Label', 'ExternalPages.UnaffiliatedReg_Mobile_Label',
            'ExternalPages.UnaffiliatedReg_Region_label', 'ExternalPages.UnaffiliatedReg_CompanyCounty_Label', 'ExternalPages.UnaffiliatedReg_CompanyName_Label',
            'ExternalPages.UnaffiliatedReg_MiddleName_Label', 'ExternalPages.UnaffiliatedReg_Suffix_Label', 'ExternalPages.UnaffiliatedReg_Citizenship_Question4',
            'ExternalPages.UnaffiliatedReg_PreferredLanguage_Label', 'ExternalPages.UnaffiliatedReg_FriendEmail_Label',
            'ExternalPages.UnaffiliatedReg_PreviousParticipant_Label', 'ExternalPages.UnaffiliatedReg_LearnerId_Label',
            'ExternalPages.UnaffiliatedReg_AddressLine1_Label', 'ExternalPages.UnaffiliatedReg_AddressLine2_Label',
            'ExternalPages.UnaffiliatedReg_CityTown_Label', 'ExternalPages.UnaffiliatedReg_ProvinceState_Label',
            'ExternalPages.UnaffiliatedReg_PostalCode_Label', 'ExternalPages.UnaffiliatedReg_SameAddress_Label',
            'ExternalPages.UnaffiliatedReg_ExportComplianceAggrement_Label', 'ExternalPages.UnaffiliatedReg_ExportComplianceAggrement_Statement',
            'ExternalPages.UnaffiliatedReg_ExportComplianceAggrement_Question', 'ExternalPages.UnaffiliatedReg_TermsAndConditions_Label',
            'ExternalPages.UnaffiliatedReg_TermsAndConditions_Stement', 'ExternalPages.UnaffiliatedReg_TermsAndConditions_Question1',
            'ExternalPages.UnaffiliatedReg_TermsAndConditions_Question2', 'ExternalPages.UnaffiliatedReg_TermsAndConditions_Link',
            'ExternalPages.UnaffiliatedReg_Thankyou_Label', 'ExternalPages.UnaffiliatedReg_Thankyou_Statement', 'ExternalPages.UnaffiliatedReg_Questions_label',
            'ExternalPages.UnaffiliatedReg_ThankYou_Support_Link', 'ExternalPages.UnaffiliatedReg_County_Label',
            'ExternalPages.UnaffiliatedReg_Citizenship_NoCountry_Selection'];

        this.state = {
            formStep: 0,
            activeIndex: 0,
            isFormValid: false,
            termsClicked: false,
            piSectionAccessed: true,
            baSectionAccessed: false,
            caSectionAccessed: false,
            ppSectionAccessed: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveCountries();
        this.props.retrieveRestrictedCountries();
        this.props.retrieveEmbargoedCountries();
        this.props.setActiveMenu('resources-menu');
    }

    componentDidUpdate() {
        let formStep = this.state.formStep;
        let registrationForm = this.state.registrationForm
            ? JSON.parse(JSON.stringify(this.state.registrationForm)) as UnaffiliatedRegistration
            : undefined;

        if (formStep === 5 && registrationForm && registrationForm.oktaId === undefined) {
            let site = this.props.site;
            let userInfo = site.userInfo;

            registrationForm.oktaId = userInfo?.sub as string;
            registrationForm.firstName = userInfo?.given_name as string;
            registrationForm.lastName = userInfo?.family_name as string;
            registrationForm.fullName = userInfo?.name as string;
            registrationForm.email = userInfo?.email as string;
            registrationForm.contactMethods = ["Email", "Phone", "Postal Mail", "Fax", "SMS"];
            this.setState({ registrationForm: registrationForm });
        }
    }

    onRestrictedSearch = (searchText: string) => {
        let regexp = new RegExp(searchText, 'i');
        let filtered = _.filter(this.props.unafilliated.restrictedCountries, country => regexp.test(country.name));

        this.setState({ restrictedCountries: filtered });
    }

    onCountrySearch = (searchText: string) => {
        let formStep = this.state.formStep;
        let regexp = new RegExp(searchText, 'i');
        let unrestrictedCountries = _.sortBy(this.props.unafilliated.countries, c => c.name);
        let restrictedCountries = _.sortBy(this.props.unafilliated.restrictedCountries, c => c.name);
        let countries = formStep != 3 ? unrestrictedCountries : restrictedCountries;
        let filtered = _.filter(countries, country => regexp.test(country.name));

        this.setState({ countries: filtered });
    }

    onCountrySelected = (evt: any) => {
        let option = evt.option;
        let formStep = this.state.formStep;

        if (option.code == 'None') {
            formStep = 4;
        }
        this.setState({ countries: undefined, formStep: formStep });
    }

    onRestrictedSelected = (evt: any) => {
        this.setState({ restrictedCountries: undefined });
    }

    canMoveForward = () => {
        let formStep = this.state.formStep;
        let registrationForm = this.state.registrationForm;

        return (formStep < 4 && (
            (formStep === 0 && registrationForm?.hasDualCitizenship !== undefined && !registrationForm?.hasDualCitizenship) ||
            (formStep === 1 && registrationForm?.isPermanentResident !== undefined && registrationForm?.isPermanentResident) ||
            (formStep === 2 && registrationForm?.isRefugee !== undefined && registrationForm?.isRefugee) ||
            (formStep === 3 && registrationForm?.isEmbargoed !== undefined && !registrationForm?.isEmbargoed)) &&
            registrationForm.citizenshipCountry != undefined) ||
            (formStep == 5 && this.state.isFormValid && registrationForm?.useBusinessAddress !== undefined &&
                this.state.piSectionAccessed && this.state.baSectionAccessed && this.state.caSectionAccessed &&
                this.state.ppSectionAccessed) ||
            (formStep == 6 && registrationForm?.complianceAgreement) ||
            (formStep == 7 && registrationForm?.termsAndConditions && registrationForm?.storeInformation);
    }

    onCancel = () => {
        this.setState({ formStep: 0, registrationForm: undefined });
    }

    onCitizenshipChange = (selected: string) => {
        if (selected === 'true') {
            this.setState({ formStep: 1 });
        }
    }

    onPermanentResidentChange = (selected: string) => {
        if (selected === 'false') {
            this.setState({ formStep: 2 });
        }
    }

    onRefugeeChange = (selected: string) => {
        if (selected === 'false') {
            this.setState({ formStep: 3 });
        }
    }

    onEmbargoChange = (selected: string) => {
        if (selected === 'true') {
            this.setState({ formStep: 4 });
        }
    }

    processNext = () => {
        let formStep = this.state.formStep;
        let registrationForm = JSON.parse(JSON.stringify(this.state.registrationForm)) as UnaffiliatedRegistration;

        if (formStep < 4 && (
            (formStep === 0 && registrationForm?.hasDualCitizenship !== undefined && !registrationForm?.hasDualCitizenship) ||
            (formStep === 1 && registrationForm?.isPermanentResident !== undefined && registrationForm?.isPermanentResident) ||
            (formStep === 2 && registrationForm?.isRefugee !== undefined && registrationForm?.isRefugee) ||
            (formStep === 3 && registrationForm?.isEmbargoed !== undefined && !registrationForm?.isEmbargoed)) &&
            registrationForm.citizenshipCountry !== undefined) {
            formStep = 5;
        }
        else {
            formStep += 1;
        }
        this.setState({ formStep: formStep, isFormValid: false });
    }

    processBack = () => {
        let formStep = this.state.formStep - 1;
        let registrationForm = JSON.parse(JSON.stringify(this.state.registrationForm)) as UnaffiliatedRegistration;

        delete registrationForm.citizenshipCountry;
        if (formStep === 2) {
            delete registrationForm.isEmbargoed;
            delete registrationForm.isRefugee;
        }
        else if (formStep === 1) {
            delete registrationForm.isPermanentResident;
        }
        else if (formStep === 0) {
            delete registrationForm.hasDualCitizenship;
        }
        this.setState({ formStep: formStep, registrationForm: registrationForm });
    }

    showCountry = () => {
        let formStep = this.state.formStep;
        let registrationForm = this.state.registrationForm;

        return (
            (formStep === 0 && registrationForm?.hasDualCitizenship !== undefined && !registrationForm?.hasDualCitizenship) ||
            (formStep === 1 && registrationForm?.isPermanentResident !== undefined && registrationForm?.isPermanentResident) ||
            (formStep === 2 && registrationForm?.isRefugee !== undefined && registrationForm?.isRefugee) ||
            (formStep === 3 && registrationForm?.isEmbargoed !== undefined && !registrationForm?.isEmbargoed)) &&
            formStep < 4;
    }

    isValidated = (evt: any) => {
        this.setState({ isFormValid: evt.valid });
    }

    onActivatePanel = (activeIndexes: number[]) => {
        let currentPanel = activeIndexes[0];
        let businessAddressAccessed = this.state.baSectionAccessed;
        let contactAddressAccessed = this.state.caSectionAccessed;
        let privatePolicyAccessed = this.state.ppSectionAccessed;

        switch (currentPanel) {
            case 1: {
                businessAddressAccessed = true;
                break;
            }
            case 2: {
                contactAddressAccessed = true;
                break;
            }
            case 3: {
                privatePolicyAccessed = true;
                break;
            }
        }
        this.setState({
            activeIndex: activeIndexes[0], baSectionAccessed: businessAddressAccessed,
            caSectionAccessed: contactAddressAccessed, ppSectionAccessed: privatePolicyAccessed
        });
    }

    onTermsAndConditions = () => {
        this.props.downloadDocument("HPE_Partner_Portal_User_Agreement_Certification_and_Learning_Terms_and_Conditions.pdf");
        this.setState({ termsClicked: true });
    }

    onSubmit = (form: any) => {
        this.props.submitRegistration(form, this.processNext);
    }

    public render() {
        let site = this.props.site;
        let userInfo = site.userInfo;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.menuStrings['ExternalPages.UnaffiliatedLearnerRegistration_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Get_Started_Label'], value: `${localUrl}/getstarted/customers` },
            { key: title, value: "" }
        ];
        let formStep = this.state.formStep;
        let unrestrictedCountries = _.sortBy(this.props.unafilliated.countries, c => c.name);
        let restrictedCountries = _.sortBy(this.props.unafilliated.restrictedCountries, c => c.name);
        let embargoedCountries = _.sortBy(this.props.unafilliated.embargoedCountries, c => c.name);
        let countryOptions = formStep != 3 ? unrestrictedCountries : restrictedCountries;
        let splitLength = Math.round(restrictedCountries.length / 2);
        let maxLength = restrictedCountries.length;
        let emSplitLength = Math.round(embargoedCountries.length / 2);
        let emMaxLength = embargoedCountries.length;
        let yesNoOptions = [
            { label: site.localizedStrings['ExternalPages.Yes_Label'], value: true },
            { label: site.localizedStrings['ExternalPages.No_Label'], value: false }
        ];
        let registrationForm = this.state.registrationForm;
        let contactMethods = ["Email", "Phone", "Postal Mail", "Fax", "SMS"];
        let useBusinessAddress = registrationForm?.useBusinessAddress !== undefined && !registrationForm?.useBusinessAddress;
        let langOptions = _.sortBy(['English ', 'Korean', 'Traditional Chinese', 'Japanese', 'German', 'Castilian Spanish', 'Italian', 'French'], l => l);
        let canSubmit = this.state.termsClicked && this.state.isFormValid;
        let countries = _.sortBy(this.state.countries ?? countryOptions, c => c.name);
        let businessCountries = _.sortBy(_.union(countries, restrictedCountries), c => c.name);
        let noCountry: Country = {
            name: site.localizedStrings['ExternalPages.UnaffiliatedReg_Citizenship_NoCountry_Selection'],
            geography: 'None',
            region: 'None',
            code: 'None'
        }

        if (formStep < 4) {
            countries.splice(0, 0, noCountry);
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="UnaffiliatedLearnerReg" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.LpcKeywords']}
            metadataDescription={site.localizedStrings['Metadata.LpcDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Heading level={1} textAlign="center" fill>{title}</Heading>
                <Box pad="small" gap="small">
                    <Box gap="small" width="large">
                        <Box direction="row-responsive" gap="small">
                            <Text weight="bold">Name:</Text>
                            <Text>{userInfo?.name}</Text>
                        </Box>
                        <Paragraph fill>
                            <HtmlComponent html={formatString(site.localizedStrings['ExternalPages.UnaffiliatedReg_PartnerRegistration_Statement'],
                                [`${localUrl}/getstarted/partners`])} />
                        </Paragraph>
                    </Box>
                    <Form value={registrationForm} onChange={nextValue => this.setState({ registrationForm: nextValue })} onValidate={this.isValidated}
                        validate='change' onSubmit={({ value }) => { this.onSubmit(value); }}>
                        <Box width="large" gap="medium" >
                            {formStep <= 4 && <Box gap="medium">
                                {(formStep < 4) && <Heading level={4}>
                                    {site.localizedStrings['ExternalPages.UnaffiliatedReg_CitizenshipVerification_Label']}
                                </Heading>}
                                {(formStep < 3) && <Box gap="small" pad={{ left: "small" }}>
                                    <Heading level={5}>
                                        {site.localizedStrings['ExternalPages.UnaffiliatedReg_RestrictedCountries_Label']}
                                    </Heading>
                                    <Box direction="row-responsive" gap="large" width="large">
                                        <Box gap="xxsmall" fill>
                                            {_.map(restrictedCountries.slice(0, splitLength), rc => <Text>{rc.name}</Text>)}
                                        </Box>
                                        <Box gap="xxsmall" fill>
                                            {restrictedCountries.length > splitLength &&
                                                _.map(restrictedCountries.slice(splitLength, maxLength), rc => <Text>{rc.name}</Text>)}
                                        </Box>
                                    </Box>
                                </Box>}
                                {formStep === 3 && <Box gap="small" pad={{ left: "small" }}>
                                    <Heading level={5}>{site.localizedStrings['ExternalPages.UnaffiliatedReg_EmbargoedCounties_Label']}</Heading>
                                    <Box direction="row-responsive" gap="large" width="large">
                                        <Box gap="xxsmall" fill>
                                            {_.map(embargoedCountries.slice(0, emSplitLength), rc => <Text>{rc.name}</Text>)}
                                        </Box>
                                        <Box gap="xxsmall" fill>
                                            {embargoedCountries.length > emSplitLength &&
                                                _.map(embargoedCountries.slice(emSplitLength, emMaxLength), rc => <Text>{rc.name}</Text>)}
                                        </Box>
                                    </Box>
                                </Box>}
                                {formStep == 0 && <Box gap="xxsmall">
                                    <Box direction="row-responsive" gap="small">
                                        <Text>1.</Text>
                                        <Paragraph fill>
                                            {site.localizedStrings['ExternalPages.UnaffiliatedReg_Citizenship_Question1']}
                                        </Paragraph>
                                    </Box>
                                    <RadioButtonGroup name="hasDualCitizenship" options={yesNoOptions} direction="row-responsive"
                                        onChange={({ target: { value } }) => { this.onCitizenshipChange(value) }} />
                                </Box>}
                                {formStep == 1 && <Box gap="xxsmall">
                                    <Box direction="row-responsive" gap="small">
                                        <Text>2.</Text>
                                        <Paragraph fill>
                                            {site.localizedStrings['ExternalPages.UnaffiliatedReg_Citizenship_Question2']}
                                        </Paragraph>
                                    </Box>
                                    <RadioButtonGroup name="isPermanentResident" options={yesNoOptions} direction="row-responsive"
                                        onChange={({ target: { value } }) => { this.onPermanentResidentChange(value) }} />
                                </Box>}
                                {formStep == 2 && <Box gap="xxsmall">
                                    <Box direction="row-responsive" gap="small">
                                        <Text>3.</Text>
                                        <Paragraph fill>
                                            {site.localizedStrings['ExternalPages.UnaffiliatedReg_Citizenship_Question3']}
                                        </Paragraph>
                                    </Box>
                                    <RadioButtonGroup name="isRefugee" options={yesNoOptions} direction="row-responsive"
                                        onChange={({ target: { value } }) => { this.onRefugeeChange(value) }} />
                                </Box>}
                                {formStep == 3 && <Box gap="xxsmall">
                                    <Box direction="row-responsive" gap="small">
                                        <Text>4.</Text>
                                        <Paragraph fill>
                                            {site.localizedStrings['ExternalPages.UnaffiliatedReg_Citizenship_Question4']}
                                        </Paragraph>
                                    </Box>
                                    <RadioButtonGroup name="isEmbargoed" options={yesNoOptions} direction="row-responsive"
                                        onChange={({ target: { value } }) => { this.onEmbargoChange(value) }} />
                                </Box>}
                                {formStep == 4 && <Box gap="xsmall">
                                    <Paragraph fill>
                                        {site.localizedStrings['ExternalPages.UnaffiliatedReg_Denial_Statement']}
                                    </Paragraph>
                                </Box>}
                                {this.showCountry() &&
                                    <Box width="medium">
                                        <FormField name="citizenshipCountry"
                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_County_Label']}>
                                            <Select name="citizenshipCountry" options={countries} labelKey='name'
                                                valueKey='code' dropHeight="medium" onSearch={this.onCountrySearch} onChange={this.onCountrySelected} />
                                        </FormField>
                                    </Box>}
                            </Box>}
                            {formStep === 5 && <Box>
                                <Accordion onActive={this.onActivatePanel} activeIndex={this.state.activeIndex}>
                                    <AccordionPanel label={site.localizedStrings['ExternalPages.UnaffiliatedReg_PersonalInformation_Label']}>
                                        <Box gap="small" pad="small">
                                            <Paragraph fill>
                                                <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_PersonalInformation_Statement']} />
                                            </Paragraph>
                                            <Box gap="small">
                                                <Box direction="row-responsive" gap="medium" >
                                                    <FormField name='salutation' htmlFor='salutation-input'
                                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_Salutation_Label']} >
                                                        <TextInput id='salutation-input' name='salutation' />
                                                    </FormField>
                                                    <Box fill="horizontal">
                                                        <FormField name='fullName' htmlFor='fullName-input' required
                                                            label={parse(site.localizedStrings['ExternalPages.UnaffiliatedReg_FullName_Label'])} >
                                                            <TextInput id='fullName-input' name='fullName' />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" >
                                                    <Box fill="horizontal">
                                                        <FormField name='firstName' htmlFor='firstName-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_FirstName_Label']} >
                                                            <TextInput id='firstName-input' name='firstName' />
                                                        </FormField>
                                                    </Box>
                                                    <Box fill="horizontal">
                                                        <FormField name='middleName' htmlFor='middleName-input'
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_MiddleName_Label']} >
                                                            <TextInput id='middleName-input' name='middleName' />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" >
                                                    <Box fill="horizontal">
                                                        <FormField name='lastName' htmlFor='lastName-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_LastName_Label']} >
                                                            <TextInput id='lastName-input' name='lastName' />
                                                        </FormField>
                                                    </Box>
                                                    <FormField name='suffix' htmlFor='suffix-input'
                                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_Suffix_Label']} >
                                                        <TextInput id='suffix-input' name='suffix' />
                                                    </FormField>
                                                </Box>
                                                <Paragraph fill size="small" color="red">
                                                    {site.localizedStrings['ExternalPages.UnaffiliatedReg_Name_Note']}
                                                </Paragraph>
                                                <FormField name='jobTitle' htmlFor='jobTitle-input'
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_JobTitle_Label']} >
                                                    <TextInput id='jobTitle-input' name='jobTitle' />
                                                </FormField>
                                                <FormField name='email' htmlFor='email-input' required
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_Email_Label']} >
                                                    <TextInput id='email-input' name='email' />
                                                </FormField>
                                                <Box direction="row-responsive" gap="medium" >
                                                    <Box fill="horizontal">
                                                        <FormField name='workPhone' htmlFor='workPhone-input'
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_Phone_Label']} >
                                                            <TextInput name="workPhone" id="workPhone-input" />
                                                        </FormField>
                                                    </Box>
                                                    <Box fill="horizontal">
                                                        <FormField name='workFax' htmlFor='workFax-input'
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_Fax_Label']} >
                                                            <TextInput name="workFax" id="workFax-input" />
                                                        </FormField>
                                                    </Box>
                                                    <Box fill="horizontal">
                                                        <FormField name='mobilPhone' htmlFor='mobilPhone-input'
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_Mobile_Label']} >
                                                            <TextInput name="mobilPhone" id="mobilPhone-input" />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" >
                                                    <Box fill="horizontal">
                                                        <FormField name='companyCountry' htmlFor='companyCountry-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_CompanyCounty_Label']} >
                                                            <Select name="companyCountry" id='companyCountry-input' options={businessCountries}
                                                                labelKey='name' valueKey='code' dropHeight="medium"
                                                                onSearch={this.onCountrySearch} onChange={this.onCountrySelected} />
                                                        </FormField>
                                                    </Box>
                                                    <Box fill="horizontal">
                                                        <FormField name='preferredLanguage' htmlFor='preferredLanguage-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_PreferredLanguage_Label']} >
                                                            <Select id='preferredLanguage-input' name='preferredLanguage' options={langOptions}
                                                                dropHeight="medium" />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                                <FormField name='friendEmail' htmlFor='friendEmail-input'
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_FriendEmail_Label']} >
                                                    <TextInput id='friendEmail-input' name='friendEmail' />
                                                </FormField>
                                                <Box direction="row-responsive" gap="medium" >
                                                    <Box width="medium">
                                                        <FormField name='previousParticipant' htmlFor='previousParticipant-input'
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_PreviousParticipant_Label']} >
                                                            <RadioButtonGroup name="previousParticipant" id='previousParticipant-input' options={yesNoOptions}
                                                                direction="row-responsive" />
                                                        </FormField>
                                                    </Box>
                                                    <FormField name='previousLearnerId' htmlFor='previousLearnerId-input'
                                                        label={parse(formatString(site.localizedStrings['ExternalPages.UnaffiliatedReg_LearnerId_Label'],
                                                            [`${localUrl}/assistKit`]))} >
                                                        <TextInput id='previousLearnerId-input' name='previousLearnerId' />
                                                    </FormField>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                    <AccordionPanel label={site.localizedStrings['ExternalPages.UnaffiliatedReg_BusinessAddress_Label']}>
                                        <Box gap="small" pad="small">
                                            <Paragraph fill>
                                                <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_BusinessAddress_Statement']} />
                                            </Paragraph>
                                            <FormField name='companyName' htmlFor='companyName-input' required
                                                label={site.localizedStrings['ExternalPages.UnaffiliatedReg_CompanyName_Label']} >
                                                <TextInput id='companyName-input' name='companyName' />
                                            </FormField>
                                            <FormField name='companyAddress1' htmlFor='companyAddress1-input' required
                                                label={site.localizedStrings['ExternalPages.UnaffiliatedReg_AddressLine1_Label']} >
                                                <TextInput id='companyAddress1-input' name='companyAddress1' />
                                            </FormField>
                                            <FormField name='companyAddress2' htmlFor='companyAddress2-input'
                                                label={site.localizedStrings['ExternalPages.UnaffiliatedReg_AddressLine2_Label']} >
                                                <TextInput id='companyAddress2-input' name='companyAddress2' />
                                            </FormField>
                                            <Box direction="row-responsive" gap="medium" >
                                                <Box fill="horizontal">
                                                    <FormField name='companyCity' htmlFor='companyCity-input' required
                                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_CityTown_Label']} >
                                                        <TextInput id='companyCity-input' name='companyCity' />
                                                    </FormField>
                                                </Box>
                                                <Box fill="horizontal">
                                                    <FormField name='companyState' htmlFor='companyState-input' required
                                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_ProvinceState_Label']} >
                                                        <TextInput id='companyState-input' name='companyState' />
                                                    </FormField>
                                                </Box>
                                                <Box fill="horizontal">
                                                    <FormField name='companyPostalCode' htmlFor='companyPostalCode-input' required
                                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_PostalCode_Label']} >
                                                        <TextInput id='companyPostalCode-input' name='companyPostalCode' />
                                                    </FormField>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                    <AccordionPanel label={site.localizedStrings['ExternalPages.UnaffiliatedReg_ContactAddress_Label']}>
                                        <Box gap="small" pad="small">
                                            <Paragraph fill>
                                                <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_ContactAddress_Statement']} />
                                            </Paragraph>
                                            <Box width="small">
                                                <FormField name='useBusinessAddress' htmlFor='useBusinessAddress-input' required
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_SameAddress_Label']} >
                                                    <RadioButtonGroup name="useBusinessAddress" id='useBusinessAddress-input' options={yesNoOptions}
                                                        direction="row-responsive" />
                                                </FormField>
                                            </Box>
                                            {useBusinessAddress && <Box gap="small">
                                                <FormField name='personalCountry' htmlFor='personalCountry-input' required
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_County_Label']} >
                                                    <Select name="personalCountry" id='personalCountry-input' options={countries}
                                                        labelKey='name' valueKey='code' dropHeight="medium"
                                                        onSearch={this.onCountrySearch} onChange={this.onCountrySelected} />
                                                </FormField>
                                                <FormField name='personalAddress1' htmlFor='personalAddress1-input' required
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_AddressLine1_Label']} >
                                                    <TextInput id='personalAddress1-input' name='personalAddress1' />
                                                </FormField>
                                                <FormField name='personalAddress2' htmlFor='personalAddress2-input'
                                                    label={site.localizedStrings['ExternalPages.UnaffiliatedReg_AddressLine2_Label']} >
                                                    <TextInput id='personalAddress2-input' name='personalAddress2' />
                                                </FormField>
                                                <Box direction="row-responsive" gap="small" >
                                                    <Box fill="horizontal">
                                                        <FormField name='personalCity' htmlFor='personalCity-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_CityTown_Label']} >
                                                            <TextInput id='personalCity-input' name='personalCity' />
                                                        </FormField>
                                                    </Box>
                                                    <Box fill="horizontal">
                                                        <FormField name='personalState' htmlFor='personalState-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_ProvinceState_Label']} >
                                                            <TextInput id='personalState-input' name='personalState' />
                                                        </FormField>
                                                    </Box>
                                                    <Box fill="horizontal">
                                                        <FormField name='personalPostalCode' htmlFor='personalPostalCode-input' required
                                                            label={site.localizedStrings['ExternalPages.UnaffiliatedReg_PostalCode_Label']} >
                                                            <TextInput id='personalPostalCode-input' name='personalPostalCode' />
                                                        </FormField>
                                                    </Box>
                                                </Box>
                                            </Box>}
                                        </Box>
                                    </AccordionPanel>
                                    <AccordionPanel label={site.localizedStrings['ExternalPages.UnaffiliatedReg_PrivacyPolicy_Label']}>
                                        <Box gap="small" pad="small">
                                            <Paragraph fill>
                                                <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_PrivacyPolicy_Statement']} />
                                            </Paragraph>
                                            <CheckBoxGroup name='contactMethods' options={contactMethods} />
                                        </Box>
                                    </AccordionPanel>
                                </Accordion>
                            </Box>}
                            {formStep === 6 && <Box gap="small">
                                <Heading level={3}>
                                    {site.localizedStrings['ExternalPages.UnaffiliatedReg_ExportComplianceAggrement_Label']}
                                </Heading>
                                <Paragraph fill>
                                    <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_ExportComplianceAggrement_Statement']} />
                                </Paragraph>
                                <FormField name="complianceAgreement" htmlFor="complianceAgreement-input" required>
                                    <CheckBox name="complianceAgreement" id="complianceAgreement-input"
                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_ExportComplianceAggrement_Question']} />
                                </FormField>
                            </Box>}
                            {formStep === 7 && <Box gap="small">
                                <Heading level={3}>
                                    {site.localizedStrings['ExternalPages.UnaffiliatedReg_TermsAndConditions_Label']}
                                </Heading>
                                <Anchor onClick={this.onTermsAndConditions} label={site.localizedStrings['ExternalPages.UnaffiliatedReg_TermsAndConditions_Link']} />
                                <Paragraph fill>
                                    <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_TermsAndConditions_Stement']} />
                                </Paragraph>
                                <FormField name="termsAndConditions" htmlFor="termsAndConditions-input" required>
                                    <CheckBox name="termsAndConditions" id="termsAndConditions-input"
                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_TermsAndConditions_Question1']} />
                                </FormField>
                                <FormField name="storeInformation" htmlFor="storeInformation-input" required>
                                    <CheckBox name="storeInformation" id="storeInformation-input"
                                        label={site.localizedStrings['ExternalPages.UnaffiliatedReg_TermsAndConditions_Question2']} />
                                </FormField>
                            </Box>}
                            {formStep === 8 && <Box gap="small">
                                <Heading level={3}>
                                    {site.localizedStrings['ExternalPages.UnaffiliatedReg_Thankyou_Label']}
                                </Heading>
                                <Paragraph fill>
                                    <HtmlComponent html={site.localizedStrings['ExternalPages.UnaffiliatedReg_Thankyou_Statement']} />
                                </Paragraph>
                                <Heading level={5}>
                                    {site.localizedStrings['ExternalPages.UnaffiliatedReg_Questions_label']}
                                </Heading>
                                <Anchor label={site.localizedStrings['ExternalPages.UnaffiliatedReg_ThankYou_Support_Link']}
                                    href={`${localUrl}/support`} />
                            </Box>}
                            {formStep != 4 && <Footer gap="small">
                                {formStep != 8 && <Button primary label={site.localizedStrings['ExternalPages.Back_Label']}
                                    icon={<FormPreviousLink />} onClick={this.processBack} disabled={formStep === 0 || formStep === 5} />}
                                {formStep < 8 && <Button secondary color="red" label={site.localizedStrings['ExternalPages.Cancel_Label']}
                                    onClick={this.onCancel} />}
                                {formStep < 7 && <Button primary label={site.localizedStrings['ExternalPages.Next_Label']} icon={<FormNextLink />}
                                    onClick={this.processNext} disabled={!this.canMoveForward()} />}
                                {formStep === 7 && <Button primary type="submit" label={site.localizedStrings['ExternalPages.Submit_Label']}
                                    disabled={!canSubmit} />}
                            </Footer>}
                        </Box>
                    </Form>
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(UnaffiliatedLearnerReg as any));
