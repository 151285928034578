import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Text, Heading, Anchor, Image, DataTable, TextInput, CheckBox, Paragraph } from 'grommet';
import { serachExperts, saveUpdates, downloadReport, smeSlice } from '../../store/Sme';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { replace, push } from 'connected-react-router';
import { ApplicationState } from '../../store';
import { siteSlice, retrieveLocalizedStrings } from '../../store/Site';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { SmeInfo } from '../../models/SmeInfo';
import { KeyValuePair } from '../../models/Common';
import BasePage from '../../components/BasePage';
import { hasRole } from '../../Utilities';

const mapDispatchToProps = {
    replace,
    push,
    serachExperts,
    saveUpdates,
    downloadReport,
    retrieveLocalizedStrings,
    ...smeSlice.actions,
    ...siteSlice.actions
}

interface AdminState {
    updateList: boolean;
    keywords: string;
    list: SmeInfo[];
}

type AdminProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class Admin extends React.PureComponent<AdminProps, AdminState> {
    constructor(props: AdminProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'MyLearning.Not_Authorized_Statement'];

        this.state = {
            updateList: true,
            keywords: '',
            list: []
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('admin-tools-menu');
    }

    public componentDidUpdate() {
        if (this.state.updateList || (this.state.list.length === 0  && this.props.sme.results.length !== 0)) {
            this.setState({ list: this.props.sme.results, updateList: false });
        }
    }

    onSearch = () => {
        this.setState({ updateList: true });
        this.props.serachExperts(this.state.keywords);
    }

    onBlock = (checked: any, learnerId: string) => {
        let list = JSON.parse(JSON.stringify(this.state.list));
        let item = list.find((sme: SmeInfo) => sme.learnerId === learnerId);

        if (item) {
            item.blocked = checked;
            this.setState({ list: list });
        }
    }

    onDownload = () => {
        this.props.downloadReport();
    }

    isDirty = () => {
        let originalList = {
            ...this.props.sme.results,
        };
        let currentList = {
            ...this.state.list
        };

        return !_.isEqual(originalList, currentList);
    }

    onSaveChanges = () => {
        let originalList = [
            ...this.props.sme.results,
        ];
        let currentList = [
            ...this.state.list
        ];
        let updates = currentList.filter(currItem => {
            let orgItem = originalList.find(sme => sme.learnerId === currItem.learnerId);

            return !_.isEqual(orgItem, currItem);
        })

        this.props.saveUpdates(updates, this.state.keywords);
        this.setState({ updateList: true });
    }

    onKeywordChange = (event: any) => {
        this.props.setResults([]);
        this.setState({ keywords: event.target.value })
    }

    onKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            this.onSearch();
        }
    }

    onResultSelected = (info: SmeInfo) => {
        this.props.setStringsLoaded(false);
        this.props.push(`/sme/application/${info.id}`);
    }

    onCancelChanges = () => {
        this.setState({ list: this.props.sme.results });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let title = site.menuStrings['ExternalPages.SME_Administrator'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: localUrl },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: "" }
        ];
        let results = this.state.list;
        let isSmeAdmin = hasRole(this.props.site.user, ['SME_ADMIN']);

        if (!user) {
            return null;
        }
        if (user && !isSmeAdmin) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (results && results.length === 1) {
            this.onResultSelected(results[0]);
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="SME_Search" {...this.props} >
            <Box fill gap="small">
                <Box height="300px" justify="center"
                    background={{ size: "cover", image: "url('images/sme/HPE_data_particles_01_1600_0_72_RGB.jpg')" }} >
                    <Box width={{ max: "medium" }} background={{ color: "#FFFFFF", opacity: "strong" }} gap="small" margin={{ left: "medium" }} pad="small">
                        <Heading level={4}>Subject Matter Expert Administration</Heading>
                        <Paragraph fill>Use this form to search for a specific SME and modify data, create notes or tag as blocked, or download the SME database.</Paragraph>
                    </Box>
                </Box>
                <Box gap="medium" fill pad={{ bottom: "small" }}>
                    <Box direction='row' gap="small" justify='between'>
                        <Box gap="xxsmall" >
                            <Text weight="bold">Search</Text>
                            <Box direction="row" gap="small" width={{ max: 'large' }} >
                                <Box width="medium">
                                    <TextInput id="keywords-input" name="keywords" placeholder="Name, Email or Learner ID" value={this.state.keywords}
                                        onChange={this.onKeywordChange} onKeyPress={(event) => this.onKeyPress(event)} ></TextInput>
                                </Box>
                                <Box>
                                    <Button primary color="brand" label="GO" onClick={this.onSearch} />
                                </Box>
                            </Box>
                        </Box>
                        <Box direction="row" gap="small" width={{ max: 'medium' }} align='end'>
                            <Anchor onClick={this.onDownload} label="Download SME Excel spreadsheet" />
                            <Anchor onClick={this.onDownload} >
                                <Image src="images/icons8-microsoft-excel-2019.svg" />
                            </Anchor>
                        </Box>
                    </Box>
                    <Box background="#7630EA" fill="horizontal" border={{ side: 'all', color: "#7630EA", size: "small" }}></Box>
                    <Box height={{ min: "medium" }}>
                        {(results && results.length >= 1) && <Box gap="medium">
                            <Box height={{ max: "medium" }} overflow="auto">
                                <DataTable fill pin="header"
                                    columns={[
                                        {
                                            property: 'dateCreated',
                                            header: <Text>Date</Text>,
                                            render: datum => <Text>{datum.dateCreated ? format(parseISO(datum.dateCreated), 'MMMM dd, yyyy') : 'N/A'}</Text>
                                        },
                                        {
                                            property: 'name',
                                            header: <Text>Name</Text>,
                                            render: datum => <Anchor onClick={() => { this.onResultSelected(datum) }} label={`${datum.firstName} ${datum.lastName}`} target={"_blank"} />
                                        },
                                        {
                                            property: 'emailAddress',
                                            header: <Text>Email</Text>
                                        },
                                        {
                                            property: 'learnerId',
                                            header: <Text>HPE Learner ID</Text>
                                        },
                                        {
                                            property: 'blocked',
                                            header: <Text>Block</Text>,
                                            render: datum => <CheckBox checked={datum.blocked} onChange={(event) => this.onBlock(event.target.checked, datum.learnerId)} />
                                        }
                                    ]}
                                    data={results}
                                />
                            </Box>
                            <Box direction="row" justify="center" fill gap="small">
                                <Button primary color="brand" onClick={this.onSaveChanges} disabled={!this.isDirty()} label='Submit changes' />
                                <Button secondary onClick={this.onCancelChanges} hidden={!this.isDirty()} label="Cancel changes" />
                            </Box>
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Admin as any));
