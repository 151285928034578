import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grommet, Box, Header, Footer, Button, Layer, Spinner, Text, Paragraph, ThemeContext, Image } from 'grommet';
import { hpe } from 'grommet-theme-hpe';
import { ApplicationState } from '../store';
import { setError, setMessage, setAcknowledgedCallback } from '../store/Site';
import parse from 'html-react-parser';
import NavMenu from './NavMenu';
import _ from 'lodash';

export const hpeTheme = {
    global: {
        breakpoints: {
            xxsmall: {
                value: 320
            },
            xsmall: {
                value: 599
            },
            medium_xxsmall: {
                value: 970
            },
            medium_xsmall: {
                value: 1260
            },
            medium_small: {
                value: 1370
            }
        }
    },
    checkBox: {
        size: "14px",
        gap: "xsmall"
    },
    radioButton: {
        size: "14px",
        gap: "xsmall"
    },
    accordion: {
        heading: {
            margin: {
                "vertical": "small"
            }
        }
    },
    table: {
        body: {
            pad: { vertical: 'none' }
        }
    }
};

const mapDispatchToProps = {
    setError,
    setMessage,
    setAcknowledgedCallback
}

type LayoutProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

interface ILayoutState {
    children?: React.ReactNode;
}

class Layout extends React.PureComponent<LayoutProps, ILayoutState> {
    onErrorAcknowledged = () => {
        this.props.setError(undefined);
        if (this.props.site.acknowledgedCallback) {
            this.props.site.acknowledgedCallback();
            this.props.setAcknowledgedCallback(undefined);
        }
    }

    onMessageAcknowledged = () => {
        this.props.setMessage(undefined);
        if (this.props.site.acknowledgedCallback) {
            this.props.site.acknowledgedCallback();
            this.props.setAcknowledgedCallback(undefined);
        }
    }

    public render() {
        let backgroundMenus = ["overview-menu"];

        return (
            <React.Fragment>
                <div id="everything" className="everything">
                    <div id="content">
                        <div id="header" className="header"></div>
                        <div id="body" className="body">
                            <Grommet theme={hpe}>
                                <ThemeContext.Extend value={hpeTheme}>
                                    <Box fill>
                                        {(this.props.site.loading || this.props.site.loadingUser) && <Layer>
                                            <Box pad="small">
                                                <Image src="images/loader.svg" />
                                            </Box>
                                        </Layer>}
                                        {this.props.site.error && <Layer>
                                            <Box width="medium" >
                                                <Header background="#A2423D" round={{ "size": "small", "corner": "top" }}>
                                                    <Box fill="horizontal"><Text textAlign="center" color="white">Error</Text></Box>
                                                </Header>
                                                <Box pad="small">
                                                    <Paragraph fill color="#A2423D" textAlign="center">{parse(this.props.site.error)}</Paragraph>
                                                </Box>
                                                <Footer fill="horizontal" pad="small" justify="end">
                                                    <Box width="xsmall" >
                                                        <Button secondary color="#A2423D" label="Dismiss" onClick={this.onErrorAcknowledged} />
                                                    </Box>
                                                </Footer>
                                            </Box>
                                        </Layer>}
                                        {this.props.site.message && <Layer>
                                            <Box width="medium" height="small">
                                                <Header background="brand" round={{ "size": "small", "corner": "top" }}>
                                                    <Box fill="horizontal"><Text textAlign="center" color="white">Information</Text></Box>
                                                </Header>
                                                <Box pad="small" height="small" alignContent="center">
                                                    <Paragraph fill textAlign="center">{parse(this.props.site.message)}</Paragraph>
                                                </Box>
                                                <Footer fill="horizontal" pad="small" justify="end">
                                                    <Box width="xsmall" >
                                                        <Button primary label="OK" onClick={this.onMessageAcknowledged} />
                                                    </Box>
                                                </Footer>
                                            </Box>
                                        </Layer>}
                                        <Box fill background={_.some(backgroundMenus, menu => menu == this.props.site.activeMenu)
                                            ? { size: "cover", image: "url('images/overview/GettyImages-978721396_1600_0_72_RGB.jpg')", opacity: "medium" }
                                            : undefined}>
                                            <Box fill className="hpehf-centered-content">
                                                {/* <div className="hpehf-centered-content"> */}
                                                <Box fill width={{ min: "small" }}>
                                                    <NavMenu />
                                                    {this.props.children}
                                                </Box>
                                                {/* </div> */}
                                            </Box>
                                        </Box>
                                    </Box>
                                </ThemeContext.Extend>
                            </Grommet>
                        </div>
                        <div id="footer" className="footer"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(Layout as any));
