import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Image, Heading, Text, Anchor, Form, FormField, TextInput, TextArea, Button } from 'grommet';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, submitForm, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    submitForm,
    ...siteSlice.actions,
}

interface IExamSecurityProps {
}

interface ExamSecurityState {
    contactInfo: any;
    showThankYou: boolean;
}

type ExamSecurityProps = IExamSecurityProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class ExamSecurity extends React.PureComponent<ExamSecurityProps, ExamSecurityState> {
    constructor(props: ExamSecurityProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['Metadata.ExamSecurityKeywords', 'Metadata.ExamSecurityDescription', 'ExternalPages.ExamSecurity_Intro_Statement',
            'ExternalPages.Support_Header_Box1_Title', 'ExternalPages.Support_Header_Box2_Title', 'ExternalPages.Support_Header_Box3_Title',
            'ExternalPages.Support_Header_Box1_Statement', 'ExternalPages.Support_Header_Box2_Statement', 'ExternalPages.Support_Header_Box3_Statement',
            'ExternalPages.Support_FirstName_Label', 'ExternalPages.Support_LastName_Label', 'ExternalPages.Support_Email_Label',
            'ExternalPages.Support_Employee_label', 'ExternalPages.Support_LearnerId_Label', 'ExternalPages.Support_Explain_Label',
            'ExternalPages.Support_Submit_Statement', 'ExternalPages.Support_ThankYou_Title', 'ExternalPages.Contact_ExamSecurity_Label',
            'ExternalPages.Support_ThankYou_Statement1', 'ExternalPages.Support_ThankYou_Statement2', 'ExternalPages.Support_ThankYou_Statement3',
            'ExternalPages.Support_ThankYou_Signature', 'ExternalPages.Cancel_Label', 'ExternalPages.Submit_Label'];

        this.state = {
            contactInfo: undefined,
            showThankYou: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('help-menu');
    }

    onSubmit = (form: any) => {
        let url = "https://www.hpe.com/h41268/live/processor_e.aspx?pid=19792";

        this.props.submitForm(url, form, this.showThankyou);
    }

    showThankyou = () => {
        this.setState({ showThankYou: true });
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Help_Label'], value: "" },
            { key: site.menuStrings['ExternalPages.Contact_Security_Label'], value: "" }
        ];
        let title = !this.state.showThankYou
            ? site.menuStrings['ExternalPages.Contact_Security_Label']
            : site.localizedStrings['ExternalPages.ExamSecurity_ThankYou_Title'];
        let explanation = 'aid_c_575900';

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="Contact_ExamSecurity" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.ExamSecurityKeywords']}
            metadataDescription={site.localizedStrings['Metadata.ExamSecurityDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad="small">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" width="large">
                        <Paragraph fill textAlign="center">{parse(site.localizedStrings['ExternalPages.ExamSecurity_Intro_Statement'])}</Paragraph>
                    </Box>
                </Box>
                <Box>
                    {!this.state.showThankYou && <Box fill>
                        <Box direction="row" gap="medium" align="center" fill="horizontal" justify="center">
                            <Box width="medium" height="xsmall" border={{ side: 'all', size: "medium", color: '#CCCCCC' }} gap="xxsmall" pad="small">
                                <Text weight="bold" textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box1_Title']}</Text>
                                <Text textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box1_Statement']}</Text>
                            </Box>
                            <Box width="medium" height="xsmall" border={{ side: 'all', size: "medium", color: '#CCCCCC' }} gap="xxsmall" pad="small">
                                <Text weight="bold" textAlign="center">{site.localizedStrings['ExternalPages.Support_Header_Box3_Title']}</Text>
                                <Anchor alignSelf="center" href="https://www.hpe.com/us/en/legal/privacy.html" target="_blank"
                                    label={site.localizedStrings['ExternalPages.Support_Header_Box3_Statement']} />
                            </Box>
                        </Box>
                        <Box width={{ max: "xlarge" }} alignSelf="center" fill="horizontal">
                            <Form onSubmit={({ value }) => this.onSubmit(value)} value={this.state.contactInfo}
                                onChange={nextValue => this.setState({ contactInfo: nextValue })}>
                                <Box gap="medium" pad={{ top: "large" }} fill>
                                    <Box direction="row" gap="medium" fill>
                                        <FormField fill name="aid_p_first_name" htmlFor="firstName-input" required label={site.localizedStrings['ExternalPages.Support_FirstName_Label']} >
                                            <TextInput id="firstName-input" name="aid_p_first_name"></TextInput>
                                        </FormField>
                                        <FormField fill name="aid_p_last_name" htmlFor="lastName-input" required label={site.localizedStrings['ExternalPages.Support_LastName_Label']} >
                                            <TextInput id="lastName-input" name="aid_p_last_name"></TextInput>
                                        </FormField>
                                    </Box>
                                    <FormField fill name="aid_p_email_address" htmlFor="email-input" required label={site.localizedStrings['ExternalPages.Support_Email_Label']} >
                                        <TextInput id="email-input" name="aid_p_email_address" ></TextInput>
                                    </FormField>
                                    <FormField fill name={explanation} htmlFor="explination-input" required label={site.localizedStrings['ExternalPages.Support_Explain_Label']}>
                                        <TextArea id="explination-input" name={explanation}></TextArea>
                                    </FormField>
                                    <Box direction="row" gap="small" justify="end">
                                        <Button secondary label={site.localizedStrings['ExternalPages.Cancel_Label']}
                                            onClick={() => { this.setState({ contactInfo: undefined }) }} />
                                        <Button primary type="submit" label={site.localizedStrings['ExternalPages.Submit_Label']} />
                                    </Box>
                                </Box>
                            </Form>
                        </Box>
                    </Box>}
                    {this.state.showThankYou && <Box fill>
                        <Box width={{ max: "xlarge" }} alignSelf="center" gap="medium">
                            <Paragraph fill>{site.localizedStrings['ExternalPages.Support_ThankYou_Statement1']}</Paragraph>
                            <Paragraph fill>{site.localizedStrings['ExternalPages.Support_ThankYou_Statement2']}</Paragraph>
                            <Paragraph fill>{parse(formatString(site.localizedStrings['ExternalPages.Support_ThankYou_Statement3'], [`${localUrl}/support`]))}</Paragraph>
                            <Paragraph fill>{parse(site.localizedStrings['ExternalPages.Support_ThankYou_Signature'])}</Paragraph>
                        </Box>
                    </Box>}
                </Box>
            </Box>}
        </BasePage>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(ExamSecurity  as any));
