import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Text, Button, TextArea, Table, TableHeader, TableRow, TableBody, TableCell, Image, Anchor } from 'grommet';
import { Hpe, LinkNext, Mail } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { formatString } from '../Utilities';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ICertificationsAdvantageProps {
}

interface ICertificationsAdvantageState {
}

type CertificationsAdvantageProps = ICertificationsAdvantageProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class CertificationsAdvantage extends React.PureComponent<CertificationsAdvantageProps, ICertificationsAdvantageState> {

    constructor(props: CertificationsAdvantageProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.CertificationsAdvantageKeywords', 'Metadata.CertificationsAdvantageDescription',
            "ExternalPages.CertificationsAdvantage_Intro_Statement", "ExternalPages.CertificationsAdvantage_WhatYouGet_Label",
            "ExternalPages.CertificationsAdvantage_Resources_Label", "ExternalPages.CertificationsAdvantage_Exams_Label",
            "ExternalPages.CertificationsAdvantage_TermsAndConditions_Label", "ExternalPages.CertificationsAdvantage_ComingSoon_Label",
            "ExternalPages.CertificationsAdvantage_Category_Label", "ExternalPages.CertificationsAdvantage_Item_Label",
            "ExternalPages.CertificationsAdvantage_Quantity_Label", "ExternalPages.CertificationsAdvantage_AvgPrice_Label",
            "ExternalPages.CertificationsAdvantage_BuyNow_Label", "ExternalPages.CertificationsAdvantage_ViewSubscription_Link",
            "ExternalPages.CertificationsAdvantage_Resources_Box1_Tile", "ExternalPages.CertificationsAdvantage_Resources_Box2_Tile",
            "ExternalPages.CertificationsAdvantage_Resources_Box3_Tile", "ExternalPages.CertificationsAdvantage_Resources_Box4_Tile",
            "ExternalPages.CertificationsAdvantage_Resources_Box1_Statement", "ExternalPages.CertificationsAdvantage_Resources_Box2_Statement",
            "ExternalPages.CertificationsAdvantage_Resources_Box3_Statement", "ExternalPages.CertificationsAdvantage_Resources_Box4_Statement",
            "ExternalPages.CertificationsAdvantage_Exams_Box1_Title", "ExternalPages.CertificationsAdvantage_Exams_Box2_Title",
            "ExternalPages.CertificationsAdvantage_Exams_Box3_Title", "ExternalPages.CertificationsAdvantage_Exams_Box1_Statement",
            "ExternalPages.CertificationsAdvantage_Exams_Box2_Statement", "ExternalPages.CertificationsAdvantage_Exams_Box3_Statement",
            "ExternalPages.CertificationsAdvantage_Exams_OR_Lebel", "ExternalPages.CertificationsAdvantage_Exams_Options_Statement",
            "ExternalPages.CertificationsAdvantage_TermsAndConditions_Statement", "ExternalPages.CertificationsAdvantage_ComingSoon_Statement",
            "ExternalPages.CertificationsAdvantage_WYG_Resources_Row1_Item", "ExternalPages.CertificationsAdvantage_WYG_Resources_Row2_Item",
            "ExternalPages.CertificationsAdvantage_WYG_Resources_Row3_Item", "ExternalPages.CertificationsAdvantage_WYG_Resources_Row4_Item",
            "ExternalPages.CertificationsAdvantage_WYG_Exams_Row1_Item", "ExternalPages.CertificationsAdvantage_WYG_Exams_Row2_Item1",
            "ExternalPages.CertificationsAdvantage_WYG_Exams_Row2_Item2", "ExternalPages.CertificationsAdvantage_WYG_Value",
            "ExternalPages.CertificationsAdvantage_WYG_TableNote", "ExternalPages.CertificationsAdvantage_TypPrice_Label",
            "ExternalPages.CertificationsAdvantage_TermsAndConditions_Link", "ExternalPages.Contact_Us_Label"];

        this.state = {
            displayForm: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('communities-programs-menu');
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    gotoTermsAndConditions = () => {
        let elem = document.getElementById('terms-conditions');

        elem?.scrollIntoView();
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.menuStrings['ExternalPages.CertificationsAdvantage_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_CommunitiesPrograms'], value: "" },
            { key: page, value: "" }
        ];

        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="CertificationsAdvantage" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.CertificationsAdvantageKeywords']}
            metadataDescription={site.localizedStrings['Metadata.CertificationsAdvantageDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Box direction="row-responsive" gap="large" pad="small">
                    <Box gap="small" alignSelf="center" fill>
                        <Heading level="3">{page}</Heading>
                        <Paragraph fill>
                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationsAdvantage_Intro_Statement'], [`${localUrl}/master-ase-advantage`]))}
                        </Paragraph>
                        <Box direction="row-responsive" gap="medium" pad="small">
                            <Box width={{ max: "small" }}>
                                <Button primary reverse icon={<Hpe />} href="https://subscription.computerdata.com"
                                    label={site.localizedStrings['ExternalPages.CertificationsAdvantage_BuyNow_Label']} />
                            </Box>
                            <Box width={{ max: "small" }}>
                                <Button secondary reverse icon={<Mail color="brand" />} href="mailto:subscriptions@computerdata.com"
                                    label={site.localizedStrings['ExternalPages.Contact_Us_Label']} />
                            </Box>
                        </Box>
                        <Anchor label={site.localizedStrings['ExternalPages.CertificationsAdvantage_ViewSubscription_Link']} icon={<LinkNext />}
                            reverse href="https://subscription.computerdata.com" target="_blank" />
                        <Anchor label={site.localizedStrings['ExternalPages.CertificationsAdvantage_TermsAndConditions_Link']} icon={<LinkNext />}
                            reverse onClick={this.gotoTermsAndConditions} target="_blank" />
                    </Box>
                    <Box fill alignSelf="center" width="large">
                        <Image fit="contain" src="images/certifications-advantage/HPE202208240094622.png" />
                    </Box>
                </Box>
                <Box gap="small">
                    <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_WhatYouGet_Label']}</Heading>
                    <Box direction="row-responsive" gap="medium" pad={{ right: "small" }} >
                        <Box width="large">
                            <Image src="images/certifications-advantage/discount.jpg" />
                        </Box>
                        <Box width="large" fill alignSelf="center" gap="small">
                            <Table margin="none">
                                <TableHeader>
                                    <TableRow>
                                        <TableCell background={{ color: "brand" }} pad={{ left: "small" }}>
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Item_Label']}</Text>
                                        </TableCell>
                                        <TableCell background={{ color: "brand" }} >
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Quantity_Label']}</Text>
                                        </TableCell>
                                        <TableCell background={{ color: "brand" }} align="right">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_AvgPrice_Label']}</Text>
                                        </TableCell>
                                        <TableCell background={{ color: "brand" }} align="right" pad={{ right: "small" }}>
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_TypPrice_Label']}</Text>
                                        </TableCell>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={4} border="bottom">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Label']}</Text>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" >
                                            {site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Resources_Row1_Item']}
                                        </TableCell>
                                        <TableCell border="bottom">1</TableCell>
                                        <TableCell border="bottom" align="right">$3,750</TableCell>
                                        <TableCell border="bottom" align="right">$3,750</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" >
                                            {site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Resources_Row2_Item']}
                                        </TableCell>
                                        <TableCell border="bottom">3</TableCell>
                                        <TableCell border="bottom" align="right">$50</TableCell>
                                        <TableCell border="bottom" align="right">$150</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" >
                                            {site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Resources_Row3_Item']}
                                        </TableCell>
                                        <TableCell border="bottom">3</TableCell>
                                        <TableCell border="bottom" align="right">$225</TableCell>
                                        <TableCell border="bottom" align="right">$675</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" >
                                            {site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Resources_Row4_Item']}
                                        </TableCell>
                                        <TableCell border="bottom">3</TableCell>
                                        <TableCell border="bottom" align="right">$200</TableCell>
                                        <TableCell border="bottom" align="right">$600</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={4} border="bottom">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Label']}</Text>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" >
                                            {site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Exams_Row1_Item']}
                                        </TableCell>
                                        <TableCell border="bottom">2</TableCell>
                                        <TableCell border="bottom" align="right">$140</TableCell>
                                        <TableCell border="bottom" align="right">$280</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" align="left">
                                            <Text>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Exams_Row2_Item1'])}</Text>
                                        </TableCell>
                                        <TableCell border="bottom">3</TableCell>
                                        <TableCell border="bottom" align="right">$265</TableCell>
                                        <TableCell border="bottom" align="right">$795</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" align="left">
                                            <Text>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_WYG_Exams_Row2_Item2'])}</Text>
                                        </TableCell>
                                        <TableCell border="bottom">1</TableCell>
                                        <TableCell border="bottom" align="right">$795</TableCell>
                                        <TableCell border="bottom" align="right">$795</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell border="bottom" align="left" colSpan={3} />
                                        <TableCell border="bottom" align="right"><Text weight="bold">$6,250</Text></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Text>{parse(site.localizedStrings["ExternalPages.CertificationsAdvantage_WYG_TableNote"])}</Text>
                        </Box>
                    </Box>
                </Box>
                <Box direction="row-responsive" border={{ side: "top" }} fill="horizontal" />
                <Box gap="small">
                    <Box direction="row-responsive" gap="large" pad="small">
                        <Box gap="small" fill>
                            <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Label']}</Heading>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box1_Tile']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box1_Statement'])}</Paragraph>
                            </Box>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box2_Tile']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box2_Statement'])}</Paragraph>
                            </Box>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box3_Tile']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box3_Statement'])}</Paragraph>
                            </Box>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box4_Tile']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Resources_Box4_Statement'])}</Paragraph>
                            </Box>
                        </Box>
                        <Box gap="small" fill>
                            <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Label']}</Heading>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Box1_Title']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Box1_Statement'])}</Paragraph>
                            </Box>
                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Options_Statement']}</Text>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Box2_Title']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Box2_Statement'])}</Paragraph>
                            </Box>
                            <Text weight="bold">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_OR_Lebel']}</Text>
                            <Box gap="small">
                                <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Box3_Title']}</Heading>
                                <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_Exams_Box3_Statement'])}</Paragraph>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box direction="row-responsive" gap="large" pad="small" id='terms-conditions'>
                    <Box gap="small" fill>
                        <Heading level="4">{site.localizedStrings['ExternalPages.CertificationsAdvantage_TermsAndConditions_Label']}</Heading>
                        <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationsAdvantage_TermsAndConditions_Statement'])}</Paragraph>
                        <Box gap="small" pad={{ bottom: "small" }}>
                            <Box width="small">
                                <Button primary size="large" reverse href="https://subscription.computerdata.com" icon={<Hpe />} target="_blank"
                                    label={site.localizedStrings['ExternalPages.CertificationsAdvantage_BuyNow_Label']} />
                            </Box>
                            <Anchor label={site.localizedStrings['ExternalPages.CertificationsAdvantage_ViewSubscription_Link']} icon={<LinkNext />}
                                reverse href="https://subscription.computerdata.com" target="_blank" />
                        </Box>
                    </Box>
                    <Box fill>
                        <Image fit="contain" src="images/certifications-advantage/HPE202302231855_800_0_72_RGB.jpg" />
                    </Box>
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(CertificationsAdvantage as any));
