import React from 'react';
import { Box, BoxProps } from 'grommet';
import * as DOMPurify from 'dompurify';
import parse from 'html-react-parser';

interface IHtmlComponentProps {
    html: string;
    createReactComponents?: boolean;
}

interface IHtmlComponentState {
}

type HtmlComponentProps =
    & IHtmlComponentProps
    & BoxProps;

export default class HtmlComponent extends React.PureComponent<HtmlComponentProps, IHtmlComponentState> {
    constructor(props: HtmlComponentProps) {
        super(props);
        
        DOMPurify.addHook('afterSanitizeAttributes', function (node: any) {
            // set all elements owning target to target=_blank
            if ('target' in node) {
                node.setAttribute('target', '_blank');
                node.setAttribute('rel', 'noopener');
            }
        });
    }

    public render() {
        let cleanHtml = DOMPurify.sanitize(this.props.html);
        let props = { ...this.props };

        return this.props.createReactComponents
            ? parse(cleanHtml)
            : <Box {...props}><div dangerouslySetInnerHTML={{ __html: cleanHtml }}>
            </div> </Box>;
    }
}
