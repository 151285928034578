import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './'
import { Curriculum } from '../models/Curriculum';
import { setLoading, setError } from './Site';

export interface CurriculumState {
    data: Curriculum | undefined
}

let initialState: CurriculumState = {
    data: undefined
}

export const curriculumSlice = createSlice({
    name: 'Curriculum',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setData } = curriculumSlice.actions;

export const retrieveCurriculum = (id: string, lang?: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/published/curriculum';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({id: id, language: lang !== undefined ? lang : null}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default curriculumSlice.reducer;
