import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import {
    Box, Button, Table, TableHeader, TableBody, TableRow, TableCell, Text, Heading, Form, FormField, TextInput,
    CheckBox, RadioButton, Paragraph, TextArea, ThemeContext, Image, Layer, Header, Footer
} from 'grommet';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { format, parseJSON, add } from 'date-fns';
import { SubjectMaterExpert } from '../../models/SubjectMaterExpert';
import { AreaOfExpertise } from '../../models/AreaOfExpertise';
import { verifyAgreement, downloadAgreement, retrieve, save, smeSlice } from '../../store/Sme';
import { EmptyGuid, hasRole } from '../../Utilities';
import { firstBy } from 'thenby';
import { push } from 'connected-react-router';

const mapDispatchToProps = {
    push,
    verifyAgreement,
    downloadAgreement,
    retrieve,
    save,
    retrieveLocalizedStrings,
    ...smeSlice.actions,
    ...siteSlice.actions
}

interface ISmeApplicationProps {
}

interface SmeApplicationState {
    areasOfExpertise: AreaOfExpertise[];
    sme?: SubjectMaterExpert;
    loadingSme: boolean;
    loadedSme: boolean;
    isNew: boolean;
    showResults: boolean;
    agreementDownloaded: boolean;
}

type SmeApplicationProps = ISmeApplicationProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class SmeApplication extends React.PureComponent<SmeApplicationProps, SmeApplicationState> {
    cdaInputRef: any;

    constructor(props: SmeApplicationProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'MyLearning.Not_Authorized_Statement'];
        let areaNames = ["Wireless/Mobility", "Switching and Routing", "Network Design (Wired and Wireless)", "Network Security",
            "Data Center Networking", "Hybrid Cloud / Edge-to-Cloud", "Servers / Compute", "Storage", "Service Delivery",
            "Generative AI, Machine Learning, and AI", "Campus Access", "SD-WAN", "GreenLake"];
        let areasOfExpertise = areaNames.map((area) => { return { name: area, level: 0 } }).sort(firstBy('name'));;

        this.state = {
            areasOfExpertise: areasOfExpertise,
            loadingSme: false,
            loadedSme: false,
            isNew: false,
            showResults: false,
            agreementDownloaded: false
        };
        this.cdaInputRef = React.createRef();
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('resources-menu');
    }

    public componentDidUpdate() {
        let id = this.getId();

        if (!this.state.sme && !this.state.loadingSme && id !== undefined && !this.state.loadedSme ||
            (this.state.sme && this.state.sme?.id != id && this.state.sme?.emailAddress != id)) {
            this.setState({ loadingSme: true });
            this.props.retrieve(id);
        }
        if (id !== undefined && this.props.sme.current && (!this.state.sme || (this.props.sme.current?.learnerId !== this.state.sme?.learnerId))) {
            let sme = JSON.parse(JSON.stringify(this.props.sme.current)) as SubjectMaterExpert;
            let foundExpertise = this.state.areasOfExpertise.filter((expertise) => _.some(sme.areasOfExpertise, (ae) => expertise.name.indexOf(ae.name) !== -1));
            let missingExpertise = this.state.areasOfExpertise.filter((expertise) => !_.some(sme.areasOfExpertise, (ae) => expertise.name.indexOf(ae.name) !== -1));

            foundExpertise = foundExpertise.map((expertise) => {
                let oe = sme.areasOfExpertise.find((ae) => expertise.name.indexOf(ae.name) !== -1);

                return oe != null
                    ? {
                        name: expertise.name,
                        level: oe.level
                    }
                    : expertise
            });
            sme.areasOfExpertise = [...foundExpertise, ...missingExpertise];
            sme.jobRole = sme.jobRole ?? "";
            sme.jobTitle = sme.jobTitle ?? "";

            this.setState({ sme: sme, loadingSme: false, loadedSme: true, isNew: sme.id == EmptyGuid });
            this.props.setCurrent(sme);
        }
    }

    getId = () => {
        return this.props.match.params.id ?? this.props.site.user?.email ?? this.props.site.userInfo?.email;
    }

    onDownloadAgreement = () => {
        this.props.setMessage("The document needs to be filled out and signed electronically using a PDF reader application, e.g. Adobe Reader.");
        setTimeout(() => {
            this.props.downloadAgreement(this.state.sme as SubjectMaterExpert, this.downloadCallback);
        }, 2000)
    }

    downloadCallback = () => {
        this.setState({ agreementDownloaded: true });
    }

    onUploadAgreement = () => {
        let fileElem = jQuery("#cda-import");

        fileElem.val('');
        this.cdaInputRef.current.click();;
    }

    onLanguageSelected = (language: string, checked: boolean) => {
        let sme = JSON.parse(JSON.stringify(this.state.sme));
        let translations = sme.examTranslations ?? [];
        let index = translations.findIndex((translation: string) => translation === language);

        if (index === -1 && checked) {
            translations.push(language);
        }
        else if (index !== -1 && !checked) {
            translations.splice(index, 1);
        }
        sme.examTranslations = translations;
        this.setState({ sme: sme });
    }

    hasLanguage = (language: string) => {
        let sme = this.state.sme;

        if (sme && sme.examTranslations) {
            let index = sme.examTranslations.findIndex((translation) => translation === language);

            return index !== -1;
        }
        return false;
    }

    hasExperience = (expertise: string, level: number) => {
        let sme = this.state.sme;
        let areaOfExpertise = sme?.areasOfExpertise.find((ae) => ae.name === expertise);

        return areaOfExpertise && areaOfExpertise.level === level;
    }

    onBlocked = (checked: boolean) => {
        let sme = JSON.parse(JSON.stringify(this.state.sme));

        sme.blocked = checked;
        this.setState({ sme: sme });
    }

    onExperienceChange = (expertise: string, level: number) => {
        let sme = JSON.parse(JSON.stringify(this.state.sme));
        let areaOfExpertise = sme.areasOfExpertise.find((ae: AreaOfExpertise) => ae.name === expertise);

        if (areaOfExpertise) {
            areaOfExpertise.level = level;
            this.setState({ sme: sme });
        }
    }

    onyearsInCurrentRoleChange = (event: any) => {
        let sme = JSON.parse(JSON.stringify(this.state.sme));

        sme.yearsInCurrentRole = event.target.value;
        this.setState({ sme: sme });
    }

    onyearsOfExperienceChange = (event: any) => {
        let sme = JSON.parse(JSON.stringify(this.state.sme));

        sme.yearsOfExperience = event.target.value;
        this.setState({ sme: sme });
    }

    onFileSelected = (event: any) => {
        let cdaFile = event.target.files[0];

        this.props.verifyAgreement(cdaFile, this.state.sme, this.onUploadCallback);
    }

    isDirty = () => {
        let original = {
            ...this.props.sme.current,
        };
        let current = {
            ...this.state.sme
        };
        let dirty = !_.isEqual(original, current);

        return dirty;
    }

    isEmployee = () => {
        return this.props.sme.current?.emailAddress.toLocaleLowerCase().indexOf('hpe.com') !== -1;
    }

    isAdmin = () => {
        return hasRole(this.props.site.user, ['SME_ADMIN']);
    }

    displayResults = () => {
        this.setState({ showResults: true })
    }

    onSave = (sme: any) => {
        this.props.save(sme, this.onSaveCallback);
    }

    onSaveCallback = () => {
        let site = this.props.site;
        let user = site.user;
        let sme = this.state.sme;
        let isMyApplication = user?.email == sme?.emailAddress;

        this.setState({ showResults: isMyApplication, sme: this.props.sme.current, agreementDownloaded: false })
    }

    onUploadCallback = (sme: SubjectMaterExpert) => {
        this.setState({ sme: sme });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let title = site.menuStrings['ExternalPages.SME_Applicant'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: localUrl },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: "" }
        ];
        let sme = this.state.sme;
        let isSmeAdmin = this.isAdmin();
        let isEmployee = this.isEmployee();
        let isMyApplication = user?.email == sme?.emailAddress;
        let isWritable = isMyApplication || !isSmeAdmin || this.state.isNew;
        let canSubmit = (sme?.jobTitle && sme?.jobRole) && (isEmployee || (!isEmployee && sme?.dateCdaSigned));
        let cdaRequired = true;

        if (!user) {
            return null;
        }
        if (sme?.agreementEndDate || sme?.dateCdaSigned) {
            let currentDate = new Date();
            let dateSigned = sme?.dateCdaSigned ? parseJSON(sme?.dateCdaSigned) : null;
            let lastSigned = this.props.sme.current?.dateCdaSigned ? parseJSON(this.props.sme.current?.dateCdaSigned) : null
            let agreementEndDate = sme?.agreementEndDate
                ? parseJSON(sme?.agreementEndDate)
                : dateSigned
                    ? add(dateSigned, { years: 1 })
                    : null;

            cdaRequired = dateSigned === null || agreementEndDate === null || (dateSigned.getDate() !== lastSigned?.getDate()) ||
                agreementEndDate <= currentDate;
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="SME_Application" {...this.props} >
            <Form value={sme} onSubmit={() => { this.onSave(this.state.sme) }} onChange={nextValue => this.setState({ sme: nextValue })}>
                <Box fill gap="small" pad={{ top: "small", bottom: "small" }} >
                    {!isMyApplication && isSmeAdmin && <Box gap="small" fill="horizontal">
                        <Box gap="small">
                            <Box direction="row" gap="small">
                                <Text weight="bold" >Last Modified:</Text>
                                <Text>{sme?.dateLastUpdated ? format(parseJSON(sme.dateLastUpdated), "dd MMM yyyy hh:mm:ss aa") : ''}</Text>
                            </Box>
                            <Box direction="row" gap="small">
                                <Text weight="bold" >Last Modified By: </Text>
                                <Text>{sme?.lastUpdatedBy}</Text>
                            </Box>
                        </Box>
                        <ThemeContext.Extend value={{ checkBox: { size: "20px", gap: "small", pad: "none" } }} >
                            <Box width='small'>
                                <CheckBox label="Blocked" id="blocked-input" name="blocked" checked={sme?.blocked}
                                    onChange={(event) => this.onBlocked(event.target.checked)} />
                            </Box>
                        </ThemeContext.Extend>
                        <FormField name="adminComments" htmlFor="adminComments-input" label={<Text weight="bold">Admin Notes</Text>} >
                            <TextArea fill id="adminComments-input" name="adminComments" ></TextArea>
                        </FormField>
                        <Box direction="row" gap="small">
                            <Button type="submit" primary disabled={!this.isDirty()} label='Submit changes' />
                            <Button secondary label='Search' onClick={() => { this.props.push(`/sme/admin`); }} />
                        </Box>
                        <Box background="brand" fill border={{ side: 'all', color: "brand", size: "small" }} />
                    </Box>}
                    {isWritable && <Box height="300px" justify="center"
                        background={{ size: "cover", image: "url('images/sme/HPE_data_particles_01_1600_0_72_RGB.jpg')" }} >
                        <Box width={{ max: "large" }} background={{ color: "#FFFFFF", opacity: "strong" }} gap="small" margin={{ left: "medium" }} pad="small">
                            <Heading level={4}>Subject Matter Expert Application</Heading>
                            <Paragraph fill>We appreciate your interest in activities related to Certification and Learning course and exam development. By
                            filling out this application, you may be invited to participate in Job Task Analysis, Item writing workshops, Beta Training or Exam
                                Technical Reviews.</Paragraph>
                        </Box>
                    </Box>}
                    <Box gap="xsmall">
                        <Box direction="row" gap="small">
                            <Text weight="bold" >Name:</Text>
                            <Text weight="bold">{`${this.state.sme?.firstName} ${this.state.sme?.lastName}`}</Text>
                        </Box>
                        <Box direction="row" gap="small">
                            <Text weight="bold" >HPE Learner ID: </Text>
                            <Text weight="bold" >{sme?.learnerId}</Text>
                        </Box>
                    </Box>
                    <Box gap="small" fill >
                        <Box gap="large" direction='row'>
                            <FormField fill name="jobTitle" htmlFor="jobTitle-input" required label={<Text weight="bold">Title</Text>}  >
                                <TextInput id="jobTitle-input" name="jobTitle" disabled={!isWritable}
                                    placeholder="e.g., Solution Architect, Systems Engineer, Enterprise Architect, Director, etc." />
                            </FormField>
                            <FormField fill name="jobRole" htmlFor="jobRole-input" required label={<Text weight="bold">Role</Text>}  >
                                <TextInput id="jobRole-input" name="jobRole" placeholder="e.g., presales, sales, administration, support, etc."
                                    disabled={!isWritable} />
                            </FormField>
                        </Box>
                        <Box gap="xsmall" fill >
                            <Text weight="bold">Years in current role</Text>
                            <Box gap="large" direction='row'>
                                <Box gap="xxsmall" width={{ max: 'medium' }}>
                                    <RadioButton name="yearsInCurrentRole" value="No additional experience" label="No additional experience"
                                        checked={sme?.yearsInCurrentRole === "No additional experience"} disabled={!isWritable}
                                        onChange={this.onyearsInCurrentRoleChange} />
                                    <RadioButton name="yearsInCurrentRole" value="Less than 2 years" label="Less than 2 years"
                                        checked={sme?.yearsInCurrentRole === "Less than 2 years"} disabled={!isWritable}
                                        onChange={this.onyearsInCurrentRoleChange} />
                                    <RadioButton name="yearsInCurrentRole" value="2 – 4 years" label="2 – 4 years"
                                        checked={sme?.yearsInCurrentRole === "2 – 4 years"} disabled={!isWritable}
                                        onChange={this.onyearsInCurrentRoleChange} />
                                </Box>
                                <Box gap="xxsmall" width={{ max: 'medium' }}>
                                    <RadioButton name="yearsInCurrentRole" value="5 - 10 years" label="5 - 10 years"
                                        checked={sme?.yearsInCurrentRole === "5 - 10 years"} disabled={!isWritable}
                                        onChange={this.onyearsInCurrentRoleChange} />
                                    <RadioButton name="yearsInCurrentRole" value="More than 10 years" label="More than 10 years"
                                        checked={sme?.yearsInCurrentRole === "More than 10 years"} disabled={!isWritable}
                                        onChange={this.onyearsInCurrentRoleChange} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box background="black" fill border={{ side: 'all', color: "black", size: "small" }}></Box>
                    <Box direction="row" gap="medium" fill>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell width="medium"><Text weight="bold">Area of Expertise</Text></TableCell>
                                    <TableCell fill><Text weight="bold">Level of Experience</Text></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width="medium"></TableCell>
                                    <TableCell fill>
                                        <Box direction="row" gap="small" justify="center" fill>
                                            <Box width={{ min: "32px" }} align='cetner'>
                                                <Text textAlign='center'>0</Text>
                                            </Box>
                                            <Box width={{ min: "32px" }}>
                                                <Text>1</Text>
                                            </Box>
                                            <Box width={{ min: "32px" }}>
                                                <Text>2</Text>
                                            </Box>
                                            <Box width={{ min: "32px" }}>
                                                <Text>3</Text>
                                            </Box>
                                            <Box width={{ min: "32px" }}>
                                                <Text>4</Text>
                                            </Box>
                                            <Box width={{ min: "32px" }}>
                                                <Text>5</Text>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {_.map(this.state.areasOfExpertise, (area, ndx) => <TableRow key={`area-of-expertise-${ndx}`}>
                                    <TableCell width="medium">
                                        <Text>{area.name}</Text>
                                    </TableCell>
                                    <TableCell fill>
                                        <Box direction="row" gap="xsmall" justify="center" fill>
                                            <RadioButton name={`area-of-expertise-${ndx}-level`} value={0} checked={this.hasExperience(area.name, 0)}
                                                onChange={event => { this.onExperienceChange(area.name, 0) }} disabled={!isWritable} />
                                            <RadioButton name={`area-of-expertise-${ndx}-level`} value={1} checked={this.hasExperience(area.name, 1)}
                                                onChange={event => { this.onExperienceChange(area.name, 1) }} disabled={!isWritable} />
                                            <RadioButton name={`area-of-expertise-${ndx}-level`} value={2} checked={this.hasExperience(area.name, 2)}
                                                onChange={event => { this.onExperienceChange(area.name, 2) }} disabled={!isWritable} />
                                            <RadioButton name={`area-of-expertise-${ndx}-level`} value={3} checked={this.hasExperience(area.name, 3)}
                                                onChange={event => { this.onExperienceChange(area.name, 3) }} disabled={!isWritable} />
                                            <RadioButton name={`area-of-expertise-${ndx}-level`} value={4} checked={this.hasExperience(area.name, 4)}
                                                onChange={event => { this.onExperienceChange(area.name, 4) }} disabled={!isWritable} />
                                            <RadioButton name={`area-of-expertise-${ndx}-level`} value={5} checked={this.hasExperience(area.name, 5)}
                                                onChange={event => { this.onExperienceChange(area.name, 5) }} disabled={!isWritable} />
                                        </Box>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </Table>
                        <Box fill pad={{ top: "small" }}>
                            <Text weight="bold">0: No experience</Text>
                            <Text weight="bold">1: Very limited</Text>
                            <Text weight="bold">2: HPE ATP / HPE Aruba Associate or equivalent</Text>
                            <Text weight="bold">3: HPE ASE / HPE Aruba Professional or equivalent</Text>
                            <Text weight="bold">4: HPE Master ASE / HPE Aruba Expert or equivalent</Text>
                            <Text weight="bold">5: Beyond HPE Master ASE / HPE Aruba Expert</Text>
                        </Box>
                    </Box>
                    <Box fill >
                        <FormField name="otherExperience" htmlFor="otherExperience-input" label={<Text weight="bold">Other experience</Text>} >
                            <TextArea fill id="otherExperience-input" name="otherExperience" disabled={!isWritable} />
                        </FormField>
                    </Box>
                    <Box gap="xsmall" fill >
                        <Text weight="bold">Years of other HPE expertise or experience</Text>
                        <Box gap="large" direction='row'>
                            <Box gap="xxsmall" width={{ max: 'medium' }}>
                                <RadioButton name="yearsOfExperience" value="No additional experience" label="No additional experience"
                                    checked={sme?.yearsOfExperience === "No additional experience"}
                                    onChange={this.onyearsOfExperienceChange} disabled={!isWritable} />
                                <RadioButton name="yearsOfExperience" value="Less than 2 years" label="Less than 2 years"
                                    checked={sme?.yearsOfExperience === "Less than 2 years"}
                                    onChange={this.onyearsOfExperienceChange} disabled={!isWritable} />
                                <RadioButton name="yearsOfExperience" value="2 – 4 years" label="2 – 4 years"
                                    checked={sme?.yearsOfExperience === "2 – 4 years"}
                                    onChange={this.onyearsOfExperienceChange} disabled={!isWritable} />
                            </Box>
                            <Box gap="xxsmall" width={{ max: 'medium' }}>
                                <RadioButton name="yearsOfExperience" value="5 - 10 years" label="5 - 10 years"
                                    checked={sme?.yearsOfExperience === "5 - 10 years"}
                                    onChange={this.onyearsOfExperienceChange} disabled={!isWritable} />
                                <RadioButton name="yearsOfExperience" value="More than 10 years" label="More than 10 years"
                                    checked={sme?.yearsOfExperience === "More than 10 years"}
                                    onChange={this.onyearsOfExperienceChange} disabled={!isWritable} />
                            </Box>
                        </Box>
                    </Box>
                    <Box background="black" fill border={{ side: 'all', color: "black", size: "small" }}></Box>
                    <Box gap="medium" pad="small" fill >
                        <FormField name="thirdPartyCredentials" htmlFor="thirdPartyCredentials-input"
                            label={<Text weight="bold">Third Party Credentials</Text>} >
                            <TextArea fill id="thirdPartyCredentials-input" name="thirdPartyCredentials" disabled={!isWritable} />
                        </FormField>
                        <FormField name="workshops" htmlFor="workshops-input"
                            label={<Text weight="bold">SME Workshops or Events</Text>} >
                            <TextArea fill id="workshops-input" name="workshops" disabled={!isWritable} />
                        </FormField>
                    </Box>
                    <Box background="black" fill border={{ side: 'all', color: "black", size: "small" }} />
                    <Box gap="small" fill >
                        <Box gap="xxsmall">
                            <Text size="large" weight="bold">Do you wish to participate in reviewing exams that have been translated?</Text>
                            <Text>(To answer yes, check any language you would like to review translated)</Text>
                        </Box>
                        <Box direction='row' fill='horizontal' gap='large'>
                            <Box gap="xxsmall" width={{ max: 'medium' }}>
                                <CheckBox label="Brazilian Portuguese" checked={this.hasLanguage("Brazilian Portuguese")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("Brazilian Portuguese", event.target.checked)} />
                                <CheckBox label="Chinese" checked={this.hasLanguage("Chinese")} disabled={!isWritable}
                                    onChange={event => this.onLanguageSelected("Chinese", event.target.checked)} />
                                <CheckBox label="French" checked={this.hasLanguage("French")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("French", event.target.checked)} />
                                <CheckBox label="German" checked={this.hasLanguage("German")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("German", event.target.checked)} />
                                <CheckBox label="Italian" checked={this.hasLanguage("Italian")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("Italian", event.target.checked)} />
                            </Box>
                            <Box gap="xxsmall" width={{ max: 'medium' }}>
                                <CheckBox label="Japanese" checked={this.hasLanguage("Japanese")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("Japanese", event.target.checked)} />
                                <CheckBox label="Korean" checked={this.hasLanguage("Korean")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("Korean", event.target.checked)} />
                                <CheckBox label="Russian" checked={this.hasLanguage("Russian")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("Russian", event.target.checked)} />
                                <CheckBox label="Spanish" checked={this.hasLanguage("Spanish")} disabled={!isWritable}
                                    onChange={(event) => this.onLanguageSelected("Spanish", event.target.checked)} />
                            </Box>
                        </Box>
                    </Box>
                    <Box background="black" fill border={{ side: 'all', color: "black", size: "small" }} />
                    <Box gap="medium" fill >
                        <FormField name="comments" htmlFor="comments-input" label={<Text weight="bold">Questions or Comments</Text>} >
                            <TextArea fill id="comments-input" name="comments" disabled={!isWritable} />
                        </FormField>
                        {isWritable && <Box gap="medium">
                            <Box background="black" fill border={{ side: 'all', color: "black", size: "small" }} margin={{ bottom: 'small' }} />
                            {(sme?.id === EmptyGuid) && <Text weight='bold'>To submit application, you must:</Text>}
                            {!isEmployee && cdaRequired && <Box gap="medium">
                                <Box direction="row" gap="small" align='center'>
                                    <Box round background="brand" width={{ min: "48px" }} height={{ min: "48px" }} align='center' justify='center'>
                                        <Text weight="bold" color="white">1</Text>
                                    </Box>
                                    <Box width='500px'>
                                        <Paragraph fill>Review and sign the HPE Confidential Disclosure Agreement</Paragraph>
                                    </Box>
                                    <Button primary color="brand" label='Download CDA' onClick={this.onDownloadAgreement}
                                        disabled={!sme?.jobTitle} />
                                </Box>
                                <Box direction="row" gap="small" align='center'>
                                    <Box round background="brand" width={{ min: "48px" }} height={{ min: "48px" }} align='center' justify='center'>
                                        <Text weight="bold" color="white">2</Text>
                                    </Box>
                                    <Box width='500px'>
                                        <Paragraph fill>Upload the signed HPE Confidential Disclosure Agreement</Paragraph>
                                    </Box>
                                    <Button primary color="brand" label='Upload CDA' onClick={this.onUploadAgreement} disabled={!this.state.agreementDownloaded} />
                                    <input id="cda-import" ref={this.cdaInputRef} type="file" name="importFile" onChange={this.onFileSelected}
                                        multiple={false} hidden accept=".pdf" />
                                </Box>
                            </Box>}
                            <Box direction="row" gap="small" align='center'>
                                {!isEmployee && cdaRequired && <Box direction="row" gap="small" align='center'>
                                    <Box round background="brand" width={{ min: "48px" }} height={{ min: "48px" }} align='center' justify='center'>
                                        <Text weight="bold" color="white">3</Text>
                                    </Box>
                                    <Box width='500px'>
                                        <Paragraph fill>Agree that the HPE Partner Ready Certification and Learning team may
                                    contact me regarding participation in course and exam development sessions.</Paragraph>
                                    </Box>
                                </Box>}
                                <Box width={{ max: "450px" }} >
                                    {sme?.id === EmptyGuid && <Button type="submit" primary disabled={!this.isDirty() || !canSubmit}
                                        label='Agree and submit application' />}
                                    {sme && sme?.id !== EmptyGuid && <Button type="submit" primary disabled={!this.isDirty() || !canSubmit} label='Submit changes' />}
                                </Box>
                            </Box>
                        </Box>}
                    </Box>
                </Box>
            </Form>
            {this.state.showResults && <Layer>
                <Box gap="small">
                    <Header pad='xsmall' fill>
                        <Box gap='xxsmall' fill pad='small'>
                            <Heading level={4} textAlign="center" fill>Certification and Learning - Subject Matter Expert (SME)</Heading>
                            <Heading level={5} fill>
                                {this.state.isNew
                                    ? "Application acknowledgement"
                                    : "SME profile update acknowledgement"}
                            </Heading>
                        </Box>
                    </Header>
                    <Box direction='row' gap="small" pad='small'>
                        <Paragraph>
                            {this.state.isNew
                                ? "We appreciate your interest in Certification and Learning course and exam development. By filling" +
                                " out this application, we may contact you if your profile matches our requirements for a specific project."
                                : "Thank you for updating your SME profile. We appreciate your willingness in assisting the HPE " +
                                "certification team with exam and course development."}
                        </Paragraph>
                        <Image fit="contain" src="images/sme/HPE201910311833_450_0_72_RGB.jpg" />
                    </Box>
                    <Footer fill="horizontal" pad="small" justify="center">
                        <Box width="xsmall" >
                            <Button primary color="brand" label="OK" onClick={() => { this.setState({ showResults: false, isNew: false }) }} />
                        </Box>
                    </Footer>
                </Box>
            </Layer>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(SmeApplication as any));
