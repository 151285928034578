import React from 'react';
import { connect } from 'react-redux';
import {
    Box, Text, Grid, Paragraph, Tab, Tabs, Card, CardBody, CardHeader, Image, CardFooter, Anchor,
    Select, Heading
} from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { retrieveLearningPartners, learningPartnerSlice } from '../../store/LearningPartner';
import parse from 'html-react-parser';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { KeyValuePair, SelectOption } from '../../models/Common';
import BasePage from '../../components/BasePage';
import _ from 'lodash';
import { FormCheckmark, FormClose } from 'grommet-icons';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveLearningPartners,
    ...siteSlice.actions,
    ...learningPartnerSlice.actions
}

interface LearningPartnersState {
    firstLoad: boolean;
    activeTab: number;
    tabs: string[];
    country: any | undefined;
    countries: SelectOption[];
}

type LearningPartnersProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class LearningPartners extends React.PureComponent<LearningPartnersProps, LearningPartnersState> {
    constructor(props: LearningPartnersProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.TrainingPartner_Statement', 'ExternalPages.Geography_Country_Label',
            'ExternalPages.SalesTraining_Label', 'ExternalPages.TechnicalTraining_Label', 'ExternalPages.TrainingPartner_Label', 'Datacard.Resources_Label',
            'ExternalPages.TrainingCompany_Label', 'ExternalPages.Yes_Label', 'ExternalPages.No_Label', 'Metadata.TraningPartnersDescription',
            'Metadata.TraningPartnersKeywords', 'ExternalPages.TrainingPartner_Banner_Statement', 'ExternalPages.SelectRegionLabel',
            'ExternalPages.SelectCountryLabel', 'ExternalPages.TrainingPartner_Statement2', 'ExternalPages.LearningPartners_Link1', 
            'ExternalPages.LearningPartners_Link2'];

        this.state = {
            firstLoad: urlLanguage !== null,
            activeTab: 0,
            tabs: [],
            country: '',
            countries: []
        };
        this.props.setActiveMenu('training-menu');
        this.props.retrieveLocalizedStrings(keys, urlLanguage);
        this.props.retrieveLearningPartners(urlLanguage);
    }

    public componentDidMount() {
    }

    public componentDidUpdate(prevProps: LearningPartnersProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;

        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            if (!this.state.firstLoad) {
                this.props.retrieveLearningPartners(urlLanguage);
            }
            this.setState({ firstLoad: false });
        }
    }

    onActivateTab = (activeTab: number) => {
        this.setState({ country: '', activeTab: activeTab });
    }

    onCountrySelected = (event: any) => {
        let selected = event.value;
        let region = this.props.partners.data?.geographies.find((geography) => _.some(geography.countries, (country: any) => country.text === selected.text));
        let tabIndex = this.state.tabs.findIndex((tabTitle) => tabTitle === region?.text);

        this.setState({ country: selected, activeTab: tabIndex == -1 ? 0 : tabIndex });
    }

    onCountrySearch = (searchText: string) => {
        let regexp = new RegExp(searchText, 'i');
        let countries = _.flatMap(this.props.partners.data?.geographies, (geography) => geography.countries)
            .sort((l: any, r: any) => l.text.localeCompare(r.text));
        let filtered = _.filter(countries, country => regexp.test(country.text));

        this.setState({ countries: filtered });
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let title = site.menuStrings['ExternalPages.TrainingPartner_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Training_Label'], value: "" },
            { key: title, value: `${localUrl}/learningpartners` }
        ];
        let geographyGroups = _.groupBy(this.props.partners.data?.partners, (partner) => { return [partner.geography] });
        let partnerIndex = 0;
        let countries = _.uniq(_.flatMap(this.props.partners.data?.geographies, (geography) => geography.countries))
            .sort((l: any, r: any) => l.text.localeCompare(r.text));
        let tabNames = _.map(geographyGroups, (partners, key) => key);

        if ((countries.length !== 0) && (this.state.countries.length === 0)) {
            this.setState({ countries: countries });
        }
        if ((tabNames.length !== 0) && (this.state.tabs.length === 0)) {
            this.setState({ tabs: tabNames });
        }

        return <BasePage breadcrumbs={breadcrumbs} title={title} metadataDescription={site.localizedStrings['Metadata.TraningPartnersDescription']}
            metadataKeywords={site.localizedStrings['Metadata.TraningPartnersKeywords']} pageName="learningPartners_index" {...this.props}>
            {site.stringsLoaded && <Box fill gap="large">
                <Box direction="row-responsive" fill="horizontal" gap="medium" pad={{ top: "medium", left: "small" }}>
                    <Box fill pad="small" gap="medium" alignSelf="center">
                        <Box fill pad="small" gap="small">
                            <Heading level={2} >{(title ?? "")}</Heading>
                            <Paragraph fill>
                                {parse(site.localizedStrings['ExternalPages.TrainingPartner_Statement'])}
                            </Paragraph>
                            <Anchor href='https://community.hpe.com/t5/advancing-life-work/how-award-winning-hpe-learning-partners-bring-learning-to-life/ba-p/7219272'
                                target='_blank'>{site.localizedStrings['ExternalPages.LearningPartners_Link1']}</Anchor>
                            <Anchor href='https://community.hpe.com/t5/advancing-life-work/hpe-learning-partners-share-how-to-make-the-most-of/ba-p/7196896'
                                target='_blank'>{site.localizedStrings['ExternalPages.LearningPartners_Link2']}</Anchor>
                        </Box>
                        <Box fill pad="small" gap="xsmall">
                            <Paragraph fill>
                                {parse(site.localizedStrings['ExternalPages.TrainingPartner_Statement2'])}
                            </Paragraph>
                            <Box direction="row-responsive" gap="small" width="medium">
                                <Select options={this.state.countries} value={this.state.country} labelKey="text" valueKey="value" dropHeight="medium"
                                    onSearch={this.onCountrySearch} clear={true}
                                    onChange={this.onCountrySelected} placeholder={site.localizedStrings['ExternalPages.SelectCountryLabel']} />
                            </Box>
                        </Box>
                    </Box>
                    <Box fill>
                        <Image src='images/learning-partners/HPE202208310071529_1600_0_72_RGB.jpg'></Image>
                    </Box>
                </Box>
                {(tabNames.length !== 0) && <Box>
                    <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="start">
                        {
                            _.map(geographyGroups, (partners, key) => {
                                return <Tab title={key} key={`partner-${partnerIndex++}`}>
                                    <Box margin="small">
                                        <Grid gap={{ row: "small", column: "small" }} rows="auto" columns="medium">
                                            {
                                                _.map(partners.sort((l, r) => { return l.name.localeCompare(r.name); }), (partner, ndx) => {
                                                    return <Card key={`partner-${ndx}`}>
                                                        <CardHeader background="#263040" pad="small" height="xxsmall" justify="center">
                                                            <Text weight="bold" size="small">{partner.name}</Text>
                                                        </CardHeader>
                                                        <CardBody>
                                                            {
                                                                _.map(partner.countries, (country, cdx) => {
                                                                    return <Box direction="row" key={`partner-${ndx}-country-${cdx}`} gap="small" background={cdx % 2 !== 0 ? "#EFEFEF" : "none"} justify="between">
                                                                        <Box width={{ min: "xsmall" }}>
                                                                            <Text weight="bold">{country.name}</Text>
                                                                        </Box>
                                                                        <Box alignSelf="end" >
                                                                            <Box justify="start" direction="row" >
                                                                                {country.salesTraining ? <FormCheckmark color="brand" /> : <FormClose color="#C54E4B" />}
                                                                                <Text>{site.localizedStrings['ExternalPages.SalesTraining_Label']}</Text>
                                                                            </Box>
                                                                            <Box justify="start" direction="row" >
                                                                                {country.technicalTraining ? <FormCheckmark color="brand" /> : <FormClose color="#C54E4B" />}
                                                                                <Text>{site.localizedStrings['ExternalPages.TechnicalTraining_Label']}</Text>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                })
                                                            }
                                                        </CardBody>
                                                        <CardFooter justify="center" background="#F5F5F5">
                                                            {partner.hasCourses
                                                                ? <Anchor href={`${localUrl}/TrainingCalendar?trainingcompany=${encodeURIComponent(partner.name)}&regions=${encodeURIComponent(key)}`} label="View available courses" />
                                                                : <Text>No courses available</Text>
                                                            }
                                                        </CardFooter>
                                                    </Card>
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </Tab>;
                            })
                        }
                    </Tabs>
                </Box>}
            </Box>
            }
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(LearningPartners as any));
