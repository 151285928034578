import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { EntityFilter } from '../models/EntityList';
import { setLoading, setError } from './Site';
import { updateList } from '../Utilities'
import { SelectOption, EntityInfo, AllOption, AllGuidOption, EmptyGuid } from '../models/Common'

export interface CredentialsState {
    entityType: string,
    languageCode: string,
    countryCode: string,
    metadataDescription: string,
    metadataKeywords: string,
    view: string,
    keywords: string,
    lauguage?: string,
    status: string,
    views: SelectOption[];
    types: SelectOption[];
    technologies: SelectOption[];
    languages: SelectOption[];
    levels: SelectOption[];
    entities: EntityInfo[];
}

let initialState: CredentialsState = {
    entityType: '',
    languageCode: 'US',
    countryCode: 'en',
    metadataDescription: '',
    metadataKeywords: '',
    view: 'all',
    keywords: '',
    lauguage: undefined,
    status: 'Active',
    views: [AllOption],
    types: [AllGuidOption],
    technologies: [AllGuidOption],
    languages: [AllGuidOption],
    levels: [AllGuidOption],
    entities: []
}

export const credentialsSlice = createSlice({
    name: 'Credentials',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.entities = action.payload;
        },
        setCredentialTechnologies: (state, action) => {
            state.technologies = action.payload;
        },
        setCredentialLevels: (state, action) => {
            state.levels = action.payload;
        },
        setCredentialLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setCredentialTypes: (state, action) => {
            state.types = action.payload;
        },
        setCredentialViews: (state, action) => {
            state.views = action.payload;
        },
        setCredentialSelected: (state, action) => {
            let values: string[] = action.payload.split('-');
            let index = parseInt(values[1]);
            let checked = values[2] === 'true';

            switch (values[0]) {
                case 'views': {
                    state.views = updateList(state.views, index, checked, false);
                    break;
                }
                case 'types': {
                    state.types = updateList(state.types, index, checked);
                    break;
                }
                case 'languages': {
                    state.languages = updateList(state.languages, index, checked);
                    break;
                }
                case 'technologies': {
                    state.technologies = updateList(state.technologies, index, checked);
                    break;
                }
                case 'levels': {
                    state.levels = updateList(state.levels, index, checked);
                    break;
                }
            }
        },
        setCredentialView: (state, action) => {
            state.view = action.payload;
        },
        setCredentialStatus: (state, action) => {
            state.status = action.payload;
        },
        setCredentialKeywords: (state, action) => {
            state.keywords = action.payload;
        }
    }
});

export const { setCredentials, setCredentialSelected, setCredentialView, setCredentialKeywords, setCredentialTechnologies, setCredentialLevels,
    setCredentialLanguages, setCredentialTypes, setCredentialViews, setCredentialStatus } = credentialsSlice.actions;

export const retrieveCredentials = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let state = getState();
    let credentials = state.credentials;
    let request: EntityFilter = {
        types: [EmptyGuid],
        languages: [EmptyGuid],
        technologies: [EmptyGuid],
        view: credentials.view,
        status: credentials.status,
        keywords: credentials.keywords,
        language: language
    }

    dispatch(setLoading(true));
    fetch('api/credentials/list', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCredentials(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveTechnologies = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/credentials/technologies', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCredentialTechnologies(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveTypes = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/credentials/types', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCredentialTypes(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveViews = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    fetch('api/credentials/views', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setCredentialViews(data));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}
export default credentialsSlice.reducer;
