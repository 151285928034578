import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { UserClaims } from '@okta/okta-auth-js';
import { User } from '../models/User';
import { SelectOption } from '../models/Common';
import _ from 'lodash';

export interface ICallback {
    name: string;
    func: Function;
}

export interface SiteState {
    loading: boolean;
    error?: string;
    message?: string;
    currentLanguage: SelectOption;
    availableLanguages: SelectOption[];
    menuStrings: any;
    localizedStrings: any;
    menusLoaded: boolean;
    stringsLoaded: boolean;
    options: any;
    loadingUser: boolean;
    activeMenu?: string;
    user?: User;
    actualUser?: User;
    userInfo?: UserClaims;
    acknowledgedCallback?: Function;
    page: string;
}

let initialState: SiteState = {
    loading: false,
    currentLanguage: { text: 'English', value: 'en-US', selected: true },
    availableLanguages: [],
    menuStrings: {},
    localizedStrings: {},
    menusLoaded: false,
    loadingUser: false,
    stringsLoaded: false,
    options: {},
    activeMenu: '',
    page: ''
}

export const siteSlice = createSlice({
    name: 'Site',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setCurrentLanguage: (state, action) => {
            state.currentLanguage = action.payload;
        },
        setAvailableLanguages: (state, action) => {
            state.availableLanguages = action.payload;
        },
        setMenuStrings: (state, action) => {
            state.menuStrings = action.payload;
        },
        setLocalizedStrings: (state, action) => {
            state.localizedStrings = action.payload;
        },
        setOptions: (state, action) => {
            state.options[action.payload.category] = action.payload.data;
        },
        setMenusLoaded: (state, action) => {
            state.menusLoaded = action.payload;
        },
        setStringsLoaded: (state, action) => {
            state.stringsLoaded = action.payload;
        },
        setActiveMenu: (state, action) => {
            state.activeMenu = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setLoadingUser: (state, action) => {
            state.loadingUser = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
            console.log(`userInfo; ${JSON.stringify(action.payload)}`);
        },
        setAcknowledgedCallback: (state, action) => {
            state.acknowledgedCallback = action.payload;
        },
        setActualUser: (state, action) => {
            state.actualUser = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        }
    }
});

export const { setLoading, setError, setMessage, setCurrentLanguage, setAvailableLanguages, setMenuStrings,
    setLocalizedStrings, setOptions, setMenusLoaded, setStringsLoaded, setActiveMenu, setUserInfo, setUser,
    setLoadingUser, setAcknowledgedCallback, setActualUser, setPage } = siteSlice.actions;

export const login = (loginInfo: UserClaims): AppThunk => async (dispatch, getState) => {
    let url = 'api/user/login';
    let state = getState();
    let request = {
        email: loginInfo.email,
        firstName: loginInfo.given_name,
        lastName: loginInfo.family_name
    }

    if (state.site.loadingUser) {
        return;
    }
    dispatch(setLoadingUser(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => res.json())
        .then(data => {
            if (!data.statusCode) {
                dispatch(setUser(data));
            }
            dispatch(setLoadingUser(false));
        }).catch(err => {
            dispatch(setLoadingUser(false));
            dispatch(setError(`${err}`));
        });
}

export const retrieveAvailableLanguages = (urlLanguage: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/items/languages';
    let state = getState();

    fetch(url, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        let selected = _.find(data, (lang: any) => _.startsWith(lang.value, urlLanguage));

        dispatch(setAvailableLanguages(data));
        if (selected && state.site.currentLanguage.value !== selected.value) {
            dispatch(setCurrentLanguage(selected));
        }
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveLocalizedStrings = (keys: string[], lang: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/resource/getstrings';

    dispatch(setStringsLoaded(false));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({ keys: keys, language: lang }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setLocalizedStrings(data));
        dispatch(setStringsLoaded(true));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveMenuStrings = (keys: string[], lang: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/resource/getstrings';

    dispatch(setMenusLoaded(false));
    fetch(url, {
        method: "POST",
        body: JSON.stringify({ keys: keys, language: lang }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setMenuStrings(data));
        dispatch(setMenusLoaded(true));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const retrieveOptions = (category: string, language?: string, callback?: Function): AppThunk => async (dispatch, getState) => {
    let url = 'api/items/list';

    fetch(url, {
        method: "POST",
        body: JSON.stringify({ category: category, language: language ? language : null }),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        if (callback) {
            callback(data);
        }
        else {
            dispatch(setOptions({ category: category, data: data }));
        }
    }).catch(err => {
        dispatch(setError(err.message ?? err));
    });
}

export const submitSupportForm = (form: any, callback: any): AppThunk => async (dispatch, getState) => {
    let serverUrl = 'api/support/submitForm';

    dispatch(setLoading(true));
    fetch(serverUrl, {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(data => {
        dispatch(setLoading(false));
        if (callback) {
            callback();
        }
    }).catch((err: any) => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const submitForm = (url: string, form: any, callback: any): AppThunk => async (dispatch, getState) => {
    var formData = new FormData();

    dispatch(setLoading(true));
    Object.keys(form).forEach(key => {
        formData.append(key, form[key]);
    });

    fetch(url, {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: formData
    }).then(() => {
        dispatch(setLoading(false));
        if (callback) {
            callback();
        }
    }).catch((err: any) => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    }).then(() => {
        if (callback) {
            callback();
        }
    });
}

export const switchUser = (email: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/user/login';
    let state = getState();
    let request = {
        email: email
    }

    if (state.site.loadingUser) {
        return;
    }
    dispatch(setLoadingUser(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return res.json();
    })
        .then(data => {
            if (data.statusCode || data.email === null) {
                throw new Error('Profile does not have authorization for this site.');
            }
            dispatch(setUser(data));
            dispatch(setLoadingUser(false));
        }).catch(err => {
            dispatch(setLoadingUser(false));
            dispatch(setError(`${err}`));
        });
}

export default siteSlice.reducer;
