import _ from 'lodash';
import { SelectOption } from './models/Common';
import { User } from './models/User';
import { Audience } from './models/Audience';

export interface HPEWindowInterface extends Window {
    digitalData: any;
    textMetrics: any;
}

declare let window: HPEWindowInterface;

export const formatString = (format: string, params: string[]) => {
    let results = format;

    if (results) {
        params.forEach((param, index) => {
            let patt = new RegExp("\\{" + index + "\\}", "g");

            results = results.replace(patt, param);
        });
    }
    return results ?? "";
}

export const loadScript = (scriptId: string, url: string, force: boolean = false, async?: boolean, callback?: any) => {
    if (force) {
        jQuery(`#${scriptId}`).remove();
    }

    let existingScript = document.getElementById(scriptId);

    if (!existingScript) {
        const script = document.createElement('script');

        if (async) {
            script.async = true;
        }
        script.src = url; // URL for the third-party library being loaded.
        script.id = scriptId; // e.g., googleMaps or stripe
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) {
                callback();
            }
        };
    }

    if (existingScript && callback) {
        callback();
    }
}

export const setupMetrics = (page: string, lc: string, cc: string) => {
    window.digitalData.page.pageInfo.breadCrumbs = ["v2.0", cc, lc, "gw", `cmpgn:certificationlearning:${page}`];

    loadScript('hfScript', `https://h50007.www5.hpe.com/hfws/${cc}/${lc}/hpe/latest.r?contentType=js&switchToCHF=true&hide_sign_in=true`, true);
    loadScript('metricsScript', 'https://www.hpe.com/global/metrics/bootstrap/prod.js');
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export const getObjectDiff = (obj1: any, obj2: any) => {
    let diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
            let resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
        }
        return result;
    }, Object.keys(obj2));

    return diff;
}

export const updateList = (originalList: SelectOption[], index: Number, checked: boolean, hasAll: boolean = true, exclusive: boolean = false) => {
    let list = JSON.stringify(originalList);
    let newList: SelectOption[] = JSON.parse(list);

    if (hasAll) {
        if (index === 0) {
            newList.forEach((item) => {
                item.selected = checked;
            });
        }
        else {
            let found = newList.find((item, ndx) => ndx === index);

            if (found) {
                found.selected = checked;
            }
            let allSelected = newList.slice(1).filter((item, ndx) => item.selected);

            newList[0].selected = allSelected.length === (newList.length - 1);
        }
    }
    else {
        let found = newList.find((item, ndx) => ndx === index);

        if (found) {
            found.selected = checked;
        }
    }
    return newList;
}

export const hasRole = (user: User | undefined, roles: string[]) => {
    return _.some(user?.audiences, (audience: Audience) => _.some(roles, (code) => code == audience.code));
}

export const isPLMTAdmin = (user: User | undefined) => {
    return _.some(user?.audiences, (audience: Audience) => audience.code.indexOf("PLMT_ADMIN") !== -1);
}

export const getPLMTAdminRegions = (user: User | undefined) => {
    let audiences = _.filter(user?.audiences, (audience: Audience) => audience.code.indexOf("PLMT_ADMIN") !== -1);

    return _.map(audiences, (audience: Audience) => {
        let info = audience.code.split("-");

        return info[1].replace('NA', 'NORTH AMERICA');
    });
}

export const isPLMTBuilderAdmin = (user: User | undefined) => {
    return _.some(user?.audiences, (audience: Audience) => audience.code.indexOf("PLMT_BUILDER_ADMIN") !== -1);
}
