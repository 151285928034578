import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from './';
import { Courseware } from '../models/cic/Courseware';
import { setLoading, setError, setMessage } from './Site';
import { Instructor } from '../models/lpc/Instructor';

export interface CICState {
    courseware: Courseware[];
}

let initialState: CICState = {
    courseware: []
}

export const cicSlice = createSlice({
    name: 'CertificationInstructorConnection',
    initialState,
    reducers: {
        setCourseware: (state, action) => {
            state.courseware = action.payload;
        }
    }
});

export const { setCourseware } = cicSlice.actions;

export const retrieveCourseware = (): AppThunk => async (dispatch, getState) => {
    dispatch(setLoading(true));
    fetch('api/cic/courseware', {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(
        response => response.json()
    ).then(data => {
        dispatch(setCourseware(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
        dispatch(setLoading(false));
    });
}

export const downloadDocument = (courseware: Courseware, document: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/cic/downloadDocument';
    let request = {
        courseware: courseware,
        document: document
    };
    let fileName = "";

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/pdf",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];

            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export default cicSlice.reducer;
