import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { KeyValuePair, EmptyGuid } from '../../models/Common';
import {
    Box, Paragraph, Heading, Text, Select, Form, CheckBoxGroup, TextInput, FormField, DataTable,
    Accordion, AccordionPanel
} from 'grommet';
import { Search } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveInstructors, retrieveInstructorPartners, exportInstructors } from '../../store/LearningPartnerConnection';
import BasePage from '../../components/BasePage';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import { Instructor } from '../../models/lpc/Instructor';
import { LearningPartner } from '../../models/LearningPartner';
import { hasRole } from '../../Utilities';

const mapDispatchToProps = {
    retrieveInstructors,
    retrieveInstructorPartners,
    retrieveLocalizedStrings,
    exportInstructors,
    ...siteSlice.actions,
}

interface IInstructorFinderProps {
}

interface IInstructorFinderState {
    instructorsLoaded: boolean;
    reloaded: boolean;
    reloadInstructors: boolean;
    instructors?: Instructor[];
    learningPartners?: LearningPartner[];
    filters?: any;
    authorizations?: any;
    processing: boolean;
}

type InstructorFinderProps = IInstructorFinderProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class InstructorFinder extends React.PureComponent<InstructorFinderProps, IInstructorFinderState> {
    allOrganization: LearningPartner = {
        id: EmptyGuid,
        name: 'All Organizations',
        hasCourses: false
    };

    constructor(props: InstructorFinderProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.LpcKeywords', 'Metadata.LpcDescription',
            'ExternalPages.Instructor_Finder_Label', 'ExternalPages.CIC_IRS_Sales_Status_Label', 'ExternalPages.CIC_IRS_Technical_Status_Label',
            'ExternalPages.CIC_IRS_Aruba_Status_Label', 'MyLearning.Export_File_Type', 'Datacard.Course_Id_Label', 'ExternalPages.Instructor_Name_Label',
            'ExternalPages.Course_Title_Label', 'ExternalPages.Certification_Grant_Date_Label', 'ExternalPages.Certification_Valid_Until_Date_Label',
            'ExternalPages.Email_Label', 'ExternalPages.Region_Label', 'ExternalPages.Relationship_Label',
            'MyLearning.Country_Label', 'ExternalPages.CIC_IRS_AvailableToAll_Label', 'ExternalPages.CIC_IRS_Exclusive_Label',
            'ExternalPages.CIC_IRS_Employee_Label', 'ExternalPages.LPD_Instructor_Finder_Into_Statement', 'ExternalPages.InstructorFinder_SelectOrganization_Label',
            'ExternalPages.Lpc_InstructorFinder_MoreDetails', 'ExternalPages.Lpc_InstructorFinder_MoreDetails_Label'];
        this.state = {
            instructorsLoaded: false,
            reloaded: false,
            reloadInstructors: false,
            processing: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveInstructorPartners();
        this.props.setActiveMenu('resources-menu');
    }

    public componentWillUnmount() {
    }

    public componentDidUpdate() {
        let site = this.props.site;
        let user = site.user;
        let isLpcAdmin = hasRole(user, ['LPC_ADMIN']);
        let isLpPpa = hasRole(user, ['LPC_PPA']);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};

        if (site.stringsLoaded && !filters.instructorTypes) {
            filters.instructorTypes = [site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label'],
            site.localizedStrings['ExternalPages.CIC_IRS_Technical_Status_Label'],
            site.localizedStrings['ExternalPages.CIC_IRS_Aruba_Status_Label']];
            this.setState({ filters: filters });
        }
        if (user) {
            // reloadInstructor and reloaded are used for swtich user functionality
            if (this.state.reloadInstructors && !site.loadingUser) {
                filters.organization = undefined;
                this.setState({ instructorsLoaded: true, reloadInstructors: false, reloaded: site.actualUser !== undefined, processing: false });
            }
            if (!isLpcAdmin && !filters.organization && this.props.lpc.instructorPartners && !this.state.processing) {
                let affiliatedPartners = _.map(this.props.lpc.instructor?.affiliations, aff => aff.learningPartner);
                let learningPartner = _.find(this.props.lpc.instructorPartners, (lp) => lp.partyId == user?.partyId);
                let organization = affiliatedPartners.length === 1
                    ? affiliatedPartners[0]
                    : learningPartner ?? this.allOrganization;

                this.setState({ processing: true });
                this.onPartnerSelected(organization);
            }
        }
        if (!isLpcAdmin && this.props.lpc.instructorPartners && !this.state.instructorsLoaded) {
            this.setState({ instructorsLoaded: true });
        }
        if ((site.actualUser && this.state.instructorsLoaded && !this.state.reloadInstructors && !this.state.reloaded && !site.loadingUser) ||
            (!site.actualUser && this.state.reloaded && !site.loadingUser && !this.state.reloadInstructors)) {
            this.setState({ reloadInstructors: true });
        }
    }

    onExport = (fileType: any) => {
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let instructors = _.filter(this.state.instructors ?? this.props.lpc.instructors, inst => inst.authorizations.length != 0);
        let request = {
            fileType: fileType.value,
            instructors: instructors
        };

        this.props.exportInstructors(request);
        this.setState({ filters: filters });
    }

    filterByKeyword = (searchText: string) => {
        let regexp = new RegExp(searchText, 'i');
        let baseInstructors = this.state.instructors ?? this.props.lpc.instructors;
        let instructors = _.filter(baseInstructors, inst => inst.authorizations && inst.authorizations.length != 0);
        let authorizations: any = _.flatMap(instructors, inst => {
            return _.map(inst.authorizations, authorization => {
                return {
                    name: inst.name,
                    email: inst.email,
                    ...authorization
                };
            });
        });

        authorizations = _.filter(authorizations, authorization => {
            return regexp.test(authorization.name) || regexp.test(authorization.email) ||
                regexp.test(authorization.activityCode) || regexp.test(authorization.activityName) ||
                regexp.test(authorization.activityValidUntil) || regexp.test(authorization.completionDate);
        });
        authorizations = _.sortBy(authorizations, authorization => authorization.activityName);

        return authorizations;
    }

    onSearchInstructors = (event: any) => {
        let searchText = event.target.value;
        let authorizations = this.filterByKeyword(searchText);
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};

        filters.keywords = searchText;
        this.setState({ authorizations: authorizations, filters: filters });
    }

    onSearchPartner = (searchText: string) => {
        let site = this.props.site;
        let user = site.user;
        let regexp = new RegExp(searchText, 'i');
        let isLpcAdmin = hasRole(user, ['LPC_ADMIN']);
        let affiliatedPartners = isLpcAdmin
            ? _.map(this.props.lpc.instructor?.affiliations, aff => aff.learningPartner)
            : this.props.lpc.instructorPartners;
        let filtered = _.filter(affiliatedPartners, lp => {
            return regexp.test(lp.name) || regexp.test(lp.partyId as string);
        });

        this.setState({ learningPartners: filtered });
    }

    onPartnerSelected = (learningPartner?: LearningPartner) => {
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};

        filters.organization = learningPartner;
        this.setState({ learningPartners: undefined, instructors: undefined, filters: filters });
        this.props.retrieveInstructors(learningPartner);
    }

    onTypeSelected = (evnt: any) => {
        let site = this.props.site;
        let selectedTypes = evnt.value;
        let filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
        let baseInstructors = this.props.lpc.instructors;
        let instructors = _.uniqBy(_.flatMap(_.map(selectedTypes, instType => {
            switch (instType) {
                case site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label']:
                    {
                        return _.filter(baseInstructors, instructor => instructor.isSalesCertified);
                    }
                case site.localizedStrings['ExternalPages.CIC_IRS_Technical_Status_Label']:
                    {
                        return _.filter(baseInstructors, instructor => instructor.isTechnicalCertified);
                    }
                case site.localizedStrings['ExternalPages.CIC_IRS_Aruba_Status_Label']:
                    {
                        return _.filter(baseInstructors, instructor => instructor.isArubaCertified);
                    }
                default: {
                    return [];
                }
            }
        })), inst => inst.email);

        filters.instructorTypes = selectedTypes;
        this.setState({ instructors: instructors, filters: filters });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.menuStrings['ExternalPages.LearningPartnerConnection_Label'];
        let page = site.localizedStrings['ExternalPages.Instructor_Finder_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: `${localUrl}/lpc` },
            { key: page, value: "" }
        ];
        let isLpcAdmin = hasRole(user, ['LPC_ADMIN']);
        let instructorTypes = [site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label'],
        site.localizedStrings['ExternalPages.CIC_IRS_Technical_Status_Label'],
        site.localizedStrings['ExternalPages.CIC_IRS_Aruba_Status_Label']];
        let exportOptions = [{
            text: "CSV",
            value: "csv"
        }, {
            text: "Excel",
            value: "xlsx"
        }];
        let affiliatedPartners = !isLpcAdmin
            ? _.map(this.props.lpc.instructor?.affiliations, aff => aff.learningPartner)
            : this.props.lpc.instructorPartners;
        let learningPartners = _.sortBy((this.state.learningPartners ?? affiliatedPartners ?? []), lp => lp.name);
        let instructors = _.filter(this.state.instructors ?? this.props.lpc.instructors, inst => inst.authorizations.length != 0);
        let authorizations: any = this.state.authorizations ?? _.flatMap(instructors, inst => {
            return _.map(inst.authorizations, authorization => {
                return {
                    name: inst.name,
                    email: inst.email,
                    region: inst.region,
                    country: inst.country,
                    relationship: inst.visibility,
                    ...authorization
                };
            });
        });
        let hasAuthorizations = authorizations && authorizations.length != 0;

        if (learningPartners.length > 1) {
            learningPartners.splice(0, 0, this.allOrganization);
        }
        authorizations = _.sortBy(authorizations, authorization => authorization.activityName);
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="InstructorFinder" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.LpcKeywords']}
            metadataDescription={site.localizedStrings['Metadata.LpcDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Heading level={1} textAlign="center" fill>{title}</Heading>
                <Box pad="small" gap="medium">
                    <Box width="large" gap="small" >
                        <Heading level={3}>{page}</Heading>
                        <Paragraph fill>{site.localizedStrings['ExternalPages.LPD_Instructor_Finder_Into_Statement']}</Paragraph>
                        <Accordion>
                            <AccordionPanel label={<Text color="brand" weight="bold" size="large">
                                {site.localizedStrings['ExternalPages.Lpc_InstructorFinder_MoreDetails_Label']}</Text>}>
                                <Box pad="small">
                                    <Paragraph fill>{site.localizedStrings['ExternalPages.Lpc_InstructorFinder_MoreDetails']}</Paragraph>
                                </Box>
                            </AccordionPanel>
                        </Accordion>
                        <Form value={this.state.filters} onChange={nextValue => this.setState({ filters: nextValue })} >
                            <Box gap="small" fill>
                                <Box direction="row-responsive" gap="medium">
                                    <Box gap="small" width="small">
                                        <FormField name="instructorTypes" htmlFor="instructorTypes-input" label="Instructor Type">
                                            <CheckBoxGroup name="instructorTypes" id="instructorTypes-input" options={instructorTypes}
                                                onChange={this.onTypeSelected} />
                                        </FormField>
                                    </Box>
                                    <Box gap="small" fill>
                                        <FormField name="organization" htmlFor="organization-input" label="Organization">
                                            <Select name="organization" id="organization-input" options={learningPartners} dropHeight="small" labelKey="name"
                                                valueKey="partyId" onSearch={this.onSearchPartner} onChange={({ option }) => { this.onPartnerSelected(option); }}
                                                placeholder={site.localizedStrings['ExternalPages.InstructorFinder_SelectOrganization_Label']} />
                                        </FormField>
                                        <Box width="small">
                                            <Select name="export" id="export-input" options={exportOptions} labelKey='text' valueKey='value'
                                                onChange={({ option }) => { this.onExport(option); }} placeholder='Export' />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width="medium" >
                                    <TextInput name="keywords" id="keywords-input" placeholder="Search" reverse type="search"
                                        icon={<Search id="search-icon" />} onChange={this.onSearchInstructors} />
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                    {hasAuthorizations && <Box>
                        <DataTable fill pin="header" data={authorizations} className="striped" primaryKey={false} columns={[
                            {
                                property: 'activityCode',
                                header: site.localizedStrings["Datacard.Course_Id_Label"],
                                render: (datum) => <Text textAlign="end">{datum.activityCode}</Text>
                            },
                            {
                                property: 'activityName',
                                header: site.localizedStrings['ExternalPages.Course_Title_Label'],
                                size: "medium"
                            },
                            {
                                property: 'name',
                                header: site.localizedStrings["ExternalPages.Instructor_Name_Label"]
                            },
                            {
                                property: 'email',
                                header: site.localizedStrings['ExternalPages.Email_Label']
                            },
                            {
                                property: 'relationship',
                                header: site.localizedStrings["ExternalPages.Relationship_Label"]
                            },
                            {
                                property: 'region',
                                header: site.localizedStrings['ExternalPages.Region_Label']
                            },
                            {
                                property: 'country',
                                header: site.localizedStrings['MyLearning.Country_Label']
                            },
                            {
                                property: 'completionDate',
                                header: site.localizedStrings['ExternalPages.Certification_Grant_Date_Label'],
                                render: (datum) => <Text>
                                    {datum.completionDate
                                        ? format(parseISO(datum.completionDate), 'yyyy-MMM-dd')
                                        : ''}</Text>
                            },
                            {
                                property: 'activityValidUntil',
                                header: site.localizedStrings['ExternalPages.Certification_Valid_Until_Date_Label'],
                                render: (datum) => <Text>
                                    {datum.activityValidUntil
                                        ? format(parseISO(datum.activityValidUntil), 'yyyy-MMM-dd')
                                        : ''}</Text>
                            }
                        ]} />
                    </Box>}
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(InstructorFinder as any));
