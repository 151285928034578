import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Anchor, Tabs, Tab } from 'grommet';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { push } from 'connected-react-router';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ICertificationExamsProps {
}

interface CertificationExamsState {
    activeTab: number;
    handlers: number[];
}

type CertificationExamsProps = ICertificationExamsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class CertificationExams extends React.PureComponent<CertificationExamsProps, CertificationExamsState> {
    constructor(props: CertificationExamsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : (this.props.location.state as any)?.activeTab
            : null;
        let langInfo = urlLanguage.split('-');
        let activeTab = 0;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'ExternalPages.Exams_Label',
            'Metadata.CertificationExamsKeywords,', 'Metadata.CertificationExamsDescription', 'Datacard.HPE_Learner_ID_Label',
            'ExternalPages.HprePres_Label', 'ExternalPages.HprePress_Button_Label', 'ExternalPages.CertificationExams_Banner_Title',
            'ExternalPages.CertificationExams_Banner_Statement', 'ExternalPages.CertificationExams_Intro_Statement',
            'ExternalPages.CertificationExams_Page_Title', 'ExternalPages.CertificationExams_Tab1', 'ExternalPages.CertificationExams_Tab2',
            'ExternalPages.CertificationExams_Tab3', 'ExternalPages.CertificationExams_Tab4', 'ExternalPages.CertificationExams_Tab1_Agreement',
            'ExternalPages.CertificationExams_Tab1_Agreement_Statement', 'ExternalPages.CertificationExams_Tab1_Card1_Title',
            'ExternalPages.CertificationExams_Tab1_Card1_Statement1', 'ExternalPages.CertificationExams_Tab1_Card2_Title',
            'ExternalPages.CertificationExams_Tab1_Card2_Statement', 'ExternalPages.CertificationExams_Tab1_Card3_Title',
            'ExternalPages.CertificationExams_Tab1_Card3_Statement', 'ExternalPages.CertificationExams_Tab2_Row1_Title',
            'ExternalPages.CertificationExams_Tab2_Row1_Statement', 'ExternalPages.CertificationExams_Tab2_Row2_Title',
            'ExternalPages.CertificationExams_Tab2_Row2_Statement', 'ExternalPages.CertificationExams_Tab2_Row3_Title',
            'ExternalPages.CertificationExams_Tab2_Row3_Statement1', 'ExternalPages.CertificationExams_Tab2_Row3_Statement2',
            'ExternalPages.CertificationExams_Tab2_Row3_Statement3', 'ExternalPages.CertificationExams_Tab3_Statement',
            'ExternalPages.CertificationExams_Tab4_Statement', 'ExternalPages.CertificationExams_Tab4_Card1_Title',
            'ExternalPages.CertificationExams_Tab4_Card1_Statement', 'ExternalPages.CertificationExams_Tab4_Card2_Title',
            'ExternalPages.CertificationExams_Tab4_Card2_Statement', 'ExternalPages.CertificationExams_Tab4_Card3_Title',
            'ExternalPages.CertificationExams_Tab4_Card3_Statement', 'ExternalPages.CertificationExams_Tab4_Row1_Title',
            'ExternalPages.CertificationExams_Tab1_Card1_SubTitle1', 'ExternalPages.CertificationExams_Tab1_Card1_SubTitle2',
            'ExternalPages.CertificationExams_Tab1_Card1_Statement2'];

        switch (tab) {
            case 'preparation':
                activeTab = 1;
                break;
            case 'types':
                activeTab = 2;
                break;
            case 'vouchers':
                activeTab = 3;
                break;
        }
        this.state = {
            activeTab: activeTab,
            handlers: []
        };

        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('technical-certification-menu');
    }

    public componentDidMount() {
    }

    onActivateTab = (activeTab: number) => {
        this.setState({ activeTab: activeTab });
        switch (activeTab) {
            case 1:
                activeTab = 1;
                this.props.push("/certification-exams/preparation");
                break;
            case 2:
                this.props.push("/certification-exams/types");
                break;
            case 3:
                this.props.push("/certification-exams/vouchers");
                break;
            default: {
                this.props.push("/certification-exams");
                break;
            }
        }
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Technical'], value: `${localUrl}/technical-certifications` },
            { key: site.localizedStrings['ExternalPages.CertificationExams_Page_Title'], value: "" }
        ];
        let title = site.localizedStrings['ExternalPages.CertificationExams_Page_Title'];

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="CertificationExams" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.CertificationExamsKeywords']}
            metadataDescription={site.localizedStrings['Metadata.CertificationExamsDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    <Box alignSelf="center" width="xlarge">
                        <Paragraph textAlign="center" fill>
                            {site.localizedStrings['ExternalPages.CertificationExams_Intro_Statement']}
                        </Paragraph>
                    </Box>
                </Box>
                <Box gap="xsmall">
                    <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="center">
                        <Tab title={site.localizedStrings['ExternalPages.CertificationExams_Tab1']}>
                            <Box pad="medium" gap="large">
                                <Box pad="medium" direction="row-responsive" gap="large" >
                                    <Box fill gap="small" alignSelf="center">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.CertificationExams_Tab1_Agreement']}</Heading>
                                        <Paragraph fill size="large">
                                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Agreement_Statement'],
                                                [`${localUrl}/documents/external/HPE_Candidate_Agreement.pdf`, `${localUrl}/assistKit`]))}
                                        </Paragraph>
                                    </Box>
                                    <Box fill alignSelf="center">
                                        <Image fit="contain" src="images/certification-exams/HPE202208250054145_1600_0_72_RGB.jpg" />
                                    </Box>
                                </Box>
                                <Box pad="medium" direction="row-responsive" gap="large" fill justify="center"
                                    background={{ size: "cover", image: "url('images/certification-exams/GettyImages-1406680618.jpg')" }} >
                                    <Box gap="small" width="medium" background="white" pad="small" >
                                        <Text weight="bold" size="large" >{parse(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card1_Title'])}</Text>
                                        <Text weight="bold">{parse(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card1_SubTitle1'])}</Text>
                                        <Paragraph fill>
                                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card1_Statement1'],
                                                [`${localUrl}/contact-security`]))}
                                        </Paragraph>
                                        <Text weight="bold" >{parse(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card1_SubTitle2'])}</Text>
                                        <Paragraph fill>
                                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card1_Statement2'],
                                                [`${localUrl}/support`]))}
                                        </Paragraph>
                                    </Box>
                                    <Box gap="small" width="medium" background="white" pad="small" >
                                        <Text weight="bold" size="large" >{site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card2_Title']}</Text>
                                        <Paragraph fill>{parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card2_Statement'],
                                            [`${localUrl}/certification-exams/vouchers`]))}
                                        </Paragraph>
                                    </Box>
                                    <Box gap="small" width="medium" background="white" pad="small" >
                                        <Text weight="bold" size="large" >{site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card3_Title']}</Text>
                                        <Paragraph fill>{parse(site.localizedStrings['ExternalPages.CertificationExams_Tab1_Card3_Statement'])}</Paragraph>
                                    </Box>
                                </Box>
                            </Box>
                        </Tab>
                        <Tab title={site.localizedStrings['ExternalPages.CertificationExams_Tab2']}>
                            <Box pad="medium" gap="large">
                                <Box pad="small" direction="row-responsive" gap="large" >
                                    <Box fill gap="medium" alignSelf="center">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row1_Title']}</Heading>
                                        <Paragraph fill size="large">
                                            {parse(site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row1_Statement'])}
                                        </Paragraph>
                                        <Box fill gap="small" >
                                            <Anchor href={`${localUrl}/courses`} label={site.menuStrings['Datacard.View_All_Courses_Label']} />
                                            <Anchor href={`${localUrl}/hpepress`} label={site.menuStrings['Datacard.HPE_Press_Books_Label']} />
                                        </Box>
                                    </Box>
                                    <Box fill alignSelf="center">
                                        <Image fit="contain" src="images/certification-exams/HPE202208240083700_1600_0_72_RGB.jpg" />
                                    </Box>
                                </Box>
                                <Box direction="row-responsive" fill
                                    background={{ size: "cover", image: "url('images/certification-exams/HPE_Gradient_Purple_Blue_Yellow_PPT.png')" }} >
                                    <Box fill gap="medium" justify="center" pad={{ top: "medium", left: "medium", bottom: "medium", right: "large" }}>
                                        <Heading level="3" color="white" >
                                            {site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row2_Title']}
                                        </Heading>
                                        <Paragraph fill color="white" size="large">
                                            {parse(site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row2_Statement'])}
                                        </Paragraph>
                                    </Box>
                                </Box>
                                <Box direction="row-responsive" gap="large">
                                    <Box fill>
                                        <Image fit="contain" src="images/certification-exams/HPE202208310102616_1600_0_72_RGB.jpg" />
                                    </Box>
                                    <Box fill gap="medium" alignSelf="center">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row3_Title']}</Heading>
                                        <Paragraph fill size="large">
                                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row3_Statement1'],
                                                [`${localUrl}/certifications`, `${localUrl}/exams`, `${localUrl}/getstarted`,
                                                `${localUrl}/learning-center`, `${localUrl}/support`]))}
                                        </Paragraph>
                                    </Box>
                                </Box>
                                <Box fill gap="medium" pad="small" border={{ side: 'all', color: 'black', size: "small" }} >
                                    <Paragraph fill size="large">
                                        {parse(site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row3_Statement2'])}
                                    </Paragraph>
                                </Box>
                                <Paragraph fill size="large">
                                    {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab2_Row3_Statement3'],
                                        [`${localUrl}/learning-center`]))}
                                </Paragraph>
                            </Box>
                        </Tab>
                        <Tab title={site.localizedStrings['ExternalPages.CertificationExams_Tab3']}>
                            <Box pad="medium" gap="medium">
                                <Paragraph fill size="large">
                                    {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab3_Statement'],
                                        [`${localUrl}/getstarted`, 'http://pearsonvue.com/hpe', 'https://techpro.hpe.com']))}
                                </Paragraph>
                            </Box>
                        </Tab>
                        <Tab title={site.localizedStrings['ExternalPages.CertificationExams_Tab4']}>
                            <Box pad="medium" gap="large">
                                <Box direction="row-responsive" fill gap="large">
                                    <Box fill gap="small" alignSelf="center">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.CertificationExams_Tab4_Row1_Title']}</Heading>
                                        <Paragraph fill size="large">
                                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab4_Statement'],
                                                [`${localUrl}/assistKit`]))}
                                        </Paragraph>
                                    </Box>
                                    <Box fill alignSelf="center">
                                        <Image fit="contain" src="images/certification-exams/GettyImages-1433224633.jpg" />
                                    </Box>
                                </Box>
                                <Box fill gap="medium" >
                                    <Box fill gap="small" >
                                        <Text weight="bold" size="xlarge" >
                                            {parse(site.localizedStrings['ExternalPages.CertificationExams_Tab4_Card1_Title'])}
                                        </Text>
                                        <Paragraph fill size="large">
                                            {parse(site.localizedStrings['ExternalPages.CertificationExams_Tab4_Card1_Statement'])}
                                        </Paragraph>
                                    </Box>
                                    <Box fill gap="small" >
                                        <Text weight="bold" size="xlarge" >
                                            {site.localizedStrings['ExternalPages.CertificationExams_Tab4_Card2_Title']}
                                        </Text>
                                        <Paragraph fill size="large">
                                            {parse(formatString(site.localizedStrings['ExternalPages.CertificationExams_Tab4_Card2_Statement'],
                                                [`${localUrl}/certification-exams/vouchers`]))}
                                        </Paragraph>
                                    </Box>
                                    <Box fill gap="small" >
                                        <Text weight="bold" size="xlarge" >
                                            {site.localizedStrings['ExternalPages.CertificationExams_Tab4_Card3_Title']}
                                        </Text>
                                        <Paragraph fill size="large">
                                            {parse(site.localizedStrings['ExternalPages.CertificationExams_Tab4_Card3_Statement'])}
                                        </Paragraph>
                                    </Box>
                                </Box>
                            </Box>
                        </Tab>
                    </Tabs>
                </Box>
            </Box>
            }
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(CertificationExams as any));
