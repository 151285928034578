import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import { ApplicationState } from '../../store';
import { KeyValuePair } from '../../models/Common';
import { Box, Paragraph, Notification, Heading, Text, Card, CardBody, Grid, Anchor } from 'grommet';
import { Group, Compliance, Language, DocumentUser, Search, Workshop, View } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { retrieveNotification, retrieveCountries } from '../../store/Plmt';
import BasePage from '../../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { hasRole, isPLMTAdmin, formatString } from '../../Utilities';
import { format, add } from 'date-fns';

const mapDispatchToProps = {
    push,
    replace,
    retrieveNotification,
    retrieveLocalizedStrings,
    retrieveCountries,
    ...siteSlice.actions,
}

interface IPlmtHomeProps {
}

interface PlmtHomeState {
    showNotification: boolean;
}

type PlmtHomeProps = IPlmtHomeProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class PlmtHome extends React.PureComponent<PlmtHomeProps, PlmtHomeState> {
    constructor(props: PlmtHomeProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.PlmtKeywords', 'Metadata.PlmtDescription', 'Datacard.Overview_Label',
            'MyLearning.PLMT_Overview_Paragraph', 'MyLearning.GapSummary_Label', 'MyLearning.Activities_label', 'MyLearning.RegionalReports_Label',
            'MyLearning.LearningAssignment_Label', 'MyLearning.Map_Reports_Label', 'MyLearning.PLMT_Title',
            'MyLearning.PLMT_GapSummary_Description', 'MyLearning.PLMT_Activities_Description', 'MyLearning.PLMT_LearningAssignment_Description',
            'MyLearning.PLMT_RegionalReports_Description', 'MyLearning.PLMT_More_Resources', 'MyLearning.Distributor_Management_Label',
            'MyLearning.How_to_Assign_Partner_Education_Manager_Role_Label', 'MyLearning.PLMT_Distributors_Statement',
            'MyLearning.PLMT_User_Guide_Label', 'ExternalPages.English_Label', 'ExternalPages.French_Label', 'ExternalPages.Spanish_Label',
            'ExternalPages.German_Label', 'ExternalPages.Japanese_Label', 'ExternalPages.Portuguese_Label', 'ExternalPages.Chinese_Label',
            'ExternalPages.Italian_Label', 'ExternalPages.Korean_Label', 'MyLearning.PLMT_Information_Link3', 'MyLearning.PLMT_Information_Link4',
            'MyLearning.Not_Authorized_Statement', 'ExternalPages.Compliance_ChangeOver'];

        this.state = {
            showNotification: false,
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveCountries();
        this.props.setActiveMenu('admin-tools-menu');
    }

    public componentDidMount() {
        this.props.retrieveNotification(this.showNotification);
    }

    goto = (url: string) => {
        window.location.href = url;
    }

    showNotification = (show: boolean) => {
        this.setState({ showNotification: show });
    }

    getAuthorizedTiles = () => {
        let site = this.props.site;
        let user = this.props.site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let isAdmin = isPLMTAdmin(user);
        let isPEM = hasRole(user, ["PEM", "PEM_Backup"]);
        let isPPA = hasRole(user, ["PPA"]);
        let isDistributor = hasRole(user, ["DISTI"]);
        let isMapUser = (user?.companyName
            ? _.some(["sophela", "selectium"], (name) => user?.companyName.toLowerCase().indexOf(name) !== -1)
            : false) && hasRole(user, ["PPA"]);
        let isAvailable = false;
        let tiles = [];
        let documentServer = `${process.env.REACT_APP_MAIN_SITE}`;
        let isPlmtUser = isAdmin || isPEM || isPPA;
        let country = _.find(this.props.plmt.countries, (country) => country.code == user?.countryCode);
        let currentDate = new Date();
        let startDate = new Date();
        let endDate = new Date();

        if (isPlmtUser) {
            startDate.setMonth(10);
            startDate.setDate(1);
            endDate.setMonth(10);
            endDate.setDate(22);
            if (currentDate < startDate || currentDate > endDate) {
                tiles.push(<Card key="summary-card" onClick={() => { this.goto(`${localUrl}/plmt/gap-summary`); }}>
                    <CardBody gap='small'>
                        <Compliance size='large' />
                        <Heading level={4}>{site.localizedStrings['MyLearning.GapSummary_Label']}</Heading>
                        <Paragraph>{parse(site.localizedStrings['MyLearning.PLMT_GapSummary_Description'])}</Paragraph>
                    </CardBody>
                </Card>);
            }
            tiles.push(<Card key="activities-card" onClick={() => { this.goto(`${localUrl}/plmt/activities`); }}>
                <CardBody gap='small'>
                    <Group size='large' />
                    <Heading level={4}>{site.localizedStrings['MyLearning.Activities_label']}</Heading>
                    <Paragraph>{parse(site.localizedStrings['MyLearning.PLMT_Activities_Description'])}</Paragraph>
                </CardBody>
            </Card>);
        }
        if (isAvailable && (isPEM || isAdmin)) {
            tiles.push(<Card key="learning-assignment-card" onClick={() => { this.goto(`${localUrl}/plmt/learning-assignment`); }}>
                <CardBody gap='small'>
                    <Workshop size='large' />
                    <Heading level={4}>{site.localizedStrings['MyLearning.LearningAssignment_Label']}</Heading>
                    <Paragraph>{parse(site.localizedStrings['MyLearning.PLMT_LearningAssignment_Description'])}</Paragraph>
                </CardBody>
            </Card>);
        }
        if (isAdmin) {
            tiles.push(<Card key="regional-reports-card" onClick={() => { this.goto(`${localUrl}/plmt/regional-reports`); }}>
                <CardBody gap='small'>
                    <Language size='large' />
                    <Heading level={4}>{site.localizedStrings['MyLearning.RegionalReports_Label']}</Heading>
                    <Paragraph>{parse(site.localizedStrings['MyLearning.PLMT_RegionalReports_Description'])}</Paragraph>
                </CardBody>
            </Card>);
        }
        if (isAdmin || ((isPEM || isPPA) && !isDistributor && country?.region == 'NORTH AMERICA')) {
            tiles.push(<Card key="distributor-management-card" onClick={() => { this.goto(`${localUrl}/plmt/distributors`); }}>
                <CardBody gap='small'>
                    <View size='large' />
                    <Heading level={4}>{site.localizedStrings['MyLearning.Distributor_Management_Label']}</Heading>
                    <Paragraph>{parse(site.localizedStrings['MyLearning.PLMT_Distributors_Statement'])}</Paragraph>
                </CardBody>
            </Card>);
        }
        if (isMapUser || isAdmin) {
            tiles.push(<Card gap='small' key="map-reports-card" >
                <CardBody gap='small'>
                    <DocumentUser size='large' />
                    <Heading level={4}>{site.localizedStrings['MyLearning.Map_Reports_Label']}</Heading>
                    <Box direction='row-responsive' gap='medium'>
                        <ul>
                            <li><Anchor label='Kenya' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_KE.xlsx`} /></li>
                            <li><Anchor label='Morocco' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_MA.xlsx`} /></li>
                            <li><Anchor label='Nigeria' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_NG.xlsx`} /></li>
                            <li><Anchor label='Bulgaria' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_BG.xlsx`} /></li>
                            <li><Anchor label='Croatia' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_HR.xlsx`} /></li>
                        </ul>
                        <ul>
                            <li><Anchor label='Serbia' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_RS.xlsx`} /></li>
                            <li><Anchor label='Lithuania' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_LT.xlsx`} /></li>
                            <li><Anchor label='Ukraine' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_UA.xlsx`} /></li>
                            <li><Anchor label='Slovenia' target="_blank" href={`${documentServer}/documents/plmtReport/PLMT_MAP_SI.xlsx`} /></li>
                        </ul>
                    </Box>
                </CardBody>
            </Card>);
        }

        return tiles;
    }

    public render() {
        let site = this.props.site;
        let user = this.props.site.user;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let title = site.localizedStrings['MyLearning.PLMT_Title'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: title, value: "" }
        ];
        let message = this.props.plmt.notification?.message
            ? parse(this.props.plmt.notification?.message)
            : null;
        let msgType: "info" | "warning" | "critical" | undefined = undefined;
        let authorizedTiles = this.getAuthorizedTiles();
        let currentDate = new Date();
        let startDate = new Date();
        let endDate = new Date();
        let nextFy = format(add(currentDate, { years: 1 }), "yy");
        let endDay = "22nd";

        if (!user) {
            return null;
        }
        if (user && authorizedTiles.length == 0) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        startDate.setMonth(10);
        startDate.setDate(1);
        endDate.setMonth(10);
        endDate.setDate(22);
        switch (this.props.plmt.notification?.type) {
            case "warning":
                {
                    msgType = "warning";
                    break;
                }
            case "critical":
                {
                    msgType = "critical";
                    break;
                }
            default:
                {
                    msgType = "info";
                    break;
                }
        }
        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="PlmtHome" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                    <Box alignSelf="center" background="#C140FF" border={{ side: 'all', color: '#C140FF', size: "medium" }} width="small"></Box>
                </Box>
                <Box pad={{ top: "large", bottom: "small" }} gap="medium">
                    <Box fill="horizontal">
                        <Heading level={3}>{site.localizedStrings['Datacard.Overview_Label']}</Heading>
                    </Box>
                    <Box fill="horizontal" direction='row-responsive' gap="medium">
                        <Box fill>
                            <Paragraph fill>{site.localizedStrings['MyLearning.PLMT_Overview_Paragraph']}</Paragraph>
                        </Box>
                        <Box fill>
                            <Text>{`${site.localizedStrings['MyLearning.PLMT_More_Resources']}:`}</Text>
                            <ul>
                                <li><Anchor label={site.localizedStrings['MyLearning.How_to_Assign_Partner_Education_Manager_Role_Label']} target="_blank"
                                    href='https://partner.hpe.com/group/prp/article-display-page?id=533650999' /></li>
                                <li>
                                    <Box direction="row-responsive" gap="small" align='center'>
                                        <Text>
                                            {site.localizedStrings['MyLearning.PLMT_User_Guide_Label']}
                                        </Text>
                                        <Box direction="row-responsive" gap="xsmall">
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCMp2RHXPCqDjGCJ3jhBRWdR4FTB" target="_blank"
                                                label={site.localizedStrings['ExternalPages.English_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DChCPV3TcChb68cV4dgFbd86c6Mj" target="_blank"
                                                label={site.localizedStrings['ExternalPages.Chinese_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCWP2JPB3WdJV8WHT4CmC4DgXPMB" target="_blank"
                                                label={site.localizedStrings['ExternalPages.French_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCHMBQ2bH2gbd8cQgWVBcfDD6MGV" target="_blank"
                                                label={site.localizedStrings['ExternalPages.German_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCmFHb9hXCMXqGQWCCB3HTTCfPdG" target="_blank"
                                                label={site.localizedStrings['ExternalPages.Italian_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCBVXP4gbhDXGGHH7J2HTJF4qm73" target="_blank"
                                                label={site.localizedStrings['ExternalPages.Japanese_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCfhMfQhXMdqGGfT33q9FMMqDm8V" target="_blank"
                                                label={site.localizedStrings['ExternalPages.Korean_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCqhqm9WGMDFhG7HRCfDQR4RCVB3" target="_blank"
                                                label={site.localizedStrings['ExternalPages.Portuguese_Label']} />
                                            <Text>|</Text>
                                            <Anchor href="https://hpe.seismic.com/Link/Content/DCR727J3qh9W6GCHqb7GFm7c7hR3" target="_blank"
                                                label={site.localizedStrings['ExternalPages.Spanish_Label']} />
                                        </Box>
                                    </Box>
                                </li>
                                <li>{parse(formatString(site.localizedStrings['MyLearning.PLMT_Information_Link3'], [`${localUrl}/support`]))}</li>
                                <li>{parse(formatString(site.localizedStrings['MyLearning.PLMT_Information_Link4'], ['https://partner.hpe.com/']))}</li>
                            </ul>
                        </Box>
                    </Box>
                    {message && this.state.showNotification &&
                        <Notification status={msgType} message={message} global onClose={() => { this.showNotification(false); }} />
                    }
                    {(currentDate >= startDate && currentDate <= endDate) &&
                        <Notification global status="info" message={formatString(site.localizedStrings['ExternalPages.Compliance_ChangeOver'], [nextFy,endDay])} />
                    }
                    {authorizedTiles.length != - 0 && <Grid fill rows={['auto', 'auto', 'auto']} columns={['flex', 'flex', 'flex']} gap="medium" responsive >
                        {authorizedTiles}
                    </Grid>}
                </Box>
            </Box>
            }
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(PlmtHome as any));
