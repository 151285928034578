import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState, AppThunk } from '.';
import { PartnerResults } from '../models/PartnerResults';
import { setLoading, setError } from './Site';
import { EmptyGuid } from '../models/Common'

export interface LearningPartnerState {
    lauguage?: string;
    data?: PartnerResults;
}

export const allOption = {
    selected: true,
    text: "Select/Deselect All",
    value: 'all'
}

export const allGuidOption = {
    selected: true,
    text: "Select/Deselect All",
    value: EmptyGuid
}

let initialState: LearningPartnerState = {
}

export const learningPartnerSlice = createSlice({
    name: 'LearningPartner',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setData } = learningPartnerSlice.actions;

export const retrieveLearningPartners = (language: string = ''): AppThunk => async (dispatch, getState) => {
    let request = {
        language: language
    }

    dispatch(setLoading(true));
    fetch('api/list/learningpartners', {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        dispatch(setData(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setError(err.message ?? err));
        dispatch(setLoading(false));
    });
}

export default learningPartnerSlice.reducer;
