import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Anchor, Tabs, Tab, ThemeContext, Button } from 'grommet';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface ISalesCertificationsProps {
}

interface SalesCertificationsState {
    activeTab: number;
    handlers: number[];
}

type SalesCertificationsProps = ISalesCertificationsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class SalesCertifications extends React.PureComponent<SalesCertificationsProps, SalesCertificationsState> {
    constructor(props: SalesCertificationsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : (this.props.location.state as any)?.activeTab
            : null;
        let langInfo = urlLanguage.split('-');
        let activeTab = 0;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.SalesCertificationsKeywords,', 'Metadata.SalesCertificationsDescription',
            'ExternalPages.TechnicalCertifications_Intro_Statement', 'ExternalPages.SalesCertifications_Banner_Title',
            "ExternalPages.SalesCertifications_Banner_Statement", "ExternalPages.SalesCertifications_Banner_Note",
            'ExternalPages.Certifications_Tab2_Card2_Title', 'ExternalPages.Certifications_Tab2_Card3_Title', 'ExternalPages.Certifications_ProgramOverview',
            'ExternalPages.Certifications_ShowcaseAchievement', 'ExternalPages.SalesCertifications_Tab1_Statement1',
            'ExternalPages.SalesCertifications_Tab1_Section1_Title', 'ExternalPages.SalesCertifications_Tab1_Section2_Title',
            'ExternalPages.SalesCertifications_Tab1_Section1_Statement', 'ExternalPages.SalesCertifications_Tab1_Section2_Statement',
            'ExternalPages.SalesCertifications_Collaterals_Label', 'ExternalPages.SalesCertifications_Picture_Title',
            'ExternalPages.SalesCertifications_Tab2_Statement', 'ExternalPages.SalesCertifications_Tab2_Quote', 'ExternalPages.Certifications_Tab3_title',
            'ExternalPages.SalesCertifications_Tab2_Author', 'ExternalPages.SalesCertifications_Tab2_Card1_Statement', 'ExternalPages.Sales_Pro_Label',
            'ExternalPages.SalesCertifications_Tab2_title', 'ExternalPages.SalesCertifications_Tab2_Card2_Statement',
            'ExternalPages.SalesCertifications_Tab2_Card3_Title', 'ExternalPages.SalesCertifications_Tab2_Card3_Statement',
            'ExternalPages.SalesCertifications_Tab3_Statement1', 'ExternalPages.SalesCertifications_Tab3_Statement12',
            'ExternalPages.SalesCertifications_Tab3_Step1', 'ExternalPages.SalesCertifications_Tab3_Step1_Statement',
            'ExternalPages.SalesCertifications_Tab3_Step2', 'ExternalPages.SalesCertifications_Tab3_Step2_Statement',
            'ExternalPages.SalesCertifications_Tab3_Step3', 'ExternalPages.SalesCertifications_Tab3_Step3_Statement',
            'ExternalPages.SalesCertifications_Tab3_Step4', 'ExternalPages.SalesCertifications_Tab3_Step4_Statement',
            'ExternalPages.SalesCertifications_Tab2_Card3_Link', 'ExternalPages.Go_Now_Label',
            'ExternalPages.TechnicalCertifications_Tab4_Step1', 'ExternalPages.SalesCertifications_Tab3_Statement2', 'ExternalPages.Sales_Certification_Label',
            'ExternalPages.SalesCertifications_Row1_Title', 'ExternalPages.SalesCertifications_Row1_Statement', 'ExternalPages.Brochure_Get_More_Label',
            'ExternalPages.SalesCertifications_Tab1_Row1_Title', 'ExternalPages.SalesCertifications_Tab1_Row1_Statement',
            'ExternalPages.SalesCertifications_Tab2_Card1_Title', 'ExternalPages.SalesCertifications_Tab2_Card2_Title'];

        switch (tab) {
            case 'sales-pro':
                activeTab = 1;
                break;
            case 'showcase':
                activeTab = 2;
                break;
        }
        if (tab == "video") {
            setTimeout(() => {
                let elem = document.getElementById("sales-video");

                elem?.scrollIntoView();
            }, 500);
        }
        this.state = {
            activeTab: activeTab,
            handlers: []
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('sales-certification-menu');
        this.props.setLoading(true);
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onActivateTab = (activeTab: number) => {
        let handlers = [...this.state.handlers];

        this.setState({ activeTab: activeTab });
        switch (activeTab) {
            case 1: {
                this.props.push("/sales-certifications/sales-pro");
                if (_.indexOf(handlers, activeTab) == -1) {
                    setTimeout(() => {
                        handlers.push(activeTab);
                        this.setState({ handlers: handlers });
                    }, 2000);
                }
                break;
            }
            case 2: {
                this.props.push("/sales-certifications/showcase");
                if (_.indexOf(handlers, activeTab) === -1) {
                    setTimeout(() => {
                        handlers.push(activeTab);
                        this.setState({ handlers: handlers });
                    }, 2000);
                }
                break;
            }
            default: {
                this.props.push("/sales-certifications");
                break;
            }
        }
    }

    gotoTechnical = () => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;

        window.location.href = `${localUrl}/technical-certifications`;
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Sales'], value: "" }
        ];
        let title = site.menuStrings['ExternalPages.Navigation_Sales'];

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="SalesCertifications" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.SalesCertificationsKeywords']}
                    metadataDescription={site.localizedStrings['Metadata.SalesCertificationsDescription']} >
                    {site.stringsLoaded && <Box gap="medium" >
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                        <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="center">
                            <Tab title={site.localizedStrings['ExternalPages.Certifications_ProgramOverview']}>
                                <Box pad={{ top: "large", bottom: "large" }} fill gap="xlarge" >
                                    <Box direction="row-responsive" gap="large">
                                        <Box gap="small" fill alignSelf="center">
                                            <Heading level="3">{site.localizedStrings['ExternalPages.SalesCertifications_Tab1_Row1_Title']}</Heading>
                                            <Paragraph fill size="large">
                                                {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab1_Row1_Statement'])}
                                            </Paragraph>
                                        </Box>
                                        <Box id="sales-video" height="medium" fill="horizontal">
                                            <iframe width="100%" height="100%" src='https://www.youtube.com/embed/Wghl-n8RQGU'
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen title="HPE Sales Certifications" />
                                        </Box>
                                    </Box>
                                    <Box direction="row-responsive" gap="large" >
                                        <Box fill gap="small">
                                            <Box fill>
                                                <Image fit="contain" src="images/sales-certifications/HPE202302220210.jpg" />
                                            </Box>
                                            <Box fill gap="small">
                                                <Heading level="3" >
                                                    {site.localizedStrings['ExternalPages.SalesCertifications_Tab1_Section1_Title']}
                                                </Heading>
                                                <Paragraph fill size="large">
                                                    {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab1_Section1_Statement'])}
                                                </Paragraph>
                                                <Anchor href="https://salespro.hpe.com/" target="_blank"
                                                    label={site.localizedStrings['ExternalPages.Sales_Pro_Label']} />
                                            </Box>
                                        </Box>
                                        <Box fill gap="small">
                                            <Box fill>
                                                <Image fit="contain" src="images/sales-certifications/HPE202208250043389.jpg" />
                                            </Box>
                                            <Box fill gap="small">
                                                <Heading level="3" >
                                                    {site.localizedStrings['ExternalPages.SalesCertifications_Tab1_Section2_Title']}
                                                </Heading>
                                                <Paragraph fill size="large">
                                                    {site.localizedStrings['ExternalPages.SalesCertifications_Tab1_Section2_Statement']}
                                                </Paragraph>
                                                <Anchor href={`${localUrl}/datacard/certification/Aruba-SCE-APAS`}
                                                    label={site.localizedStrings['ExternalPages.Sales_Certification_Label']} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title={site.localizedStrings['ExternalPages.SalesCertifications_Tab2_title']}>
                                <Box pad={{ top: "large", bottom: "large" }} fill gap="medium">
                                    <Box direction="row-responsive" gap="large">
                                        <Box fill gap="small" alignSelf="center">
                                            <Heading level="3">{site.localizedStrings['ExternalPages.SalesCertifications_Row1_Title']}</Heading>
                                            <Paragraph fill size="large">
                                                {parse(site.localizedStrings['ExternalPages.SalesCertifications_Row1_Statement'])}
                                            </Paragraph>
                                            <Box width="small">
                                                <Button primary label={site.localizedStrings['ExternalPages.Go_Now_Label']}
                                                    href="https://salespro.hpe.com/" target="_blank" />
                                            </Box>
                                        </Box>
                                        <Box fill alignSelf="center">
                                            <Image fit="contain" src="images/sales-certifications/HPE2022042205117.jpg" />
                                        </Box>
                                    </Box>
                                    <Box pad="medium" direction="row-responsive" gap="large" justify="center">
                                        <Box gap="small" background="white" width="medium">
                                            <Image src="images/sales-certifications/HPE2022042509184_450_0_72_RGB.jpg" />
                                            <Box pad="small" background="white">
                                                <Heading level="4">
                                                    {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab2_Card1_Title'])}
                                                </Heading>
                                                <Paragraph>
                                                    {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab2_Card1_Statement'])}
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                        <Box gap="small" background="white" width="medium">
                                            <Image src="images/sales-certifications/HPE2022042605118_450_0_72_RGB.jpg" />
                                            <Box pad="small">
                                                <Heading level="4">
                                                    {site.localizedStrings['ExternalPages.SalesCertifications_Tab2_Card2_Title']}
                                                </Heading>
                                                <Paragraph>
                                                    {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab2_Card2_Statement'])}
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                        <Box gap="small" background="white" width="medium">
                                            <Image src="images/sales-certifications/HPE2022042710078_450_0_72_RGB.jpg" />
                                            <Box pad="small">
                                                <Heading level="4">
                                                    {site.localizedStrings['ExternalPages.SalesCertifications_Tab2_Card3_Title']}
                                                </Heading>
                                                <Paragraph>
                                                    {site.localizedStrings['ExternalPages.SalesCertifications_Tab2_Card3_Statement']}
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title={site.localizedStrings['ExternalPages.Certifications_ShowcaseAchievement']}>
                                <Box pad={{ top: "large", bottom: "large" }} fill gap="large">
                                    <Box direction="row-responsive" pad={{ left: "medium", right: "medium" }} gap="large">
                                        <Box gap="small" fill alignSelf="center">
                                            <Heading level="3">{site.localizedStrings['ExternalPages.Certifications_Tab3_title']}</Heading>
                                            <Paragraph fill size="large">
                                                {site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Statement1']}
                                            </Paragraph>
                                            <Paragraph fill size="large">
                                                {parse(formatString(site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Statement2'],
                                                    [`${localUrl}/documents/external/digital_badges.pdf `, `${localUrl}/assistkit`]))}
                                            </Paragraph>
                                        </Box>
                                        <Box fill alignSelf="center">
                                            <Image src="images/sales-certifications/HPE202208260022238.jpg" />
                                        </Box>
                                    </Box>
                                    <Box background="#F5F5F5" pad={{ left: "medium", right: "medium" }}>
                                        <Box direction="row-responsive" gap="small">
                                            <Box fill>
                                                <Box gap="small" align="center" width="medium" pad="small">
                                                    <Image src="images/hpe-image-3-200x200.jpg" />
                                                    <Text weight="bold">{site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Step1']}</Text>
                                                    <Paragraph fill textAlign="center">
                                                        {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Step1_Statement'])}
                                                    </Paragraph>
                                                </Box>
                                            </Box>
                                            <Box fill>
                                                <Box gap="small" align="center" width="medium" pad="small">
                                                    <Image src="images/hpe-image-4-200x200.jpg" />
                                                    <Text weight="bold">{site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Step2']}</Text>
                                                    <Paragraph fill textAlign="center">
                                                        {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Step2_Statement'])}
                                                    </Paragraph>
                                                </Box>
                                            </Box>
                                            <Box fill>
                                                <Box gap="small" align="center" width="medium" pad="small">
                                                    <Image src="images/sales-certifications/hpe-image-6-200x200.jpg" />
                                                    <Text weight="bold">{site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Step3']}</Text>
                                                    <Paragraph fill textAlign="center">
                                                        {parse(site.localizedStrings['ExternalPages.SalesCertifications_Tab3_Step3_Statement'])}
                                                    </Paragraph>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Image src="images/sales-certifications/SalesCertBadges1600x200.png" />
                                    </Box>
                                </Box>
                            </Tab>
                        </Tabs>
                    </Box>}
                </BasePage>
            )
            }
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(SalesCertifications as any));
