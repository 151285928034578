import React from 'react';
import { Box, Text, BoxProps, Anchor, Card, CardBody, Image, Table, TableBody, TableRow, TableCell } from 'grommet';
import { EntityInfo } from '../models/Common';
import { formatString } from '../Utilities';
import { CertificationInfo } from '../models/Certification';

interface ISummaryCardProps {
    key: string;
    text?: string;
    image?: string;
    icon?: any
    description?: any;
    data?: EntityInfo | CertificationInfo;
    href?: string;
    linkLabel?: string;
    status?: any;
    cardBorder?: any;
    cardHeight?: string;
};

type SummaryCardProps = ISummaryCardProps & BoxProps;

export default class SummaryCard extends React.PureComponent<SummaryCardProps> {
    render() {
        let item = this.props.data;
        let localUrl = `${process.env.REACT_APP_MAIN_SITE}`;
        let registrationUrl = formatString(String(item?.registrationUrl), [localUrl]);
        let regTarget = registrationUrl.indexOf(localUrl) === -1
            ? "_blank"
            : "_self";
        let nameTarget = this.props.href?.indexOf(localUrl) === -1
            ? "_blank"
            : "_self";
        let hasImage = item?.digitalBadge ?? this.props.image;

        return <Box {...this.props}  >
            <Card height={{ min: this.props.cardHeight ? this.props.cardHeight : "xsmall" }} >
                <CardBody direction="row" pad="xxsmall" gap="small" >
                    <Table>
                        <TableBody>
                            <TableRow>
                                {(hasImage || this.props.icon) && <TableCell>
                                    <Box height="xsmall" width={this.props.icon ? "xxsmall" : "xsmall"} justify="center" align="center" >
                                        {this.props.icon && <Box align="center" justify="center" fill >{this.props.icon}</Box>}
                                        {hasImage && <Image fit="contain" src={item?.digitalBadge ?? this.props.image} alt={item?.name ?? this.props.text} />}
                                    </Box>
                                </TableCell>}
                                <TableCell>
                                    <Box gap="small" pad={{ right: "xxsmall" }} >
                                        {this.props.href && <Anchor size="small" href={this.props.href} target={nameTarget}>{item?.name ?? this.props.text}</Anchor>}
                                        {!this.props.href && <Text size="small" weight="bold">{item?.name ?? this.props.text}</Text>}
                                        {this.props.description && <Box fill>{this.props.description}</Box>}
                                        <Box direction="row" fill gap="small">
                                            {item?.registrationUrl && this.props.linkLabel && <Anchor size="small" href={registrationUrl} label={this.props.linkLabel} target={regTarget} />}
                                            {this.props.status}
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardBody>
            </Card>
        </Box>;

    }
}
