export interface DataRequest {
    id: string;
    language?: string;
    version?: number;
}

export interface KeyValuePair {
    key: string;
    value: any;
}

export interface SelectOption {
    selected: boolean;
    text: string;
    value: string;
}

export interface ListItem {
    id: string;
    name: string;
    programId: string;
    releaseStatus: string;
    technology: string;
}

export interface EntityInfo extends PublishedEntity{
    sabaId: string;
    externalId: string;
    hasDigitalBadge: boolean;
    digitalBadge: string;
    registrationUrl: string;
    technologies: string[];
    deliveryLanguages: string[];
    isNew:boolean;
    isCredential: boolean;
    isPerformanceBased: boolean;
    certificationType:KeyValuePair;
    deliveryFormats: string[];
}

export interface PublishedEntity {
    baseRegisterUrl: string;
    publicSiteUrl: string;
    trainingCalendarUrl: string;
    hpLearnerIdUrl: string;
    personVueUrl: string;
    learningCenterAssistKitUrl: string;
    learningCenterUrl: string;
    courseRegisterUrl: string;
    certificationRegisterUrl: string;
    curriculumRegisterUrl: string;
    entityType: string;
    programId: string;
    name: string;
    description: string;
    version: number;
    level: string;
    levelOrder: number;
    launchDate: Date;
    releaseStatus: KeyValuePair;
    originalLaunchDate: Date;
    isDeliveryReady: boolean;
    metadataKeywords: string;
    metadataDescription: string;
    countryCode: string;
    languageCode: string;
    type: KeyValuePair;
}

export interface RelatedItem {
    programId: string;
    modifiedProgramId: string;
    name: string;
    isActive: boolean;
    entityType: string;
    id: string;
    showOnWeb: boolean;
    isAvailableInLanguage: boolean;
}

export interface CourseDetailItem extends ListItem {
    duration: string;
    format: string;
    isRegister: boolean;
    registerUrl: string;
    isCurriculum: boolean;
    showOnWeb: boolean;
    modifiedProgramId: string;
    itemType: string;
    isActive: boolean;
}

export interface RecommendedTraining {
    key: string;
    value: CourseDetailItem[];
}

export interface RequiredItem {
    id: string;
    name: string;
    programId: string;
    isActive: boolean;
    showOnWeb: boolean;
    sequence: number;
    itemType: KeyValuePair;
    url: string;
    modifiedProgramId: string;
    recommendedTraining: RecommendedTraining[];
}

export interface RequirementItem {
    index: number;
    sequence: number;
    numberRequired: number;
    requiredItems: RequiredItem[];
    specialInstructions: string;
    condition: string;
}

export interface RequirementPath {
    name: string;
    sequence: number;
    requirements: RequirementItem[];
    prerequisites: RequirementItem[];
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export const AllOption = {
    selected: true,
    text: "Select/Deselect All",
    value: 'all'
}

export const AllGuidOption = {
    selected: true,
    text: "Select/Deselect All",
    value: EmptyGuid
}
