import React from 'react';
import { connect } from 'react-redux';
import { Box, Text, Image, Anchor, ResponsiveContext, AccordionPanel, Accordion, Paragraph } from 'grommet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../store/';
import { retrieveUpgradePath, upgradePathSlice } from './../../store/UpgradePath';
import parse from 'html-react-parser';
import { retrieveLocalizedStrings, siteSlice } from '../../store/Site';
import { UpgradePath as UpgradePathModel } from '../../models/UpgradePath';
import { KeyValuePair, RecommendedTraining, RequirementItem, RequirementPath } from '../../models/Common';
import { formatString } from '../../Utilities';
import BasePage from '../../components/BasePage';

const mapDispatchToProps = {
    retrieveUpgradePath,
    retrieveLocalizedStrings,
    ...upgradePathSlice.actions,
    ...siteSlice.actions
}

interface UpgradePathState {
    activePanel: number;
    activeSubPanel: number;
}

type UpgradePathProps =
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class UpgradePath extends React.PureComponent<UpgradePathProps, UpgradePathState> {
    constructor(props: UpgradePathProps) {
        super(props);
        this.state = {
            activePanel: 1,
            activeSubPanel: -1
        };
    }
    public componentDidMount() {
        let currentLang = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : null
            : null;
        let keys = ['Datacard.Digital_Badge_Label', 'Datacard.Digital_Badge_Statement',
            'Datacard.UpgradePath_NotAvailable', 'Datacard.Acquire_Statement', 'Datacard.Skill_Level_Label', 'Datacard.Candidate',
            'Datacard.Print_This_Page', 'ExternalPages.Certification_And_Learning_Label', 'Datacard.Upgrade_Details_Label',
            'Datacard.Berfore_You_Begin', 'Datacard.Upon_Completion_Label', 'Datacard.Requirement_Label',
            'Datacard.Paths_Available_For_This_Certification_Label', 'Datacard.Apply_For_This_Certification_Label', 'Datacard.Before_You_Begin_Statement',
            'Datacard.Typical_Customer_Label', 'Datacard.Summary_Label', 'Datacard.This_Certification_Enables_You_To_Label', 'Datacard.Certification_Achieved',
            'Datacard.Additional_Certification_Also_Achieved_Label', 'Datacard.Upgrading_From_A_Previous_Version_Label', 'Datacard.Transitioning_From_Non_HPE_Certifications_Label',
            'Datacard.Move_Up_From_A_Prerequisite_Label', 'Datacard.Earning_My_First_HPE_Certification_Label', 'Datacard.Must_Have_Label', 'Datacard.Must_Have_These_Label',
            'Datacard.Must_Have_N_Label', 'Datacard.No_Prerequisites_Statement', 'Datacard.Certification_Training_Statement', 'Datacard.OR_Label',
            'Datacard.Paths_Available_For_This_UpgradePath_Label', 'Datacard.Register_For_This_Label'];

        this.props.retrieveLocalizedStrings(keys, currentLang);
        this.props.retrieveUpgradePath(this.props.match.params.id, currentLang);
    }

    public componentDidUpdate(prevProps: UpgradePathProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = prevProps.site.currentLanguage;

        if (currentLang.value !== previousLang.value && !site.loading) {
            let urlLanguage = this.props.match
                ? this.props.match.params.lang
                    ? this.props.match.params.lang
                    : null
                : null;

            this.props.retrieveUpgradePath(this.props.match.params.id, urlLanguage);
        }
    }

    showDigitalBaage = () => {
        let badgeElement = document.getElementById('badge-statement');

        this.setState({ activePanel: 0, activeSubPanel: 2 });
        badgeElement?.scrollIntoView();
    }

    onPanelChange = (activePanels: number[]) => {
        if (activePanels.length !== 0) {
            this.setState({ activePanel: activePanels[0] });
        }
        else {
            this.setState({ activePanel: -1 });
        }
    }

    onSubPanelChange = (activeSubPanels: number[]) => {
        if (activeSubPanels.length !== 0) {
            this.setState({ activeSubPanel: activeSubPanels[0] });
        }
        else {
            this.setState({ activeSubPanel: -1 });
        }
    }

    renderPrerequisite = (prerequisite: RequirementItem) => {
        let site = this.props.site;

        return prerequisite.requiredItems.map((item, ndx) => {
            if (item.isActive) {
                let url = this.props.match.params.lang
                    ? `${process.env.REACT_APP_MAIN_SITE}/${this.props.match.params.lang}/datacard/${item.itemType.value}/${item.modifiedProgramId}`
                    : `${process.env.REACT_APP_MAIN_SITE}/datacard/${item.itemType.value}/${item.modifiedProgramId}`;

                if (item.itemType.value.indexOf('Other') !== -1) {
                    url = formatString(`${site.localizedStrings['General.ExternalSabaUrl']}`, [item.programId]);
                }
                return <Anchor href={url} label={item.name} key={`preq-${ndx}`} ></Anchor>
            }
            return <Text key={`preq-${ndx}`}>{parse(item.name)}</Text>
        })
    }

    renderRequirement = (requirement: RequirementItem) => {
        let site = this.props.site;

        return requirement.requiredItems.map((item, ndx) => {
            let url = item.url;
            let itemType = item.itemType.value;
            let recommendedTraining = item.recommendedTraining;

            if (itemType.indexOf('Other') !== -1) {
                url = formatString(`${site.localizedStrings['General.ExternalSabaUrl']}`, [item.programId]);
            }
            return <Box key={`req-${ndx}`} margin={{ bottom: "small" }} align="end" gap="small" pad={{ right: "xsmall" }}>
                <Box fill align="end">
                    <Text weight="bold">{item.name}</Text>
                    <Text>{`${itemType} ${item.programId}`}</Text>
                    {item.isActive && <Anchor href={url} label={formatString(site.localizedStrings['Datacard.Register_For_This_Label'], [itemType])}></Anchor>}
                </Box>
                {this.renderTraining(recommendedTraining)}
                {(requirement.numberRequired !== requirement.requiredItems.length) && (ndx < requirement.requiredItems.length - 1) && <Box>
                    <Text weight="bold">{site.localizedStrings['Datacard.OR_Label']}</Text>
                </Box>}
            </Box>
        })
    }

    renderRequirementPaths = (requirementPaths: RequirementPath[]) => {
        let site = this.props.site;

        return requirementPaths.map((path: RequirementPath, index) => <AccordionPanel key={`rpath-${path.sequence}`} label={`${path.name}`}>
            <Box pad={{ left: "medium" }}>
                {path.prerequisites.length === 0
                    ? <Paragraph>{site.localizedStrings['Datacard.No_Prerequisites_Statement']}</Paragraph>
                    : <Box pad={{ top: "small" }} key={`prereq-box-${index}`}>
                        {path.prerequisites.map((prerequisite, index) => {
                            return <Box key={`up-preq-box-${index}`}>
                                <Text>{prerequisite.condition}</Text>
                                <Box pad={{ left: "medium" }} >
                                    {this.renderPrerequisite(prerequisite)}
                                </Box>
                            </Box>
                        })}
                    </Box>}
                <Box width={{ max: "564px" }} >
                    {
                        path.requirements.map((requirement, index) => {
                            return <Box pad={{ top: "small" }} key={`req-box-${index}`}>
                                <Text weight="bold">{`${site.localizedStrings['Datacard.Requirement_Label']} ${index + 1} - ${requirement.condition}`}</Text>
                                <Box pad={{ left: "medium" }} border={{ side: "bottom", style: (path.requirements.length - 1) !== index ? "solid" : "hidden" }}>
                                    {this.renderRequirement(requirement)}
                                </Box>
                            </Box>
                        })
                    }
                </Box>
            </Box>
        </AccordionPanel>);
    }

    renderTraining = (recommendedTraining: RecommendedTraining[]) => {
        let details = recommendedTraining !== null ? recommendedTraining.filter(a => a.value.length !== 0) : [];
        let showOptionName = details.length > 1;
        let site = this.props.site;

        return details.length !== 0 && <Box align="end">
            <Text>{site.localizedStrings['Datacard.Certification_Training_Statement']}</Text>
            {
                recommendedTraining.map((training, tndx) => {
                    let items = training.value;

                    return items.map((item, dndx) => <Box key={`trn${tndx}-detail-${dndx}`} align="end">
                        {showOptionName && <Text >{training.key}</Text>}
                        <Text weight="bold">{item.name}</Text>
                        <Text>{`${item.itemType} ${item.programId}`}</Text>
                        {item.isActive && <Anchor href={item.registerUrl} label={formatString(site.localizedStrings['Datacard.Register_For_This_Label'], [item.itemType])}></Anchor>}
                    </Box>)
                })
            }
        </Box>
    }

    renderUpgradePath = (upgradePath: UpgradePathModel) => {
        let site = this.props.site;

        return <ResponsiveContext.Consumer>
            {size => (
                <Box fill >
                    {upgradePath.hasDigitalBadge &&
                        <Box fill="horizontal" >
                            <Anchor onClick={() => { this.showDigitalBaage(); }} alignSelf="center">
                                <Box height={size === "xsmall" ? "small" : "288px"} width={size === "xsmall" ? "small" : "288px"} pad={{ bottom: "small" }}>
                                    <Image fit="contain" src={upgradePath.digitalBadge} />
                                </Box>
                            </Anchor>
                        </Box>
                    }
                    <Box pad={{ top: "small" }} >
                        {parse(upgradePath.description)}
                    </Box>
                    <Accordion activeIndex={this.state.activePanel} onActive={activeIndexes => { this.onPanelChange(activeIndexes); }} >
                        <AccordionPanel label={site.localizedStrings['Datacard.Upgrade_Details_Label']}>
                            <Box pad={{ left: "medium" }}>
                                <Accordion activeIndex={this.state.activeSubPanel} onActive={activeIndexes => { this.onSubPanelChange(activeIndexes); }}>
                                    <AccordionPanel label={site.localizedStrings['Datacard.Berfore_You_Begin']}>
                                        <Box pad={{ left: "medium" }} gap="small">
                                            <Box>
                                                <Paragraph fill>
                                                    {parse(formatString(site.localizedStrings['Datacard.Acquire_Statement'],
                                                        [upgradePath.learningCenterUrl, upgradePath.hpLearnerIdUrl, upgradePath.learningCenterAssistKitUrl]))}
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                    <AccordionPanel label={site.localizedStrings['Datacard.Upon_Completion_Label']}>
                                        <Box pad={{ left: "medium" }} gap="small">
                                            <Box gap="none">
                                                {parse(upgradePath.certificationsAchieved)}
                                            </Box>
                                            {upgradePath.hasDigitalBadge && <Box>
                                                <Text weight='bold' size="small">{site.localizedStrings['Datacard.Digital_Badge_Label']}</Text>
                                                <Paragraph fill id="badge-statement">
                                                    {parse(site.localizedStrings['Datacard.Digital_Badge_Statement'])}
                                                </Paragraph>
                                            </Box>}
                                        </Box>
                                    </AccordionPanel>
                                </Accordion>
                            </Box>
                        </AccordionPanel>
                        <AccordionPanel label={site.localizedStrings['Datacard.Paths_Available_For_This_UpgradePath_Label']}>
                            <Box pad={{ left: "medium" }}>
                                <Accordion>
                                    {this.renderRequirementPaths(upgradePath.requirementPaths)}
                                </Accordion>
                            </Box>
                        </AccordionPanel>
                    </Accordion>
                </Box>
            )}
        </ResponsiveContext.Consumer>;
    }

    public render() {
        let upgradePath = this.props.upgradePath.data;
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.View_All_Certifications_Label'], value: `${localUrl}/certifications` },
            { key: upgradePath?.name, value: `datacard/course/${upgradePath?.programId}` }
        ];
        let printUrl = `${siteUrl}/datasheet/upgradePath/${upgradePath?.programId}?version=${upgradePath?.version}`;
        let title = upgradePath?.name ?? '';

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName={`datacard_${upgradePath?.programId}`} print={printUrl} {...this.props}
            metadataKeywords={upgradePath?.metadataKeywords} metadataDescription={upgradePath?.metadataDescription} >
            {upgradePath && <Box fill>
                {this.renderUpgradePath(upgradePath)}
            </Box>
            }
            {!this.props.site.loading && !upgradePath && <Box fill align="center" justify="center" height={{ min: "medium" }}>
                {parse(formatString(site.localizedStrings['Datacard.UpgradePath_NotAvailable'], [siteUrl]))}
            </Box>
            }
        </BasePage>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(UpgradePath  as any));
