import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { setLoading, setError, setMessage } from './Site';
import { Activity } from '../models/plmt/Activity';
import { Country } from '../models/Country';
import { SelectOption } from '../models/Common';
import { format } from 'date-fns';
import { Notification } from '../models/plmt/Notification';
import { GapSummary } from '../models/plmt/GapSummary';
import { SigningEntityInfo } from '../models/plmt/SigningEntityInfo';
import { AnalysisResponse } from '../models/plmt/AnalysisResponse';
import { PartyInfo } from '../models/plmt/PartyInfo';
import { BuilderInfo } from '../models/plmt/BuilderInfo';

export interface PlmtState {
    activities?: Activity[];
    countries?: Country[];
    signingEntities?: SigningEntityInfo[];
    parties?: PartyInfo[];
    notification?: Notification;
    gapSummaries?: GapSummary[];
    gapAnalysis?: AnalysisResponse;
    partnerTypes?: SelectOption[];
    distributors?: SelectOption[];
    builderInfo?: BuilderInfo;
}

let initialState: PlmtState = {
}

export const plmtSlice = createSlice({
    name: 'Plmt',
    initialState,
    reducers: {
        setActivities: (state, action) => {
            state.activities = action.payload;
        },
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setSigningEntities: (state, action) => {
            state.signingEntities = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        },
        setPartnerTypes: (state, action) => {
            state.partnerTypes = action.payload;
        },
        setGapSummaries: (state, action) => {
            state.gapSummaries = action.payload;
        },
        setGapAnalysis: (state, action) => {
            state.gapAnalysis = action.payload;
        },
        setParties: (state, action) => {
            state.parties = action.payload;
        },
        setDistributors: (state, action) => {
            state.distributors = action.payload;
        },
        setBuilderInfo: (state, action) => {
            state.builderInfo = action.payload;
        }
    }
});

export const { setActivities, setCountries, setSigningEntities, setNotification, setPartnerTypes, setGapSummaries,
    setGapAnalysis, setParties, setDistributors, setBuilderInfo } = plmtSlice.actions;

export const retrieveLearnerIds = (info: any): AppThunk => async (dispatch) => {
    let url = 'api/plmt/LookupLearnerIds';
    let data = new FormData();
    let filename = ';'

    if (info.emails) {
        data.append("idList", info.emails);
    }
    if (info.emailFile && info.emailFile.length !== 0) {
        let idFile = info.emailFile[0];

        data.append("importFile", idFile, idFile.name);
    }
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: data
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            filename = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then((blob: any) => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", filename);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const retrieveActivities = (request: any, callback?: Function): AppThunk => async (dispatch) => {
    let url = 'api/plmt/Activities';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        dispatch(setActivities(data));
        if (callback) {
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
        if (callback) {
            callback();
        }
    });
}

export const retrieveCountries = (): AppThunk => async (dispatch) => {
    let url = 'api/plmt/countries';
    let request = {
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setCountries(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setError(`${err}`));
        dispatch(setLoading(false));
    });
}

export const retrieveSigningEntities = (request: any): AppThunk => async (dispatch) => {
    let url = 'api/plmt/signingEntities';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setSigningEntities(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const retrieveParties = (request: any): AppThunk => async (dispatch, getState) => {
    let url = 'api/plmt/parties';
    let state = getState();

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setParties(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const retrieveNotification = (callback: Function): AppThunk => async (dispatch, getState) => {
    let url = 'api/plmt/notification';
    let state = getState();
    let request = {
        audience: (state.site.user?.email.toLowerCase().indexOf('hpe.com') !== -1) ? 'Internal' : 'External',
        language: state.site.currentLanguage.value
    };

    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        if (data) {
            callback(true);
        }
        dispatch(setNotification(data));
    }).catch(err => {
        dispatch(setError(`${err}`));
    });
}

export const exportActivities = (request: any, callback: Function): AppThunk => async (dispatch) => {
    let url = 'api/plmt/exportActivities';
    let currentTime = new Date();
    let fileName = `plmtActivities_${format(currentTime, 'yyyyMMddhhmmss')}.xlsx`;

    if (request.fileType === 'csv') {
        fileName = `plmtActivities_${format(currentTime, 'yyyyMMddhhmmss')}.zip`;
    }
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
        callback();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const exportAnalysis = (request: any): AppThunk => async (dispatch, getState) => {
    let url = 'api/plmt/exportGapAnalysis';
    let currentTime = new Date();
    let fileName = `plmtGapAnalysis_${format(currentTime, 'yyyyMMddhhmmss')}.xlsx`;
    let data = getState().plmt.gapAnalysis;

    if (request.fileType === 'csv') {
        fileName = `plmtGapAnalysis_${format(currentTime, 'yyyyMMddhhmmss')}.zip`;
    }
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json, application/zip, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    })
        .then(blob => {
            if (blob.message || blob.statusCode || blob.errors) {
                throw new Error(blob.message ?? blob);
            }

            let a = $("<a style='display: none;'/>");
            let url = window.URL.createObjectURL(blob);

            dispatch(setLoading(false));
            a.attr("href", url);
            a.attr("download", fileName);
            $("body").append(a);
            a[0].click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }).catch(err => {
            dispatch(setLoading(false));
            dispatch(setError(`${err}`));
        });
}

export const exportSummaries = (request: any): AppThunk => async (dispatch, getState) => {
    let url = 'api/plmt/exportGapSummary';
    let currentTime = new Date();
    let fileName = `plmtGapAnalysis_${format(currentTime, 'yyyyMMddhhmmss')}.xlsx`;

    if (request.fileType === 'csv') {
        fileName = `plmtGapAnalysis_${format(currentTime, 'yyyyMMddhhmmss')}.zip`;
    }
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const retrieveGapSummary = (request: any): AppThunk => async (dispatch) => {
    let url = 'api/plmt/gapSummary';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setGapSummaries(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const retrieveGapAnalysis = (request: any): AppThunk => async (dispatch) => {
    let url = 'api/plmt/gapAnalysis';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setGapAnalysis(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const retrieveDistributors = (request: any, callback?: Function): AppThunk => async (dispatch) => {
    let url = 'api/plmt/Distributors';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        dispatch(setDistributors(data));
        if (callback) {
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
        if (callback) {
            callback();
        }
    });
}

export const saveDistributors = (request: any, callback?: Function): AppThunk => async (dispatch) => {
    let url = 'api/plmt/saveDistributors';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        dispatch(setDistributors(data));
        if (callback) {
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
        if (callback) {
            callback();
        }
    });
}

export const loadBuilderInfo = (request: any, callback?: Function): AppThunk => async (dispatch) => {
    let url = 'api/plmt/builderInfo';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return (res.status == 204)
            ? null
            : res.json();
    }).then(data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setBuilderInfo(data));
        dispatch(setLoading(false));
        if (callback) {
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export const uploaduilder = (builderFile: any): AppThunk => async (dispatch, getState) => {
    let url = 'api/plmt/loadBuilder';
    let state = getState();
    let data = new FormData();
    let user = state.site.user;
    let region = state.plmt.builderInfo?.region ?? "";

    dispatch(setLoading(true));
    data.append("region", region);
    data.append("uploadedBy", user ? `${user?.firstName} ${user?.lastName};${user?.email}` : "");
    data.append("builderFile", builderFile, builderFile.name);
    fetch(url, {
        method: "POST",
        body: data
    }).then(res => {
        if (!res.ok) {
            return res.json();
        }
    }).then(async data => {
        if (data?.statusCode) {
            throw Error(data.message);
        }
        dispatch(setLoading(false));
        dispatch(setMessage('Processing of the uploaded builder has been initiated.  An email will be sent to those in the distribution list with the results.'));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const saveBuilderInfo = (builderInfo: any, callback?: Function): AppThunk => async (dispatch) => {
    let url = 'api/plmt/saveBuilderInfo';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(builderInfo),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        dispatch(setBuilderInfo(data));
        dispatch(setLoading(false));
        if (callback) {
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const downloadBuilder = (request: any): AppThunk => async (dispatch) => {
    let url = 'api/plmt/downloadBuilder';
    let currentTime = new Date();
    let fileName = `plmtBuilder_${request.region}_${format(currentTime, 'yyyyMMddhhmmss')}.xlsx`;

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            Accept: "application/json, application/zip, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then(blob => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(`${err}`));
    });
}

export default plmtSlice.reducer;
