import React from 'react';
import { Menu } from 'grommet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { retrieveAvailableLanguages, retrieveMenuStrings, retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { ApplicationState } from '../store';
import { SelectOption } from '../models/Common';
import { push } from 'connected-react-router';
import { setupMetrics } from '../Utilities'
import _ from 'lodash';

const mapDispatchToProps = {
    push,
    retrieveAvailableLanguages,
    retrieveMenuStrings,
    retrieveLocalizedStrings,
    ...siteSlice.actions
}

interface ILanguageMenuProps {
}

type LanguageMenuProps =
    ApplicationState // ... state we've requested from the Redux store
    & ILanguageMenuProps
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

interface ILanguageMenuState {
}

class LanguageMenu extends React.PureComponent<LanguageMenuProps, ILanguageMenuState> {

    onLanguageChange = (lang: SelectOption) => {
        let site = this.props.site;
        let location = window.location;
        let currentPath = _.replace(location.pathname, /\/tr/i, '');
        let pathInfo = currentPath.split('/');
        let langInfo = lang.value.split('-');
        let urlLanguage = pathInfo.length >= 2 ? pathInfo[1] : pathInfo[0];
        let selectedLang = site.availableLanguages
            ? _.find(site.availableLanguages, (av) => {
                return av.value.startsWith(urlLanguage);
            })
            : null;
        let basePath = (selectedLang ? currentPath.replace(`${urlLanguage}`, '') : currentPath).replace('//', '/');
        let newPath: any = lang.value === 'en-US'
            ? `${basePath}`
            : `/${langInfo[0]}${basePath}`;

        if (currentPath !== newPath) {
            let localizedStrings = Object.keys(site.localizedStrings);
            let menuStrings = Object.keys(site.menuStrings);

            this.props.setCurrentLanguage(lang);
            this.props.retrieveMenuStrings(menuStrings, langInfo[0]);
            this.props.retrieveLocalizedStrings(localizedStrings, langInfo[0]);
            this.props.push(newPath, this.props.site);

            setupMetrics(site.page, langInfo[0], langInfo[1]);
        }
    }

    public render() {
        let site = this.props.site;
        let langItems = site.availableLanguages
            ? _.map(site.availableLanguages, (av, index) => {
                return { label: `${av.text}`, onClick: () => { this.onLanguageChange(av); } }
            })
            : [];

        return <Menu key="sitelanguage-menu" label={site.currentLanguage.text} size='small' items={langItems} />;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(LanguageMenu as any));
