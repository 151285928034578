import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import {
    Box, Paragraph, Image, Heading, Text, Card, CardHeader, CardBody,
    Tabs, Tab, Anchor, Header, Layer, Footer, Button
} from 'grommet';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import HtmlComponent from '../components/HtmlComponent';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    ...siteSlice.actions,
}

interface IGetStartedProps {
}

interface GetStartedState {
    activeTab: number;
    handlers: number[];
    showEmployeeWarning: boolean;
}

type GetStartedProps = IGetStartedProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class GetStarted extends React.PureComponent<GetStartedProps, GetStartedState> {
    constructor(props: GetStartedProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : null
            : null;
        let activeTab = 0;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.GetStartedKeywords,', 'Metadata.GetStartedDescription',
            'Datacard.HPE_Learner_ID_Label', 'ExternalPages.GetStarted_Intro_Statement',
            'ExternalPages.GetStarted_LearnerId_Card_1_Title', 'ExternalPages.GetStarted_LearnerId_Card_2_Title',
            'ExternalPages.GetStarted_LearnerId_Card_3_Title', 'ExternalPages.GetStarted_LearnerId_Card_1_Body',
            'ExternalPages.GetStarted_LearnerId_Card_1_Body_Bullet_1', 'ExternalPages.GetStarted_LearnerId_Card_1_Body_Bullet_2',
            'ExternalPages.GetStarted_LearnerId_Card_1_Body_Bullet_3', 'ExternalPages.GetStarted_LearnerId_Card_2_Body_Bullet_1',
            'ExternalPages.GetStarted_LearnerId_Card_2_Body_Bullet_2', 'ExternalPages.Hpe_Employee_Label', 'ExternalPages.GetStarted_Customer_Label',
            'ExternalPages.Hpe_Partner_Label', 'ExternalPages.GetStarted_LearnerId_Card_3_Body_Bullet_1',
            'ExternalPages.GetStarted_LearnerId_Card_3_Body_Bullet_2', 'ExternalPages.GetStarted_Partner_Statement',
            'ExternalPages.GetStarted_Partner_Step1', 'ExternalPages.GetStarted_Partner_Step2',
            'ExternalPages.GetStarted_Partner_Step1_Bullet1', 'ExternalPages.GetStarted_Partner_Step1_Bullet2',
            'ExternalPages.GetStarted_Partner_Step1_Bullet3', 'ExternalPages.GetStarted_Partner_Step2_Bullet1',
            'ExternalPages.GetStarted_Partner_Step2_Bullet2', 'ExternalPages.GetStarted_Partner_Step2_Bullet3',
            'ExternalPages.HPE_Partner_Habits', 'ExternalPages.Partner_Program_label', 'ExternalPages.Servie_Qualification_Label',
            'ExternalPages.Get_Help_Label', 'ExternalPages.Contact_Our_Support_Label', 'ExternalPages.Get_Most_Label',
            'ExternalPages.GetStarted_Customer_Statement', 'ExternalPages.GetStarted_Customer_Step1_Bullet1',
            'ExternalPages.GetStarted_Customer_Step1_Bullet2', 'ExternalPages.GetStarted_Customer_Step1_Bullet3',
            'ExternalPages.GetStarted_Customer_Step1_Bullet4', 'ExternalPages.GetStarted_Customer_Step1_Bullet5',
            'ExternalPages.GetStarted_Customer_Step1_Bullet6', 'ExternalPages.GetStarted_Customer_Step2_Bullet3',
            'ExternalPages.GetStarted_Employee_Step','ExternalPages.GetStarted_PeopleCare_Link',
            'ExternalPages.GetStarted_Employee_Step_Bullet1', 'ExternalPages.GetStarted_Employee_Step_Bullet2',
            'ExternalPages.GetStarted_Employee_Step_Bullet3', 'ExternalPages.Ask_HR_Label', 'ExternalPages.Aruba_Partner_Habits',
            'ExternalPages.Empower_Customers', 'ExternalPages.No_Label', 'ExternalPages.Yes_Label', 'ExternalPages.Review_Partner_Guide_Link',
            'ExternalPages.EmployeeCertification_Warning_Statement', 'Datacard.View_HPE_Certification_Label'];

        switch (tab) {
            case 'partners':
                activeTab = 1;
                break;
            case 'customers':
                activeTab = 2;
                break;
            case 'employees':
                activeTab = 3;
                break;
        }
        this.state = {
            activeTab: activeTab,
            handlers: [],
            showEmployeeWarning: false
        };
        this.onActivateTab(activeTab);
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('getstarted-menu');
        this.props.setLoading(true);
    }

    public componentDidMount() {
        this.props.setLoading(false);
    }

    onActivateTab = (activeTab: number) => {
        let handlers = [...this.state.handlers];

        this.setState({ activeTab: activeTab });
        switch (activeTab) {
            case 1:
                this.props.push('/getstarted/partners')
                if (_.indexOf(handlers, activeTab) == -1) {
                    setTimeout(() => {
                        handlers.push(activeTab);
                        jQuery("#step2-anchor").on('click', this.gotoStep2);
                        jQuery(".learner-link").on('click', this.gotoLearnerTab);
                        this.setState({ handlers: handlers });
                    }, 1000);
                }
                break;
            case 2:
                this.props.push('/getstarted/customers')
                if (_.indexOf(handlers, activeTab) === -1) {
                    setTimeout(() => {
                        handlers.push(activeTab);
                        jQuery(".learner-link").on('click', this.gotoLearnerTab);
                        this.setState({ handlers: handlers });
                    }, 1000);
                }
                break;
            case 3:
                this.props.push('/getstarted/employees')
                if (_.indexOf(handlers, activeTab) === -1) {
                    setTimeout(() => {
                        handlers.push(activeTab);
                        jQuery("#employee-cert-link").on('click', () => { this.setState({ showEmployeeWarning: true }) });
                        this.setState({ handlers: handlers });
                    }, 1000);
                }
                break;
            default: {
                this.props.push('/getstarted')
                break;
            }
        }
    }

    gotoLearnerTab = () => {
        this.setState({ activeTab: 0 });
    }

    gotoStep2 = () => {
        let step2 = document.getElementById('step2');

        step2?.scrollIntoView();
    }

    openEmployeeSite = () => {
        let employeeUrl = `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications`;

        this.setState({ showEmployeeWarning: false });
        window.open(employeeUrl, "_blank");
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let siteUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let employeeUrl = `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications`;
        let title = site.menuStrings['Datacard.Get_Started_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: '/' },
            { key: title, value: "" }
        ];

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="Get_started" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.HomePageKeywords']}
            metadataDescription={site.localizedStrings['Metadata.HomePageDescription']} >
            {this.state.showEmployeeWarning && <Layer>
                <Box width={{ max: "large" }} height={{ min: "small" }}>
                    <Header background="brand" round={{ "size": "small", "corner": "top" }}>
                        <Box fill="horizontal">
                            <Text textAlign="center" color="white">{site.localizedStrings['Datacard.View_HPE_Certification_Label']}</Text>
                        </Box>
                    </Header>
                    <Box pad="small" height="small" alignContent="center">
                        <Paragraph fill textAlign="center">
                            {parse(site.localizedStrings['ExternalPages.EmployeeCertification_Warning_Statement'])}
                        </Paragraph>
                    </Box>
                    <Footer fill="horizontal" pad="small" justify="end">
                        <Box direction="row" gap="small" align="end">
                            <Button primary label={site.localizedStrings['ExternalPages.Yes_Label']}
                                onClick={this.openEmployeeSite} />
                            <Button secondary label={site.localizedStrings['ExternalPages.No_Label']}
                                onClick={() => { this.setState({ showEmployeeWarning: false }) }} />
                        </Box>
                    </Footer>
                </Box>
            </Layer>}
            {site.stringsLoaded && <Box gap="medium">
                <Box gap="xsmall">
                    <Box fill="horizontal">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                    </Box>
                </Box>
                <Box alignSelf="center" >
                    <Paragraph fill>
                        {site.localizedStrings['ExternalPages.GetStarted_Intro_Statement']}
                    </Paragraph>
                </Box>
                <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="center">
                    <Tab title={String(site.localizedStrings['Datacard.HPE_Learner_ID_Label'])} >
                        <Box fill gap="medium" pad={{ top: "small", bottom: "medium" }}>
                            <Box direction="row" background="#F5F5F5" gap="medium" justify="center" pad="small" margin={{ bottom: "small" }}>
                                <Card width="medium" round={false}>
                                    <CardHeader>
                                        <Image fit="contain" src="images/get-started/GettyImages-1361878977_1600_0_72_RGB.jpg" />
                                    </CardHeader>
                                    <CardBody gap="small">
                                        <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_1_Title']}</Text>
                                        <Box gap="small">
                                            <Text>{site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_1_Body']}</Text>
                                            <Anchor onClick={() => { this.setState({ activeTab: 1 }); }}
                                                label={site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_1_Body_Bullet_1']} />
                                            <Anchor onClick={() => { this.setState({ activeTab: 2 }); }}
                                                label={site.localizedStrings['ExternalPages.GetStarted_Customer_Label']} />
                                            <Anchor onClick={() => { this.setState({ activeTab: 3 }); }}
                                                label={site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_1_Body_Bullet_3']} />
                                        </Box>
                                    </CardBody>
                                </Card>
                                <Card width="medium" round={false}>
                                    <CardHeader>
                                        <Image fit="contain" src="images/get-started/GettyImages-1339031914_1600_0_72_RGB.jpg" />
                                    </CardHeader>
                                    <CardBody gap="small">
                                        <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_2_Title']}</Text>
                                        <Box gap="small">
                                            <Text>{site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_2_Body']}</Text>
                                            <Text>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_2_Body_Bullet_1'],
                                                [`${localUrl}/locate-learnerId`]))}</Text>
                                        </Box>
                                    </CardBody>
                                </Card>
                                <Card width="medium" round={false}>
                                    <CardHeader>
                                        <Image fit="contain" src="images/get-started/GettyImages-1361878979_1600_0_72_RGB.jpg" />
                                    </CardHeader>
                                    <CardBody gap="small">
                                        <Text weight="bold" size="large">{site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_3_Title']}</Text>
                                        <Box gap="small">
                                            <Text>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_LearnerId_Card_3_Body_Bullet_1'],
                                                [`${localUrl}/assistKit`]))}</Text>
                                        </Box>
                                    </CardBody>
                                </Card>
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title={String(site.localizedStrings['ExternalPages.Hpe_Partner_Label'])} >
                        <Box fill gap="xlarge" pad={{ top: "medium", bottom: "medium" }}>
                            <Box direction="row-responsive" fill gap="large" pad={{ top: "medium", left: "small" }} >
                                <Box fill alignSelf="center">
                                    <Paragraph fill size="large">
                                        {parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Partner_Statement'],
                                            [`${localUrl}/assistKit`, `${localUrl}/locate-learnerId`, 'https://www.hpe.com/psnow/doc/4aa4-2093enw',
                                            `${localUrl}/support`]))}
                                    </Paragraph>
                                </Box>
                                <Box fill alignSelf="center">
                                    <Image fit="contain" src="images/get-started/Stocksy_927552.jpg" />
                                </Box>
                            </Box>
                            <Box direction="row-responsive" fill="horizontal" gap="large" >
                                <Box gap="small" fill>
                                    <Image fit="contain" src="images/get-started/HPE_story_13.jpg" />
                                    <Box gap="small">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.GetStarted_Partner_Step1']}</Heading>
                                        <Box>
                                            <ul className="bullet-list">
                                                <li>{parse(site.localizedStrings['ExternalPages.GetStarted_Partner_Step1_Bullet1'])}</li>
                                                <li>{site.localizedStrings['ExternalPages.GetStarted_Partner_Step1_Bullet2']}</li>
                                                <li>{parse(site.localizedStrings['ExternalPages.GetStarted_Partner_Step1_Bullet3'])}</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box id="step2" gap="small" fill>
                                    <Image fit="contain" src="images/get-started/GettyImages_story_231.jpg" />
                                    <Box fill gap="small">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.GetStarted_Partner_Step2']}</Heading>
                                        <Box>
                                            <ul className="bullet-list">
                                                <li>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Partner_Step2_Bullet1'], [localUrl]))}</li>
                                                <li>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Partner_Step2_Bullet2'], [localUrl]))}</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title={String(site.localizedStrings['ExternalPages.GetStarted_Customer_Label'])} >
                        <Box fill gap="large" pad={{ bottom: "medium" }}>
                            <Box direction="row-responsive" fill gap="large" pad={{ top: "medium", left: "small" }} >
                                <Box fill pad={{ top: "small" }} alignSelf="center">
                                    <Paragraph fill size='large'>
                                        <HtmlComponent html={formatString(site.localizedStrings['ExternalPages.GetStarted_Customer_Statement'],
                                            [`${localUrl}/assistKit`, `${localUrl}/locate-learnerId`, `${localUrl}/documents/external/4AA4-2093.pdf`,
                                            `${localUrl}/support`])} />
                                    </Paragraph>
                                </Box>
                                <Box fill alignSelf="center">
                                    <Image fit="contain" src="images/get-started/GettyImages-1344913245.jpg" />
                                </Box>
                            </Box>
                            <Box direction="row-responsive" fill="horizontal" gap="large" >
                                <Box gap="small" fill>
                                    <Box fill>
                                        <Image fit="contain" src="images/get-started/GettyImages_story_232.jpg" />
                                    </Box>
                                    <Box fill gap="medium">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.GetStarted_Partner_Step1']}</Heading>
                                        <ul className="bullet-list">
                                            <li>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Customer_Step1_Bullet1'],
                                                [`${localUrl}/unaffiliated-learner-reg`]))}</li>
                                            <li>{site.localizedStrings['ExternalPages.GetStarted_Customer_Step1_Bullet2']}</li>
                                            <li>{site.localizedStrings['ExternalPages.GetStarted_Customer_Step1_Bullet3']}</li>
                                            <li>{site.localizedStrings['ExternalPages.GetStarted_Customer_Step1_Bullet4']}</li>
                                            <li>{site.localizedStrings['ExternalPages.GetStarted_Customer_Step1_Bullet5']}</li>
                                            <li>{site.localizedStrings['ExternalPages.GetStarted_Customer_Step1_Bullet6']}</li>
                                        </ul>
                                    </Box>
                                </Box>
                                <Box id="step2" gap="small" fill>
                                    <Box fill>
                                        <Image fit="contain" src="images/get-started/HPE_story_19.jpg" />
                                    </Box>
                                    <Box fill gap="medium">
                                        <Heading level="3">{site.localizedStrings['ExternalPages.GetStarted_Partner_Step2']}</Heading>
                                        <Box>
                                            <ul className="bullet-list">
                                                <li>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Partner_Step2_Bullet1'], [localUrl]))}</li>
                                                <li>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Partner_Step2_Bullet2'], [localUrl]))}</li>
                                                <li>{parse(site.localizedStrings['ExternalPages.GetStarted_Customer_Step2_Bullet3'])}</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title={String(site.localizedStrings['ExternalPages.Hpe_Employee_Label'])} >
                        <Box direction="row-responsive" fill gap="large" pad={{ top: "medium", left: "small" }} >
                            <Box fill pad="small" alignSelf="center">
                                <Paragraph size="large" fill>
                                    <ul className="bullet-list">
                                        <li>{parse(formatString(site.localizedStrings['ExternalPages.GetStarted_Employee_Step_Bullet1'], [employeeUrl]))}</li>
                                        <li>{parse(site.localizedStrings['ExternalPages.GetStarted_Employee_Step_Bullet3'])}</li>
                                        <li>{parse(site.localizedStrings['ExternalPages.GetStarted_PeopleCare_Link'])}</li>
                                    </ul>
                                </Paragraph>
                            </Box>
                            <Box fill alignSelf="center">
                                <Image fit="contain" src="images/get-started/GettyImages-1388797636.jpg" />
                            </Box>
                        </Box>
                    </Tab>
                </Tabs>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(GetStarted as any));
