import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '.';
import { SubjectMaterExpert } from '../models/SubjectMaterExpert';
import { SmeInfo } from '../models/SmeInfo';
import { setLoading, setError } from './Site';

export interface SmeState {
    currentInfo?: SmeInfo;
    current?: SubjectMaterExpert;
    results: SmeInfo[];
}

export const initialState: SmeState = {
    results: []
}

export const smeSlice = createSlice({
    name: 'Sme',
    initialState,
    reducers: {
        setCurrentInfo: (state, action) => {
            state.currentInfo = action.payload;
        },
        setCurrent: (state, action) => {
            state.current = action.payload;
        },
        setResults: (state, action) => {
            state.results = action.payload;
        }
    }
});

export const { setResults, setCurrent, setCurrentInfo } = smeSlice.actions;

export const serachExperts = (keywords: string): AppThunk => async (dispatch) => {
    let url = 'api/sme/search';
    let request = {
        keywords
    };

    dispatch(setLoading(true));
    dispatch(setCurrentInfo(undefined));
    dispatch(setResults([]));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        dispatch(setResults(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const downloadReport = (): AppThunk => async (dispatch) => {
    let url = 'api/sme/downloadReport';
    let request = {
    }
    let filename = '';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            filename = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then((blob: any) => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", filename);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const saveUpdates = (updates: SmeInfo[], keywords: string): AppThunk => async (dispatch) => {
    let url = 'api/sme/saveUpdates';
    let request = {
        updates,
        keywords
    }

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        dispatch(setCurrent(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const save = (sme: any, callback?: Function): AppThunk => async (dispatch, getState) => {
    let url = 'api/sme/save';
    let state = getState();

    sme.lastUpdatedBy = `${state.site.user?.firstName} ${state.site.user?.lastName}`;
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(sme),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        dispatch(setCurrent(data));
        dispatch(setLoading(false));
        if (callback) {
            callback();
        }
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const retrieve = (smeId: string): AppThunk => async (dispatch, getState) => {
    let url = 'api/sme/Retrieve';
    let state = getState();
    let request = (/^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/).test(smeId)
        ? {
            id: smeId,
            firstName: state.site.user?.firstName ?? state.site.userInfo?.given_name ?? '',
            lastName: state.site.user?.lastName ?? state.site.userInfo?.family_name ?? ''
        }
        : {
            email: state.site.user?.email ?? state.site.userInfo?.email,
            firstName: state.site.user?.firstName ?? state.site.userInfo?.given_name ?? '',
            lastName: state.site.user?.lastName ?? state.site.userInfo?.family_name ?? ''
        }
    if (!smeId) {
        return;
    }
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(response => {
        return response.json();
    }).then(data => {
        if (data.statusCode || data.errors) {
            throw Error(data.message ?? data.errors);
        }
        dispatch(setCurrent(data));
        dispatch(setLoading(false));
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export const downloadAgreement = (sme: SubjectMaterExpert, callback: Function): AppThunk => async (dispatch) => {
    let url = 'api/sme/getAgreement';
    let fileName = '';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(sme),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then((blob: any) => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
        callback();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const viewAgreement = (): AppThunk => async (dispatch, getState) => {
    let url = 'api/sme/viewAgreement';
    let state = getState();
    let sme = state.sme.current;
    let fileName = '';

    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: JSON.stringify(sme),
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        if (res.ok) {
            let header = res.headers.get('Content-Disposition');
            let parts = header!.split(';');

            fileName = parts[1].split('=')[1];
            return res.blob();
        }
        return res.json();
    }).then((blob: any) => {
        if (blob.message || blob.statusCode || blob.errors) {
            throw new Error(blob.message ?? blob);
        }

        let a = $("<a style='display: none;'/>");
        let url = window.URL.createObjectURL(blob);

        dispatch(setLoading(false));
        a.attr("href", url);
        a.attr("download", fileName);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message ?? err));
    });
}

export const verifyAgreement = (importFile: any, sme: any, callback: Function): AppThunk => async (dispatch) => {
    let url = 'api/sme/verifyAgreement';
    let data = new FormData();
    let request = JSON.stringify(sme);

    data.append("expert", request);
    data.append("importFile", importFile, importFile.name);
    dispatch(setLoading(true));
    fetch(url, {
        method: "POST",
        body: data
    }).then(response => {
        return (response.status == 204)
            ? null
            : response.json();
    }).then(data => {
        if (data.statusCode) {
            throw Error(data.message);
        }
        // dispatch(setCurrent(data));
        dispatch(setLoading(false));
        callback(data);
    }).catch(err => {
        dispatch(setLoading(false));
        dispatch(setError(err.message));
    });
}

export default smeSlice.reducer;
